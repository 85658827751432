let desserts = [

    {
        name: "CAFFEINE TOAST",
        desciption: "TOASTED BANANA BREAD, COFFEE BUTTER",
        price_en: "21",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-01-caffeine_toast.jpg",
    },

    {
        name: "ORGANIC CHIA SEEDS COOKIES",
        desciption: "ORGANIC BLACK CHIA, MILLED FLAXSEED, PUMPKIN SEED, BRAZIL NUTS, ALMONDS",
        price_en: "9",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-02-organic_chia_seeds_cookies.jpg",
    },

    {
        name: "CHOCOLATE PEANUT BUTTER",
        desciption: "CHOCOLATE GRAHAM BISCUIT, PEANUTS BUTTER CREAM, MILK CHOCOLATE AND DARK CHOCOLATE",
        price_en: "32",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-03-chocolate_peanut_butter.jpg",
    },

    {
        name: "CLOUD",
        desciption: "BAKED CHEESECAKE, CRUSHED COOKIES, VANILLA SOUR CREAM, ICING SUGAR",
        price_en: "29",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-04-cloud.jpg",
    },

    {
        name: "RASPBERRY JAM AND MIXED NUTS COOKIES",
        desciption: "DRIED BLUEBERRY, CARAMELIZED ALMONDS, RAW SUGAR, FLEUR DE SEL, RASPBERRY JAM",
        price_en: "11",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-05-raspberry_jam_and_mixed_nuts_cookies.jpg",
    },

    {
        name: "ENERGY BALL",
        desciption: "MEJDOL DATES, CACAO POWDER, ROLLED OATS, NATURAL HONEY, RAW CASHEWS AND ALMOND",
        price_en: "14",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-06-energy_ball.jpg",
    },

    {
        name: "WHITE SESAME COOKIES",
        desciption: "WHITE SESAME, ALMOND FLOUR, UNSALTED BUTTER, MALDON SALT",
        price_en: "7",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-07-white_sesame_cookies.jpg",
    },

    {
        name: "BROWNIE BROWNIE",
        desciption: "LURPAK BUTTER, DARK BROWN SUGAR, FRESH EGG, DARK CHOCOLATE, CACOA POWDER, ALL PURPOSE FLOUR, SALTED CARAMEL SAUCE",
        price_en: "23",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-08-brownie_brownie.jpg",
    },

    {
        name: "SPINACH DANISH",
        desciption: "BABY SPINACH, FETA CHEESE, PHILADELPHIA CHEESE, CAULIFLOWER",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-09-spinach_danish.jpg",
    },

    {
        name: "TOMATO DANISH",
        desciption: "CHERRY TOMATO, TOMATO SAUCE, MOZZARELLA CHEESE, OREGANO",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-10-tomato_danish.jpg",
    },

    {
        name: "STRAWBERRY DANISH",
        desciption: "PASTRY CREAM, STRAWBERRY JAM, FRESH STRAWBERRY",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-11-strawberry_danish.jpg",
    },

    {
        name: "VANILLA FLAN",
        desciption: "FRESHLY BAKED CUSTARD WITH VANILLA FLAVOR, CARAMELIZED SUGAR",
        price_en: "35",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-12-vanilla_flan.jpg",
    },

    {
        name: "ZAATAR CROISSANT",
        desciption: "ZAATAR POWDER, OLIVE OIL",
        price_en: "20",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-13-zaatar_croissant.jpg",
    },

    {
        name: "FORKFUL OF JOY STRAWBERRY",
        desciption: "VANILLA SPONGE CAKE, STRAWBERRIES, PASTRY CREAM",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-14-forkful_of_joy_strawberry.jpg",
    },

    {
        name: "FORKFUL OF JOY BLUEBERRY",
        desciption: "VANILLA SPONGE CAKE, BLUEBERRIES, PASTRY CREAM",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-15-forkful_of_joy_blueberry.jpg",
    },

    {
        name: "BANANA BREAD PEANUT BUTTER",
        desciption: "BANANA BREAD WITH PEANUT BUTTER",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-16-banana_bread_peanut_butter.jpg",
    },
]

let dessertsAr = [

    {
        name_ar: "كوكيز الشيا العضوية ",
        description_ar: "شيا عضوية سوداء، بذور الكتان المطحون، بذور اليقطين، مكسرات برازيلية، لوز",
        price_en: "9",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-02-organic_chia_seeds_cookies.jpg",
    },

    {
        name_ar: "كافيين توست ",
        description_ar: "خبز الموز المحمص، زبدة القهوة",
        price_en: "21",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-01-caffeine_toast.jpg",
    },

    {
        name_ar: "كلاود ",
        description_ar: "تشيز كيك مخبوز، كوكيز مهروسة، الكريمة الحامضة، بودرة السكر",
        price_en: "29",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-04-cloud.jpg",
    },

    {
        name_ar: "شكولاته بينت بتر ",
        description_ar: "سكويت جراهام بالشوكولاتة، كريمة زبدة الفول السوداني، شوكولاتة بالحليب وشوكولاتة داكنة",
        price_en: "32",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-03-chocolate_peanut_butter.jpg",
    },

    {
        name_ar: "كرة الطاقة ",
        description_ar: "تمر المجهول، بودرة الكاكاو، شوفان، عسل طبيعي، كاجو ولوز نيئ",
        price_en: "14",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-06-energy_ball.jpg",
    },

    {
        name_ar: "كوكيز مربى التوت مع المكسرات ",
        description_ar: "توت مجفف، لوز مكرمل، سكر خام، تفلور دي سيل، مربى التوت",
        price_en: "11",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-05-raspberry_jam_and_mixed_nuts_cookies.jpg",
    },

    {
        name_ar: "بروني بروني ",
        description_ar: "زبدة لورباك، سكر بني غامق، بيض طازج، شوكولاتة داكنة، مسحوق كاكاو، دقيق لجميع الأغراض، صلصة كراميل مملحة",
        price_en: "23",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-08-brownie_brownie.jpg",
    },

    {
        name_ar: "كوكيز السمسم الابيض ",
        description_ar: "سمسم ابيض، طحين اللوز، زبده غير مملحة، ملح مالدون        ",
        price_en: "7",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-07-white_sesame_cookies.jpg",
    },

    {
        name_ar: "دانش الطماطم ",
        description_ar: "طماطم كرزية، صلصة طماطم، جبنة موزاريلا، أعشاب عضوية",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-10-tomato_danish.jpg",
    },

    {
        name_ar: "دانش السبانخ ",
        description_ar: "سبانخ صغيرة، جبنة فيتا، جبنة فيلادلفيا، قرنبيط",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-09-spinach_danish.jpg",
    },

    {
        name_ar: "فانيلا فلان ",
        description_ar: "كاسترد طازج بنكهة الفانيليا، سكر مكرمل",
        price_en: "35",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-12-vanilla_flan.jpg",
    },

    { 
        name_ar: "دانش الفراولة ",
        description_ar: "كريم المعجنات، مربى الفراولة، والفراولة الطازجة",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-11-strawberry_danish.jpg",
    },

    {
        name_ar: "فوركفل اوف جوي الفراولة ",
        description_ar: "كيكة فانيلا سبونج، فراولة، كريمة المعجنات",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-14-forkful_of_joy_strawberry.jpg",
    },

    {
        name_ar: "كرواسون الزعتر ",
        description_ar: "مسحوق زعتر، زيت زيتون",
        price_en: "20",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-13-zaatar_croissant.jpg",
    },

    {
        name_ar: "خبز الموز مع زبدة الفول السوداني ",
        description_ar: "خبز الموز مع زبدة الفول السوداني",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-16-banana_bread_peanut_butter.jpg",
    },

    {
        name_ar: "فوركفل اوف جوي التوت الأزرق ",
        description_ar: "كيكة فانيلا سبونج، التوت الأزرق، كريمة المعجنات",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/dessert/Dessert-15-forkful_of_joy_blueberry.jpg",
    },


]

export  {
    desserts,
    dessertsAr
}