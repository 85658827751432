import styled from "styled-components";
import { FillerContainerStyled, CategoryHeaderStyled, ItemContainerStyled, ItemEnglishTitleStyled, ItemImageContainerStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, CategoryContainerStyled, ArabicContainerStyled, ItemArabicTitleStyled, ItemArabicDescriptionStyled } from "../lbMenu/CommonStyled";
import {cakes} from "../../../data/lbCategories/a-la-carte/menuItems";

function BreakFastToastsEv({}) {
    return (
        <>
        <CategoryContainerStyled>
            <CategoryHeaderStyled>
                <span>Toasts</span>
            </CategoryHeaderStyled>
            <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-toast lb.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        LB Toast
                    <PriceContainerStyled>
                        49
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Healthy bloomer bread, with avocado puree top it with goat cheese puree, mix sesame seeds</p>
                        <p>Add: Egg / 12 QR • Mushroom / 12 QR</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        توست لو بلو
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        خبز صحي مع االفوكادو و جبنة الماعز، السمسم و الاعشاب
                        <p>اضافة: بيض / ١٢ ر.ق • فطر / ١٢ ر.ق</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
            </CategoryContainerStyled>
        </>
    )
}

export default BreakFastToastsEv;