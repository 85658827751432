import styled from "styled-components";

const CategoryHeadingStyled = styled.div`
    position: relative;
    padding: 30px 40px;
    padding-top: 60px;

    img {
        width: 100%;
    }

`

const SingleFoodItemIndexStyled = styled.div`
    position: relative;
    margin-bottom: 20px;
    padding: 0px 10px;
`

const FoodItemImageContainer = styled.div`
    position: relative;
    margin-bottom: 8px;
    img {
        width: 100%;
    }
`

const SingleFoodItemDescription = styled.div`
    display: flex;
    justify-content: space-between;
`

const FoodTextContainerStyled = styled.div`
    position: relative;
    padding-left: 10px;
`

//ask zina for font
const ItemNameEn = styled.div`
    position: relative;
    margin-bottom: 2px;
    margin-top: -2px;
    font-family: "Futura std";
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.1px;
`
const ItemNameAr = styled.div`
    position: relative;
    margin-top: -5px;
    margin-bottom: 4px;
    font-family: "DIN Next LT Arabic";
    font-size: 13px;
    /* span {
        
        font-size: 10px;
    } */
`

const ItemPriceStyled = styled.div`
    position: relative;
    padding-right: 10px;
    span {
        font-weight: bolder;
  
    }
`

const CopyRightStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    padding: 40px 0px;
    
    width: 100%;
    img {
        width: 45%;
    }
`

export {
    CategoryHeadingStyled,
    SingleFoodItemIndexStyled,
    FoodItemImageContainer,
    SingleFoodItemDescription,
    FoodTextContainerStyled,
    ItemNameEn,
    ItemNameAr,
    ItemPriceStyled,
    CopyRightStyled,
}