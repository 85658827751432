import styled from "styled-components";


//this will be the pop up component that we will trigger on actions
function PopUpMessageContainer({popUpMessage}) {

    return (
        <PopUpMessageContainerStyled className= "animate__animated animate__fadeInDown animate__faster">
            {popUpMessage}
        </PopUpMessageContainerStyled>
    )
}

const PopUpMessageContainerStyled = styled.div`
    position: fixed;
    inset: 0;
    margin: auto;
    height: 100px;
    width: 300px;
    aspect-ratio: 16/9;
    background-color: lightgray;
    border: 1px solid transparent;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    padding: 20px;
    text-align: center;
`

export default PopUpMessageContainer;