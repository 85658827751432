const cold_drinks = [

    {
        name: "Black rob roy",
        name_ar: "بلاك روب روي",
        price: "45",
        image_path: "/habra-menu-designs/cold drinks/Habra Menu- Black Rob Roy.jpg",
    },

    {
        name: "Boracay lust",
        name_ar: "شهوة بوراكاي",
        price: "45",
        image_path: "/habra-menu-designs/cold drinks/Habra Menu- Boracay Lust.jpg",
    },

    {
        name: "Cucumber lemonade",
        name_ar: "عصير الليمون بالخيار",
        price: "35",
        image_path: "/habra-menu-designs/cold drinks/Habra Menu- Cucumber Lemonade.jpg",
    },

    {
        name: "Kumquat bellini",
        name_ar: "كومكوات بيليني",
        price: "35",
        image_path: "/habra-menu-designs/cold drinks/Habra Menu- Kumquat Bellini.jpg",
    },

    {
        name: "Lychee mojito",
        name_ar: "ليتشي موخيتو",
        price: "35",
        image_path: "/habra-menu-designs/cold drinks/Habra Menu- Lychee Mojito.jpg",
    },

    {
        name: "Mix berry passion",
        name_ar: "مزيج شغف التوت",
        price: "35",
        image_path: "/habra-menu-designs/cold drinks/Habra Menu- Mix Berry.jpg",
    },
]

export default cold_drinks;