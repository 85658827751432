import styled from "styled-components";
import {CategoryHeadingStyled, SingleFoodItemIndexStyled, FoodItemImageContainer, SingleFoodItemDescription, FoodTextContainerStyled, ItemNameEn, ItemNameAr, ItemPriceStyled} from "./ChariotCommonStyled";

function BreakfastGrid({}) {
    return (
        <>
            <CategoryHeadingStyled>
                <img src = "/chariot-menu-designs/Breakfast PNG/Chariot-Breakfast-07.png" alt = "Breakfast Category chariot menu"/>
            </CategoryHeadingStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/Breakfast PNG/Chariot-Breakfast-02.png" alt = ""/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>CHEESY SCRAMBLED EGG
                            <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> with dill, light creamy mustard sauce</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                         <div>بيض مخفوق
                        <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}> 
                         مع الشبث وجبنة الشيدر  
                         </span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px", marginTop: "-2px"}} >25 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/Breakfast PNG/Chariot-Breakfast-05.png" alt = ""/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>
                        MUSHROOM SCRAMBLED EGG
                            <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> With mushroom & light creamy sauce</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div>
                        بيض مخفوق
                        <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                        مع الفطر
                        </span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>29 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/Breakfast PNG/Chariot-Breakfast-04.png" alt = ""/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>
                        BACON SCRAMBLED EGG
                            <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> with bacon & light creamy sauce</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div>
                        بيض مخفوق
                        <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                        مع البيكون
                        </span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>36 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/Breakfast PNG/breakfast- mixed cheese bun.png" alt = ""/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>
                        MIXED CHEESE BUN
                            <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> halloumi, cheddar, mozzarella</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div>
                        خبزة الأجبان المشكلة
                        <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                        حلوم، شيدار، موزاريلا
                        </span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>26 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/Breakfast PNG/breakfast- omelette.png" alt = "chariot breakfast omelette"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>
                        OMELETTE
                            <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> with sour dough toast</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div>
                        أومليت
                        <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                        مع الخبز                        
                        </span>
                        </div>
                        <span style = {{fontFamily: "Futura Std", color: "red", fontSize: "8px"}}>REPLACE SOUR DOUGH WITH AVOCADO TOAST</span>
                        <div style = {{marginTop: "-12px"}}>
                        <span style = {{fontFamily: "ntaqat", color: "red", fontSize: "8px"}}>إستبدل الخبز بخبز الأفوكادو</span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>30 QR</span>
                    <div style = {{paddingTop: "20px"}}>
                        <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px", color: "red"}}>47 QR</span>
                    </div>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/Breakfast PNG/breakfast- cheesy omelette.png" alt = "chariot cheesy omelette"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>CHEESY OMELETTE
                            <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> with sour dough toast</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div>
                        أومليت الجبنة
                        <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                        مع الخبز
                        </span >
                        </div>
                    </ItemNameAr>
                    <span style = {{fontFamily: "Futura Std", color: "red", fontSize: "8px"}}>REPLACE SOUR DOUGH WITH AVOCADO TOAST</span>
                        <div style = {{marginTop: "-8px"}}>
                        <span style = {{fontFamily: "ntaqat", color: "red", fontSize: "8px"}}>إستبدل الخبز بخبز الأفوكادو</span>
                        </div>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "13px"}}>39 QR</span>
                    <div style = {{paddingTop: "20px"}}>
                        <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px", color: "red"}}> 56 QR</span>
                    </div>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/Breakfast PNG/breakfast- mushroom swiss omelette.png" alt = ""/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>
                        MUSHROOM SWISS OMELETTE
                            <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> with sour dough toast</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div>
                        أومليت الفطر
                        <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                        مع الجبنة السويسرية والخبز                     
                        </span>
                        </div>
                    </ItemNameAr>
                    <span style = {{fontFamily: "Futura Std", color: "red", fontSize: "8px"}}>REPLACE SOUR DOUGH WITH AVOCADO TOAST</span>
                        <div style = {{marginTop: "-8px"}}>
                        <span style = {{fontFamily: "ntaqat", color: "red", fontSize: "8px"}}>إستبدل الخبز بخبز الأفوكادو</span>
                        </div>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>48 QR</span>
                    <div style = {{paddingTop: "20px"}}>
                        <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px", color: "red"}}>65 QR</span>
                    </div>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/Breakfast PNG/breakfast- breakfast plater.png" alt = ""/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>
                        BREAKFAST PLATER
                            <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> grilled halloumi, grilled tomato, avocado</span>
                            <br/>
                            <span style = {{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> your choice of eggs with sour dough</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div>
                        شاريوت بلاتر
                        <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                        حلوم مشوي، طماطم مشوية، أفوكادو                
                        </span>
                        <br/>
                        <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>بيض إختياري وخبز</span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>47 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/Breakfast PNG/breakfast- breakfast burito.png" alt = ""/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>
                        BREAKFAST BURITO
                            <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> Scrambled eggs, mushroom, guacamole,</span>
                            <br/>
                            <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}>mashed black beans, mixed cheese & sauce</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div>
                          بوريتو 
                        <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                        بيض مخفوق، فطر، جواكامولي، فاصوليا 
                        </span>
                        <br/>
                        <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}> سوداء مهروسة، جبنة مشكلة وصوص </span>
                        </div>
                        <span style = {{fontFamily: "Futura Std", color: "red", fontSize: "8px"}}>ADD TURKEY HAM OR BEEF BACON - 10 QR</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>33 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img style = {{ borderRadius: "10px"}} src = "/chariot-menu-designs/Breakfast PNG/Chariot-Breakfast-06.jpg" alt = ""/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>
                        GRANOLA
                            <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> with greek yogurt & raspberry</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div>
                        الجرانولا
                        <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                        مع روب يوناني وتوت الأحمر
                        </span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>35 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/Breakfast PNG/breakfast- vegan avocado toast.png" alt = ""/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>
                        VEGAN AVOCADO TOAST
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div>
                        افوكادو توست 
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>35 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
        </>
    )
}

export default BreakfastGrid;