import {useRef, useEffect} from "react";
import { AfternoonFullMenu, AfternoonEnhancedMenu, AfternoonCreamMenu} from "./";

function Afternoon({afternoon, navBarTwoRef, auth}) {

    const afternoonSections = useRef([]);
    let categoriesIndices = ['0', '1', '2'];

    //tracks the section id as we scroll
    function windowScrollTracker(currentScroll) {
        var sectionFromTop;
        var sectionId;

        //get the id of the current active cateogry as we scroll
        afternoonSections.current.forEach((section) => {
            sectionFromTop = section.getBoundingClientRect().top;

            if (sectionFromTop <= 10) {
                sectionId = section.getAttribute("id");
            }            
        })

        //pass the current active section's id to the nav bar tracker function
        navBarTracker(sectionId)
    }

    //activates the current active section based on id
    async function navBarTracker(sectionId) {
       
        switch(sectionId) {
            //activates the full menu category
            case '1':
                navBarTwoRef.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates the enhanced mneu category
            case '2':
                navBarTwoRef.current.scrollLeft = 100;
                await activeCategoryHandler(sectionId)
            break;

            //activates the cream tea category
            case '3':
                navBarTwoRef.current.scrollLeft = 100;
                await activeCategoryHandler(sectionId)
            break;
        }

        return "resolved"
    }

    //changes the colour of the active category
    function activeCategoryHandler(sectionId) {
        var index = sectionId - 1;
        console.log(index);
        afternoon.current[index].style.color = "#F5A800"

        categoriesIndices.map((item) => {
            if(item != index) {
                afternoon.current[item].style.color = "white"
            }
        })
    }

    useEffect(() => {
        function handleScroll(e) {
            var currentScroll = window.pageYOffset;
            windowScrollTracker(currentScroll)
        }     

        var timer = null;

        function scrollHandler() {
            if(timer !== null) {
                clearTimeout(timer);
            }
            timer = setTimeout(function () {
                handleScroll()
            }, 50)
        }

        window.addEventListener('scroll', scrollHandler)

        //cleanup event listener
        return () => {
            window.removeEventListener('scroll', scrollHandler )
        }

    }, [])


    return (
        <>
            <section id = "1" ref = {(el) => afternoonSections.current[0] = el}>
                <AfternoonFullMenu/>
            </section>
            <section id = "2" ref = {(el) => afternoonSections.current[1] = el}>
                <AfternoonEnhancedMenu/>
            </section>
            <section id = "3" ref = {(el) => afternoonSections.current[2] = el}>
                <AfternoonCreamMenu/>
            </section>
        </>
    )
}

export default Afternoon;