
import styled from "styled-components";
import {useEffect, useState, useRef} from "react";
import {ChefsRecommendation, ColdDrinks, Dessert, Drinks, HabraPlates, HotDrinks, Sauces, SharingPlates, Sides, SpecialityCuts, Veggie} from "../../components/MenuGrids/HabraMenu";
import { CopyRightStyled } from "../../components/MenuGrids/HabraMenu/generalStyles";
import {useNavigate} from "react-router-dom";

function HabraMenuEn({}) {
    const [triggerLanguage, setTriggerLanguage] = useState("en");
    const sliderContainer = useRef(null);
    const habraCatef = useRef([]);
    const habraSectionsRef = useRef([]);
    const categoriesIndices = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9',, '10']
    const navigate = useNavigate();

    //triggers the arabic/english menu
    function handleTriggerMenu() {
        navigate("/restaurants/menus/habra-ar");
    }

    //activate the cateogry we click on
    function navClickHandler(id) {
        activeCategoryHandler(id)
    }

    //activates/deactivates the categories that we click on or we are currently on
    function activeCategoryHandler(sectionId) {
        var index = sectionId -1;
        
        habraCatef.current[index].style.color = "#d08344";

        categoriesIndices.map((item) => {
            if(item != index) {
                habraCatef.current[item].style.color = "black";
            }
        })
    }

    //this functiont tracks the user position as he scrolls down the page
    function windowScrollTracker(currentScroll) {
        var sectionFromTop;
        var sectionId;

        //gets the id of the category we are ona s we scroll    
        habraSectionsRef.current.forEach((section) => {
            sectionFromTop = section.getBoundingClientRect().top;

            if(sectionFromTop <= 10) {
                sectionId = section.getAttribute("id");
            }
        })

        navBarTracker(sectionId);
    
    }

    async function navBarTracker(sectionId) {
    
        switch(sectionId) {
            //activates the sharing plates cateogry
            case '1':
                sliderContainer.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates the habra plates cateogory
            case '2':
                sliderContainer.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates the veggie plates category
            case '3':
                sliderContainer.current.scrollLeft = 80;
                await activeCategoryHandler(sectionId)
            break;

            //activates the speciality cuts category
            case '4':
                sliderContainer.current.scrollLeft = 100;
                await activeCategoryHandler(sectionId)
            break;
            //activates the chefs recommendation category
            case '5':
                sliderContainer.current.scrollLeft = 250;
                await activeCategoryHandler(sectionId)
            break;

            //activates the sides category
            case '6':
                sliderContainer.current.scrollLeft = 350;
                await activeCategoryHandler(sectionId)
            break;

            //activates the sauces category
            case '7':
                sliderContainer.current.scrollLeft = 350;
                await activeCategoryHandler(sectionId);
            break;

            //activates the dessert category
            case '8':
                sliderContainer.current.scrollLeft = 400;
                await activeCategoryHandler(sectionId);
            break;

            //activates the cold drinks category
            case '9':
                sliderContainer.current.scrollLeft = 450;
                await activeCategoryHandler(sectionId);
            break;

            //activates drinks category
            case '10':
                sliderContainer.current.scrollLeft = 500;
                await activeCategoryHandler(sectionId);
            break;

            //activates hot drinks
            case '11':
                sliderContainer.current.scrollLeft = 550;
                await activeCategoryHandler(sectionId);
            break;
        }

        return "resolved";
    }

    //function to move the slider
    function useHorizontalScrollEventEn(callback) {
        const positionRef = useRef(0);

        return(e) => {
            const x = e.currentTarget.scrollLeft;
            if(x !== positionRef.current) {
                positionRef.current = x;
                callback(e);
            }
        }
    }

    useEffect(() => {
        function handleScroll(e) {
            var currentScroll = window.pageYOffset;
            windowScrollTracker(currentScroll);
        }

        var timer = null;

        window.addEventListener('scroll', function() {
            if(timer !== null) {
                clearTimeout(timer)
            }
            timer = setTimeout(function() {
                handleScroll()
            }, 50)
        })
    }, [])

    return (
        <HabraPageStyled>
                <SliderContainerStyled ref = {sliderContainer} onScroll = {useHorizontalScrollEventEn} triggerLanguage = "en">
                    <NavTextStyled>
                     
                        <a href = "#1" ref = {(el) => habraCatef.current[0]=el} onClick = {()=>navClickHandler("1")}>
                                <span>SHARING PLATES</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#2" ref = {(el) => habraCatef.current[1]=el} onClick = {()=>navClickHandler("2")}>
                                <span>HABRA PLATES</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#3" ref = {(el) => habraCatef.current[2]=el} onClick = {()=>navClickHandler("3")}>
                                <span>VEGGIE PLATES</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#4" ref = {(el) => habraCatef.current[3]=el} onClick = {()=>navClickHandler("4")}>
                                <span>SPECIALITY CUTS</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#5" ref = {(el) => habraCatef.current[4]=el} onClick = {()=>navClickHandler("5")}>
                                <span>CHEF'S RECOMMENDATION</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#6" ref = {(el) => habraCatef.current[5]=el} onClick = {()=>navClickHandler("6")}>
                                <span>SIDES</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#7" ref = {(el) => habraCatef.current[6]=el} onClick = {()=>navClickHandler("7")}>
                                <span>SAUCES</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#8" ref = {(el) => habraCatef.current[7]=el} onClick = {()=>navClickHandler("8")}>
                                <span>DESSERT</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#9" ref = {(el) => habraCatef.current[8]=el} onClick = {()=>navClickHandler("9")}>
                                <span>COLD DRINKS</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#10" ref = {(el) => habraCatef.current[9]=el} onClick = {()=>navClickHandler("10")}>
                                <span>DRINKS</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#11" ref = {(el) => habraCatef.current[10]=el} onClick = {()=>navClickHandler("11")}>
                                <span>HOT DRINKS</span>
                        </a>
                    </NavTextStyled>
                    
                </SliderContainerStyled>
                <TestingBlockStyled>
                        <img src = "/habra-menu-designs/test-habra-image.jpg"/>
                </TestingBlockStyled>
                <LanguageTriggerContainerStyled triggerLanguage = "en">
                    <span onClick = {() => handleTriggerMenu("ar")}>عربي</span>
                </LanguageTriggerContainerStyled>
                <CategoryContainerStyled>
                    <section id = "1" ref = {(el) => habraSectionsRef.current[0] = el}>
                        <SharingPlates triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "2" ref = {(el) => habraSectionsRef.current[1] = el}>
                        <HabraPlates triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "3" ref = {(el) => habraSectionsRef.current[2] = el}>
                        <Veggie triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "4" ref = {(el) => habraSectionsRef.current[3] = el}>
                        <SpecialityCuts triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "5" ref = {(el) => habraSectionsRef.current[4] = el}>
                        <ChefsRecommendation triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "6" ref = {(el) => habraSectionsRef.current[5] = el}>
                        <Sides triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "7" ref = {(el) => habraSectionsRef.current[6] = el}>
                        <Sauces triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "8" ref = {(el) => habraSectionsRef.current[7] = el}>
                        <Dessert triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "9" ref = {(el) => habraSectionsRef.current[8] = el}>
                        <ColdDrinks triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "10" ref = {(el) => habraSectionsRef.current[9] = el}>
                        <Drinks triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "11" ref = {(el) => habraSectionsRef.current[10] = el}>
                        <HotDrinks triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CopyRightStyled>
                    <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                                <img src = "/Developed-By-LSD.svg"/>
                    </a>
                </CopyRightStyled>
            </HabraPageStyled>
    )
}

const HabraPageStyled = styled.div`
    position: relative;
    background-color:white;
    width: 100vw;
`

const SliderContainerStyled = styled.div`
    position: fixed;
    inset: 0;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    gap: 20px;
    align-items: center;
    width: 80%;
    height: 50px;
    z-index: 1;
    overflow: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
    display: none;
  }
`

//the cover block
const TestingBlockStyled = styled.div`
    position: relative;
   
    top: 50px;

    img {
        width: 100%;
    }

`

const NavTextStyled = styled.div`
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
    font-family: "Tahoma Bold";
    font-size: 8px;
   

   span {
    z-index: 10;
    width: 100%;
   }
`

const NavTextStyledAr = styled.div`
    position: relative;
    font-family: "DIN Next LT Arabic";
    font-size: 14px;
`

const LanguageTriggerContainerStyled = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    background-color: white;
    align-items: center;
    z-index: 1;
    inset: 0px;
    left: 80%;
    width: 20%;
    height: 50px;
    font-size: 12px;

     span {
        padding: 5px;
     }
`

const CategoryContainerStyled = styled.div`
    position: relative;
    margin-top: 80px;
`

export default HabraMenuEn;
