import styled from "styled-components";

function WorkWeb({}) {
    return (
        <>
            <WorkWebStyled>
                <div>
                    work web section goes here
                </div>
            </WorkWebStyled>
        </>
    )
}

const WorkWebStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default WorkWeb;