const am_en = [
    {
        name_en: "Hummus & Foul Mudammas ",
        description_en: " Chickpea paste, olive oil, tomatoes, foul mudammas",
        price: "23",
        img_path: "/five-am-menu-designs/AM/01-AM-Hummus&Foul Mudammas-72dpi.jpg"
    },

    {
        name_en: "Egg & Cream Cheese ",
        description_en: " Free range eggs, cream cheese, sourdough bread",
        price: "18",
        img_path: "/five-am-menu-designs/AM/02-AM-Egg&cream-72dpi.jpg"
    },

    {
        name_en: "Hamsa Chicken ",
        description_en: " Chickpeas, chicken, potato, capsicum, tomato sauce with masala spices",
        price: "24",
        img_path: "/five-am-menu-designs/AM/04-AM Shakshuka-72dpi.jpg"
    },

    {
        name_en: "AM Shakshuka ",
        description_en: " Scrambled eggs mixed with fresh tomato, onion and paprika",
        price: "18",
        img_path: "/five-am-menu-designs/AM/04-AM-Hamsa Chicken-72dpi.jpg"
    },

    {
        name_en: "Scrambeled Egg with Mushroom & Kalamata Olives ",
        description_en: " Eggs, portobello mushroom, kalamata olives, brown bread",
        price: "33",
        img_path: "/five-am-menu-designs/AM/05-AM-Scrambeled Egg&Kalamata-72dpi.jpg"
    },

    {
        name_en: "Eggcellent Croissant ",
        description_en: "26",
        price: "26",
        img_path: "/five-am-menu-designs/AM/06-AM-Eggcellent Croissant-72dpi.jpg"
    },

    {
        name_en: "Hamsa Chicken Liver ",
        description_en: "",
        price: "28",
        img_path: "/five-am-menu-designs/AM/07-AM-Hamsa Chicken Liver-72dpi.jpg"
    },

    {
        name_en: "Fould Modammas ",
        description_en: "",
        price: "26",
        img_path: "/five-am-menu-designs/AM/08-AM-Foul Modammas-72dpi.jpg"
    },

    {
        name_en: "Fattah Fried Nest ",
        description_en: "Meat, Chickpeas, Yogurt ",
        price: "24",
        img_path: "/five-am-menu-designs/AM/09-AM-Fattah Nest-72dpi.jpg"
    },

    {
        name_en: "Popcorn Falafel Roll ",
        description_en: " Corn, Ginger, Parsley, Onion, Cheese,Tahini Sauce",
        price: "15",
        img_path: "/five-am-menu-designs/AM/10-AM-Popcorn Falafel Roll-72dpi.jpg"
    },

    {
        name_en: "Avocado Walnut Butter Toast ",
        description_en: " Mixed Cheese, Avocado Paste, Spiced Cherry Tomato",
        price: "21",
        img_path: "/five-am-menu-designs/AM/11-AM-Avocado walnut-72dpi.jpg"
    },

    {
        name_en: "Egg Nest & Pesto ",
        description_en: " Sour Cream, Pesto, Egg",
        price: "33",
        img_path: "/five-am-menu-designs/AM/12-AM-Egg Nest& pesto-72dpi.jpg"
    },

    {
        name_en: "Muhammara & Portobello Toast ",
        description_en: " Poached Egg & Hollandaise",
        price: "26",
        img_path: "/five-am-menu-designs/AM/13-AM-Muhammara&Portobello-72dpi.jpg"
    },

    {
        name_en: "Mini Omelette ",
        description_en: " Avocado & Spiced Cherry Tomato",
        price: "19",
        img_path: "/five-am-menu-designs/AM/14-AM-Mini Omlette-72dpi.jpg"
    },

    {
        name_en: "Asparagus Labneh ",
        description_en: " Labneh, Asparagus, Your Choice of Egg, Pine Nuts",
        price: "33",
        img_path: "/five-am-menu-designs/AM/15-AM-Asparagus Labneh-72dpi.jpg"
    },

    {
        name_en: "Folded egg ",
        description_en: " Potato & Button Mushroom, Avocado",
        price: "19",
        img_path: "/five-am-menu-designs/AM/16-AM-Folded egg-72dpi.jpg"
    },

    {
        name_en: "Granola & Kiwi",
        description_en: "",
        price: "34",
        img_path: "/five-am-menu-designs/AM/17-AM-Granola&kiwi-72dpi.jpg"
    },



]

const am_ar = [

    {
        name_ar: "بيض وجبنة الكريم ",
        description_ar: "بيض، جبنة الكريم، خبزة السوردو ",
        price: "18",
        img_path: "/five-am-menu-designs/AM/02-AM-Egg&cream-72dpi.jpg"
    },

    {
        name_ar: "حمص طحينة مع الفول المدمس ",
        description_ar: "زيت زيتون، طماطم، فول، حمص ",
        price: "23",
        img_path: "/five-am-menu-designs/AM/01-AM-Hummus&Foul Mudammas-72dpi.jpg"
    },

    {
        name_ar: "أي أم شكشوكة ",
        description_ar: "بيض، صلصة طماطم، بصل والبابريكا ",
        price: "18",
        img_path: "/five-am-menu-designs/AM/04-AM-Hamsa Chicken-72dpi.jpg"
    },

    {
        name_ar: "حمسة دجاج ",
        description_ar: "نخي، بطاطا، طماطم، فلفل حلو، بهارات مسالة ",
        price: "24",
        img_path: "/five-am-menu-designs/AM/04-AM Shakshuka-72dpi.jpg"
    },

    {
        name_ar: "كرواسون بالبيض المخفوق والجبنة ",
        description_ar: "",
        price: "26",
        img_path: "/five-am-menu-designs/AM/06-AM-Eggcellent Croissant-72dpi.jpg"
    },

    {
        name_ar: "بيض مخفوق مع الفطر وزيتون الكلاماتا ",
        description_ar: "بيض، فطر، زيتون الكلاماتا، وخبز السوردو ",
        price: "33",
        img_path: "/five-am-menu-designs/AM/05-AM-Scrambeled Egg&Kalamata-72dpi.jpg"
    },

    {
        name_ar: "فول مدمس",
        description_ar: "",
        price: "26",
        img_path: "/five-am-menu-designs/AM/08-AM-Foul Modammas-72dpi.jpg"
    },

    {
        name_ar: "حمسة كبدة دجاج",
        description_ar: "",
        price: "28",
        img_path: "/five-am-menu-designs/AM/07-AM-Hamsa Chicken Liver-72dpi.jpg"
    },

    {
        name_ar: "لفائف فلافل الفشار ",
        description_ar: "ذرة، زنجبيل، بقدونس، بصل، جبنة، صلصة طحينة ",
        price: "15",
        img_path: "/five-am-menu-designs/AM/10-AM-Popcorn Falafel Roll-72dpi.jpg"
    },

    {
        name_ar: "فتة حمص واللحم المقلية ",
        description_ar: "لحم، حمص، لبن ",
        price: "24",
        img_path: "/five-am-menu-designs/AM/09-AM-Fattah Nest-72dpi.jpg"
    },

    {
        name_ar: "عش البيض وصلصة البيستو ",
        description_ar: "لبنة، بيستو، بيض ",
        price: "33",
        img_path: "/five-am-menu-designs/AM/12-AM-Egg Nest& pesto-72dpi.jpg"
    },

    {
        name_ar: "توست الافوكادو وزبدة الجوز ",
        description_ar: "جبن مشكل، معجون افوكادو، طماطم كرزية متبله ",
        price: "21",
        img_path: "/five-am-menu-designs/AM/11-AM-Avocado walnut-72dpi.jpg"
    },

    {
        name_ar: "أوملت صغير ",
        description_ar: "الأفوكادو والطماطم الكرز المتبل ",
        price: "19",
        img_path: "/five-am-menu-designs/AM/14-AM-Mini Omlette-72dpi.jpg"
    },

    {
        name_ar: "توست محمرة وفطر البورتابيلو بالبيض ",
        description_ar: "بيض مسلوق وهولنديز ",
        price: "26",
        img_path: "/five-am-menu-designs/AM/13-AM-Muhammara&Portobello-72dpi.jpg"
    },

    {
        name_ar: "بيض مطوي ",
        description_ar: "البطاطا والفطر، الأفوكادو ",
        price: "19",
        img_path: "/five-am-menu-designs/AM/16-AM-Folded egg-72dpi.jpg"
    },

    {
        name_ar: "الهليون مع اللبنة والبيض ",
        description_ar: "لبنة، الهليون، اختيارك من البيض والصنوبر ",
        price: "33",
        img_path: "/five-am-menu-designs/AM/15-AM-Asparagus Labneh-72dpi.jpg"
    },

    {
        name_ar: "جرنولة والكيوي",
        description_ar: "",
        price: "34",
        img_path: "/five-am-menu-designs/AM/17-AM-Granola&kiwi-72dpi.jpg"
    },

    
]

export {
    am_en,
    am_ar,
}