import styled from "styled-components";
import { ChariotMenuPageStyled } from "../styles/PageStyled";
import { useEffect, useRef, useState } from "react";
import {register} from "swiper/element/bundle";
import { AllDayGrid, BreakfastGrid, IceCreamGrid, DrinksGrid, IcedTeaGrid, MilkshakesGrid, MojitosGrid, SweetsGrid} from "../components/MenuGrids/chariotMenu";
import { CopyRightStyled } from "../components/MenuGrids/chariotMenu/ChariotCommonStyled";
import $ from "jquery";

register();

function ChariotMenu({}) {

  
    const sliderTesting = useRef(null);
    const sliderBox = useRef(null);
    const chariotCatRef = useRef([]);
    const chariotSectionsRef = useRef([]);
    const pagePositionRef = useRef(null);
    let categoriesIndices = ["0", "1", "2", "3", "4", "5", "6", "7"]; //categories of chariot menu
    
    //activate the category we click on
    function navClickHandler(id) {
         activeCategoryHandler(id);
    }

    //track's users position from top of screen as we scroll down. 
    //compares it to the category sections
     function windowScrollTracker(currentScroll) {

        var sectionFromTop;
        var sectionId;

        // --------- if manager decides to add logo in future------------------
        // if(navBarRef.current.getBoundingClientRect().top < 0) {
        //     console.log("hello")
        //     navBarRef.current.style.position = "fixed";
        //     navBarRef.current.style.inset = "0";
        // }

        // if(pagePositionRef.current.getBoundingClientRect().top > 0) {
        //     navBarRef.current.style.position = "relative";
        //     chariotCatRef.current[0].style.color = "#ea0029";
        // }

        chariotSectionsRef.current.forEach((section) => {
            sectionFromTop = section.getBoundingClientRect().top;
            if(sectionFromTop <= 100) {
                sectionId  = section.getAttribute("id");
            }
      
        })


        // console.log("the section we are in atm is: ", sectionId);
        navBarTracker(sectionId, currentScroll); //function to activate the the category we are on as we scroll

        //function to move the slider as we move
        if(sectionId == 1) {
            sliderTesting.current.scrollLeft = 0;
            }
        if(sectionId == 2) {
            sliderTesting.current.scrollLeft = 50;
            }
           
        if(sectionId == 3) {
            sliderTesting.current.scrollLeft = 150;
            }
        
        if(sectionId == 4) {
            sliderTesting.current.scrollLeft = "200";
            }
        
        if(sectionId == 5) {
            sliderTesting.current.scrollLeft = "350";
            }
        
        if(sectionId == 6) {
            sliderTesting.current.scrollLeft = "400";
            }
        
        if(sectionId == 7) {
            sliderTesting.current.scrollLeft = "480";
            }
        
        if(sectionId == 8) {
            sliderTesting.current.scrollLeft = "500";
            }
    }

    async function navBarTracker(sectionId, currentScroll, horizontalScroll) {
        switch(sectionId) {
            case '1':
                await activeCategoryHandler(sectionId);
            break;

            case '2':
                await activeCategoryHandler(sectionId);
            break;

            case '3':
                await activeCategoryHandler(sectionId);
            break;

            case '4':
                await activeCategoryHandler(sectionId);
            break;

            case '5':
                await activeCategoryHandler(sectionId);
            break;

            case '6':
                await activeCategoryHandler(sectionId);
            break;

            case '7':
                await activeCategoryHandler(sectionId);
            break;

            case '8':
                await activeCategoryHandler(sectionId);
            break;
        }

        return "resolved";
    }


    function activeCategoryHandler(sectionId) {
         console.log(sectionId);
        var index = sectionId-1;
        chariotCatRef.current[index].style.color = "white";
        chariotCatRef.current[index].style.textShadow = "1px 1px 0px black"

        //making all other cateogries inactive
        categoriesIndices.map((item) => {
            if (item != index) {
                chariotCatRef.current[item].style.color = "#ea0029"
            }
        })
    }

    function useHorizontalScrollEvent(callback) {
        const positionRef = useRef(0);

        return (e) => {
            const x = e.currentTarget.scrollLeft;
            if(x !== positionRef.current) {
                positionRef.current = x;
                callback(e);
            }
        }
    }
    const handleScrollSlide = useHorizontalScrollEvent((e) => {
          console.log(e.currentTarget.scrollLeft);
     
    })

    useEffect(() => {
         function handleScroll(e) {
            var currentScroll = window.pageYOffset;
            windowScrollTracker(currentScroll);
           
        }
        var timer = null;

        window.addEventListener('scroll', function() {
            if(timer !== null) {
                clearTimeout(timer);
            }
            timer = setTimeout(function() {
                handleScroll()
            }, 50)
        });

        return () => {
            window.removeEventListener('scroll', function() {
                if(timer !== null) {
                    clearTimeout(timer);
                }
                timer = setTimeout(function() {
                    handleScroll()
                }, 50)
            });
        }
    },[])
    return (
        <>
        {/* <FillerContainerStyled>
            <img src = "/chariot-menu-designs/CHARIOT-ScreenMenuLogo.jpg"/>
        </FillerContainerStyled> */}
        <ChariotMenuPageStyled ref = {pagePositionRef}>
            <SliderContainer ref = {sliderTesting} onScroll={handleScrollSlide}>
                <SliderBox ref = {sliderBox}>
                    <NavTextStyled>
                        <img src = "/chariot-menu-designs/Chariot-MenuElements-V1-03.png"/>
                        
                        <a href = "#1" ref = {(el) => chariotCatRef.current[0] = el} onClick = {() => navClickHandler("1")} >
                            <span>Breakfast</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <img src = "/chariot-menu-designs/Chariot-MenuElements-V1-03.png"/>
                        <a href = "#2" ref = {(el) => chariotCatRef.current[1] = el} onClick = {() => navClickHandler("2")}>
                        <span>All Day</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <img src = "/chariot-menu-designs/Chariot-MenuElements-V1-03.png"/>
                        <a href = "#3" ref = {(el) => chariotCatRef.current[2] = el} onClick = {() => navClickHandler("3")}>
                            <span>Drinks</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <img src = "/chariot-menu-designs/Chariot-MenuElements-V1-03.png"/>
                        <a href = "#4" ref = {(el) => chariotCatRef.current[3] = el} onClick = {() => navClickHandler("4")}>
                            <span>Ice Cream</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <img src = "/chariot-menu-designs/Chariot-MenuElements-V1-03.png"/>
                        <a href = "#5" ref = {(el) => chariotCatRef.current[4] = el} onClick = {() => navClickHandler("5")}>
                            <span>Iced Tea</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <img src = "/chariot-menu-designs/Chariot-MenuElements-V1-03.png"/>
                        <a href = "#6" ref = {(el) => chariotCatRef.current[5] = el} onClick = {() => navClickHandler("6")}>
                            <span>Milkshakes</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                        <img src = "/chariot-menu-designs/Chariot-MenuElements-V1-03.png"/>
                        <a href = "#7" ref = {(el) => chariotCatRef.current[6] = el} onClick = {() => navClickHandler("7")}>
                            <span>Mojitos</span>
                        </a>
                    </NavTextStyled>
                    <NavTextStyled>
                    <img src = "/chariot-menu-designs/Chariot-MenuElements-V1-03.png"/>
                        <a href = "#8" ref = {(el) => chariotCatRef.current[7] = el} onClick = {() => navClickHandler("8")}>
                            <span>Sweets</span>
                        </a>
                    <img src = "/chariot-menu-designs/Chariot-MenuElements-V1-03.png"/>
                    </NavTextStyled>
                </SliderBox>
            </SliderContainer>
            <CategoryWrapStyled>
                <CategoryContainerStyled style = {{marginTop: "20px"}}>
                    <section id = "1" ref = {(el) => chariotSectionsRef.current[0] = el}>
                        <BreakfastGrid/>
                    </section>
                </CategoryContainerStyled>
            </CategoryWrapStyled>
            <CategoryWrapStyled>
                <CategoryContainerStyled>
                    <section id = "2" ref = {(el) => chariotSectionsRef.current[1] = el}>
                        <AllDayGrid/>
                    </section>  
                </CategoryContainerStyled>
            </CategoryWrapStyled>
            <CategoryWrapStyled>
                <CategoryContainerStyled>
                    <section id = "3" ref = {(el) => chariotSectionsRef.current[2] = el}>
                        <DrinksGrid/>
                    </section>  
                </CategoryContainerStyled>
            </CategoryWrapStyled>
            <CategoryWrapStyled>
                <CategoryContainerStyled>
                    <section id = "4" ref = {(el) => chariotSectionsRef.current[3] = el}>
                        <IceCreamGrid/>
                    </section>  
                </CategoryContainerStyled>
            </CategoryWrapStyled>
            <CategoryWrapStyled>
                <CategoryContainerStyled>
                    <section id = "5" ref = {(el) => chariotSectionsRef.current[4] = el}>
                        <IcedTeaGrid/>
                    </section>  
                </CategoryContainerStyled>
            </CategoryWrapStyled>
            <CategoryWrapStyled>
                <CategoryContainerStyled>
                    <section id = "6" ref = {(el) => chariotSectionsRef.current[5] = el}>
                        <MilkshakesGrid/>
                    </section>  
                </CategoryContainerStyled>
            </CategoryWrapStyled>
            <CategoryWrapStyled>
                <CategoryContainerStyled>
                    <section id = "7" ref = {(el) => chariotSectionsRef.current[6] = el}>
                        <MojitosGrid/>
                    </section>  
                </CategoryContainerStyled>
            </CategoryWrapStyled>
            <CategoryWrapStyled>
                <CategoryContainerStyled>
                    <section id = "8" ref = {(el) => chariotSectionsRef.current[7] = el}>
                        <SweetsGrid/>
                    </section>  
                </CategoryContainerStyled>
            </CategoryWrapStyled>
            <CopyRightStyled style = {{backgroundColor: "rgba(240, 234, 206, 1)"}}>
                    <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                            <img src = "/Developed-By-LSD.svg"/>
                    </a>
            </CopyRightStyled>
        </ChariotMenuPageStyled>
        
        </>
    )
}

const CategoryWrapStyled = styled.div`
    position: relative;
    display:flex;
    justify-content: center; 
    background: radial-gradient(circle farthest-corner, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 1) 25%, rgba(240, 234, 206, 1) 65%);
`

const SliderContainer = styled.div`
    position: fixed;
    inset:0;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    background-color: #f5a800;
    width: 100%;;
    height: 50px;
    z-index: 1;
    overflow: scroll;
    scroll-behavior: smooth;

    ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background-color: transparent;
    }

`
const SliderBox = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    transition: all 1000ms ease 0s;
`

const FillerContainerStyled = styled.div`
  
    width: 100%;
    z-index: 100;
    height: 218px;

    img {
        position: absolute;
        inset: 0;
        max-width: 100%;
    }
`

const NavBarStyled = styled.div`
    position: fixed;
    inset: 0;
    height: 50px;
    padding: 15px 0px 5px 10px;
    background-color: #f5a800;
    z-index: 1;

    
`
const NavTextStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
    
    a {
        
        font-size: 20px;
        color: #ea0029;
        font-family: "LHF Old Tom Poster Letter";
    }
    text-shadow: 1px 1px 1px black;

    img {
        margin-top: -2px;
        width: 12px;
        height: 12px;

    }
`

const CategoryContainerStyled = styled.div`
    position: relative;
    border: 1px solid black;
    border-radius: 20px;
    top: 45px;
    margin-bottom: 60px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: -30px;

`

// const NavbarStyled = styled.div`
//     position: fixed;
//     inset: 0;
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
//     width: 100%;
//     height: 80px;
//     padding: 0px 20px;
//     text-align: center;
//     background-color: #f5a800;
// `
// const TestingStyled = styled.div`
//     position: relative;
    
//     top: 79px;
//     /* background-color: green; */
// `

export default ChariotMenu;