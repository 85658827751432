const sides = [

    {
        img_src: "/blt-menu-designs/Side Dishes JPG/Carousel JPG/Fries-1072x801px.jpg",
        name_en: "FRENCH FRIES",
        description_en: "",
        name_ar: "بطاطس مقلية",
        description_ar: "",
        price: "30",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Side Dishes JPG/Carousel JPG/Gratin-1072x801px.jpg",
        name_en: "GRATIN POTATO",
        description_en: "Potato, rosemary cream, butter, nutmeg, cheese",
        name_ar: "بطاطس محمرة",
        description_ar: "بطاطس، كريمة إكليل الجبل،زبدة جوزة الطيب، جبنة",
        price: "35",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Side Dishes JPG/Carousel JPG/Green-asparagus-1072x801px.jpg",
        name_en: "GREEN ASPARAGUS",
        description_en: "Asparagus, oyster sauce, butter, cherry tomato, olive oil",
        name_ar: "هليون أخضر",
        description_ar: "هليون، صوص محار، مشروم، زبدة، طماطم كرزية، ملح، زيت زيتون",
        price: "43",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Side Dishes JPG/Carousel JPG/Green-beans-1072x801px.jpg",
        name_en: "GREEN BEANS",
        description_en: "Green beans with spicy butter sauce",
        name_ar: "فاصوليا خضراء",
        description_ar: "فاصوليا خضراء مع صوص الزبدة الحارة",
        price: "43",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Side Dishes JPG/Carousel JPG/Mashed-potato-1072x801px.jpg",
        name_en: "MASHED POTATO",
        description_en: "Mashed potato, milk, unsalted butter",
        name_ar: "بطاطس مهروسة",
        description_ar: "بطاطس مهروسة، حليب، زبدة غير مملحة",
        price: "35",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Side Dishes JPG/Carousel JPG/Rice-1072x801px.jpg",
        name_en: "STEAMED FRENCH RICE",
        description_en: "White rice with onion, butter",
        name_ar: "رز فرنسي مبخر",
        description_ar: "أرز فرنسي مبخر بزعتر وقشر الليمون",
        price: "30",
        currency: "QR",
    },


]

export default sides;