let defToShareEn = [

    {
        name: "CHEEK, ",
        details: ` "BARON" SPICED, SAFFRON ORZO, GREEN TOMATO CHUTNEY`,
        price: "150",
        img_path: "/baron-menu-designs-2/defToShare/01 CHEEK-1072x800px.jpg"
    },

    {
        name: "SHANK, ",
        details: "OVERNIGHT LAMB, BAHARAT, MAJBOOS RISOTTO, PARSLEY, SHALLOT",
        price: "135",
        img_path: "/baron-menu-designs-2/defToShare/02 SHANK-1072x800px.jpg"
    },

    {
        name: "CURRY, ",
        details: "FISH, SHRIMP, COCONUT, CRISP SHALLOT, KUMQUAT, CORIANDER, CHILLI, RICE",
        price: "99",
        img_path: "/baron-menu-designs-2/defToShare/03 CURRY-1072x800px.jpg"
    },

    {
        name: "POULET, ",
        details: "CHARGRILLED BABY CHICKEN, TOUM, DIRTY-RICE, MARKOUK",
        price: "115",
        img_path: "/baron-menu-designs-2/defToShare/04 POULET-1072x800px.jpg"
    },

    {
        name: "SIRLOIN, ",
        details: "ANGUS, ’BARON’ BUTTER, BABY POTATO, SUCRINE SALAD",
        price: "350",
        img_path: "/baron-menu-designs-2/defToShare/05 Sirloin-edit-72dpi.jpg"
    },

    {
        name: "TENDERLOIN, ",
        details: " ANGUS, HERB CHUTNEY, POTATO, SUCRINE SALAD",
        price: "290",
        img_path: "/baron-menu-designs-2/defToShare/06 TENDERLOIN-1072x800px.jpg"
    },

    {
        name: "FISH OF THE DAY, ",
        details: " WHOLE ROASTED, CAPER SALSA, SUCRINE SALAD, BABY POTATO",
        price: "245",
        img_path: "/baron-menu-designs-2/defToShare/07 Fish-72dpi.jpg"
    },
]

let defToShareAr = [

    {
        name: "لحم شانك، ",
        details: "لحم ضأن مطهو طوال الليل، بهارات، ريزوتو مجبوس، بقدونس، كراث ",
        price: "135",
        img_path: "/baron-menu-designs-2/defToShare/02 SHANK-1072x800px.jpg"
    },

    {
        name: "لحم خد، ",
        details: "متبل بتوابل ”بارون“، أورزو بالزعفران، صلصة الطماطم الخضراء ",
        price: "150",
        img_path: "/baron-menu-designs-2/defToShare/01 CHEEK-1072x800px.jpg"
    },

    {
        name: "دجاج، ",
        details: "فروج صغير مشوي على الفحم، ثوم، أرز متبل، خبز مارقوق ",
        price: "115",
        img_path: "/baron-menu-designs-2/defToShare/04 POULET-1072x800px.jpg"
    },

    {
        name: "كاري، ",
        details: "سمك، روبيان، جوز الهند، كراث مقرمش، كُمكوات، كزبرة، فلفل حار، أرز ",
        price: "99",
        img_path: "/baron-menu-designs-2/defToShare/03 CURRY-1072x800px.jpg"
    },

    {
        name: "تندر لوين، ",
        details: "لحم أنغوس، صلصة الأعشاب، بطاطا مهروسة، وسلطة السكرين ",
        price: "290",
        img_path: "/baron-menu-designs-2/defToShare/06 TENDERLOIN-1072x800px.jpg"
    },

    {
        name: "سير لوين، ",
        details: "لحم أنغوس، زبدة ”بارون“، بطاطس صغيرة، سلطة سكرين ",
        price: "350",
        img_path: "/baron-menu-designs-2/defToShare/05 Sirloin-edit-72dpi.jpg"
    },

    {
        name: "سمك اليوم، ",
        details: "سمك مشوي كامل، صلصة الكبر، سلطة سكرين، بطاطس صغيرة ",
        price: "245",
        img_path: "/baron-menu-designs-2/defToShare/07 Fish-72dpi.jpg"
    },
]

export {
    defToShareEn,
    defToShareAr,
}