import {Box, TextField, Button, MenuItem} from "@mui/material";
import styled from "styled-components";
import { useState } from "react";
import axios from 'axios';
// import {BottonContainer, PopUpMessageContainer} from "./components/";
// import {handleFormPopUpMsg} from "./functions";
import { useLocation, useNavigate } from "react-router-dom";


function AddItemChariot({}) {
    return (
        <>
        <div>add item page goes here</div>
        </>
    )
}

export default AddItemChariot;