//this file will contain all the categories of each restaurant

// -------- shawarma el khebbez ----------
let sekCategories = [
    {
        refNo: "#category_8",
        category: "combos",
        className: "category_8",
    },

    {
        refNo: "#category_1",
        category: "sandwiches",
        className: "category_1",
    },

    {
        refNo: "#category_2",
        category: "bowls",
        className: "category_2",
    },

    {
        refNo: "#category_3",
        category: "plates",
        className: "category_3"
    },

    {
        refNo: "#category_4",
        category: "dips",
        className: "category_4",
    },

    {
        refNo: "#category_5",
        category: "add-ons",
        className: "category_5",
    },

    {
        refNo: "#category_6",
        category: "salads",
        className: "category_6",
    },

    {
        refNo: "#category_7",
        category: "appetizers",
        className: "category_7",
    },

    {
        refNo: "#category_9",
        category: "drinks",
        className: "category_9",
    }
]

// -------- Shaqab menu categories ----------
let sekShaqab = [
    {
        refNo: "#category_1",
        category: "sandwiches",
        className: "category_1 active",
    },

    {
        refNo: "#category_2",
        category: "bowls",
        className: "category_2",
    },

    {
        refNo: "#category_3",
        category: "add-ons",
        className: "category_3",
    },

    {
        refNo: "#category_9",
        category: "drinks",
        className: "category_9",
    },
]


// -------- LE TRAIN BLEU MENU ----------
let LeTrainBleuMenu = [
    {
        category: "Soups",
        panel_no: "panel1"
    },

    {
        category: "Salad",
        panel_no: "panel2",
    },

    {
        category: "Hot Starters",
        panel_no: "panel3",
    },

    {
        category: "Main Course",
        panel_no: "panel4",
    },

    {
        category: "Pasta & Risotto",
        panel_no: "panel5",
    },

    {
        category: "Side Dishes",
        panel_no: "panel6",
    },

    {
        category: "Dessert",
        panel_no: "panel7",
    },

    {
        category: "Cakes",
        panel_no: "panel8",
    },

    {
        category: "Drinks",
        panel_no: "panel9",
    },

    {
        category: "Afternoon Tea",
        panel_no: "panel10",
    },
]

// -------- LE TRAIN BLEU MENU ----------
let chariot_coffees = [

    {
        item_en: "BREWED COFFEE",
        item_ar: "قهوة محضرة",
        price: "25 QR",
    },

    {
        item_en: "AMERICANO",
        item_ar: "أميريكانو",
        price: "20 QR",
    },

    {
        item_en: "ESPRESSO",
        item_ar: "إسبريسو",
        price: "16 QR",
    },

    {
        item_en: "DOUBLE ESPRESSO",
        item_ar: "إسبريسو دوبل",
        price: "20 QR",
    },

    {
        item_en: "CORTADO",
        item_ar: "كورتادو",
        price: "21 QR",
    },

    {
        item_en: "FLAT WHITE",
        item_ar: "فلات وايت",
        price: "23 QR",
    },

    {
        item_en: "LATTE",
        item_ar: "لاتيه",
        price: "24 QR",
    },

    {
        item_en: "CAPPUCCINO",
        item_ar: "كابوشينو",
        price: "23 QR",
    },

    {
        item_en: "V60 ",
        item_ar: "في ٦٠",
        price: "25 QR",
    },

    {
        item_en: "DOLCE DE LECHE",
        item_ar: "دولشي دي ليشي",
        price: "25 QR",
    },

    {
        item_en: "CHOCOLATE MILK",
        item_ar: "شكولاتة بالحليب",
        price: "25 QR",
    },

    {
        item_en: "KARAK",
        item_ar: "كرك",
        price: "8 QR",
    },

    {
        item_en: "ROSE ICE CREAM",
        item_ar: "فرابوتشينو الورد",
        price: "27 QR",
    },
]

let chariot_drinks = [
    {
        item_en: "ORANGE JUICE",
        item_ar: "عصير برتقال",
        price: "21 QR",
    },

    {
        item_en: "LEMONADE",
        item_ar: "ليموناضة",
        price: "23 QR",
    },

    {
        item_en: "SOFT DRINKS",
        item_ar: "مشروبات غازية",
        price: "11 QR",
    },
]

let chariot_water = [
    {
        item_en: "STILL WATER 330 ML",
        price: "12 QR",
    },

    {
        item_en: "STILL WATER 750ML",
        price: "17 QR",
    },

    {
        item_en: "SPARKLING 330ML",
        price: "12 QR",
    },

    {
        item_en: "ARWA WATER 500ML",
        price: "5 QR",
    },
]

export {
    sekCategories,
    sekShaqab,
    LeTrainBleuMenu,
    chariot_coffees,
    chariot_drinks,
    chariot_water,
}