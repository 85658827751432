import styled from "styled-components";
import { GridPageStyled, GridContainerStyled, GridTitleContainerStyled, GridImageContainerStyled, ButtonContainerStyled, ComingSoonTempStyled } from "./GridCommanStyles";
import {Grid} from "@mui/material";
import {LogoContainerStyled, ImageContainerFrontStyled, ImageContainerBackStyled} from "../../pages/Lsd";


function IbisGrid({setTriggerGrid, setIsClicked, isClicked}) {

  return (
    <>
            <GridPageStyled>
                <GridTitleContainerStyled>
                Ibis & Adagio Hotel
                </GridTitleContainerStyled>
                <GridContainerStyled>
                    <Grid container spacing = {2} rowSpacing={2}>
                        <Grid item xs = {12} sm = {12}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/ibis-grid/1-LSD-Website-Ibis.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {12}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/ibis-grid/2-LSD-Website-Ibis.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {8}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/ibis-grid/3-LSD-Website-Ibis.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {4}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/ibis-grid/4-LSD-Website-Ibis.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {12}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/ibis-grid/5-LSD-Website-Ibis.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {4}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/ibis-grid/6-LSD-Website-Ibis.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {8}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/ibis-grid/7-LSD-Website-Ibis.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {6}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/ibis-grid/8-LSD-Website-Ibis.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {6}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/ibis-grid/9-LSD-Website-Ibis.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {12}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/ibis-grid/10-LSD-Website-Ibis.jpg" style = {{marginBottom: "20px"}}/>
                            </GridImageContainerStyled>
                        </Grid>
                      </Grid>
                </GridContainerStyled>
            </GridPageStyled>
    </>
)
}



export default IbisGrid;