//This file will contain special styles of pages

import styled from "styled-components";

const MenuPageStyled = styled.div`
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
`

const LeTrainBleuPageStyled = styled.div`
    position: relative;
    padding: 0px 15%;
    background-color: #011e41;
    
`

const WhiteCoffeeSpotPageStyled = styled.div`
    position: relative;
    width: 100vw;
    padding: 0px 18px;
    max-width: 1200px;
    margin: 0 auto;
`

const WhiteCoffeeSpotPagrArStyled = styled.div`
    position: relative;
    width: 100vw;
    max-width: 1200px;
    margin: 0 auto;
`

const ChariotMenuPageStyled = styled.div`
    position: relative;
    width: 100vw;
    /* background: radial-gradient(circle farthest-corner, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 46.48%, rgba(255, 255, 255, 1) 54.15%, rgba(240, 232, 206, 1) 100%); */
      /* background: radial-gradient(circle farthest-corner, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 1) 25%, rgba(240, 234, 206, 1) 65%); */
`

const ErgonMenuPageStyled = styled.div`
    position: relative;
    width: 100vw;
    padding: 19px 15px;
`

const LeBleuPageStyled = styled.div`
    position: relative;
    width: 100vw;
`

export {
    MenuPageStyled,
    WhiteCoffeeSpotPageStyled,
    WhiteCoffeeSpotPagrArStyled,
    LeTrainBleuPageStyled,
    ChariotMenuPageStyled,
    ErgonMenuPageStyled,
    LeBleuPageStyled,
    
}