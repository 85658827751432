import styled from "styled-components";
import {useState, useEffect, useRef} from "react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {motion} from "framer-motion";



function LsdIntroPage({}) {
    return (
        <>
            <CoverPageStyled>
                <LogoImageContainerStyled> 
                    <img  src = "lsd_pics/LSD-Logo-2.svg" alt = "Lovely Sunny Day company logo" />
                </LogoImageContainerStyled>
                <ArrowContainerStyled>
                    <a href = "/home">
                        <FontAwesomeIcon icon={faAngleDown} size="lg" style ={{color: "white", fontSize: "20px",}} />
                    </a>
                </ArrowContainerStyled>
            </CoverPageStyled>
        </>
    )
}

const CoverPageStyled = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    gap: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #a8d9d6;
`

const LogoImageContainerStyled = styled.div`
    position: relative;
    width: 50%;
    max-width: 500px;
    img {
        width: 100%;
    }
`

const ArrowContainerStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 50%;

`
export default LsdIntroPage;