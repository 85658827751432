import styled from "styled-components";

function PanCakesEn({}) {
    return (
        <>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pancakes Pics/pancakes-butter.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Butter pancakes <br/>
                        <ItemDescriptionParStyled>
                            <TextItalicBoxStyled>
                                with buter, orange blossom honey & granola
                            </TextItalicBoxStyled>
                        </ItemDescriptionParStyled>
                    </p>
                    <PriceBoxStyled>55 QR</PriceBoxStyled> 
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pancakes Pics/pancakes-hazelnut.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Hazelnut pancakes <br/>
                        <ItemDescriptionParStyled>
                            <TextItalicBoxStyled>
                                with hazelnut praline & nuts
                            </TextItalicBoxStyled>
                        </ItemDescriptionParStyled>
                    </p>
                    <PriceBoxStyled>55 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Pancakes Pics/pancakes-greek yogurt.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Greek yogurt pancake <br/>
                        <ItemDescriptionParStyled>
                            <TextItalicBoxStyled>
                                with honey, fresh fruits & granola
                            </TextItalicBoxStyled>
                        </ItemDescriptionParStyled>
                    </p>
                    <PriceBoxStyled>55 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
        </>
    )
}

function PanCakesAr({}) {
    return (
        <>
            <SingleItemContainerStyledAr>
                <ImageContainerStyledAr>
                    <img src = "/ergon-menu-desgins/Pancakes Pics/pancakes-butter.png"/>
                </ImageContainerStyledAr>
                <ItemDescriptionArStyled>
                    <p>
                    بانكيك مع زبدة <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>
                            زهرة البرتقال، عسل وغرانولا
                        </TextItalicBoxStyled>
                    </ItemDescriptionParArStyled>
                    </p>
                    <PriceBoxStyled>٥٥ ر.ق</PriceBoxStyled> 
                </ItemDescriptionArStyled>
            </SingleItemContainerStyledAr>
            <SingleItemContainerStyledAr>
                <ImageContainerStyledAr>
                    <img src = "/ergon-menu-desgins/Pancakes Pics/pancakes-hazelnut.png"/>
                </ImageContainerStyledAr>
                <ItemDescriptionArStyled>
                    <p>
                    بانكيك مع بارالاين <br/>
                    <ItemDescriptionParArStyled>
                        <TextItalicBoxStyled>
                        مكسرات البندق
                        </TextItalicBoxStyled>
                    </ItemDescriptionParArStyled>
                    </p>
                    <PriceBoxStyled>٥٥ ر.ق</PriceBoxStyled>
                </ItemDescriptionArStyled>
            </SingleItemContainerStyledAr>
            <SingleItemContainerStyledAr>
                <ImageContainerStyledAr>
                    <img src = "/ergon-menu-desgins/Pancakes Pics/pancakes-greek yogurt.png"/>
                </ImageContainerStyledAr>
                <ItemDescriptionArStyled>
                    <p>
                    بانكيك مع كريم الزبادي اليوناني <br/>
                    <ItemDescriptionParArStyled>
                        <TextItalicBoxStyled>
                        سل، الفواكة الطازجة والجرانولا
                        </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                    </p>
                    <PriceBoxStyled>٥٥ ر.ق</PriceBoxStyled>
                </ItemDescriptionArStyled>
            </SingleItemContainerStyledAr>
        </>
    )
}

const SingleItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
`

const SingleItemContainerStyledAr = styled(SingleItemContainerStyled)`
    flex-direction: row-reverse;
`

const ImageContainerStyled = styled.div`
    position: relative;
    max-width: 100px;
    width: 100%;
    img {
        width: 100%;
      
    }
`

const ImageContainerStyledAr = styled(ImageContainerStyled)`
    transform: scaleX(-1);
`

const ItemDescriptionStyled = styled.div`



    font-family: "Gotham Greek Book";
    font-size: 14px;
    p {
        margin: 0px;
        line-height: 15px;
    }
`

const ItemDescriptionArStyled = styled(ItemDescriptionStyled)`
    font-family: "GE SS Text";
    font-size: 15px;
    text-align: right;
`

const ItemDescriptionParStyled = styled.div`
    padding-right: 20px;
`

const ItemDescriptionParArStyled = styled.div`
    padding-left: 20px;
`

const TextItalicBoxStyled = styled.span`
    font-weight: lighter;
    font-style: italic;
    font-size: 12px;
`
const PriceBoxStyled = styled.div`
    position: relative;
    margin-top: 4px;
    font-weight: bold;
    font-size: 12px;
`

export {
    PanCakesEn,
    PanCakesAr,
};