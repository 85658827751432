import styled from "styled-components";
import {NavLink} from "react-router-dom";
import {useState, useEffect, useRef} from "react";
import {Work, WorkBrandIdentity, WorkMotion, WorkWeb, WorkEnvironmental, WorkAdvertising, WorkPhotography} from "../components/"
// import $ from 'jquery';
import ReactCardFlip from "react-card-flip";
import {motion} from "framer-motion";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



//the bone of the website
function Lsd({}){

    const [triggerWebsite, setTriggerWebsite] = useState(false);
    const [isDesktop, setIsDesktop] = useState(true);
    const [triggerMobileNavBar, setTriggerMobileNavBar] = useState(false);
    const [isFlipped, setIsFlipped] = useState(false);
    const [triggerSubCategory, setTriggetSubCategory] = useState(false);
    const [triggerGrid, setTriggerGrid] = useState(false);
    const [activeDiv, setActiveDiv] =  useState(false);

    //function to handle when a user first enters a website
    function handleTriggerWebsite(e) {
        //add cookies to prevent the website from reloading
        e.preventDefault()
        document.body.style.overflow = 'auto';
        
        setTriggerWebsite(true)
    }
    function handleTriggerWebsiteScroll() {
        console.log('scrolled')
    }

    function handleSubCategory(action) {
        setTriggetSubCategory(action);
    }

    //controls when to switch to mobile
    function updateScreenSize() {
        if(window.innerWidth <= 700) setIsDesktop(false);
        else setIsDesktop(true);
    }

    function handleClickTest(e) {
        e.preventDefault();
        
        document.body.style.overflow = 'auto';
        //to close the grids on desktop ONLY and prevents the nav bar from opening on desktop!
        if(window.innerWidth > 700 && triggerGrid == true) {
            setIsFlipped(prevIsFlipped => !prevIsFlipped);
            setTriggerGrid(false);
        }

        //opens the nav bar on phones only
        else if(window.innerWidth <= 700 && triggerGrid == false) {
            if(isFlipped == false) {
                setTriggerMobileNavBar(true);
            }
            else {
                setTriggerMobileNavBar(false);
            }
            setIsFlipped(prevIsFlipped => !prevIsFlipped)  
        }
        else {
            setTriggerGrid(false);
            setIsFlipped(prevIsFlipped => !prevIsFlipped); 

        }
    }


    useEffect(() => {
        //on start of app
        if(window.innerWidth <= 700) setIsDesktop(false);
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    }, [])
    
    
    //trigger this component when the website is under construction or updates.
    // return (
    //     <>
    //         <TempContainerStyled>
           
    //             <TempImageContainerStyled>
    //                 <img src = "lsd_pics/LSD-RisingSoon-Logo.gif" alt = "cover pic is supposed to be here"/>
    //             </TempImageContainerStyled>
    //         </TempContainerStyled>
    //     </>
    // )

        return(
            <> 
            <div>
                <LogoContainerStyled onClick = {handleClickTest}>
                    <ReactCardFlip isFlipped = {isFlipped} flipDirection="horizontal">
                        <ImageContainerFrontStyled>
                            <img src = "lsd_pics/LSD-burger-1.svg"/>
                        </ImageContainerFrontStyled>
                        <ImageContainerBackStyled>
                            <img src = "lsd_pics/LSD-burger-2.svg"/>
                        </ImageContainerBackStyled>
                    </ReactCardFlip>
                </LogoContainerStyled>

                        {/* this is the desktop nav bar */}
                        {!isDesktop && !triggerMobileNavBar && <MobileTestBar triggerGrid = {triggerGrid}/>}
                        {isDesktop && !triggerGrid && <LsdNavBarStyled>
                            <ul style = {{display: "flex", gap: "30px", height: "100%", fontFamily: "Recoleta",  fontSize: "35px", paddingTop: "0px", marginTop: "30px"}}>
                                <li>
                                    <NavLink to = "/home" className = {({isActive}) => isActive? "lsd-active-link": "lsd-link"}  onClick = {() => handleSubCategory(false)}> 
                                        Work
                                    </NavLink>
                                </li>
                               <li>
                                    <NavLink to = "/about" className = {({isActive}) => isActive? "lsd-active-link": "lsd-link"}  onClick = {() => handleSubCategory(false)}>
                                        About
                                    </NavLink>
                               </li>
                                <li>
                                    <NavLink to = "/contact-us"  className = {({isActive}) => isActive? "lsd-active-link": "lsd-link"}  onClick = {() => handleSubCategory(false)}>
                                        Contact
                                    </NavLink>
                                </li>
                                <NavBarSocialsContainerStyled>
                                    <div>
                                        <li style = {{marginLeft: "-16px", marginTop: "14px", fontSize: "20px"}}>
                                        <a href = "https://www.instagram.com/lovelysunnydaymena/" target = "_blank">
                                            Instagram
                                        </a>
                                        </li>   
                                    </div>
                                    {/* <div>
                                        <li>
                                        <a>
                                            Facebook
                                        </a>
                                        </li>   
                                    </div>
                                    <div>
                                        <li>
                                        <a>
                                            Linkedin
                                        </a>
                                        </li>
                                    </div> */}
                                </NavBarSocialsContainerStyled>
                            </ul>
                        </LsdNavBarStyled>}
                        {triggerSubCategory && <SubNavBarStyled>
                            <ul style = {{marginTop: "-10px", padding: "0px 50px", display: "flex", gap: "20px", fontSize: "11px", fontFamily: "Recoleta Alt",}}>
                                <li>
                                    <a href = "#category_1.1" alt = "Lovely Sunny Day">
                                        SELECTED
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.2" alt = "Lovely Sunny Day Brand Identity projects">
                                        BRAND IDENTITY
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.3" alt = "Lovely Sunny Day Motion projects">
                                        MOTION
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.4" alt = "Lovely Sunny Day WEB projects">
                                        WEB
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.5" alt = "Lovely Sunny Day environmental projects">
                                        ENVIRONMENTAL
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.6" alt = "Lovely Sunny Day advertising projects">
                                        ADVERTISING
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.7" alt = "Lovely Sunny Day photography projects">
                                        PHOTOGRAPHY
                                    </a>
                                </li>
                            </ul>
                        </SubNavBarStyled>}
                <PageStyled>
                     {/* this is the phone nav bar */}       
                    {triggerMobileNavBar && <MobileNavBarStyled>
                        <MobileNavBarFlexBoxStyled>
                           <NavBarBlockStyled>
                                <li>
                                    <NavLink to = "/home" alt = "lovely sunny day studio work section">
                                        <span onClick = {handleClickTest}>Work</span>
                                    </NavLink>
                                </li>
                           </NavBarBlockStyled>
                           <NavBarBlockStyled>
                                <li>
                                    <NavLink to =  "/about" alt = "lovely sunny day studio about section" >
                                        <span>About</span>
                                    </NavLink>
                                </li>
                           </NavBarBlockStyled>
                           <NavBarBlockStyled>
                                <li>
                                    <NavLink to =  "/contact-us" alt = "lovely sunny day studio contact section">
                                        <span>Contact</span>
                                    </NavLink>
                                </li>
                           </NavBarBlockStyled>
                           <MobileSocialMediaContainerStyled>
                            <div>
                                <li>
                                    <a href = "https://www.instagram.com/lovelysunnydaymena/" alt = "lovely sunny day studio social media instagram link">
                                        <span>Instagram</span>
                                    </a>
                                </li>
                            </div>
                            {/* <div>
                                <li>
                                    <a href = "" alt = "lovely sunny day studio social media facebook link">
                                        <span>Facebook</span>
                                    </a>
                                </li>
                            </div>
                            <div>
                                <li>
                                    <a href = "" alt = "lovely sunny day studio social media linkedin link">
                                        <span>Linkedin</span>
                                    </a>
                                </li>
                            </div> */}
                           </MobileSocialMediaContainerStyled>
                        </MobileNavBarFlexBoxStyled>
                    </MobileNavBarStyled>}
            
                    <section  id = "category_1">
                        <Work isFlipped = {isFlipped} setIsFlipped = {setIsFlipped} triggerGrid = {triggerGrid} setTriggerGrid = {setTriggerGrid}/>
                        {/* <section id = "category_1.2">
                            <WorkBrandIdentity id = "category_1.2"/>
                        </section>
                        <section id = "category_1.3">
                            <WorkMotion/>
                        </section>
                        <section id = "category_1.4">
                            <WorkWeb/>
                        </section>
                        <section id = "category_1.5">
                            <WorkEnvironmental/>
                        </section>
                        <section id = "category_1.6">
                            <WorkAdvertising/>
                        </section>
                        <section id = "category_1.7">
                            <WorkPhotography/>
                        </section> */}
                    </section>
                </PageStyled>
                </div>
            </>
        )
 }

 const CoverPageStyled = styled.div`
    position: fixed;
    inset:0;
    z-index: 12;
    width: 100vw;
    height: 100vh;
    display: flex;
    gap: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #a8d9d6;
`

const LogoImageContainerStyled = styled.div`
    position: relative;
    width: 50%;
    max-width: 500px;
    img {
        width: 100%;
    }
`

const ArrowContainerStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 50%;

`

 const LsdNavBarStyled = styled.div`
    width: 100%;
    z-index: 1;
    position: fixed;
    inset: 0;
    background-color: white;
    height: 140px;
    padding: 0px 10px;
 `

const NavBarSocialsContainerStyled = styled.div`
    position: relative;
    display: flex;
    gap: 18px;
    margin-left: 20px;

    li {
        font-size: 16px;
    }
`

const MobileNavBarStyled = styled.div`
    position: fixed;
    inset: 0;
    width: 100%;
    background-color: #a8d9d6;
    z-index: 1;
`

const MobileNavBarFlexBoxStyled = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: -15px;
    justify-content: center;
    gap: 3px;
    align-items: center;
`

const NavBarBlockStyled = styled.div`
    position: relative;

    span {
        font-family: "Recoleta";
        font-size: 50px;
    }
`

const MobileSocialMediaContainerStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 8px;
    text-align: center;

    span {
        font-family: "Recoleta";
        font-size: 25px;
    }
`

const TempContainerStyled = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #9dd0ca;
    inset: 0;
    width: 100%;

    img {
        width: 100%;
    }
`

const TempImageContainerStyled = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    
    img {
        width: 120%;
    }
`

const PageStyled = styled.div`
    position: relative;
    top: 140px;
    width: 100%;
    /* padding: 10px 55px; */

    z-index:0;
    @media (max-width: 700px) {
        padding: 0px 0px;
    }
`

const MobileTestBar = styled.div`
    
    position: fixed;
    width: 100%;
    height: 140px;

    z-index: 2;
    background-color: ${props => (props.triggerGrid ? "none" : "white")};
`

const LogoContainerStyled = styled.div`
    position: fixed;
    right: 55px;
    top: 36px;
    width: 65px;
    z-index: 10;
    height: 65px;

    @media(max-width: 700px) {
        
        right: 25px;
        top: 25px;

    }
`

const ImageContainerFrontStyled = styled.div`
    position: absolute;
    width: 100%;
    img {
        width: 100%;
    }
`

const ImageContainerBackStyled = styled.div`
    position: absolute;
    img {
        width: 100%;
    }
`
const SubNavBarStyled = styled.div`
    position: fixed;
    top: 40px;
    z-index: 1;

    a {
        cursor: pointer;
    }
`


export {
    Lsd,
    LogoContainerStyled,
    ImageContainerFrontStyled,
    ImageContainerBackStyled,
}