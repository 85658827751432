
import { LeTrainBleuPageStyled } from "../styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/Typography";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styled from "styled-components";
import { SandwichesGrid, AfternoonTea, CakesGrid, DessertGrid, DrinksGrid, HotStartersGrid, MainCourseGrid, PastaARisottoGrid, SaladsGrid, SideDishesGrid, SoupsGrid } from "../components/MenuGrids/bltMenu";
import { useState } from "react";
import { LeTrainBleuMenu } from "../data/foodMenuCategories";
import {styled as style} from "@mui/system";


function LtbMenu() {

    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (e, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        console.log(panel);
    };

    return (
        <>
            <LeTrainBleuPageStyled>
            <LTBLogoStyled>
                <img src = "/blt-menu-designs/ltb-logo.png" alt = "Le Train Bleu logo"/>
            </LTBLogoStyled>
                <Accordion
                    style = {{backgroundColor: "#011e41"}}
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                    elevation={0}
                >
                    <AccordionSummary className="category-box" expandIcon = {<ArrowDropDownIcon style={{color: "#dbbc74"}}/>}>
                        <CategoryStyled>
                        <Typography className = "category-title">
                            <CategoryFlexBoxStyled>
                                <div>Soup</div>
                                <CategoryArTexStyled>الشوربات</CategoryArTexStyled>
                            </CategoryFlexBoxStyled>
                        </Typography>
                        </CategoryStyled>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <SoupsGrid/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion 
                    style = {{backgroundColor: "#011e41"}}
                    expanded={expanded == "panel2"}
                    onChange = {handleChange("panel2")}
                    elevation={0}
                >
                    <AccordionSummary expandIcon = {<ArrowDropDownIcon style={{color: "#dbbc74"}}/>}>
                        <Typography className= "category-title">
                            <CategoryStyled>
                                <CategoryFlexBoxStyled>
                                    <div>Salads</div>
                                    <CategoryArTexStyled>السلطات</CategoryArTexStyled>
                                </CategoryFlexBoxStyled>
                            </CategoryStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <SaladsGrid/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion 
                    style = {{backgroundColor: "#011e41"}}
                    expanded={expanded == "panel3"}
                    onChange = {handleChange("panel3")}
                    elevation={0}
                >
                    <AccordionSummary expandIcon = {<ArrowDropDownIcon style={{color: "#dbbc74"}}/>}>
                        <Typography className= "category-title">
                            <CategoryStyled>
                                <CategoryFlexBoxStyled>
                                    <div>Hot Starters</div>
                                    <CategoryArTexStyled>المقبلات الساخنة</CategoryArTexStyled>
                                </CategoryFlexBoxStyled>
                            </CategoryStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <HotStartersGrid/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion 
                    style = {{backgroundColor: "#011e41"}}
                    expanded={expanded == "panel4"}
                    onChange = {handleChange("panel4")}
                    elevation={0}
                >
                    <AccordionSummary expandIcon = {<ArrowDropDownIcon style={{color: "#dbbc74"}}/>}>
                        <Typography className= "category-title">
                            <CategoryStyled>
                                <CategoryFlexBoxStyled>
                                    <div>Sandwiches</div>
                                    <CategoryArTexStyled>الساندويشات</CategoryArTexStyled>
                                </CategoryFlexBoxStyled>
                            </CategoryStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <SandwichesGrid/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion 
                    style = {{backgroundColor: "#011e41"}}
                    expanded={expanded == "panel5"}
                    onChange = {handleChange("panel5")}
                    elevation={0}
                >
                    <AccordionSummary expandIcon = {<ArrowDropDownIcon style={{color: "#dbbc74"}}/>}>
                        <Typography className= "category-title">
                            <CategoryStyled>
                                <CategoryFlexBoxStyled>
                                    <div>Main Course</div>
                                    <CategoryArTexStyled> الأطباق الرئيسية</CategoryArTexStyled>
                                </CategoryFlexBoxStyled>
                            </CategoryStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <MainCourseGrid/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion 
                    style = {{backgroundColor: "#011e41"}}
                    expanded={expanded == "panel6"}
                    onChange = {handleChange("panel6")}
                    elevation={0}
                >
                    <AccordionSummary expandIcon = {<ArrowDropDownIcon style={{color: "#dbbc74"}}/>}>
                        <Typography className= "category-title">
                            <CategoryStyled>
                                <CategoryFlexBoxStyled>
                                    <div>Pasta & Risotto</div>
                                    <CategoryArTexStyled>الباستا والريزوتو</CategoryArTexStyled>
                                </CategoryFlexBoxStyled>
                            </CategoryStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <PastaARisottoGrid/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion 
                    style = {{backgroundColor: "#011e41"}}
                    expanded={expanded == "panel7"}
                    onChange = {handleChange("panel7")}
                    elevation={0}
                >
                    <AccordionSummary expandIcon = {<ArrowDropDownIcon style={{color: "#dbbc74"}}/>}>
                        <Typography className= "category-title">
                            <CategoryStyled>
                                <CategoryFlexBoxStyled>
                                    <div>Side Dishes</div>
                                    <CategoryArTexStyled>الأطباق الجانبية</CategoryArTexStyled>
                                </CategoryFlexBoxStyled>
                            </CategoryStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                            <SideDishesGrid/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion 
                    style = {{backgroundColor: "#011e41"}}
                    expanded={expanded == "panel8"}
                    onChange = {handleChange("panel8")}
                    elevation={0}
                >
                    <AccordionSummary expandIcon = {<ArrowDropDownIcon style={{color: "#dbbc74"}}/>}>
                        <Typography className= "category-title">
                            <CategoryStyled>
                                <CategoryFlexBoxStyled>
                                    <div>Dessert</div>
                                    <CategoryArTexStyled> حلويات </CategoryArTexStyled>
                                </CategoryFlexBoxStyled>
                            </CategoryStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                            <DessertGrid/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion 
                    style = {{backgroundColor: "#011e41"}}
                    expanded={expanded == "panel9"}
                    onChange = {handleChange("panel9")}
                    elevation={0}
                >
                    <AccordionSummary expandIcon = {<ArrowDropDownIcon style={{color: "#dbbc74"}}/>}>
                        <Typography className= "category-title">
                            <CategoryStyled>
                                <CategoryFlexBoxStyled>
                                    <div>Cakes</div>
                                    <CategoryArTexStyled> الكيك </CategoryArTexStyled>
                                </CategoryFlexBoxStyled>
                            </CategoryStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CakesGrid/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion id = "#accordion"
                    style = {{backgroundColor: "#011e41"}}
                    expanded={expanded == "panel10"}
                    onChange = {handleChange("panel10")}
                    elevation={0}
                >
                    <AccordionSummary expandIcon = {<ArrowDropDownIcon style={{color: "#dbbc74"}}/>}>
                        <Typography className= "category-title">
                            <CategoryStyled>
                                <CategoryFlexBoxStyled>
                                    <div>Drinks</div>
                                    <CategoryArTexStyled> قائمة المشروبات </CategoryArTexStyled>
                                </CategoryFlexBoxStyled>
                            </CategoryStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                            <DrinksGrid/>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion 
                    style = {{backgroundColor: "#011e41"}}
                    expanded={expanded == "panel11"}
                    onChange = {handleChange("panel11")}
                    elevation={0}
                >
                    <AccordionSummary expandIcon = {<ArrowDropDownIcon style={{color: "#dbbc74"}}/>}>
                        <Typography className= "category-title">
                            <CategoryLastStyled>
                                <CategoryFlexBoxStyled>
                                    <div>Afternoon Tea </div>
                                    <CategoryArTexStyled>  الأفترنون تي </CategoryArTexStyled>
                                </CategoryFlexBoxStyled>
                            </CategoryLastStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Typography>
                        <AfternoonTea/>
                        </Typography>
                    </AccordionDetails>
                </Accordion> 
                <div className = "ltb-menu-filler"/>
                <CopyRightStyled>
                        <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                            <img src = "/blt-menu-designs/Developed-By-LSD-Reversed.svg"/>
                        </a>
                </CopyRightStyled>
            </LeTrainBleuPageStyled>
           
        </>
    )
}

const CopyRightStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center; 
    padding: 40px 0px;

    width: 100%;
    img {
        width: 45%;
    }
`



const CategoryStyled = styled.div`
    position: absolute;
    width:93%;
    margin-left: 2px;
    inset:0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(219, 188, 116,0.6);
`

const CategoryArTexStyled = styled.div`
    margin-top: 2px;
    font-size: 13px;
    font-family: "URWGeometricArabic-ExtraLight";
`

const CategoryFlexBoxStyled = styled.div`
    position: relative;
    display: flex;
    gap: 10px;
`
const CategoryLastStyled = styled.div`
    position: absolute;
    width:93%;
    margin-left: 2px;
    inset:0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid transparent;


`

const LTBLogoStyled = styled.div`
    position: relative;
    padding: 30px 0px;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;



    img {
        width:50%;
    }
`

export default LtbMenu;