const drinks = [

    {
        name: "Coke",
        name_ar: "فحم الكوك 250 مل",
        price: "18",
        image_path: "/habra-menu-designs/drinks/Habra Menu- Coca Cola Regular.jpg",
    },

    {
        name: "Diet coke",
        name_ar: "دايت كوكاكولا 250 مل",
        price: "18",
        image_path: "/habra-menu-designs/drinks/Habra Menu- Coca Cola Light.jpg",
    },

    {
        name: "Sprite",
        name_ar: "سبرايت 250 مل",
        price: "18",
        image_path: "/habra-menu-designs/drinks/Habra Menu- Sprite.jpg",
    },

    {
        name: "Aqua panna 250 ml",
        name_ar: "Aqua panna 250 ml",
        price: "20",
        image_path: "/habra-menu-designs/drinks/Habra Menu- Mineral Water.jpg",
    },

    {
        name: "San pallegrino 250 ml",
        name_ar: "San pallegrino 250 ml",
        price: "20",
        image_path: "/habra-menu-designs/drinks/Habra Menu- Sparkling Water.jpg",
    },
]

export default drinks;