let sweetsEn = [

    {
        name: "MERRY CREAM, ",
        details: " WALNUT HONEY CINNAMON OR SOUR CHERRY OR KUMQUAT SWEET SPOON OR OLIVE OIL, MALDON SALT",
        price: "47",
        img_path: "/baron-menu-designs-2/sweets/01 MERRY-CREAM-1072x800px.jpg"
    },

    {
        name: `“DATE”, `,
        details: " STEAMED CAKE, TAHINI ICE CREAM, SALTED CARAMEL",
        price: "47",
        img_path: "/baron-menu-designs-2/sweets/02 Date Cake-72dpi.jpg"
    },

    {
        name: "CHOCOLATE, ",
        details: " FLOURLESS, CRÈME FRAÎCHE, MALDON",
        price: "47",
        img_path: "/baron-menu-designs-2/sweets/03-Chocolate-72dpi.jpg"
    },

    {
        name: "SUNDAE, ",
        details: " CARAMALIZED ALMONDS, DULCE DE LECHE",
        price: "50",
        img_path: "/baron-menu-designs-2/sweets/04-Sundae-72dpi.jpg"
    },

    {
        name: "CRÈME BRÛLÉE, ",
        details: " CREAMY, TORCHED MERINGUE",
        price: "65",
        img_path: "/baron-menu-designs-2/sweets/05 CREME BRULEE.jpg"
    },

    {
        name: "CHEESECAKE, ",
        details: " BAKED, FRESH MANGO",
        price: "65",
        img_path: "/baron-menu-designs-2/sweets/06 MANGO-CHEESECAKE-1072x800px.jpg"
    },

    {
        name: `A "Mess, "`,
        details: " YOGHURT CREAM, DUQQAH, BERRIES, ICE CREAM, LEMON",
        price: "85",
        img_path: "/baron-menu-designs-2/sweets/07 MESS-1072x800px.jpg"
    },
]

let sweetsAr = [

    {
        name: `"التمر"، `,
        details: "كعكة على البخار، آيس كريم الطحيني، الكراميل المملح",
        price: "47",
        img_path: "/baron-menu-designs-2/sweets/02 Date Cake-72dpi.jpg"
    },

    {
        name: "ميري كريم، ",
        details: "عسل الجوز وقرفة أو كرز حامض ",
        price: "47",
        img_path: "/baron-menu-designs-2/sweets/01 MERRY-CREAM-1072x800px.jpg"
    },

    {
        name: "سانداي، ",
        details: "لوز مكرمل، دولسي دي ليتشي ",
        price: "50",
        img_path: "/baron-menu-designs-2/sweets/04-Sundae-72dpi.jpg"
    },

    {
        name: "شوكولاتة، ",
        details: "كعكة بدون دقيق، كريمة فريش، ملح مالدون ",
        price: "47",
        img_path: "/baron-menu-designs-2/sweets/03-Chocolate-72dpi.jpg"
    },

    {
        name: "تشيز كيك، ",
        details: "مخبوزة، مانجو طازج ",
        price: "65",
        img_path: "/baron-menu-designs-2/sweets/06 MANGO-CHEESECAKE-1072x800px.jpg"
    },

    {
        name: `"فوضى"، `,
        details: "كريمة الزبادي، الدقة، توت، آيس كريم، ليمون ",
        price: "85",
        img_path: "/baron-menu-designs-2/sweets/05 CREME BRULEE.jpg"
    },

    {
        name: "كريم بروليه، ",
        details: "كريمة ناعمة، ميرينغ بروليه ",
        price: "65",
        img_path: "/baron-menu-designs-2/sweets/07 MESS-1072x800px.jpg"
    },
]

export {
    sweetsEn,
    sweetsAr,
}