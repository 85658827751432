//This page will render an indentical menu of sek shaqab
//it is temp: future plans is to add the cover photo on top

import { MenuPageStyled, MenuCoverPageStyled, MenuStickerContainerStyled} from "../styles";
import { sekShaqab } from "../data/foodMenuCategories";
import { BowlsGrid, SandwichesGrid, AddOnsGrid, DrinksGrid } from "../components/MenuGrids/sekShaqab";
import {useEffect, useState} from "react";
import styled from "styled-components";
import $ from 'jquery';


function ShaqabMenu() {

    useEffect(() => {
        const sections = document.querySelectorAll("section");
        const navLi = document.querySelectorAll("nav .foodNavBar ul li");
        
        var navBar = $('.foodNavBar').offset().top;

        window.onscroll = () => {
            var currentScroll = window.pageYOffset;
            console.log("current scroll is: ", currentScroll);
            console.log("nav bar is : ", navBar);
            if(currentScroll >= navBar)  {
                console.log("reached nav bar");
                $('.foodNavBar').css({
                    position: 'fixed',
                    top: '0',
                    left: '0',
                })
            } else {
                $('.foodNavBar').css({
                    position: 'static',
                })
            }
            var current = "";
            sections.forEach((section) => {
                const sectionTop = section.offsetTop; //how far the section is from top
                if (window.pageYOffset  >= sectionTop - 60) {
                    current = section.getAttribute("id");
                }
            });
    
            navLi.forEach((li) => {
                li.classList.remove("active");
                if(li.classList.contains(current)) {
                    li.classList.add("active");
                }
            });
        };
    }, [])

    return (
        <>
        <MenuPageStyled>
            <Test>
                <img src = "/sek-shaqab-menu-designs/sek-menu-cover-shaqab.jpg"/>
            </Test>
            <nav>
                    <div className = "foodNavBar shaqab-nav">
                        <ul>
                            {
                                sekShaqab.map(({refNo, category, className}) => {
                                    return (
                                        <>
                                        <li className = {className}>
                                            <a className = "shaqab-font" href = {refNo}>
                                                {category}
                                            </a>
                                        </li>
                                    </>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </nav>
            <div>
            <section id = "category_1">
                <SandwichesGrid/>
            </section>
            <section id = "category_2">
                <BowlsGrid/>
            </section>
            <section id = "category_3">
                <AddOnsGrid/>
            </section>
            <section id = "category_4">
                <DrinksGrid/>
            </section>
            </div>
            </MenuPageStyled>
        </>
    )
}

const Test = styled.div`
    position: relative;
    img {
        width:100%;
    }
`


export default ShaqabMenu;


