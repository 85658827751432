import styled from "styled-components";

function DessertsEn({}) {
    return (
        <>
            <div>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Desserts Pics/desserts- baklava.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Baklava <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>
                                    Fillo pastry with pistachio
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>16 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Desserts Pics/desserts- kataifi.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Kataifi <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>
                                    Fillo pastry with walnuts & syrup
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>16 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Desserts Pics/desserts- saragli.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Saragli <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>
                                    Fillo pastry rolls with walnuts & syrub
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>8 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Desserts Pics/desserts- karidopita.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Karidopita <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>
                                    walnut cake with syrub
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>16 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
            </div>
        </>
    )
}

function DessertsAr({}) {
    return(
        <>
            <div>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Desserts Pics/desserts- baklava.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        بقلاوة <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>
                            رقائق محشية بالفستق الحلبي                            </TextItalicBoxStyled>
                            </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>١٦ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Desserts Pics/desserts- kataifi.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        قطايف <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>
                            عجينة محشية بالبندق والشيرة 
                            </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>١٦ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Desserts Pics/desserts- saragli.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        ساراقلي <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>
                            لفائف العجينة محشية بالبندق والشيرة 
                            </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٨ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Desserts Pics/desserts- karidopita.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        كاريدوبيتا <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>
                              كيكة البندق والشيرة 
                            </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>١٦ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
            </div>
        </>
    )
}

const SingleItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
    /* img {
        width: 60%;
    } */
`

const SingleItemContainerStyledAr = styled(SingleItemContainerStyled)`
    flex-direction: row-reverse;
`

const ImageContainerStyled = styled.div`
    position: relative;
    max-width: 75px;
    width: 100%;
    img {
        width: 100%;
      
    }
`

const ImageContainerStyledAr = styled(ImageContainerStyled)`
    transform: scaleX(-1);
`

const ItemDescriptionStyled = styled.div`



    font-family: "Gotham Greek Book";
    font-size: 14px;
    p {
        margin: 0px;
        line-height: 15px;
    }
`

const ItemDescriptionArStyled = styled(ItemDescriptionStyled)`
    font-family: "GE SS Text";
    font-size: 15px;
    text-align: right;
`

const ItemDescriptionParStyled = styled.div`
    padding-right: 20px;
`

const ItemDescriptionParArStyled = styled.div`
    padding-left: 20px;
`

const TextItalicBoxStyled = styled.span`
    font-weight: lighter;
    font-style: italic;
    font-size: 12px;
`
const PriceBoxStyled = styled.div`
    position: relative;
    margin-top: 4px;
    font-weight: bold;
    font-size: 12px;
`

const PriceBoxStyledAr = styled(PriceBoxStyled)`
    text-align: right;
`

export {
    DessertsEn,
    DessertsAr,
}