const pasta  = [
    
    {
        img_src: "/blt-menu-designs/Pasta & Rissotto JPG/Carousel JPG/Fetuccine-1072x801px.jpg",
        name_en: "FETTUCCINE TRUFFLE",
        description_en: "Fettuccine pasta, truffle sauce, white onion, basil leave, cooking cream, slice black truffle, parmesan cheese",
        name_ar: "فيتوتشيني الترافل",
        description_ar: "باستا فيتوتشيني، صلصة ترافل، بصل أبيض، ريحان، كريمة الطبخ، شريحة الترافل السوداء، جبنة بارميزان",
        price: "112",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Pasta & Rissotto JPG/Carousel JPG/Lasagna-1072x801px.jpg",
        name_en: "LASAGNA",
        description_en: "Homemade lasagna sheets, minced beef, tomato sauce, herbs, thermidor sauce with béchamel sauce, mozzarella cheese and parmesan",
        name_ar: "لازانيا",
        description_ar: "شرائح لازانيا، لحم مفروم، صلصة طماطم، أعشاب، صلصة ثيرميدور مع صلصة بشاميل، جبنة موتزاريلا وبارميزان",
        price: "89",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Pasta & Rissotto JPG/Carousel JPG/safron-shrimp-1072x801px.jpg",
        name_en: "SAFFRON RISOTTO SHRIMP",
        description_en: "Risotto rice, saffron, parmesan cheese, shrimp, olive oil, garlic and onion",
        name_ar: "ريزوتو الروبيان مع الزعفران",
        description_ar: "ريزوتو، زعفران، جبنة بارميزان، روبيان، زيت زيتون، ثوم و بصل",
        price: "79",
        currency: "QR",

    },
]

export default pasta;