//grid styling for Dips page of sek resturant
//refNo 4

import { Grid } from "@mui/material";

function DipsGrid() {
    return (
        <Grid container spacing = {0.1}>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-menu-designs/Menu-Title-JPG/Menu-Title-04.jpg"/>
                </div>
            </Grid>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-menu-designs/Dips JPG/Dip-01.jpg"/>
                </div>
            </Grid>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-menu-designs/Dips JPG/Dip-02.jpg"/>
                </div>
            </Grid>
        </Grid>
    )
}

export default DipsGrid;