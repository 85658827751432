import styled from "styled-components";
import { CategoryHeaderStyled, ItemContainerStyled, ItemEnglishTitleStyled, ItemImageContainerStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, CategoryContainerStyled, ArabicContainerStyled, ItemArabicTitleStyled, ItemArabicDescriptionStyled, FillerContainerStyled } from "./CommonStyled";
import {soups} from "../../../data/lbCategories/a-la-carte/menuItems";

function CarteSoupsGrid({}) {
    return (
        <>
        <CategoryContainerStyled>
            {/* <CategoryHeaderStyled>
                <span>Soup</span>
            </CategoryHeaderStyled> */}
                {
                    soups.map(({item_img_src, name_en, description_en, name_ar, description_ar, price, currency}) => {
                        return (
                            <ItemContainerStyled>
                                <ItemImageContainerStyled>
                                    <img src = {item_img_src} alt = {name_en}/>
                                </ItemImageContainerStyled>
                                <ItemEnglishTitleStyled>
                                    {name_en}
                                    <PriceContainerStyled>
                                        {price}
                                        <span>{currency}</span>
                                    </PriceContainerStyled>
                                </ItemEnglishTitleStyled>
                                <ItemEnglishDescriptionStyled>
                                    <p>
                                        {description_en}
                                    </p>
                                </ItemEnglishDescriptionStyled>
                                <ArabicContainerStyled>
                                    <ItemArabicTitleStyled>
                                        {name_ar}
                                    </ItemArabicTitleStyled>
                                    <ItemArabicDescriptionStyled>
                                        <p>
                                            {description_ar}
                                        </p>
                                    </ItemArabicDescriptionStyled>
                                </ArabicContainerStyled>
                                <FillerContainerStyled/>
                            </ItemContainerStyled>
                        )
                    })
                }

            </CategoryContainerStyled>
        </>
    )
}


export default CarteSoupsGrid;

