//This component basically just scrolls back to the top of the page when 
//the router redirects the active route

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollTopTop() {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}