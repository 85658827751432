const breads_en = [
    {
        name_en: "Tannour Bread",
        description_en: "",
        price: "3",
        img_path: "/five-am-menu-designs/breads/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_en: "Sourdough",
        description_en: "",
        price: "3",
        img_path: "/five-am-menu-designs/breads/02-bread&dips-Sourdough-72dpi.jpg"
    },

    {
        name_en: "Sesame Chili Crackers",
        description_en: "",
        price: "3",
        img_path: "/five-am-menu-designs/breads/03-bread&dips-Seasame Chili crackers-72dpi.jpg"
    },

    {
        name_en: "Bread Basket",
        description_en: "",
        price: "8",
        img_path: "/five-am-menu-designs/breads/04-bread&dips-Bread Basket-72dpi.jpg"
    },

    {
        name_en: "Labneh & Makdous",
        description_en: "",
        price: "15",
        img_path: "/five-am-menu-designs/breads/05-bread&dips-Labneh & makdous-72dpi.jpg"
    },

    {
        name_en: "Zaatar Labneh & Pepper Marmalade",
        description_en: "",
        price: "19",
        img_path: "/five-am-menu-designs/breads/06-bread&dips-Zaatar labneh-72dpi.jpg"
    },

    {
        name_en: "Chili Feta & Olives",
        description_en: "",
        price: "12",
        img_path: "/five-am-menu-designs/breads/07-bread&dips-Chili feta & olives-72dpi.jpg"
    },

    {
        name_en: "Whipped White Feta",
        description_en: "",
        price: "13",
        img_path: "/five-am-menu-designs/breads/08-bread&dips-Whipped white feta-72dpi.jpg"
    },

    {
        name_en: "Clotted Ashta & Honey",
        description_en: "",
        price: "28",
        img_path: "/five-am-menu-designs/breads/09-bread&dips-Clottedd ashta & honey-72dpi.jpg"
    },

    {
        name_en: "Sweet Dips",
        description_en: " Pumpkin Sweet, Rose Jam, Butter, Honey",
        price: "27",
        img_path: "/five-am-menu-designs/breads/10-bread&dips-Sweet Dips-72dpi.jpg"
    },

    {
        name_en: "Hummus Palestine",
        description_en: "",
        price: "17",
        img_path: "/five-am-menu-designs/breads/11-bread&dips-Hummus palestine-72dpi.jpg"
    },

    {
        name_en: "Leek Dip",
        description_en: "",
        price: "17",
        img_path: "/five-am-menu-designs/breads/12-bread&dips-Leek dip-72dpi.jpg"
    },

    {
        name_en: "Zaa’luk Dip",
        description_en: "",
        price: "17",
        img_path: "/five-am-menu-designs/breads/13-bread&dips-Zaaluk dip-72dpi.jpg"
    },

    {
        name_en: "Paprika & Feta",
        description_en: "",
        price: "17",
        img_path: "/five-am-menu-designs/breads/14-bread&dips-Paprika & Feta-72dpi.jpg"
    },

]

const breads_ar = [
    {
        name_ar: "سوردو",
        description_ar: "",
        price: "3",
        img_path: "/five-am-menu-designs/breads/02-bread&dips-Sourdough-72dpi.jpg"
    },

    {
        name_ar: "خبزة التنور",
        description_ar: "",
        price: "3",
        img_path: "/five-am-menu-designs/breads/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_ar: "سلة الخبز",
        description_ar: "",
        price: "8",
        img_path: "/five-am-menu-designs/breads/04-bread&dips-Bread Basket-72dpi.jpg"
    },

    {
        name_ar: "بسكويت الفلفل والسمسم",
        description_ar: "",
        price: "3",
        img_path: "/five-am-menu-designs/breads/03-bread&dips-Seasame Chili crackers-72dpi.jpg"
    },

    {
        name_ar: "لبنة بالزعتر مع مربى الفلفل",
        description_ar: "",
        price: "19",
        img_path: "/five-am-menu-designs/breads/06-bread&dips-Zaatar labneh-72dpi.jpg"
    },

    {
        name_ar: "لبنة ومكدوس",
        description_ar: "",
        price: "15",
        img_path: "/five-am-menu-designs/breads/05-bread&dips-Labneh & makdous-72dpi.jpg"
    },

    {
        name_ar: "جبنة الفيتا البيضاء المخفوقة",
        description_ar: "",
        price: "13",
        img_path: "/five-am-menu-designs/breads/08-bread&dips-Whipped white feta-72dpi.jpg"
    },

    {
        name_ar: "جبنة الفيتا الحارة مع الزيتون",
        description_ar: "",
        price: "12",
        img_path: "/five-am-menu-designs/breads/07-bread&dips-Chili feta & olives-72dpi.jpg"
    },

    {
        name_ar: "التغميسات الحلوة",
        description_ar: "مربى الياقطين، مربى الورد، الزبدة والعسل",
        price: "27",
        img_path: "/five-am-menu-designs/breads/10-bread&dips-Sweet Dips-72dpi.jpg"
    },

    {
        name_ar: "قشطة وعسل",
        description_ar: "",
        price: "28",
        img_path: "/five-am-menu-designs/breads/09-bread&dips-Clottedd ashta & honey-72dpi.jpg"
    },

    {
        name_ar: "تغميسة الكراث",
        description_ar: "",
        price: "17",
        img_path: "/five-am-menu-designs/breads/12-bread&dips-Leek dip-72dpi.jpg"
    },

    {
        name_ar: "حمص فلسطين",
        description_ar: "",
        price: "17",
        img_path: "/five-am-menu-designs/breads/11-bread&dips-Hummus palestine-72dpi.jpg"
    },

    {
        name_ar: "تغميسة الزعلوك",
        description_ar: "",
        price: "17",
        img_path: "/five-am-menu-designs/breads/13-bread&dips-Zaaluk dip-72dpi.jpg"
    },

    {
        name_ar: "تغميسة الكراث",
        description_ar: "",
        price: "17",
        img_path: "/five-am-menu-designs/breads/12-bread&dips-Leek dip-72dpi.jpg"
    },

    {
        name_ar: "البابريكا والفيتا",
        description_ar: "",
        price: "17",
        img_path: "/five-am-menu-designs/breads/14-bread&dips-Paprika & Feta-72dpi.jpg"
    },
]

export  {
    breads_en,
    breads_ar,
};