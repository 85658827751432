import styled from "styled-components";

function WorkAdvertising({}) {
    return (
        <>
            <WorkAdvertisingStyled>
                <div>
                    work advertising section goes here
                </div>
            </WorkAdvertisingStyled>
        </>
    )
}

const WorkAdvertisingStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default WorkAdvertising;