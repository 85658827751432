import styled from "styled-components";

function GreekYogurtBowlEn({}) {
    return (
        <>
            <div>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Greek Yogurt Bowl Pics/Yogurt-granolaBowl.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Granola greek yogurt bowl <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>
                                    with homemade granola, berries & honey
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>41 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Greek Yogurt Bowl Pics/Yogurt-datesBowl.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Dates Super Bowl <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>
                                    with dates, almond milk, tahini, peanutbutter, cocoa, oats, sunflower seeds, chia seeds & fruits
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>42 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
            </div>
        </>
    )
}

function GreekYogurtBowlAr({}) {
    return (
        <>
            <div>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Greek Yogurt Bowl Pics/Yogurt-granolaBowl.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        صحن زبادي يوناني مع جرانولا <br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>
                                جرانولا منزلية، توت وعسل
                                </TextItalicBoxStyled>
                            </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٤١ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Greek Yogurt Bowl Pics/Yogurt-datesBowl.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        صحن زبادي يوناني مع تمر <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>
                            تمر، حليب اللوز، طحينة، زبدة فستق،
كاكاو، شوفان، حبوب دوار الشمس
بذور الشيا والفواكة
                            </TextItalicBoxStyled>
                            </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٤٢ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
            </div>
        </>
    )
}

const SingleItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
    /* img {
        width: 60%;
    } */
`

const SingleItemContainerStyledAr = styled(SingleItemContainerStyled)`
    flex-direction: row-reverse;
`

const ImageContainerStyled = styled.div`
    position: relative;
    max-width: 100px;
    width: 100%;
    img {
        width: 100%;
      
    }
`

const ImageContainerStyledAr = styled(ImageContainerStyled)`
    transform: scaleX(-1);
`

const ItemDescriptionStyled = styled.div`



    font-family: "Gotham Greek Book";
    font-size: 14px;
    p {
        margin: 0px;
        line-height: 15px;
    }
`

const ItemDescriptionArStyled = styled(ItemDescriptionStyled)`
    font-family: "GE SS Text";
    font-size: 15px;
    text-align: right;
`

const ItemDescriptionParStyled = styled.div`
    padding-right: 20px;
`

const ItemDescriptionParArStyled = styled.div`
    padding-left: 20px;
`

const TextItalicBoxStyled = styled.span`
    font-weight: lighter;
    font-style: italic;
    font-size: 12px;
`
const PriceBoxStyled = styled.div`
    position: relative;
    margin-top: 4px;
    font-weight: bold;
    font-size: 12px;
`

export {
    GreekYogurtBowlEn,
    GreekYogurtBowlAr,
};