import styled from "styled-components";
import { GridPageStyled, GridContainerStyled, GridTitleContainerStyled, GridImageContainerStyled} from "./GridCommanStyles";
import {Grid} from "@mui/material";
import {LogoContainerStyled, ImageContainerFrontStyled, ImageContainerBackStyled} from "../../pages/Lsd";
import {useEffect, useState} from "react"

function WhiteCoffeeSpotGrid({setTriggerGrid, setIsFlipped, isFlipped}) {

    //close the grid and change the logo 
    function handleTriggerfn() {
        console.log("hello")
        
         setTriggerGrid(false);
         setIsFlipped(false);
    }

    return (
        <>
             <GridPageStyled>
                      <GridTitleContainerStyled>
                            White Coffee Spot
                        </GridTitleContainerStyled>
                    <GridContainerStyled>
                        <Grid container spacing = {2} rowSpacing={2}>
                            <Grid item xs = {12} sm = {12}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/wcs-grid/LSD-Website-WCS-V1-01.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/wcs-grid/LSD-Website-WCS-V1-02.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/wcs-grid/LSD-Website-WCS-V1-03.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/wcs-grid/LSD-Website-WCS-V1-04.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {12}>
                                <VideoContainerStyled>
                                    <video controls muted poster = "/lsd_pics/wcs-grid/LSD-Website-video 1- frame.jpg">
                                        <source src = "/lsd_pics/wcs-grid/LSD-Website-video 1.mp4" type = "video/mp4"/>
                                    </video>
                                </VideoContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {8}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/wcs-grid/LSD-Website-WCS-V1-06.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/wcs-grid/LSD-Website-WCS-V1-07.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {12}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/wcs-grid/LSD-Website-WCS-V1-08.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {12}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/wcs-grid/LSD-Website-WCS-V1-09.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {6}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/wcs-grid/LSD-Website-WCS-V1-10.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {6}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/wcs-grid/LSD-Website-WCS-V1-11.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {12}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/wcs-grid/LSD-Website-WCS-V1-12.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {12}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/wcs-grid/LSD-Website-WCS-V1-13.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                        </Grid>
                    </GridContainerStyled>
                </GridPageStyled>
        </>
    )
}

const VideoContainerStyled = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    overflow: hidden;


    video {
   
        width: 100%;
        height: 100%;
    }
`

export default  WhiteCoffeeSpotGrid;