import styled from "styled-components"

function CheeseCakeEn({}) {
    return (
        <>
            <div>
                <div>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/ergon-menu-desgins/CheeseCake Pics/cheeseCake-Berry.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>Vanilla cheesecake<br/>
                            <ItemDescriptionParStyled>
                               <TextItalicBoxStyled>
                                with digestive & red fruits
                               </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>37 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                </div>
                <div>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/CheeseCake Pics/cheeseCake-Caramel.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>Salted caramel cheesecake<br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>
                                    with speculoos & milk chocolate
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>37 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                </div>
                <div>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/CheeseCake Pics/cheeseCake-Pistachio.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>Pistachio cheesecake <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>
                                    with digestive
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>37 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                </div>
            </div>
            
        </>
    )
}

function CheeseCakeAr({}) {
    return (
        <>
            <div>
                <div>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                            <img src = "/ergon-menu-desgins/CheeseCake Pics/cheeseCake-Berry.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>شيزكيك الفانيلا<br/>
                            <ItemDescriptionParArStyled>
                               <TextItalicBoxStyled>
                               مع ديجستف والفواكة الحمراء
                               </TextItalicBoxStyled>
                               </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٣٧ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                </div>
                <div>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/CheeseCake Pics/cheeseCake-Caramel.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>شيز كيك الكراميل المملح<br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>
                                مع سبيكولوس والشوكولاته
                                </TextItalicBoxStyled>
                                </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٣٧ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                </div>
                <div>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/CheeseCake Pics/cheeseCake-Pistachio.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>شيز كيك الفستق الحلبي<br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>
                                مع ديجستف
                                </TextItalicBoxStyled>
                                </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٣٧ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                </div>
            </div>
            
        </>
    )
}


const SingleItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
    /* img {
        width: 60%;
    } */
`

const SingleItemContainerStyledAr = styled(SingleItemContainerStyled)`
    flex-direction: row-reverse;
`

const ImageContainerStyled = styled.div`
    position: relative;
    max-width: 100px;
    width: 100%;
    img {
        width: 100%;
      
    }
`

const ImageContainerStyledAr = styled(ImageContainerStyled)`
    transform: scaleX(-1);
`

const ItemDescriptionStyled = styled.div`
    font-family: "Gotham Greek Book";
    font-size: 14px;
    p {
        margin: 0px;
        line-height: 15px;
    }
`
const ItemDescriptionParStyled = styled.div`
    padding-right: 20px;
`

const ItemDescriptionParArStyled = styled.div`
    padding-left: 20px;
`

const ItemDescriptionArStyled = styled(ItemDescriptionStyled)`
    font-family: "GE SS Text";
    font-size: 15px;
    text-align: right;
`

const TextItalicBoxStyled = styled.span`
    font-weight: lighter;
    font-style: italic;
    font-size: 12px;
`
const PriceBoxStyled = styled.div`
    position: relative;
    margin-top: 4px;
    font-weight: bold;
    font-size: 12px;
`

export {
    CheeseCakeEn,
    CheeseCakeAr,
};