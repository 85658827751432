import styled from "styled-components";
import { GridPageStyled, GridContainerStyled, GridTitleContainerStyled, GridImageContainerStyled, ButtonContainerStyled } from "./GridCommanStyles";
import {Grid} from "@mui/material";


function ChariotGrid({setTriggerGrid, setIsClicked, isClicked}) {

    //close the grid and change the logo 
    function handleTriggerfn() {
        document.body.style.overflow = 'auto'
        setTriggerGrid(false);
        setIsClicked(false);
   }

    return (
        <>
          
                <GridPageStyled>
                    <GridTitleContainerStyled>
                            Chariot Diner
                    </GridTitleContainerStyled>
                    <GridContainerStyled>
                        <Grid container spacing = {2} rowSpacing={2}>
                            <Grid item xs = {12} sm = {12}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/chariot-grid/1-LSD-Website-Chariot.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {6}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/chariot-grid/2-LSD-Website-Chariot.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {6}>
                                <VideoContainerStyled>
                                    <video autoPlay loop controls muted>
                                        <source src = "/lsd_pics/chariot-grid/3-LSD-Website-Chariot.mp4" type = "video/mp4"/>
                                    </video>
                                </VideoContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/chariot-grid/4-LSD-Website-Chariot.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/chariot-grid/5-LSD-Website-Chariot.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/chariot-grid/6-LSD-Website-Chariot.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {12}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/chariot-grid/7-LSD-Website-Chariot.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {8}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/chariot-grid/8-LSD-Website-Chariot.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/chariot-grid/9-LSD-Website-Chariot.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/chariot-grid/10-LSD-Website-Chariot.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/chariot-grid/11-LSD-Website-Chariot.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/chariot-grid/12-LSD-Website-Chariot.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {12}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/chariot-grid/13-LSD-Website-Chariot.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {8}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/chariot-grid/14-LSD-Website-Chariot.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <VideoContainerStyled>
                                    <video controls muted>
                                        <source src = "/lsd_pics/chariot-grid/15-14-LSD-Website-Chariot.mp4" type = "video/mp4"/>
                                    </video>
                                </VideoContainerStyled>
                            </Grid>
                        </Grid>
                    </GridContainerStyled>
                </GridPageStyled>
        </>
    )
}

const VideoContainerStyled = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    overflow: hidden;


    video {
   
        width: 100%;
        height: 100%;
    }
`

export default ChariotGrid;