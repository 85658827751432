import {Box, TextField} from "@mui/material";
import styled from "styled-components";
import InputAdornment from '@mui/material/InputAdornment';
import { useState, useEffect } from "react";
import {Button, MenuItem} from "@mui/material"
import axios from 'axios';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css'
import {Link, useLocation, useNavigate} from "react-router-dom";

//this component will be deleted and everyhting will be on the same page
//this component is for searching for item that exists in the data base and we can either delete or edit them/add item.
function UpdateItem() {

    const url = 'https://www.lovelysunnydayapi.com/api/v1/testMenus'; //make this dynamic later
    const [items, setItems] = useState(null) //the items we get back from the data
    const [search, setSearch] = useState(""); //state for the search term
    const [category, setCategory] = useState("") //we will search by category
    const [itemID, setItemID] = useState("");
    const [popUpOpen, setPopUpOpen] = useState(false);
    const [editPopUpOpen, setEditPopUpOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const from  = location.state?.from?.pathname || "/restaurants/menus/login";

    const categoryList = [
        {
            value: "BREAKFAST",
            label: "BREAKFAST",
        },
        {
            value: "A LA CARTE",
            label: "A LA CARTE",
        },

        {
            value: "BOUTIQUE",
            label: "BOUTIQUE",
        },

        {
            value: "",
            label: "all",
        }
    ]


    //gets all items from the database
    const getAllItems = async() => {
        try {
            const link = `${url}?search=${search}&category=${category}` 
            console.log(link)
            const {data} = await axios.get(link, {
                withCredentials: true, 
            });
            setItems(data);
            console.log(data)
        } catch (error) {
            console.log(error)
        }
    }

    //handles the search field
     function handleSearch(e) {
        const {name, value} = e.target;
        setSearch(value);
  
    }

    //handles the category field
    async function handleCategory(e) {
        const {name, value} = e.target;
        setCategory(value);
    }

    //deletes item from the database
    async function deleteItem() {
        const link = `${url}/${itemID}`
        const response = await axios.delete(link, {
            withCredentials: true,
        })
        console.log('item deleted successfully: ', response.data)
        setPopUpOpen(false);
        getAllItems();
    }

    //opens the pop for deleting item
    function handleItemPopUp(_id) {
        //set pop up screen here
        setPopUpOpen(true);
        //set id here
        setItemID(_id);
    }

    //closes any pop up screen
    function closePopUp() {
        setPopUpOpen(false);
    }

    async function handleLogout(e) {
        e.preventDefault();
        try {
            await fetch("https://www.lovelysunnydayapi.com/api/v1/auth/logout", {
                credentials: "include",
            })
            localStorage.removeItem("auth")
            navigate(from, {replace: true})
        } catch (error) {
            
        }
    }

    //get all the items on load of page
    useEffect(() => {
        getAllItems();
    }, [search, category])

    return (
        <>
            {popUpOpen && <ClosePopUpStyled>
                Are you sure you want to delete this item ?
                <DeleteInsideButtonStyled onClick = {deleteItem}>
                    Yes
                </DeleteInsideButtonStyled>
                <ClosePopButtonStyled onClick = {closePopUp}>
                    close
                </ClosePopButtonStyled>
            </ClosePopUpStyled>}
            <ButtonWrapperStyled>
                    <ButtonContainerStyled>
                        <Button
                            onClick = {handleLogout}
                            variant = "contained"
                            color = "primary"
                        >
                            LOG OUT
                        </Button>
                    </ButtonContainerStyled>
                    <ButtonContainerStyled>
                        <Button
                            component = {Link}
                            to = "/restaurants/menus/le-bleu/admin/add-item"
                            target = "_blank"
                            variant = "contained"
                            color = "primary"
                        >
                            ADD ITEM
                        </Button>
                    </ButtonContainerStyled>
                </ButtonWrapperStyled>
            <SearchFormStyled>
                <SearchFormTitleStyled>
                    Search Form
                </SearchFormTitleStyled>
                <FormContainerStyled>
                    <Box
                        component = "form"
                        sx = {{
                            display: 'flex',
                            gap: 5,
                            '& .MuiTextField-root': {m: 1, width: '25ch'},
                        }}
                        noValidate
                        autoCapitalize="off"
                    >
                        <TextField
                            id = "standard-basic"
                            label = "Search"
                            name = "search"
                            value = {search}
                            onChange = {handleSearch}
                        ></TextField>
                        <TextField
                            
                            id = "standard-select"
                            select
                            label = "Category"
                            name = "category"
                            value = {category}
                            onChange = {handleCategory}
                            helperText = "Please select a category"
                        >
                          
                            {categoryList.map((option) => (
                                <MenuItem key = {option.value} value = {option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </FormContainerStyled>
            </SearchFormStyled>
            {items  != null && <DisplayItemsContainerStyled >
                {items.items.map(({name_en, price, _id}) => {
                    return (
                        <ItemBoxStyled>
                               <ButtonsStyled>
                                <DeleteButtonStyled onClick = {() => handleItemPopUp(_id)}>
                                    del
                                </DeleteButtonStyled>
                                <EditButtonStyled>
                                    <Link to = {`/restaurants/menus/le-bleu/admin/edit-item/${_id}`} target = '_blank'>
                                        edit
                                    </Link>
                                </EditButtonStyled>
                               </ButtonsStyled>
                               <ItemContainerStyled>
                                <div>
                                    <span>name: {name_en}</span>
                                </div>
                                <div>
                                    <span>price: {price}</span>
                                </div>
                             </ItemContainerStyled>
                        </ItemBoxStyled>
                    )
                })}
            </DisplayItemsContainerStyled>}
        </>
    )
}



const ClosePopUpStyled = styled.div`
    position: absolute;
    inset: 0;
    margin: 0 auto;
    top: 25%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    width: 50%;
    height: 50vh;
    background-color: red;
    padding:  10px;
`
const SearchFormStyled = styled.div`
    position: relative;
    margin: 0 auto;
    width: 60%;
    margin-top: 40px;
    padding: 20px 40px;
    background-color: lightgray;
`

const SearchFormTitleStyled = styled.div`
    position: relative;
    margin-bottom: 40px;
`

const FormContainerStyled = styled.div`
    position: relative;
    
`

const DisplayItemsContainerStyled = styled.div`
    position: relative;
    display: flex;
    gap: 50px;
    margin-left: 40px;
    margin-top: 40px;
    flex-wrap: wrap;
`

const ItemBoxStyled = styled.div`
    position: relative;
`
const ItemContainerStyled = styled.div`
    position: relative;
    display: flex;
    gap: 30px;
    padding: 5px 10px;
    border: 1px solid blue;
`
const ButtonsStyled = styled.div`
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: turquoise;
    padding: 20px;
`


const DeleteButtonStyled = styled.div`
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    width: 50px;
    padding: 5px 0px;
    border: 1px solid green;
    
`
const EditButtonStyled = styled.div`
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 60px;
    width: 50px;
    padding: 5px 0px;
    border: 1px solid green;
`

const DeleteInsideButtonStyled = styled.div`
    padding: 15px 30px;
   
    margin-left: 10px;
    background-color: blue;
    cursor: pointer;
`

const ClosePopButtonStyled = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    top: 0;
    right: 0px;
    background-color: blue;
    cursor: pointer;
`

const ButtonWrapperStyled = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 40px 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
`

const ButtonContainerStyled = styled.div`
    position: relative;
    scale: 1;
    transition: all 300ms;
    &:hover {
        scale: 1.05
    }
`


export default UpdateItem;