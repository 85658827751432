const sharing_plates = [
    {
        name: "Bone marrow",
        name_ar: "نخاع العظام",
        price: "55",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Bone Marrow.jpg",
    },

    {
        name: "Korean fries",
        name_ar: "بطاطس كورية",
        price: "58",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Korean fries.jpg",
    },

    {
        name: "Short rib tacos",
        name_ar: "تاكو ضلع قصير",
        price: "85",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- short Ribs Tacos.jpg",
    },

    {
        name: "Crispy chicken tacos",
        name_ar: "تاكو دجاج مقرمش",
        price: "55",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Cripsy Chicken Tacos.jpg",
    },

    {
        name: "Kaffir coconut chicken skewers",
        name_ar: "أسياخ دجاج جوز الهند الكفير",
        price: "75",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- kaffir Coconut Chicken Skewers.jpg",
    },

    {
        name: "Tendys sliders",
        name_ar: "ميني برغر تنديز",
        price: "98",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Tendy's Slider.jpg",
    },

    {
        name: "Wagyu Swiss sliders",
        name_ar: "واغيو ميني برغر مع جبنة سويسرية",
        price: "145",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Wagyu Swiss Slider.jpg",
    },

    {
        name: "Sliced Rib eye",
        name_ar: "شريحة لحم الضلع المقطعة",
        price: "190",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Sliced Ribeye.jpg",
    },

    {
        name: "Signature lamb chops",
        name_ar: "شرائح لحم الخروف المميزة",
        price: "85",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Signature Lamb Chops.jpg",
    },

    {
        name: "Chicken sliders (grilled)",
        name_ar: "ميني برغر دجاج (مشوي)",
        price: "55",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Chicken Slider 13 Grilled.jpg",
    },

    {
        name: "Chicken sliders (fried)",
        name_ar: "ميني برغر دجاج (مقلي)",
        price: "55",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Chicken Slider 13 Fried.jpg",
    },
]


export default sharing_plates;