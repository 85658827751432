import styled from "styled-components";

function WorkEnvironmental({}) {
    return (
        <>
            <WorkEnvironmentalStyled>
                <div>
                    environmentl section goes here
                </div>
            </WorkEnvironmentalStyled>
        </>
    )
}

const WorkEnvironmentalStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default WorkEnvironmental