import styled from "styled-components";

function WorkMotion({}) {
    return (
        <>
            <WorkMotionStyled>
                <div>
                    work motion section goes here
                </div>
            </WorkMotionStyled>
        </>
    )
}


const WorkMotionStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default WorkMotion;