

const toShareEn = [

    {
        name: "KALE, ",
        details: " PUFFED-RICE, HERBS, RADISH, CUCUMBER, CAULIFLOWER, RAISIN, CITRUS DRESSING",
        price: "50",
        img_path: "/baron-menu-designs-2/toShare/01 Kale-72dpi.jpg"

    },

    {
        name: "ZUCCHINI, ",
        details: " FETA, SHALLOT DRESSING, RADISH, PISTACHIO, CRISP SHALLOT, MINT",
        price: "50",
        img_path: "/baron-menu-designs-2/toShare/02 Zucchini-72dpi.jpg"

    },

    {
        name: "BABY GEM ",
        details: " CUCUMBER, RADISH, POMEGRANATE, SPRING ONION, LEMON DRESSING",
        price: "45",
        img_path: "/baron-menu-designs-2/toShare/03 BABY-GEM-1072x800px.jpg"

    },

    {
        name: "PAROS, ",
        details: " HEIRLOOM TOMATOES, WHIPPED FETA, KALAMATA, DAKOS, LEMON DRESSING",
        price: "65",
        img_path: "/baron-menu-designs-2/toShare/04 PAROS-1072x800px.jpg"

    },

    {
        name: "MUSHROOM, ",
        details: " WHIPPED CHICKPEA, DUQQAH, HERB CHUTNEY, BARON BREAD",
        price: "45",
        img_path: "/baron-menu-designs-2/toShare/05 Mushroom 2-edit-72dpi.jpg"

    },


    {
        name: "CORN, ",
        details: " ROASTED, FETA, PEPPERS, SOFT HERBS, CHILLI, LEMON",
        price: "55",
        img_path: "/baron-menu-designs-2/toShare/06 Corn-72dpi.jpg"

    },

    {
        name: "DATES, ",
        details: " SOUJOUK, TOMATO, DIBS RUMMAN, PISTACHIO, CRISP HERBS",
        price: "65",
        img_path: "/baron-menu-designs-2/toShare/07 Date-edit-72dpi.jpg"

    },

    {
        name: "EGGPLANT, ",
        details: " ROASTED, SOFT HERBS, FETA, PEPPER, PINE NUT",
        price: "55",
        img_path: "/baron-menu-designs-2/toShare/08 Eggplant-72dpi.jpg"

    },

    {
        name: "HALLOUMI, ",
        details: " ROASTED, PISTACHIO, TOMATO CHUTNEY, ALLEPO PEPPER, CRISP HERBS",
        price: "65",
        img_path: "/baron-menu-designs-2/toShare/09 Halloumi-72dpi.jpg"

    },

    {
        name: "BIZRI, ",
        details: " CRISP BABY SHRIMP, HERBS, PADRON, CHATTA, KEWPIE, LIME",
        price: "50",
        img_path: "/baron-menu-designs-2/toShare/10 BIZRI-1072x800px.jpg"

    },

    {
        name: "SCALLOP, ",
        details: " ON-SHELL, MISO BUTTER, SOY-LEMON DRESSING, SCALLION-CHUTNEY, CHILLI",
        price: "135",
        img_path: "/baron-menu-designs-2/toShare/04 Scallop-72dpi.jpg"

    },

    {
        name: "CALAMARI, ",
        details: " CRISP, CORIANDER AIOLI, LEMON, CHATTA",
        price: "80",
        img_path: "/baron-menu-designs-2/toShare/11 Calamari-72dpi.jpg"

    },

    {
        name: "TENDERLOIN SKEWERS, ",
        details: " TAHINA, POMEGRANATE, SOFT HERBS, CRISP SHALLOT, BREAD",
        price: "95",
        img_path: "/baron-menu-designs-2/toShare/13 TENDERLOIN-SKEWERS-1072x800px.jpg"
 
    },

    {
        name: "CAULIFLOWER, ",
        details: " WHOLE ROASTED, “SPICED”, YOGHURT TAHINI, WALNUT, POMEGRANATE, ROSE",
        price: "95",
        img_path: "/baron-menu-designs-2/toShare/14 Cauliflower-72dpi.jpg"

    },
]

const toShareAr = [

    {
        name: "كوسا، ",
        details: "جبنة فيتا، صلصة الكراث، فجل، فستق، كراث مقرمش، نعناع ",
        price: "50",
        img_path: "/baron-menu-designs-2/toShare/02 Zucchini-72dpi.jpg"

    },

    {
        name: "كرنب، ",
        details: "أرز مقرمش، أعشاب، خيار، زهرة، زبيب، فجل، صلصة الحامضيات ",
        price: "50",
        img_path: "/baron-menu-designs-2/toShare/01 Kale-72dpi.jpg"

    },

    {
        name: "سلطة باروس، ",
        details: "طماطم ،جبنة فيتا مخفوقة، زيتون كالاماتا، داكوس، صلصة الليمون ",
        price: "65",
        img_path: "/baron-menu-designs-2/toShare/04 PAROS-1072x800px.jpg"

    },

    {
        name: "خس صغير، ",
        details: "خيار، فجل، رمان، بصل أخضر، صلصة الليمون ",
        price: "45",
        img_path: "/baron-menu-designs-2/toShare/03 BABY-GEM-1072x800px.jpg"

    },

    {
        name: "ذرة، ",
        details: "محمصة، جبنة فيتا، فلفل، حبوب الذرة، أعشاب ناعمة، فلفل حار، ليمون ",
        price: "65",
        img_path: "/baron-menu-designs-2/toShare/06 Corn-72dpi.jpg"

    },

    {
        name: "فطر، ",
        details: "حمص مخفوق، دقة، صلصة الأعشاب، ليمون، خبز بارون ",
        price: "45",
        img_path: "/baron-menu-designs-2/toShare/05 Mushroom 2-edit-72dpi.jpg"

    },

    {
        name: "باذنجان مشوي، ",
        details: "أعشاب مقرمشة، فليفلة، صنوبر، طحينة ",
        price: "55",
        img_path: "/baron-menu-designs-2/toShare/08 Eggplant-72dpi.jpg"

    },


    {
        name: "تمر، ",
        details: "سجق، لحم مقدد، طماطم شاتني، دبس الرمان، فستق، أعشاب مقرمشة ",
        price: "65",
        img_path: "/baron-menu-designs-2/toShare/07 Date-edit-72dpi.jpg"

    },

    {
        name: "بزري، ",
        details: "روبيان صغير مقرمش، أعشاب، فلفل بادرون، صلصة شطة، كيوبي، ليمون ",
        price: "55",
        img_path: "/baron-menu-designs-2/toShare/10 BIZRI-1072x800px.jpg"

    },

    {
        name: "حلومي مشوي، ",
        details: "زبدة، فستق، صلصة الطماطم، زبدة حلبية ",
        price: "65",
        img_path: "/baron-menu-designs-2/toShare/09 Halloumi-72dpi.jpg"

    },

    {
        name: "كاليمار، ",
        details: "ليمون، شطة ، كزبرة ",
        price: "80",
        img_path: "/baron-menu-designs-2/toShare/11 Calamari-72dpi.jpg"

    },

    {
        name: "سكلوب، ",
        details: "سكالوب مع القشر، زبدة ميسو، صلصة البصل الأخضر، صويا وليمون ",
        price: "135",
        img_path: "/baron-menu-designs-2/toShare/04 Scallop-72dpi.jpg"

    },

    {
        name: "قرنبيط، ",
        details: "زهرة مشوية زبدة مبهرة، طراطور طحينة باللبن، صلصة الجوز، رمان، ورقيات، بتلة الورد",
        price: "95",
        img_path: "/baron-menu-designs-2/toShare/14 Cauliflower-72dpi.jpg"

    },

    {
        name: "أسياخ تندرلوين، ",
        details: "طحينة، رمان، أعشاب طازجة، كراث مقرمش، خبز",
        price: "95",
        img_path: "/baron-menu-designs-2/toShare/13 TENDERLOIN-SKEWERS-1072x800px.jpg"

    },
]

export {
    toShareAr,
    toShareEn,
}