import styled from "styled-components";


function CoverContainer({setShowCover}) {

    function handleClick() {
        setShowCover(false);
    }
    return (
        <>
            <CoverContainerStyled onClick = {handleClick}>
                <LogoContainerStyled>
                    <img src = "/leBleu-menu-designs/LB-Logo-Black.png"/>
                </LogoContainerStyled>
                <CoverImageContainerStyled>
                    <img src = "/leBleu-menu-designs/LB-BonAppetit-illust.png"/>
                </CoverImageContainerStyled>
                <MenuImageStyled>
                    <img src = "/leBleu-menu-designs/LB-buttonMenu.png"/>
                </MenuImageStyled>
            </CoverContainerStyled>
        </>
    )
}

const CoverContainerStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 60px;
    padding: 30px;
    margin-top: -10px;
`

const LogoContainerStyled = styled.div`
    position: relative;
    width: 35%;
    margin-top: 55px;
    

    img {
        width: 100%;
    }
`

const MenuImageStyled = styled.div`
    position: relative;
    width: 50%;
    margin-top: 10px;
    

    img {
        width: 100%;
    }
`

const CoverImageContainerStyled = styled.div`
    position: relative;
    width: 80%;

    img {
        width: 100%;
    }
`

export default CoverContainer;