const veggie_plates = [
    {
        name: "Baby gem salad",
        name_ar: "سلطة الجوهرة الصغيرة",
        price: "58",
        image_path: "/habra-menu-designs/veggie/Habra Menu- Baby Gem Salad.jpg",
    },

    {
        name: "Baked beetroot",
        name_ar: "الشمندر المخبوز",
        price: "35",
        image_path: "/habra-menu-designs/veggie/Habra Menu- Baked Beetroot.jpg",
    },

    {
        name: "Crispy okra",
        name_ar: "البامية المقرمشة",
        price: "35",
        image_path: "/habra-menu-designs/veggie/Habra Menu- Crispy Okra.jpg",
    },

    {
        name: "Grilled avocado",
        name_ar: "أفوكادو مشوي",
        price: "45",
        image_path: "/habra-menu-designs/veggie/Habra Menu- Grilled Avocado.jpg",
    },

    {
        name: "Kale & nouri slaw",
        name_ar: "الكايل و النوري سلو",
        price: "32",
        image_path: "/habra-menu-designs/veggie/Habra Menu- Kale & Nori.jpg",
    },

    {
        name: "Roasted baby carrots",
        name_ar: "جزر صغير مشوي",
        price: "52",
        image_path: "/habra-menu-designs/veggie/Habra Menu- Roasted Baby Carrots.jpg",
    },

    {
        name: "Baked sweet potato",
        name_ar: "البطاطا الحلوة المخبوزة",
        price: "39",
        image_path: "/habra-menu-designs/veggie/Habra Menu- Baked Sweet Potato.jpg",
    },
]

export default veggie_plates;