const chefs_recommendation = [

    {
        name: "Habra salad",
        name_ar: "سلطة هبرا",
        price: "28",
        image_path: "/habra-menu-designs/chef's recommendation/Habra Menu- Habra Salad.jpg",
    },

    {
        name: "Habra carpaccio",
        name_ar: "هابرا كارباتشيو",
        price: "85",
        image_path: "/habra-menu-designs/chef's recommendation/Habra Menu- Harba Carpacio.jpg",
    },

    {
        name: "Sous vide miso lamb rack",
        name_ar: "أضلاع لحم الخروف بطريقة السو ڤيد ( لشخصين)",
        price: "260",
        image_path: "/habra-menu-designs/chef's recommendation/Habra Menu- Sous Vide Miso Lamb Rack.jpg",
    },

    {
        name: "Wagyu in kaffir stems",
        name_ar: "واغيو في الكفير",
        price: "120",
        image_path: "/habra-menu-designs/chef's recommendation/Habra Menu- Wagyu in Keffir.jpg",
    },
]

export default chefs_recommendation;