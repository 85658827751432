
import { useState, useRef } from "react";
import styled from "styled-components";
import AfternoonTeaMenus from "./AfternoonTeaMenus";

function AfternoonTea() {
    
//     const [showDish, setShowDish] = useState(-1);
//     if(showDish < 0) {
//         return (
//             <>
//                 {
//                     <BtnTempStyled>
//                         <a href = "/restaurants/menus/le-train-bleu/Afternoon-tea">
//                             click for afternoon menu
//                         </a>
//                     </BtnTempStyled>
//                 }
//             </>
//         )
//     }

        const [showMenu, setShowMenu] = useState(0);
        const full_menu = useRef(0);
        const enhanced_menu  = useRef(0);
        const cream_tea = useRef(0);

        function handleChange(e) {
            setShowMenu(e);
            if(e == '1') {
                full_menu.current.style.color = "#dbbc74";
                enhanced_menu.current.style.color = "white";
                cream_tea.current.style.color = "white";
            }
            if(e == '2') {
                full_menu.current.style.color = "white";
                enhanced_menu.current.style.color = "#dbbc74";
                cream_tea.current.style.color = "white";
            }
            if(e == '3') {
                full_menu.current.style.color = "white";
                enhanced_menu.current.style.color = "white";
                cream_tea.current.style.color = "#dbbc74";
            }

        }
        return (
            <>
                <AfternoonTeaCover>
                    <img src = "/blt-menu-designs/afternoonTea-cover-pic.jpg"/>
                </AfternoonTeaCover>
                <div className="afternoon_tea_menus">
                    <div>
                        <MenuNameStyled  ref = {full_menu} onClick = {() => handleChange("1")}>
                            <span>FULL MENU</span>
                            <span>QR 250</span>
                        </MenuNameStyled>
                    </div>
                    <div>
                        <MenuNameStyled ref = {enhanced_menu} onClick = {() => handleChange("2")}>
                            <span>ENHANCED MENU</span>
                            <span>QR 285</span>
                        </MenuNameStyled>
                    </div>
                    <div>
                        <MenuNameStyled ref = {cream_tea} onClick = {() => handleChange("3")} >
                            <span>CREAM TEA</span>
                            <span>QR 99</span>
                        </MenuNameStyled>
                    </div>
                </div>
                <AfternoonTeaMenus menu_id = {showMenu}/>
            </>
        )
    }

const AfternoonTeaCover = styled.div`
    position: relative;

    img {
        width: 100%;
    }
`

const BtnTempStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const MenuNameStyled = styled.div`
    font-size: 12px;
    font-family: "Palatino";
    display: flex;
    flex-direction: column;
    gap: 8px;
    color:white;
    text-align: center;
`
export default AfternoonTea;