import styled from "styled-components";
import {CategoryHeadingStyled, SingleFoodItemIndexStyled, FoodItemImageContainer, SingleFoodItemDescription, FoodTextContainerStyled, ItemNameEn, ItemNameAr, ItemPriceStyled, CopyRightStyled} from "./ChariotCommonStyled";


function SweetsGrid({}) {
    return (
        <>
            <CategoryHeadingStyled>
                <img src = "/chariot-menu-designs/Sweets PNG/Chariot-Sweets-V1-10.png" alt = "sweets Category chariot menu"/>
            </CategoryHeadingStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Sweets PNG/Chariot-Sweets-V1-03.png" alt = "HOT CAKE WITH DULCE DE LECHE SAUCE chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>HOT CAKE WITH DULCE DE LECHE SAUCE</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>الكيكة السخنة مع الدولشي دي ليشي</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>45 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Sweets PNG/Chariot-Sweets-V1-04.png" alt = "CRO-WAFFLE WITH ICE CREAM & COCOA SAUCE chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>CRO-WAFFLE WITH ICE CREAM & COCOA SAUCE</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>وافل الكرواسون مع الأيس كريم والكاكاو</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>43 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Sweets PNG/Chariot-Sweets-V1-05.png" alt = "CRO-WAFFLE W/BANANA, NUTELLA & ICE CREAM chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>CRO-WAFFLE W/BANANA, NUTELLA & ICE CREAM</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>وافل الكرواسون محشي بالنوتيلا والموز مع الأيس كريم</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>43 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Sweets PNG/Chariot-Sweets-V1-06.png" alt = "CRO-WAFFLE W/STRAWBERRY, NUTELLA & ICE CREAM chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>CRO-WAFFLE W/STRAWBERRY, NUTELLA & ICE CREAM</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>وافل الكرواسون محشي</span>
                        <br/>
                        <span> بالنوتيلا والفراولة مع الأيس كريم</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>43 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Sweets PNG/Chariot-Sweets-V1-07.png" alt = "FRENCH TOAST CHEESE AND JAM chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>FRENCH TOAST CHEESE AND JAM</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>فرنش توست جبنة ومربة</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>51 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Sweets PNG/Chariot-Sweets-V1-08.png" alt = "FRENCH TOAST DULCHE DE LECHE chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>FRENCH TOAST DULCHE DE LECHE</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>فرنش توست الدولشي دي ليشي</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>51 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Sweets PNG/Chariot-Sweets-V1-09.png" alt = "FRENCH TOAST MAPLE SYRUP chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>FRENCH TOAST MAPLE SYRUP</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>فرنش توست شراب القيقب</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}> 51 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Sweets PNG/Chariot-Sweets-V1-11.png" alt = "FRENCH TOAST MAPLE SYRUP chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>OLD TOWN CHOCOLATE FONDANT</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>آولد تاون فندان الشوكولاتة</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>39 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Sweets PNG/sweets- monsterCookie.png" alt = "MONSTER COOKIES chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>MONSTER COOKIES W/NUTELLA & LOTUS ICE CREAM</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>منستر  كوكيز مع آيس كريم النوتيلا واللوتس</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>37 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Sweets PNG/sweets- cheesecake.png" alt = "chariot menu sweets cheeecake"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>NY CHEESECAKE SUNDAE</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>نيو يورك تشيز كيك سنداي</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>37 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
        </>
    )
}

export default SweetsGrid;