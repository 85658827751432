import styled from "styled-components";
import {CategoryHeadingStyled, SingleFoodItemIndexStyled, FoodItemImageContainer, SingleFoodItemDescription, FoodTextContainerStyled, ItemNameEn, ItemNameAr, ItemPriceStyled} from "./ChariotCommonStyled";

function IcedTeaGrid({}) {
    return (
        <>
            <CategoryHeadingStyled>
                <img src = "/chariot-menu-designs/Iced Tea PNG/Chariot-IcedTea-V1-03.png" alt = "Iced tea Category chariot menu"/>
            </CategoryHeadingStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Iced Tea PNG/Chariot-IcedTea-V1-02.png" alt = " summer blast chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>SUMMER BLAST</span>
                    </ItemNameEn>
                    <ItemNameAr>
                       <span>سمر بلاست</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>27 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Iced Tea PNG/Chariot-IcedTea-V1-04.png" alt = "peach chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>PEACH</span>
                    </ItemNameEn>
                    <ItemNameAr>
                       <span>خوخ</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>25 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Iced Tea PNG/Chariot-IcedTea-V1-05.png" alt = "passion chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>   
                    <ItemNameEn>
                        <span>PASSION</span>
                    </ItemNameEn>
                    <ItemNameAr>
                       <span>باشون</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>25 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled> 
        </>
    )
}

export default IcedTeaGrid;