import styled from "styled-components";
import {CategoryHeadingStyled, SingleFoodItemIndexStyled, FoodItemImageContainer, SingleFoodItemDescription, FoodTextContainerStyled, ItemNameEn, ItemNameAr, ItemPriceStyled} from "./ChariotCommonStyled";


function MilkshakesGrid({}) {
    return (
        <>
            <CategoryHeadingStyled>
                <img src = "/chariot-menu-designs/Milkshakes PNG/Chariot-Milkshakes-V1-03.png" alt = " Milkshakes chariot menu"/>
            </CategoryHeadingStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Milkshakes PNG/Chariot-Milkshakes-V1-02.png" alt = "STRAWBERRY milkshake chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>STRAWBERRY</span>
                    </ItemNameEn>
                    <ItemNameAr>
                       <span>فراولة</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>32 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Milkshakes PNG/Chariot-Milkshakes-V1-04.png" alt = "oreo milkshake chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>OREO</span>
                    </ItemNameEn>
                    <ItemNameAr>
                       <span>اوريو</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>31 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Milkshakes PNG/Chariot-Milkshakes-V1-05.png" alt = "banana lotus milkshake chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>BANANA LOTUS</span>
                    </ItemNameEn>
                    <ItemNameAr>
                       <span>موز واللوتس</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>33 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
        </>
    )
}

export default MilkshakesGrid;