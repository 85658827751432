import styled from "styled-components";


const CategoryHeaderStyled = styled.div`
    position: relative;
    text-align: center;
    margin-bottom: 30px;
`

const CategoryTitleArStyled = styled.div`
    position: relative;
    font-family: "GE SS Text";
    font-size: 20px;
    margin-top: 60px;
`

const CategoryTitleEnglishStyled = styled.div`
    position: relative;
    font-family: "Professor";
    font-size: 30px;
    text-align: center;
    padding: 0px 50px;
    margin-bottom: 20px;
`

const ItemComponentStyled = styled.div`
    position: relative;
    text-align: center;
    padding: 0px 20px;
    margin-bottom: 20px;
`

const ItemTextArStyled = styled.div`
    position: relative;
    margin-bottom: 3px;
    font-family: "GE SS Text";
`

const ItemTextEnStyled = styled.div`
    position: relative;
    font-family: "Futura Std Book";
`

const AwarenessComponentStyled = styled.div`
    position: relative;
    text-align: center;
    padding: 0px 20px;
    margin-top: 80px;
    font-family: "Futura Std Book"
`
const AwarnessLineOneStyled = styled.div`
    position: relative;
    color: #006580;
    font-size: 12px;
`

const AwarnessLineTwoStyled = styled.div`
    position: relative;
    font-size: 10px;
    margin-top: -12px;
`

const AfternoonImageCoverStyled = styled.div`
    position: relative;
    margin-top: 30px;
    padding: 0px 40px;
    width: 100%;
    background-color: white;
    top: 100px;

  
    img {
        width: 100%;
    }
`


export {
    CategoryHeaderStyled,
    CategoryTitleArStyled,
    CategoryTitleEnglishStyled,
    ItemComponentStyled,
    ItemTextArStyled,
    ItemTextEnStyled,
    AwarenessComponentStyled,
    AwarnessLineOneStyled,
    AwarnessLineTwoStyled,
    AfternoonImageCoverStyled
}