//this 

import {BreakfastEggs, BreakfastToasts, BreakfastYogurt} from "./"
import {useEffect, useRef} from "react"

function BreakfastComps({breakfastEls, activeCategory, navBarTwoRef}) {

    const breakfastSections = useRef([])
    let categoriesIndices = ['0','1','2']


    //tracks the section id as we scroll
    function windowScrollTracker(currentScroll) {
        var sectionFromTop;
        var sectionId;

        //get the id of the current active cateogry as we scroll
        breakfastSections.current.forEach((section) => {
            sectionFromTop = section.getBoundingClientRect().top;

            if (sectionFromTop <= 10) {
                sectionId = section.getAttribute("id");
            }            
        })

        //pass the current active section's id to the nav bar tracker function
        navBarTracker(sectionId)
    }

    //changes the colour of the active category
    function activeCategoryHandler(sectionId) {
        var index = sectionId - 1;
        breakfastEls.current[index].style.color = "#F5A800"

        categoriesIndices.map((item) => {
            if(item != index) {
                breakfastEls.current[item].style.color = "white"
            }
        })
    }

    //activates the current active section based on id
    async function navBarTracker(sectionId) {
       
        switch(sectionId) {
            //activates the eggs category
            case '1':
                navBarTwoRef.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates the toasts category
            case '2':
                navBarTwoRef.current.scrollLeft = 100;
                await activeCategoryHandler(sectionId)
            break;

            //activates the pudding & yogurt category
            case '3':
                navBarTwoRef.current.scrollLeft = 100;
                await activeCategoryHandler(sectionId)
            break;
        }

        return "resolved"
    }

    useEffect(() => {
        function handleScroll(e) {
            var currentScroll = window.pageYOffset;
            windowScrollTracker(currentScroll)
        }     

        var timer = null;

        function scrollHandler() {
            if(timer !== null) {
                clearTimeout(timer);
            }
            timer = setTimeout(function () {
                handleScroll()
            }, 50)
        }

        window.addEventListener('scroll', scrollHandler)

        //cleanup event listener
        return () => {
            window.removeEventListener('scroll', scrollHandler )
        }

    }, [])

  

    return (
        <>
            <section id = "1" ref = {(el) => breakfastSections.current[0] = el}>
                <BreakfastEggs/>
            </section>
            <section id = "2" ref = {(el) => breakfastSections.current[1] = el}>
                <BreakfastToasts/>
            </section>
            <section id = "3" ref = {(el) => breakfastSections.current[2] = el}>
                <BreakfastYogurt/>
            </section>
        </>
    )
}

export default BreakfastComps;