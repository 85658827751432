import styled from "styled-components";
import {CategoryHeadingStyled, SingleFoodItemIndexStyled, FoodItemImageContainer, SingleFoodItemDescription, FoodTextContainerStyled, ItemNameEn, ItemNameAr, ItemPriceStyled} from "./ChariotCommonStyled";


function MojitosGrid({}) {
    return (
        <>
            <CategoryHeadingStyled>
                <img src = "/chariot-menu-designs/Mojitos PNG/Chariot-Mojitos-V1-03.png" alt = "Mojitos Category chariot menu"/>
            </CategoryHeadingStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Mojitos PNG/Chariot-Mojitos-V1-02.png" alt = "SUMMER FREEZE chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>SUMMER FREEZE</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>سمر فريز</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>27 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Mojitos PNG/Chariot-Mojitos-V1-04.png" alt = "PASSION FRUIT chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>PASSION FRUIT</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>باشون فروت</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>26 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Mojitos PNG/Chariot-Mojitos-V1-05.png" alt = "CLASSIC chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>CLASSIC</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>كلاسيك</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>25 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                    <img src = "/chariot-menu-designs/Mojitos PNG/Chariot-Mojitos-V1-06.png" alt = "BLUE SHARK chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>BLUE SHARK</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>بلو شارك</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>27 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
        </>
    )
}

export default MojitosGrid;