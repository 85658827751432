let beverages = [

    {
        name: "LAVENDER LEMONADE ",
        description: "",
        price_en: "24",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-01- lavender_lemonade.jpg",
    },

    {
        name: "FRESH ORANGE JUICE ",
        description: "",
        price_en: "21",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-02- fresh_orange_juice.jpg",
    },

    {
        name: "SAKURA LEMONADE ",
        description: "",
        price_en: "21",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-03- sakura_lemonade.jpg",
    },

    {
        name: "STILL WATER ",
        description: "",
        price_en: "10",
        price_ar: "١٠",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-04- still_water.jpg",
    },

    {
        name: "SPARKLING WATER ",
        description: "",
        price_en: "10",
        price_ar: "١٠",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-05- sparkling_water.jpg",
    },

    {
        name: "CLASSIC MOJITO ",
        description: "",
        price_en: "24",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-06- classic_mojito.jpg",
    },

    {
        name: "STRAWBERRY MOJITO ",
        description: "",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-07- strawberry_mojito.jpg",
    },

    {
        name: "PASSION FRUIT MOJITO ",
        description: "",
        price_en: "29",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-08- passion_fruit_mojito.jpg",
    },

    {
        name: "PEACH MOJITO ",
        description: "",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-09-peach_mojito.jpg",
    },
]

let beveragesAr = [

    {
        name_ar: "عصير برتقال",
        description_ar: "",
        price_en: "21",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-02- fresh_orange_juice.jpg",
    },

    {
        name_ar: "لافندر ليمونيد",
        description_ar: "",
        price_en: "24",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-01- lavender_lemonade.jpg",
    },

    {
        name_ar: "ماء معدني",
        description_ar: "",
        price_en: "10",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-04- still_water.jpg",
    },

    {
        name_ar: "ساكورا ليمونيد",
        description_ar: "",
        price_en: "21",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-03- sakura_lemonade.jpg",
    },

    {
        name_ar: "موهيتو كلاسك",
        description_ar: "",
        price_en: "24",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-06- classic_mojito.jpg",
    },

    {
        name_ar: "ماء غازي",
        description_ar: "",
        price_en: "10",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-05- sparkling_water.jpg",
    },

    {
        name_ar: "موهيتو باشن فروت",
        description_ar: "",
        price_en: "29",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-08- passion_fruit_mojito.jpg",
    },

    {
        name_ar: "موهيتو فراولة",
        description_ar: "",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-07- strawberry_mojito.jpg",
    },

    {
        name_ar: "موهيتو خوخ",
        description_ar: "",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/beverages/Beverages-09-peach_mojito.jpg",
    },
]


let coffee = [

    {
        name: "ESPRESSO ",
        description: "",
        price_en: "14",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-01-espresso.jpg",
    },

    {
        name: "FLAT WHITE ",
        description: "",
        price_en: "21",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-02-flat_white.jpg",
    },

    {
        name: "V60 ",
        description: "",
        price_en: "24",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-03-v60.jpg",
    },

    {
        name: "CORTADO ",
        description: "",
        price_en: "19",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-04-cortado.jpg",
    },

    {
        name: "COLD BREW COFFEE ",
        description: "",
        price_en: "24",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-05-cold_brew_coffee.jpg",
    },

    {
        name: "MARBLE LATTE ",
        description: "",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-06-marble_latte.jpg",
    },

    {
        name: "MATCHA LATTE ",
        description: "",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-07-matcha_latte.jpg",
    },

    {
        name: "PINK MATCHA ",
        description: "",
        price_en: "28",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-08-pink_matcha.jpg",
    },

    {
        name: "DOPPIO ",
        description: "",
        price_en: "17",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-09-doppio.jpg",
    },

    {
        name: "HOT CHOCOLATE ",
        description: "",
        price_en: "24",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-10-hot_chocolate.jpg",
    },

    {
        name: "MOCHA ",
        description: "",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-11-mocha.jpg",
    },

    {
        name: "CAFE CON LECHE ",
        description: "MEDIUM / LARGE",
        price_en: "M21 / L24",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-12-cafe_con_leche.jpg",
    },

    {
        name: "LATTE ",
        description: "MEDIUM / LARGE",
        price_en: "M21 / L24",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-13-latte.jpg",
    },

    {
        name: "CAPPUCCINO ",
        description: "MEDIUM / LARGE",
        price_en: "M21 / L24",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-14-cappuccino.jpg",
    },

    {
        name: "AMERICANO ",
        description: "MEDIUM / LARGE",
        price_en: "M17 / L17",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-15-americano.jpg",
    },
]

let coffeeAr = [

    {
        name_ar: "فلات وايت ",
        description_ar: "",
        price_en: "21",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-02-flat_white.jpg",
    },

    {
        name_ar: "إسبريسو",
        description_ar: "",
        price_en: "14",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-01-espresso.jpg",
    },

    {
        name_ar: "كورتادو",
        description_ar: "",
        price_en: "19",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-04-cortado.jpg",
    },

    {
        name_ar: "في ٦٠ ",
        description_ar: "",
        price_en: "24",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-03-v60.jpg",
    },

    {
        name_ar: "ماربيل لاتيه ",
        description_ar: "",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-06-marble_latte.jpg",
    },

    {
        name_ar: "كولد برو",
        description_ar: "",
        price_en: "24",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-05-cold_brew_coffee.jpg",
    },

    {
        name_ar: "بينك ماتشا ",
        description_ar: "",
        price_en: "28",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-08-pink_matcha.jpg",
    },

    {
        name_ar: "ماتشا لاتيه ",
        description_ar: "",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-07-matcha_latte.jpg",
    },

    {
        name_ar: "هوت شوكولاتة ",
        description_ar: "",
        price_en: "24",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-10-hot_chocolate.jpg",
    },

    {
        name_ar: "دوبيو",
        description_ar: "",
        price_en: "17",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-09-doppio.jpg",
    },

    {
        name_ar: "كافي كون لتشي",
        description_ar: "",
        price_en: "M21 / L24",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-12-cafe_con_leche.jpg",
    },

    {
        name_ar: "موكا ",
        description_ar: "",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-11-mocha.jpg",
    },

    {
        name_ar: "كابتشينو",
        description_ar: "",
        price_en: "M21 / L24",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-14-cappuccino.jpg",
    },

    {
        name_ar: "لاتيه",
        description_ar: "",
        price_en: "M21 / L24",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-13-latte.jpg",
    },

    {
        name_ar: "أمريكانو",
        description_ar: "",
        price_en: "M17 / L17",
        img_path: "/wcsNew-menu.designs/beverages/Coffee-15-americano.jpg",
    },
]



export {
    beverages,
    coffee,
    beveragesAr,
    coffeeAr,
}