import styled from "styled-components";
import {Box, IconButton, InputAdornment, TextField} from "@mui/material";
import axios from "axios";
import {useState, useEffect} from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Button from "@mui/material/Button"
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { ThemeProvider } from "@mui/material/styles";
import { GeneralButtonStyled } from "../components/MenuGrids/lbMenuNew/CommonStyled";
import {TailSpin} from "react-loader-spinner";


function AdminLogin({}) {

    const url = 'https://www.lovelysunnydayapi.com/api/v1/auth/login';
    const {setAuth, auth}  = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] =  useState(false);
    const location = useLocation();
    var from;
  

    //the text fields of the form
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })

    //hooks for the errors of the text fields
    const [fieldErrors, setFieldsErrors] = useState({
        email_err: '',
        password_err: '',
    })

    //hooks to trigger field errors
    const [triggerFieldError, setTriggerFieldError] = useState({
        email_err_bool: false,
        password_err_bool: false,
    })

    //hook to show or hide password
    const [showPassword, setShowPassword] = useState(false);

    //hook to redirect to dashboard once user is authenticated
    const [isRedirected, setIsRedirected] = useState(false);


    //a function which handles whats written inside the form text fields
    function handleChange(e) {
        const {name, value} = e.target
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    //a function to show or hide password when user clicks on eye icon
    function handleClickShowPassword() {
        setShowPassword(!showPassword);
    }

    //this function checks for form errors
    function checkForError() {
        var errorExists = 0;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; //checks if the email is valid or not
        setFieldsErrors((prevErrors) => ({
            "email_err": '',
            "password_err": '',
        }))
        setTriggerFieldError((prevErrors) => ({
            "email_err_bool": false,
            "password_err_bool": false,
        }))

        if(formData.email == '') {
            setFieldsErrors((prevErrors) => ({
                ...prevErrors,
                "email_err": "Please enter an email address!"
            }))
            setTriggerFieldError((prevErrors) => ({
                ...prevErrors,
                "email_err_bool": true
            }))
            errorExists = 1;
        }

        if(!emailRegex.test(formData.email)) {
            setFieldsErrors((prevErrors) => ({
                ...prevErrors,
                "email_err": "Please enter a valid email address."
            }))
            setTriggerFieldError({
                ...triggerFieldError,
                "email_err_bool": true,
            })
            errorExists = 1;
        }
        
        if(formData.password == '') {
            setFieldsErrors((prevErrors) => ({
                ...prevErrors,
                "password_err": "Please enter your password!"
            }))
            setTriggerFieldError((prevErrors) => ({
                ...prevErrors,
                "password_err_bool": true,
            }))
            errorExists = 1;
        }
        if(errorExists == 1) {
            return true;
        }
        else {
            return false;
        }
    }

        //function to submit the form
        async function handleFormSubmit(e) {
            e.preventDefault();
            const errorExists = checkForError();
            if(errorExists) {
                alert("Please fill the fields marked in red!")
            }
            else {
                setLoading(true);
                try {
                    const {data:{user:user}} = await axios.post(url, formData, {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        withCredentials: true
                    })
            
                    //here in set auth..we will see if its different for each user who logs in
                    setAuth({
                        ...auth,
                        email: formData.email,
                        role: user.role
                    })

                    //change this later to dynamically take the user to their corresponding restaurants
                    from = location.state?.from?.pathname || `/restaurants/menus/le-bleu`;
                    navigate(from, {replace: true})
                } catch (error) {
                    setLoading(false);
                    console.log('Server responded with status', error.response.status);
                    console.log('Error message from server', error.response.data.msg);
                    alert("Please enter the correct email or password!")
                }
                
            }
    
        }

    
    return  (
        <>
           {loading ? <LoadingBoxStyled>
                    <TailSpin
                        visible={true}
                        height= "40"
                        width = "40"
                        color = "#006580"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        //check documentation for more
                        
                    />
                </LoadingBoxStyled> 
                : <FormContainerStyled onSubmit={handleFormSubmit}>
                <Box
                    component = "form"
                    sx = {{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 7,
                        width: "50%",
                    }}
                    noValidate
                    autoCapitalize="off"
                >
                    <TextField
                        required
                        id = "standard-basic"
                        label = "Email"
                        name = "email"
                        value = {formData.email}
                        onChange = {handleChange}
                        helperText = {fieldErrors.email_err}
                        error = {triggerFieldError.email_err_bool}
                        >

                    </TextField>
                    <TextField
                        required
                        id = "standard-basic"
                        type = {showPassword ? "text": "password"}
                        label = "Password"
                        name = "password"
                        value = {formData.password}
                        onChange = {handleChange}
                        helperText = {fieldErrors.password_err}
                        error = {triggerFieldError.password_err_bool}
                        InputProps = {{
                            endAdornment: (
                                <InputAdornment position= "end">
                                    <IconButton
                                        aria-label= "toggle password visibility"
                                        onClick = {handleClickShowPassword}
                                        edge = "end"
                                    >
                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    >
                    </TextField>
                    <BottomContainerStyled>
                        <ThemeProvider theme = {GeneralButtonStyled}>
                            <Button type = "submit" variant = "contained" color = "primary" style = {{width: "100%"}}>
                                login
                            </Button>
                        </ThemeProvider>
                    </BottomContainerStyled>
                </Box>
            </FormContainerStyled>}
        </>
    )
}

const FormContainerStyled = styled.div`
    position: relative;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;  
`

const LoadingBoxStyled = styled(FormContainerStyled)`
`

const BottomContainerStyled = styled.div`
    position: relative;
    margin: 0 auto;
    width: 50%;
    scale: 1;
    transition: all 300ms;
    &:hover {
        scale: 1.05
    }
`

export default AdminLogin;