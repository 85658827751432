import styled from "styled-components";
import {Button} from "@mui/material";

function BottonContainer({name}) {
    return (
        <BottonContainerStyled>
            <Button
                type
                variant
                color
            >
                {name}
            </Button>
        </BottonContainerStyled>
    )
}

const BottonContainerStyled = styled.div`
    position: relative;
    width: 100%;
    scale: 1;
    transition: all 300ms;
    &:hover {
        scale: 1.05
    }
`


export default BottonContainer;