import styled from "styled-components";
import { ltb_flex_aft_menu_one, ltb_flex_aft_menu_two, ltb_flex_aft_scones_menu, ltb_flex_aft_tea_coffee,ltb_flex_aft_tea_coffee_enhanced } from "../../../data/ltb-categories-details";



function AfternoonTeaMenus({menu_id}) {
    
    if(menu_id == "1") {
        return (
            <FlextTestStyled>
                <MenuTitleStyled>
                    <span>FULL MENU</span>
                    <span>QR 250</span>
                </MenuTitleStyled>
                <div style = {{display: "flex", flexDirection: "column", gap: "8px",  marginTop: "20px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "14px", lineHeight: "15px"}}>ساندويتشات منوعة مشكلة من الخبز المصنوع يدويا</span>
                    <span style = {{fontSize: "14px", fontFamily: "Palatino", lineHeight: "15px"}}>SELECTION OF SANDWICHES ON ARTISAN BREAD</span>
                </div>
                <FlexMenuContainerOne>
                    {
                        ltb_flex_aft_menu_one.map(({name_ar, name_en}) => {
                            return (
                                <div style = {{display: "flex", flexDirection: "column", gap: "2px"}}>
                                    <span style = {{fontFamily: "URWGeometricArabic", lineHeight: "15px"}}>{name_ar}</span>
                                    <span style = {{fontFamily: "Palatino LT", lineHeight: "15px"}}>{name_en}</span>
                                </div>
                            )
                        })
                    }
                </FlexMenuContainerOne>
                <div style = {{display: "flex", flexDirection: "column", gap: "8px",  marginTop: "20px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "14px", lineHeight: "15px"}}>بستري</span>
                    <span style = {{fontSize: "14px", fontFamily: "Palatino",lineHeight: "15px"}}>PASTRY ENSEMBLE</span>
                </div>
                <FlexMenuContainerOne>
                    {
                        ltb_flex_aft_menu_two.map(({name_ar, name_en}) => {
                            return (
                                <div style = {{display: "flex", flexDirection: "column"}}>
                                    <span style = {{fontFamily: "URWGeometricArabic", lineHeight: "15px"}}>{name_ar}</span>
                                    <span style = {{fontFamily: "Palatino LT",lineHeight: "15px"}}>{name_en}</span>
                                </div>
                            )
                        })
                    }
                </FlexMenuContainerOne>
                <div style = {{display: "flex", flexDirection: "column", gap: "8px",  marginTop: "20px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "14px", color: "#dbbc74", lineHeight: "15px"}}>كريم تي</span>
                    <span style = {{fontFamily: "Palatino", fontSize: "14px", color: "#dbbc74",lineHeight: "15px"}}>CREAM TEA</span>
                </div>
                <div style = {{display: "flex", flexDirection: "column", gap: "8px",  marginTop: "10px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "14px", lineHeight: "15px"}}>سكونز</span>
                    <span style = {{fontFamily: "Palatino", fontSize: "14px", lineHeight: "15px"}}>SCONES</span>
                </div>
                <FlexMenuContainerOne>
                    {
                            ltb_flex_aft_scones_menu.map(({name_ar, name_en}) => {
                                return (
                                    <div style = {{display: "flex", flexDirection: "column",}}>
                                        <span style = {{fontFamily: "URWGeometricArabic", lineHeight: "15px"}}>{name_ar}</span>
                                        <span style = {{fontFamily: "Palatino LT", lineHeight: "15px"}}>{name_en}</span>
                                    </div>
                                )
                            })
                        }
                </FlexMenuContainerOne>
                <div style = {{display: "flex", flexDirection: "column", gap: "8px",  marginTop: "20px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "14px", lineHeight: "15px"}}>تشكيلة استثنائية من الشاي او القهوة</span>
                    <span style = {{fontFamily: "Palatino", fontSize: "14px", lineHeight: "15px"}}>SELECTION OF PREMIUM TEA OR COFFEE</span>
                </div>
                <FlexMenuContainerOne>
                {
                            ltb_flex_aft_tea_coffee.map(({name_ar, name_en}) => {
                                return (
                                    <div style = {{display: "flex", flexDirection: "column"}}>
                                        <span style = {{fontFamily: "URWGeometricArabic",lineHeight: "15px"}}>{name_ar}</span>
                                        <span style = {{fontFamily: "Palatino LT", lineHeight: "15px"}}>{name_en}</span>
                                    </div>
                                )
                            })
                        }
                </FlexMenuContainerOne>
                <div style = {{display: "flex", flexDirection: "column",  marginTop: "20px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "8px", color: "#dbbc74"}}>الحد الأقصى ضيفان لكل مجموعة، للحجز التواصل عل 44434442</span>
                    <span style = {{fontFamily: "Palatino LT", fontSize: "8px", color: "#dbbc74"}}>Max party size is 2 guest per set, for Reservations Please Call 44434442</span>
                    <span style = {{fontFamily: "Palatino", fontSize: "8px"}}>If you have any concerns regarding food allergies, please alert your server prior to ordering (V) -Vegetarian, (N) - Contains Nuts, (S)- Contains Shellfish, (G)- Contains Gluten, (D)- Contains DiaryVanilla Meringue</span>
                </div>
            </FlextTestStyled>
        )
    }

    if(menu_id == "2") {
        return (
            <FlextTestStyled>
                <MenuTitleStyled>
                    <span>ENHANCED MENU</span>
                    <span>QR 250</span>
                </MenuTitleStyled>
                <div style = {{display: "flex", flexDirection: "column", gap: "8px",  marginTop: "20px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "14px", lineHeight: "15px"}}>ساندويتشات منوعة مشكلة من الخبز المصنوع يدويا</span>
                    <span style = {{fontSize: "14px", fontFamily: "Palatino", lineHeight: "15px"}}>SELECTION OF SANDWICHES ON ARTISAN BREAD</span>
                </div>
                <FlexMenuContainerOne>
                    {
                        ltb_flex_aft_menu_one.map(({name_ar, name_en}) => {
                            return (
                                <div style = {{display: "flex", flexDirection: "column", gap: "2px"}}>
                                    <span style = {{fontFamily: "URWGeometricArabic", lineHeight: "15px"}}>{name_ar}</span>
                                    <span style = {{fontFamily: "Palatino LT", lineHeight: "15px"}}>{name_en}</span>
                                </div>
                            )
                        })
                    }
                </FlexMenuContainerOne>
                <div style = {{display: "flex", flexDirection: "column", gap: "8px",  marginTop: "20px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "14px", lineHeight: "15px"}}>بستري</span>
                    <span style = {{fontSize: "14px", fontFamily: "Palatino",lineHeight: "15px"}}>PASTRY ENSEMBLE</span>
                </div>
                <FlexMenuContainerOne>
                    {
                        ltb_flex_aft_menu_two.map(({name_ar, name_en}) => {
                            return (
                                <div style = {{display: "flex", flexDirection: "column"}}>
                                    <span style = {{fontFamily: "URWGeometricArabic", lineHeight: "15px"}}>{name_ar}</span>
                                    <span style = {{fontFamily: "Palatino LT",lineHeight: "15px"}}>{name_en}</span>
                                </div>
                            )
                        })
                    }
                </FlexMenuContainerOne>
                <div style = {{display: "flex", flexDirection: "column", gap: "8px",  marginTop: "20px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "14px", color: "#dbbc74", lineHeight: "15px"}}>كريم تي</span>
                    <span style = {{fontFamily: "Palatino", fontSize: "14px", color: "#dbbc74",lineHeight: "15px"}}>CREAM TEA</span>
                </div>
                <div style = {{display: "flex", flexDirection: "column", gap: "8px",  marginTop: "10px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "14px", lineHeight: "15px"}}>سكونز</span>
                    <span style = {{fontFamily: "Palatino", fontSize: "14px", lineHeight: "15px"}}>SCONES</span>
                </div>
                <FlexMenuContainerOne>
                    {
                            ltb_flex_aft_scones_menu.map(({name_ar, name_en}) => {
                                return (
                                    <div style = {{display: "flex", flexDirection: "column",}}>
                                        <span style = {{fontFamily: "URWGeometricArabic", lineHeight: "15px"}}>{name_ar}</span>
                                        <span style = {{fontFamily: "Palatino LT", lineHeight: "15px"}}>{name_en}</span>
                                    </div>
                                )
                            })
                        }
                </FlexMenuContainerOne>
                <div style = {{display: "flex", flexDirection: "column", gap: "8px",  marginTop: "20px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "14px", lineHeight: "15px"}}>تشكيلة استثنائية من الشاي او القهوة</span>
                    <span style = {{fontFamily: "Palatino", fontSize: "14px", lineHeight: "15px"}}>SELECTION OF PREMIUM TEA OR COFFEE</span>
                </div>
                <FlexMenuContainerOne>
                {
                            ltb_flex_aft_tea_coffee.map(({name_ar, name_en}) => {
                                return (
                                    <div style = {{display: "flex", flexDirection: "column"}}>
                                        <span style = {{fontFamily: "URWGeometricArabic",lineHeight: "15px"}}>{name_ar}</span>
                                        <span style = {{fontFamily: "Palatino LT", lineHeight: "15px"}}>{name_en}</span>
                                    </div>
                                )
                            })
                        }
                </FlexMenuContainerOne>
                <div style = {{display: "flex", flexDirection: "column", gap: "8px",  marginTop: "20px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "14px", color: "#dbbc74", lineHeight: "15px"}}>عزز تجربتك للأفترنون تي</span>
                    <span style = {{fontFamily: "Palatino", fontSize: "14px", color: "#dbbc74", lineHeight: "15px"}}>ENHANCE YOUR AFTERNOON TEA EXPERIENCE</span>
                </div>
                <FlexMenuContainerOne>
                {
                            ltb_flex_aft_tea_coffee_enhanced.map(({name_ar, name_en}) => {
                                return (
                                    <div style = {{display: "flex", flexDirection: "column"}}>
                                        <span style = {{fontFamily: "URWGeometricArabic", lineHeight: "15px"}}>{name_ar}</span>
                                        <span style = {{fontFamily: "Palatino LT", lineHeight: "15px"}}>{name_en}</span>
                                    </div>
                                )
                            })
                        }
                </FlexMenuContainerOne>
                <div style = {{display: "flex", flexDirection: "column",  marginTop: "20px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "8px", color: "#dbbc74"}}>الحد الأقصى ضيفان لكل مجموعة، للحجز التواصل عل 44434442</span>
                    <span style = {{fontFamily: "Palatino LT", fontSize: "8px", color: "#dbbc74"}}>Max party size is 2 guest per set, for Reservations Please Call 44434442</span>
                    <span style = {{fontFamily: "Palatino", fontSize: "8px"}}>If you have any concerns regarding food allergies, please alert your server prior to ordering (V) -Vegetarian, (N) - Contains Nuts, (S)- Contains Shellfish, (G)- Contains Gluten, (D)- Contains DiaryVanilla Meringue</span>
                </div>
            </FlextTestStyled>
        )
    }

    if(menu_id == "3") {
        return (
            <FlextTestStyled>
                <MenuTitleStyled>
                    <span>CREAM TEA</span>
                    <span>QR 99</span>
                </MenuTitleStyled>
                <div style = {{display: "flex", flexDirection: "column", gap: "8px",  marginTop: "20px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "14px", color: "#dbbc74", lineHeight: "15px"}}>كريم تي</span>
                    <span style = {{fontFamily: "Palatino", fontSize: "14px", color: "#dbbc74",lineHeight: "15px"}}>CREAM TEA</span>
                </div>
                <div style = {{display: "flex", flexDirection: "column", gap: "8px",  marginTop: "10px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "14px", lineHeight: "15px"}}>سكونز</span>
                    <span style = {{fontFamily: "Palatino", fontSize: "14px", lineHeight: "15px"}}>SCONES</span>
                </div>
                <FlexMenuContainerOne>
                    {
                            ltb_flex_aft_scones_menu.map(({name_ar, name_en}) => {
                                return (
                                    <div style = {{display: "flex", flexDirection: "column",}}>
                                        <span style = {{fontFamily: "URWGeometricArabic", lineHeight: "15px"}}>{name_ar}</span>
                                        <span style = {{fontFamily: "Palatino LT", lineHeight: "15px"}}>{name_en}</span>
                                    </div>
                                )
                            })
                        }
                </FlexMenuContainerOne>
                <div style = {{display: "flex", flexDirection: "column",  marginTop: "20px", textAlign: "center"}}>
                    <span style = {{fontFamily: "URWGeometricArabic", fontSize: "8px", color: "#dbbc74"}}>الحد الأقصى ضيفان لكل مجموعة، للحجز التواصل عل 44434442</span>
                    <span style = {{fontFamily: "Palatino LT", fontSize: "8px", color: "#dbbc74"}}>Max party size is 2 guest per set, for Reservations Please Call 44434442</span>
                    <span style = {{fontFamily: "Palatino", fontSize: "8px"}}>If you have any concerns regarding food allergies, please alert your server prior to ordering (V) -Vegetarian, (N) - Contains Nuts, (S)- Contains Shellfish, (G)- Contains Gluten, (D)- Contains DiaryVanilla Meringue</span>
                </div>
            </FlextTestStyled>
        )
    }
}


const FlextTestStyled = styled.div`
    position: relative;
    display: flex;
    padding: 30px 0px;
    flex-direction: column;
    align-items: center;
    color: white;
`
const FlexMenuContainerOne = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap:10px;
    text-align: center;
    margin-top: 20px;

    span {
        font-size: 11px;
    }
`

const MenuTitleStyled = styled.div`
    position: relative;
    display:flex;
    flex-direction: column;
    

    span {
        font-family: "Palatino";
        color: #dbbc74;
        font-size: 16px;
        text-align: center;
    }
`


export default AfternoonTeaMenus;