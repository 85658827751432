let aperitifEn = [

    {
        name: "FLUFFY FIZZ, ",
        details: " LEMON, LIME, CHERRY JUICE, ORANGE SELTZER",
        price: "45",
        img_path: "/baron-menu-designs-2/aperitif/01 FLUFFY FIZZ-1072x800px.jpg"
    },

    {
        name: "BLACKBERRY, ",
        details: " COCONUT MILK, VANILLA SELTZER",
        price: "45",
        img_path: "/baron-menu-designs-2/aperitif/02 Blackberry-72dpi.jpg"
    },

    {
        name: "LEMONADE, ",
        details: " SUMAC BEETROOT SYRUP, LEMON, SUMAC, SELTZER",
        price: "45",
        img_path: "/baron-menu-designs-2/aperitif/03 Lemonade-72dpi.jpg"
    },

    {
        name: "HAWAI, ",
        details: " BLUEBERRY, LEMON, ROSE, LYCHEE SELTZER",
        price: "45",
        img_path: "/baron-menu-designs-2/aperitif/04 huwai-72dpi.jpg"
    },

    {
        name: "BEACH, ",
        details: " GRENADINE ROSE, LIME SELTZER",
        price: "45",
        img_path: "/baron-menu-designs-2/aperitif/05 Beach-72dpi.jpg"
    },

    {
        name: "ZA’ATAR, ",
        details: " LONDON DRY, ZA’ATAR SYRUP, AGAVE LEMON JUICE",
        price: "45",
        img_path: "/baron-menu-designs-2/aperitif/06 Zaatar-72dpi.jpg"
    },

    {
        name: "OLD PORT, ",
        details: " PASSIONFRUIT, LIME, LEMON, MINT, SPARKLING",
        price: "55",
        img_path: "/baron-menu-designs-2/aperitif/07 Old Port-72dpi.jpg"
    },

    {
        name: "SPIRITS, ",
        details: " SELTZER ITALIAN SPRITZ",
        price: "45",
        img_path: "/baron-menu-designs-2/aperitif/08 Sprits-72dpi.jpg"
    },
]

let aperitifAr = [

    {
        name: "توت اسود، ",
        details: "حليب جوز الهند، ماء غازي بنكهة الفانيليا ",
        price: "45",
        img_path: "/baron-menu-designs-2/aperitif/02 Blackberry-72dpi.jpg"
    },

    {
        name: "فلافي فيز، ",
        details: "ليمون، عصير ليم، عصير كرز، ماء غازي برتقال ",
        price: "45",
        img_path: "/baron-menu-designs-2/aperitif/01 FLUFFY FIZZ-1072x800px.jpg"
    },

    {
        name: "هاواي، ",
        details: "توت أزرق، ليمون، ماء ورد، ماء غازي بالليتشي ",
        price: "45",
        img_path: "/baron-menu-designs-2/aperitif/04 huwai-72dpi.jpg"
    },

    {
        name: "ليمونادة، ",
        details: "شراب سماق الشمندر، ليمون، سماق، ماء غازي ",
        price: "45",
        img_path: "/baron-menu-designs-2/aperitif/03 Lemonade-72dpi.jpg"
    },

    {
        name: "زعتر، ",
        details: "لندن دراي، شراب الزعتر، عصير ليمون ",
        price: "45",
        img_path: "/baron-menu-designs-2/aperitif/06 Zaatar-72dpi.jpg"
    },

    {
        name: "بيتش، ",
        details: "شراب رمان، الورد ماء غازي بالليمون ",
        price: "45",
        img_path: "/baron-menu-designs-2/aperitif/05 Beach-72dpi.jpg"
    },

    {
        name: "مشروبات منعشة، ",
        details: "سيلتزر سبرتز الإيطالية ",
        price: "45",
        img_path: "/baron-menu-designs-2/aperitif/08 Sprits-72dpi.jpg"
    },

    {
        name: "أولد بورت، ",
        details: "باشن فروت، ليمون، لايم، نعناع، مشروب غازي ",
        price: "45",
        img_path: "/baron-menu-designs-2/aperitif/07 Old Port-72dpi.jpg"
    },
]

export {
    aperitifEn,
    aperitifAr,
}