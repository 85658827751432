import { Grid } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ltb_cake } from "../../../data/ltb-categories-details";

function CakesGrid() {

    const [showDish, setShowDish] = useState(-1);
    function handleClick(id) {
        setShowDish(id);
    }

    if(showDish < 0) {
        return (
            <>
                {/* <BtnTempStyled>
                    <div>temp button - cakes image goes here</div>
                    <button onClick = {()=> setShowDish(1)}>click for cakes</button>
                </BtnTempStyled> */}
                <div style = {{marginTop: "30px"}}/>
                {
                    ltb_cake.map(({name_en, name_ar, price})=> {
                        return (
                            <CakeItemContainerStyled>
                                <CakeItemWrapper>
                                    <div>{name_en}</div>
                                    <div style = {{fontFamily: "URWGeometricArabic"}}>{name_ar}</div>
                                </CakeItemWrapper>
                                <div>{price}</div>
                            </CakeItemContainerStyled>
                        )
                    })
                }
            </>
        )
    }

    // if(showDish >=0) {
    //     return (
    //         <CakeAndDrinksContainerStyled>
    //             <CakeAndDrinksCloseBtn>
    //                 <FontAwesomeIcon icon = {faXmark} onClick ={()=>setShowDish(-1)}/>
    //             </CakeAndDrinksCloseBtn>
    //             {
    //                 ltb_cake.map(({name_en, name_ar, price})=> {
    //                     return (
    //                         <CakeItemContainerStyled>
    //                             <CakeItemWrapper>
    //                                 <div>{name_en}</div>
    //                                 <div>{name_ar}</div>
    //                             </CakeItemWrapper>
    //                             <div>{price}</div>
    //                         </CakeItemContainerStyled>
    //                     )
    //                 })
    //             }
    //         </CakeAndDrinksContainerStyled>
    //     )
    // }
}

const BtnTempStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const CakeAndDrinksContainerStyled = styled.div`
    position: fixed;
    inset:0;
    display: flex;
    flex-direction: column;
    padding: 0px 10%;
    justify-content: center;
    z-index: 1;
    background-color: #011e41;
    color:  #dbbc74;
    
`

const CakeItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    justify-content: space-between;
    padding-bottom: 30px;
    color:  #dbbc74;

    font-size: 14px;
    font-family: "Palatino"
`

const CakeItemWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`
const CakeAndDrinksCloseBtn = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    margin-left: 88%;
    margin-top: 4%;
    width: 30px;
    height: 30px;

    font-size: 16px;
    cursor: pointer;
`


export default CakesGrid;