import { Grid } from "@mui/material"
import styled from "styled-components";
import { useState } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ltb_drinks_fresh_fruit_juice, ltb_drinks_fresh_soft_drinks, ltb_drinks_hot_cold, ltb_drinks_mocktails, ltb_drinks_teas, ltb_drinks_water, ltb_cake } from "../../../data/ltb-categories-details";

function DrinksGrid() {

    const [showDish, setShowDish] = useState(-1);
    function handleClick(id) {
        setShowDish(id);
    }

    if(showDish < 0) {
        return (
            <>
                {/* <BtnTempStyled>
                    <div>temp button - cakes image goes here</div>
                    <button onClick = {()=> setShowDish(1)}>click for cakes</button>
                </BtnTempStyled> */}
                <div style = {{marginTop: "30px"}}/>
                <CategoryTitleStyled>
                    Mocktails
                </CategoryTitleStyled>
                {
                    ltb_drinks_mocktails.map(({name_en, name_ar, price})=> {
                        return (
                            <CakeItemContainerStyled>
                                <CakeItemWrapper>
                                    <div>{name_en}</div>
                                    <div style = {{fontFamily: "URWGeometricArabic"}}>{name_ar}</div>
                                </CakeItemWrapper>
                                <div>{price}</div>
                            </CakeItemContainerStyled>
                        )
                    })
                }
                <CategoryTitleStyled>
                    Fresh Fruit Juice
                </CategoryTitleStyled>
                {
                    ltb_drinks_fresh_fruit_juice.map(({name_en, name_ar, price})=> {
                        return (
                            <CakeItemContainerStyled>
                                <CakeItemWrapper>
                                    <div>{name_en}</div>
                                    <div style = {{fontFamily: "URWGeometricArabic"}}>{name_ar}</div>
                                </CakeItemWrapper>
                                <div>{price}</div>
                            </CakeItemContainerStyled>
                        )
                    })
                }
                <CategoryTitleStyled>
                    Soft Drinks
                </CategoryTitleStyled>
                {
                    ltb_drinks_fresh_soft_drinks.map(({name_en, name_ar, price})=> {
                        return (
                            <CakeItemContainerStyled>
                                <CakeItemWrapper>
                                    <div>{name_en}</div>
                                    <div style = {{fontFamily: "URWGeometricArabic"}}>{name_ar}</div>
                                </CakeItemWrapper>
                                <div>{price}</div>
                            </CakeItemContainerStyled>
                        )
                    })
                }
                <CategoryTitleStyled>
                    Hot & Cold
                </CategoryTitleStyled>
                {
                    ltb_drinks_hot_cold.map(({name_en, name_ar, price})=> {
                        return (
                            <CakeItemContainerStyled>
                                <CakeItemWrapper>
                                    <div>{name_en}</div>
                                    <div style = {{fontFamily: "URWGeometricArabic"}}>{name_ar}</div>
                                </CakeItemWrapper>
                                <div>{price}</div>
                            </CakeItemContainerStyled>
                        )
                    })
                }
                <CategoryTitleStyled>
                    Teas
                </CategoryTitleStyled>
                {
                    ltb_drinks_teas.map(({name, types_en, types_ar,price}) => {
                        return (
                            <CakeItemContainerStyled>
                                <CakeItemWrapper>
                                    <div>{name}</div>
                                    {
                                        types_en.map((tea) => {
                                            return (
                                                <span style = {{fontFamily: "Palatino LT"}}>{tea}</span>
                                            )
                                        })
                                    }
                                    <div style = {{marginTop: "8px", display:"flex", flexDirection: "column"}}>
                                        {types_ar.map((tea) => {
                                            return (
                                                <span style = {{fontFamily: "URWGeometricArabic"}}>{tea}</span>
                                            )
                                        })}
                                     </div>
                                </CakeItemWrapper>
                                <div>{price}</div>
                            </CakeItemContainerStyled>
                        )
                    })
                }
                <CategoryTitleStyled>
                    Water
                </CategoryTitleStyled>
                {
                    ltb_drinks_water.map(({name_en, name_ar, price})=> {
                        return (
                            <CakeItemContainerStyled>
                                <CakeItemWrapper>
                                    <div>{name_en}</div>
                                    <div style = {{fontFamily: "URWGeometricArabic"}}>{name_ar}</div>
                                </CakeItemWrapper>
                                <div>{price}</div>
                            </CakeItemContainerStyled>
                        )
                    })
                }
            </>
        )
    }

    if(showDish >=0) {
        return (
            <>
            </>
        )
    }
}

const CakeAndDrinksContainerStyled = styled.div`
    position: fixed;
    inset:0;
    display: flex;
    flex-direction: column;
    padding: 0px 10%;
    justify-content: center;
    z-index: 1;
    background-color: #011e41;
    color:  #dbbc74;
    
`

const CategoryTitleStyled = styled.div`
    color:  #dbbc74;
    font-family: "NeonoirW01";
    font-size: 20px;
    font-weight:bolder;
    margin-bottom: 8px;
`

const CakeItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    justify-content: space-between;
    padding-bottom: 30px;
    color:  #dbbc74;

    font-size: 14px;
    font-family: "Palatino";
`

const CakeItemWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`
const CakeAndDrinksCloseBtn = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    margin-left: 88%;
    margin-top: 4%;
    width: 30px;
    height: 30px;

    font-size: 16px;
    cursor: pointer;
`

export default DrinksGrid;