//grid styling for Appetizers page of sek resturant
//refNo 7

import { Grid } from "@mui/material"

function AppetizersGrid() {
    return (
        <Grid container spacing = {0.1}>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-menu-designs/Menu-Title-JPG/Menu-Title-07.jpg"/>
                </div>
            </Grid>
            <Grid item xs = {6}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-menu-designs/Appetizers JPG/Appetizer-01.jpg"/>
                </div>
            </Grid>
            <Grid item xs = {6}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-menu-designs/Appetizers JPG/Appetizer-02.jpg"/>
                </div>
            </Grid>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-menu-designs/Appetizers JPG/Appetizer-03.jpg"/>
                </div>
            </Grid>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-menu-designs/Appetizers JPG/Appetizer-04.jpg"/>
                </div>
            </Grid>
          
        </Grid>
    )
}

export default AppetizersGrid;