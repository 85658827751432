import styled from "styled-components";
import {useEffect, useState, useRef} from "react";
import { CopyRightStyled } from "../../components/MenuGrids/baronMenuNew/generalStyles";
import { ToShareGrid, PastaDumplingsGrid, BaoGrid, PizzaGrid, DefToShare, AperitifGrid, SweetsGrid, BeverageGrid } from "../../components/MenuGrids/baronMenuNew";
import { useNavigate } from "react-router-dom";

function BaronMenuNewAr({}) {
    const [triggerLanguage, setTriggerLanguage] = useState('ar');
    const sliderContainer = useRef(null)
    const sliderContainerAr = useRef(null);
    const baronSectionsRef = useRef([]);
    const baronCatRef = useRef([]);
    let categoriesIndices = ['0', '1', '2', '3', '4', '5', '6', '7'];
    const navigate = useNavigate();

    //triggers the arabic/english menu
    function handleTriggerMenu(language) {
        navigate("/restaurants/menus/baron");
    }


    //activate the cateogry we click on
    function navClickHandler(id) {
        activeCategoryHandler(id)
    }

    //activates/deactivates the categories that we click on or we are currently on
    function activeCategoryHandler(sectionId) {
        var index = sectionId-1;

        baronCatRef.current[index].style.color = "#007481"

        categoriesIndices.map((item) => {
            if(item != index) {
                baronCatRef.current[item].style.color = "#3D3935"
            }
        })
    }

    //this function tracks the user position as he scrolls down the page
    function windowScrollTracker(currentScroll) {

        var sectionFromTop;
        var sectionId;

        //gets the id of the cateogry we are on as we scroll
        baronSectionsRef.current.forEach((section) => {
            sectionFromTop = section.getBoundingClientRect().top;
          
            if (sectionFromTop <= 10) {
                sectionId = section.getAttribute("id");
            }
        }) 

        console.log("the current section id is: ", sectionId)

        navBarTracker(sectionId)
        
        
    }

    async function navBarTracker(sectionId) {

        switch(sectionId) {
            //activates the to share cateogry
            case '1':
                sliderContainer.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates the pasta and dum cateogory
            case '2':
                sliderContainer.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates the bao & buns category
            case '3':
                sliderContainer.current.scrollLeft = -100;
                await activeCategoryHandler(sectionId)
            break;

            //activates the pizza category
            case '4':
                ;
                sliderContainer.current.scrollLeft = -150;
                await activeCategoryHandler(sectionId)
            break;

            //activates the def to share category
            case '5':
                sliderContainer.current.scrollLeft = -300;
                await activeCategoryHandler(sectionId)
            break;

            //activates the aperitif category
            case '6':
                await activeCategoryHandler(sectionId)
            break;

            //activates the sweets category
            case '7':
                sliderContainer.current.scrollLeft = -700;
                await activeCategoryHandler(sectionId)
            break;

            //activates the beverages category
            case '8':
                await activeCategoryHandler(sectionId)
            break;
        }
        return "resolved";
    }

  

    useEffect(() => {
        function handleScroll(e) {
            var currentScroll = window.pageYOffset;
            windowScrollTracker(currentScroll);
            console.log(triggerLanguage)
        }

        var timer = null;

            window.addEventListener('scroll', function() {
                if(timer !== null) {
                    clearTimeout(timer)
                }
                timer = setTimeout(function () {
                    handleScroll()
                }, 50)
            })
    }, [])

    return (
        <BaronPageStyled>
            <SliderContainerStyled ref = {sliderContainer}  triggerLanguage = "ar">
                <NavTextStyled>
                    <a href = "#1" ref = {(el) => baronCatRef.current[0] = el} onClick = {() => navClickHandler("1")}>
                        <span>للمشاركة</span>
                    </a>    
                </NavTextStyled>
                <NavTextStyled>
                    <a href = "#2" ref = {(el) => baronCatRef.current[1] = el} onClick = {() => navClickHandler("2")}>
                        <span>المعكرونة</span>
                    </a>    
                </NavTextStyled>
                <NavTextStyled>
                    <a href = "#3" ref = {(el) => baronCatRef.current[2] = el} onClick = {() => navClickHandler("3")}>
                        <span>خبز البوا والبرغر</span>
                    </a>    
                </NavTextStyled>
                <NavTextStyled>
                    <a href = "#4" ref = {(el) => baronCatRef.current[3] = el} onClick = {() => navClickHandler("4")}>
                        <span>مشابهة لكنها ليست ... بيتزا</span>
                    </a>    
                </NavTextStyled>
                <NavTextStyled>
                    <a href = "#5" ref = {(el) => baronCatRef.current[4] = el} onClick = {() => navClickHandler("5")}>
                        <span>أطباق للتشارك بالتأكيد</span>
                    </a>    
                </NavTextStyled>
                <NavTextStyled>
                    <a href = "#6" ref = {(el) => baronCatRef.current[5] = el} onClick = {() => navClickHandler("6")}>
                        <span>الموكتيل</span>
                    </a>    
                </NavTextStyled>
                <NavTextStyled>
                    <a href = "#7" ref = {(el) => baronCatRef.current[6] = el} onClick = {() => navClickHandler("7")}>
                        <span>الحلويات</span>
                    </a>    
                </NavTextStyled>
                <NavTextStyled>
                    <a href = "#8" ref = {(el) => baronCatRef.current[7] = el} onClick = {() => navClickHandler("8")}>
                        <span>المشروبات</span>
                    </a>    
                </NavTextStyled>
            </SliderContainerStyled>
            <LanguageTriggerContainerStyled triggerLanguage = "ar">
                <span onClick = {() => handleTriggerMenu("en")}>En</span>
            </LanguageTriggerContainerStyled>
            <CategoryContainerStyled>
                <section id = "1" ref = {(el) => baronSectionsRef.current[0] = el}>
                    <ToShareGrid triggerLanguage = {triggerLanguage}/>
                </section>
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <section id = "2" ref = {(el) => baronSectionsRef.current[1] = el}>
                    <PastaDumplingsGrid triggerLanguage = {triggerLanguage}/>
                </section>
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <section id = "3" ref = {(el) => baronSectionsRef.current[2] = el}>
                    <BaoGrid triggerLanguage = {triggerLanguage}/>
                </section>
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <section id = "4" ref = {(el) => baronSectionsRef.current[3] = el}>
                    <PizzaGrid triggerLanguage = {triggerLanguage}/>
                </section>
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <section id = "5" ref = {(el) => baronSectionsRef.current[4] = el}>
                    <DefToShare triggerLanguage = {triggerLanguage}/>
                </section>
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <section id = "6" ref = {(el) => baronSectionsRef.current[5] = el}>
                    <AperitifGrid triggerLanguage = {triggerLanguage}/>
                </section>
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <section id = "7" ref = {(el) => baronSectionsRef.current[6] = el}>
                    <SweetsGrid triggerLanguage = {triggerLanguage}/>
                </section>
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <section id = "8" ref = {(el) => baronSectionsRef.current[7] = el}>
                    <BeverageGrid triggerLanguage = {triggerLanguage}/>
                </section>
            </CategoryContainerStyled>
            <CopyRightStyled>
                <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                    <img src = "/Developed-By-LSD.svg"/>
                </a>
            </CopyRightStyled>
        </BaronPageStyled>
    )
}

const BaronPageStyled = styled.div`
    position: relative;
    width: 100vw;
`

const SliderContainerStyled = styled.div`
    position: fixed;
    inset: 0;
    left: 20%;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    gap: 20px;
    align-items: center;
    width: 80%;
    height: 50px;
    z-index: 1;
    overflow: scroll;
    scroll-behavior: smooth;
    flex-direction: row-reverse;
`

const NavTextStyled = styled.div`
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
    font-family:  "Tajawal Medium";
    font-size: 9px;

    a {
        color: #3D3935;
    }
`

const LanguageTriggerContainerStyled = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    background-color: white;
    align-items: center;
    z-index: 1;
    inset: 0px;
    left:0;
    width: 20%;
    height: 50px;
    font-family: "Tajawal Medium";
    font-size: 12px;

     span {
        padding: 5px;
     }
`

const CategoryContainerStyled = styled.div`
    position: relative;
    margin-top: 80px;
`
export default BaronMenuNewAr;