let baoBunsEn = [
    {
        name: "BRISKET, ",
        details: " BAO, OCTO DRESSING, SPRING ONION, HOISIN, KEWPIE, PICKLES, PEANUT, HERBS",
        price: "85",
        img_path: "/baron-menu-designs-2/bao&buns/01 BRISKET-1072x800px.jpg"
    },

    {
        name: "BFC, ",
        details: `“BARON SPICED” FRIED CHICKEN, HOUSE-PICKLE, MILK BUN, FRIES`,
        price: "65",
        img_path: "/baron-menu-designs-2/bao&buns/02 BFC-1072x800px.jpg"
    },

    {
        name: "STEAK HACHE, ",
        details: " ANGUS MINCE, BRIOCHE BUN, BARON SAUCE, CHEDDAR, FRIES",
        price: "75",
        img_path: "/baron-menu-designs-2/bao&buns/03 STEAK-HACHE-1072x800px.jpg"
    },

    {
        name: "SEABASS, ",
        details: " BRIOCHE BUN, CRISP, CILANTRO, HOUSE-PICKLES, TARATOR TARTAR, FRIES",
        price: "75",
        img_path: "/baron-menu-designs-2/bao&buns/04 SEABASS-1072x800px.jpg"
    },
]

let baoBunsAr = [

    {
        name: "بي آف سي، ",
        details: "دجاج مقلي بتوابل ”بارون“، مخللات منزلية، خبز حليب، بطاطس مقلية ",
        price: "65",
        img_path: "/baron-menu-designs-2/bao&buns/02 BFC-1072x800px.jpg"
    },

    {
        name: "بريسكت، ",
        details: "باو، صلصة أوكتو، بصل أخضر، صوص هويسن، كيوبي، مخللات، فول سوداني، أعشاب ",
        price: "85",
        img_path: "/baron-menu-designs-2/bao&buns/01 BRISKET-1072x800px.jpg"
    },

    {
        name: "سمك القاروص، ",
        details: "خبز بريوش، مقرمش، كزبرة، مخللات منزلية، صلصة طرطور تارتار، بطاطس مقلية ",
        price: "75",
        img_path: "/baron-menu-designs-2/bao&buns/04 SEABASS-1072x800px.jpg"
    },

    {
        name: "ستيك هاتشي، ",
        details: "لحم أنغوس مفروم، خبز بريوش، صلصة بارون، جبنة شيدر، بطاطس مقلية ",
        price: "75",
        img_path: "/baron-menu-designs-2/bao&buns/03 STEAK-HACHE-1072x800px.jpg"
    },
]

export {
    baoBunsEn,
    baoBunsAr,
}