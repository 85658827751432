import styled from "styled-components";

function ToastsEn({}) {
    return (
        <>
           <div>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Toasts Pics/toast-sourdough butter.png" />
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>Sourdough toasts with butter <br/>
                    <ItemDescriptionParStyled>
                        <TextItalicBoxStyled>
                            honey, jams & peanutbutter
                        </TextItalicBoxStyled>
                    </ItemDescriptionParStyled>
                        <PriceBoxStyled>35 QR</PriceBoxStyled>
                        
                    </p>
                    <p>
                        <ItemDescriptionParStyled>
                            <TextItalicBoxStyled>
                                Add sourdough croissant
                            </TextItalicBoxStyled>
                        </ItemDescriptionParStyled>
                        <PriceBoxStyled style = {{fontSize: "12px", fontStyle: "italic"}}>+ 18 QR</PriceBoxStyled>
                    </p>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Toasts Pics/toast- smashed avocado.png" />
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Smashed Avocado toast <br/>
                        <ItemDescriptionParStyled>
                            <TextItalicBoxStyled>
                                on sourdough with cherry tomatoes, chill lime & olive oil
                            </TextItalicBoxStyled>
                        </ItemDescriptionParStyled>
                    </p>
                    <PriceBoxStyled>45 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/Toasts Pics/toast-cherry tomatoes.png" />
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Cherry tomatoes <br/>
                        <ItemDescriptionParStyled>
                            <TextItalicBoxStyled>
                            avocado, feta, lime & chives on sourdough toast
                            </TextItalicBoxStyled>
                        </ItemDescriptionParStyled>
                    </p>
                    <PriceBoxStyled>48 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
           </div>
        </>
    )
}

function ToastsAr({}) {
    return (
        <>
           <div>
            <SingleItemContainerStyledAr>
                <ImageContainerStyledAr>
                    <img src = "/ergon-menu-desgins/Toasts Pics/toast-sourdough butter.png" />
                </ImageContainerStyledAr>
                <ItemDescriptionArStyled>
                    <p>توست الساوردو مع زبدة <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>
                            عسل، مربى وزبدة الفستق 
                            </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        <PriceBoxStyled>٣٥ ر.ق</PriceBoxStyled>
                    </p>
                    <p>
                    <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>
                            إضافة كرواسان الساوردو 
                            </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        <PriceBoxStyled style = {{fontSize: "12px", fontStyle: "italic"}}> ١٨ ر.ق</PriceBoxStyled>
                    </p>
                </ItemDescriptionArStyled>
            </SingleItemContainerStyledAr>
            <SingleItemContainerStyledAr>
                <ImageContainerStyledAr>
                    <img src = "/ergon-menu-desgins/Toasts Pics/toast- smashed avocado.png" />
                </ImageContainerStyledAr>
                <ItemDescriptionArStyled>
                    <p>
                    توست الساوردو مع أفوكادو  <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>
                            مع طماطم كرزية، ليمون فلفل وزيت زيتون 
                            </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                    </p>
                    <PriceBoxStyled>٤٥ ر.ق</PriceBoxStyled>
                </ItemDescriptionArStyled>
            </SingleItemContainerStyledAr>
            <SingleItemContainerStyledAr>
                <ImageContainerStyledAr>
                    <img src = "/ergon-menu-desgins/Toasts Pics/toast-cherry tomatoes.png" />
                </ImageContainerStyledAr>
                <ItemDescriptionArStyled>
                    <p>
                    توست الساوردو مع طماطم كرزية <br/>
                    <ItemDescriptionParArStyled>
                        <TextItalicBoxStyled>
                        أفوكادو، جبنة فيتا، ليمون، ثوم معمر على توست الساوردو
                        </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                    </p>
                    <PriceBoxStyled>٤٨ ر.ق</PriceBoxStyled>
                </ItemDescriptionArStyled>
            </SingleItemContainerStyledAr>
           </div>
        </>
    )
}

const SingleItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
    /* img {
        width: 60%;
    } */
`

const SingleItemContainerStyledAr = styled(SingleItemContainerStyled)`
    flex-direction: row-reverse;
`

const ImageContainerStyled = styled.div`
    position: relative;
    max-width: 100px;
    width: 100%;
    img {
        width: 100%;
      
    }
`

const ImageContainerStyledAr = styled(ImageContainerStyled)`
    transform: scaleX(-1);
`

const ItemDescriptionStyled = styled.div`



    font-family: "Gotham Greek Book";
    font-size: 14px;
    p {
        margin: 0px;
        line-height: 15px;
    }
`

const ItemDescriptionArStyled = styled(ItemDescriptionStyled)`
    font-family: "GE SS Text";
    font-size: 15px;
    text-align: right;
`

const ItemDescriptionParStyled = styled.div`
    padding-right: 20px;
`

const ItemDescriptionParArStyled = styled.div`
    padding-left: 20px;
`

const TextItalicBoxStyled = styled.span`
    font-weight: lighter;
    font-style: italic;
    font-size: 12px;
`
const PriceBoxStyled = styled.div`
    position: relative;
    margin-top: 4px;
    font-weight: bold;
    font-size: 12px;
`

export {
    ToastsEn,
    ToastsAr,
};