//dont delete this. It is also used for the menus

import styled from "styled-components";
const MenuCoverPageStyled = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    padding-left: 30px;
    background-color: #f1efd9;
    display: block;
    
    img {
        height: 100%;
   
    }
`

const MenuStickerContainerStyled = styled.div`
    cursor: pointer;
    position: absolute;
    inset:0;
    left: 35%;
    top: 8%;
    display:block;
    width: 100px;
   
    height: 100px;

    img {
        width: 100px;
        height: 100px;
    }
`

export {
    MenuCoverPageStyled,
    MenuStickerContainerStyled,
}