let sandwiches = [

    {
        name: "CHILLI CHICKEN ",
        description: " QATARI CHICKEN, ESPELLETE CHILLI, JAPANESE MAYO, WHITE LOAF",
        price_en: "23",
        img_path: "/wcsNew-menu.designs/sandwiches/Sandwiches-01-chilli_chicken.jpg",
    },

    {
        name: "TURKEY AND CHEESE ",
        description: " SMOKED TURKEY, MOZZARELLA CHEESE, EMMENTAL CHEESE WITH MAYO MUSTARD SAUCE",
        price_en: "41",
        img_path: "/wcsNew-menu.designs/sandwiches/Sandwiches-02-turkey_cheese.jpg",
    },

    {
        name: "TUNA ",
        description: " SPANISH TUNA, TONNATO SAUCE, MULTISEED LOAF",
        price_en: "23",
        img_path: "/wcsNew-menu.designs/sandwiches/Sandwiches-03-tuna.jpg",
    },

    {
        name: "HALLOUMI SANDWICH ",
        description: " GRILLED HALLOUMI CHEESE, SUNDRY TOMATO CHUTNEY WITH CREAM CHEESE, CHERRY TOMATO CONFIT, MIX LETTUCE, MULTISEED LOAF",
        price_en: "23",
        img_path: "/wcsNew-menu.designs/sandwiches/Sandwiches-04-halloumi_sandwich.jpg",
    },

    {
        name: "CAESAR SANDWICH ",
        description: " CHICKEN BREAST, CAESAR SAUCE, PARMESAN CHEESE IN A FRENCH BAGUETTE",
        price_en: "43",
        img_path: "/wcsNew-menu.designs/sandwiches/Sandwiches-05-caesar_sandwich.jpg",
    },

    {
        name: "VEGGIE ",
        description: " EGGPLANT, ZUCCHINI, WALNUT CHUTNEY, MOZZARELLA, MULTISEED LOAF",
        price_en: "23",
        img_path: "/wcsNew-menu.designs/sandwiches/Sandwiches-06-veggie.jpg",
    },

    {
        name: "SMOKED CHICKEN CHEESE SANDWICH ",
        description: " RED CABBAGE, WHITE CABBAGE, SMOKED CHICKEN, SMOKED BBQ SAUCE, CHEDDAR CHEESE SLICE, MAYO",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/sandwiches/Sandwiches-07-smoked_chicken_cheese_sandwich.jpg",
    },
]

let sandwichesAr = [

    {
        name_ar: "ساندويتش حبش ",
        description_ar: "صدر ديك رومي مدخن، جبنة موزاريلا, جبنة امنتال فرنسي صلصة المايونيز والخردل",
        price_en: "41",
        img_path: "/wcsNew-menu.designs/sandwiches/Sandwiches-02-turkey_cheese.jpg",
    },

    {
        name_ar: "دجاج حار ",
        description_ar: "دجاج بلدي، فلفل الاسبيليت، مايو ياباني، خبز ابيض",
        price_en: "23",
        img_path: "/wcsNew-menu.designs/sandwiches/Sandwiches-01-chilli_chicken.jpg",
    },

    {
        name_ar: "ساندويش حلومي ",
        description_ar: "جبنة حلومي مشوية، صلصة طماطم متنوعة مع جبنة كريمية، كونفيت طماطم كرزية، خس مشكل، خبز متعدد الحبوب",
        price_en: "23",
        img_path: "/wcsNew-menu.designs/sandwiches/Sandwiches-04-halloumi_sandwich.jpg",
    },

    {
        name_ar: "تونا ",
        description_ar: "تونا إسباني، توناتو صوص الإيطالي، خبز متعدد الحبوب",
        price_en: "23",
        img_path: "/wcsNew-menu.designs/sandwiches/Sandwiches-03-tuna.jpg",
    },

    {
        name_ar: "فيجي ",
        description_ar: "باذنجان، كوسى، صلصة الجوز، جبنة الموزاريلا، خبز متعدد الحبوب",
        price_en: "23",
        img_path: "/wcsNew-menu.designs/sandwiches/Sandwiches-06-veggie.jpg",
    },

    {
        name_ar: "ساندويتش سيزار دجاج ",
        description_ar: "صدور الدجاج, صلصة السيزر, جبنة البارميزان, الخبز الفرنسي الأساسي",
        price_en: "43",
        img_path: "/wcsNew-menu.designs/sandwiches/Sandwiches-05-caesar_sandwich.jpg",
    },

    {
        name_ar: "ساندوتش الدجاج المدخن بالجبنة ",
        description_ar: "كرنب أحمر، كرنب أبيض، دجاج مدخن، صوص باربيكيو مدخن، شريحة جبنة شيدر، مايوني",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/sandwiches/Sandwiches-07-smoked_chicken_cheese_sandwich.jpg",
    },




]

export {
    sandwiches,
    sandwichesAr,
} 