import styled from "styled-components";

function SandwichesEn({}) {
    return (
        <>
            <div>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Sandwiches Pics/sandwiches- butter turkey croissant.png" />
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Butter Turkey Croissant <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>
                                    with turkey, cretan gruyere, tomato, mayo & lettuce
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>30 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Sandwiches Pics/sandwiches- bread bresaola.png" />
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Sourdough Bread Bresaola <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>
                                    soft cheese, breasola, tomato & avocado
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>35 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
            </div>
        </> 
    )
}

function SandwichesAr({}) {
    return (
        <>
            <div>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Sandwiches Pics/sandwiches- butter turkey croissant.png" />
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        كرواسان الزبدة مع ديك رومي <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>
                            جبنة الكريتان غرويير،
طماطم، مايونيز، خس 
                            </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٣٠ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Sandwiches Pics/sandwiches- bread bresaola.png" />
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        خبز الساوردو بريزولا <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>
                            جبنة طرية، طماطم وأفوكادو
                            </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٣٥ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
            </div>
        </> 
    )
}

const SingleItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
    /* img {
        width: 60%;
    } */
`

const SingleItemContainerStyledAr = styled(SingleItemContainerStyled)`
    flex-direction: row-reverse;
`

const ImageContainerStyled = styled.div`
    position: relative;
    max-width: 100px;
    width: 100%;
    img {
        width: 100%;
      
    }
`

const ImageContainerStyledAr = styled(ImageContainerStyled)`
    transform: scaleX(-1);
`

const ItemDescriptionStyled = styled.div`

    font-family: "Gotham Greek Book";
    font-size: 14px;
    p {
        margin: 0px;
        line-height: 15px;
    }
`

const ItemDescriptionArStyled = styled(ItemDescriptionStyled)`
    font-family: "GE SS Text";
    font-size: 15px;
    text-align: right;
`

const ItemDescriptionParStyled = styled.div`
    padding-right: 20px;
`

const ItemDescriptionParArStyled = styled.div`
    padding-left: 20px;
`

const TextItalicBoxStyled = styled.span`
    font-weight: lighter;
    font-style: italic;
    font-size: 12px;
`
const PriceBoxStyled = styled.div`
    position: relative;
    margin-top: 4px;
    font-weight: bold;
    font-size: 12px;
   font-weight: bold;
`
export {
    SandwichesEn,
    SandwichesAr
};