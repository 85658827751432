import { juices, coffee, juicesAr, coffeeAr } from "../../../data/baronNewCategories/baronBeverages";
import {Grid} from "@mui/material"
import { MainCategoryContainerStyled, CategoryContainerStyled, CategoryHeaderStyled, CategoryHeaderArStyled,  CategorySubHeaderStyled, CategorySubHeaderArStyled, CategoryGridStyled, ItemContainerStyled, ItemImageContainer, ItemBoxTwoStyled, ItemDetailsContainer, ItemDetailsStyled, ItemDetailsArStyled,  ItemPriceContainer, ItemPriceContainerAr, ItemNameStyled, ItemNameArStyled } from "./generalStyles";


function BeverageGrid({triggerLanguage}) {
    
    if(triggerLanguage == "en") {
        return (
            <MainCategoryContainerStyled>
               <CategoryContainerStyled>
                    <CategoryHeaderStyled>
                        Beverages
                    </CategoryHeaderStyled>
                    <CategorySubHeaderStyled>
                        Juices
                    </CategorySubHeaderStyled>
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {4}>
                        {
                            juices.map(({name, details, price, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemDetailsContainer>
                                                    <div>
                                                        <ItemNameStyled>
                                                            {name}
                                                        </ItemNameStyled>
                                                        <ItemDetailsStyled>{details}</ItemDetailsStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                                <ItemPriceContainer>
                                                    {price} <span>QR</span>
                                                </ItemPriceContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                <CategorySubHeaderStyled>
                        COFFEE
                    </CategorySubHeaderStyled>
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {4}>
                        {
                            coffee.map(({name, details, price, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemDetailsContainer>
                                                    <div>
                                                        <ItemNameStyled>
                                                            {name}
                                                        </ItemNameStyled>
                                                        <ItemDetailsStyled>{details}</ItemDetailsStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                                <ItemPriceContainer>
                                                    {price} <span>QR</span>
                                                </ItemPriceContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
            </MainCategoryContainerStyled>
        )
    }

    if(triggerLanguage == "ar") {
        return (
            <MainCategoryContainerStyled>
               <CategoryContainerStyled>
                    <CategoryHeaderArStyled>
                    مشروبات
                    </CategoryHeaderArStyled>
                    <CategorySubHeaderArStyled>
                    عصائر
                    </CategorySubHeaderArStyled>
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {4}>
                        {
                            juicesAr.map(({name, details, price, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemPriceContainerAr>
                                                    <div>{price}</div>
                                                    <div><span>ر.ق</span></div>   
                                                </ItemPriceContainerAr>
                                                <ItemDetailsContainer style = {{textAlign: "right"}}>
                                                    <div>
                                                        <ItemNameArStyled>
                                                            {name}
                                                        </ItemNameArStyled>
                                                        <ItemDetailsArStyled>{details}</ItemDetailsArStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                <CategorySubHeaderArStyled>
                قهوة
                    </CategorySubHeaderArStyled>
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {4}>
                        {
                            coffeeAr.map(({name, details, price, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemPriceContainerAr>
                                                    <div>{price}</div>
                                                    <div><span>ر.ق</span></div>   
                                                </ItemPriceContainerAr>
                                                <ItemDetailsContainer style = {{textAlign: "right"}}>
                                                    <div>
                                                        <ItemNameArStyled>
                                                            {name}
                                                        </ItemNameArStyled>
                                                        <ItemDetailsArStyled>{details}</ItemDetailsArStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
            </MainCategoryContainerStyled>
        )
    }
}

export default BeverageGrid;