//this script will contain json data of the categories / subcategories that we will manually update

// ---------------------------------- A LA CARTE - soft drinks section --------------------------//
const soft_drinks = [

    {
        name_en: "Coca Cola",
        name_ar: "كولا",
        price: "18",
        currency: "QR",
    },

    {
        name_en: "Diet Coke",
        name_ar: "كولا دايت",
        price: "18",
        currency: "QR",
    },

    {
        name_en: "Orange Fanta",
        name_ar: "فانتا البرتقال",
        price: "18",
        currency: "QR",
    },

    {
        name_en: "Strawberry Fanta",
        name_ar: "فانتا الفراولة",
        price: "18",
        currency: "QR",
    },

    {
        name_en: "Sprite",
        name_ar: "سبرايت",
        price: "18",
        currency: "QR",
    },
]

// ---------------------------------- BOUTIQUE - selection of premium tea section --------------------------//
const premium_tea = [

    {
        name_en: "White tea",
        description: "Jasmine Pearls",
        name_ar: "شاي أبيض",
        price: "45",
        currency: "QR",
    },

    {
        name_en: "Green tea",
        description: "Green Tea with Mint, Green Jasmine Premium",
        name_ar: "شاي أخضر",
        price: "35",
        currency: "QR",
    },

    {
        name_en: "Oolong tea",
        description: "Lime high Grown Oolong",
        name_ar: "أولونغ",
        price: "30",
        currency: "QR",
    },

    {
        name_en: "Black Tea",
        description: "Darjeeling Early Gray, Le Bleu breakfast tea, Flowery Early Grey",
        name_ar: "شاي أسود",
        price: "30",
        currency: "QR",
    },

    {
        name_en: "Herbal tea",
        description: "Orderant verbana",
        name_ar: "شاي هربل",
        price: "32",
        currency: "QR",
    },
]

// ---------------------------------- A LA CARTE - Water section --------------------------//
const water = [

    {
        name_en: "Still water large",
        name_ar: "مياه معدنية كبيرة",
        price: "24",
        currency: "QR",
    },

    {
        name_en: "Still water small",
        name_ar: "مياه معدنية صغيرة",
        price: "16",
        currency: "QR",
    },

    {
        name_en: "Large sparkling water",
        name_ar: "مياه غازية كبيرة",
        price: "24",
        currency: "QR",
    },

    {
        name_en: "Small sparkling water",
        name_ar: "مياه غازية صغيرة",
        price: "16",
        currency: "QR",
    },
]

// ---------------------------------- Afternoon Tea - FULL MENU SECTION --------------------------//
const afternoon_full_menu = [

    {
        name_en: "Spicy Chicken mayo in white bread (G)",
        name_ar: "الدجاج الحار بالمايونيز مع الخبز الابيض",
    },

    {
        name_en: "Salted cucumber carpaccio with cream cheese and chives mousse on spinach bread (G/D)",
        name_ar: "خبز السبانخ مغطى بلحاف من رقائق الخيار المملحة مع كريمة الجبنة و مووس الثوم",
    },

    {
        name_en: "Free-range egg mayo with radish cress on plain whole loaf bread (G)",
        name_ar: "الفجل والخردل مع مايونيز البيض البلدي على رغيف حبوب القمح الكاملة",
    },

    {
        name_en: "Slow roasted beef, mayo horseradish and arugula on multi cereal loaf (G)",
        name_ar: "لحم عجل مشوي على نار هادئة، مقدمة مع الفجل الحار والجرجر على رغيف خبز الحبوب المتنوعه",
    },

    {
        name_en: "Cashew cheese and sauerkraut on multi cereal loaf",
        name_ar: "جبنة الكاجو والملفوف المخلل على خبز متعدد الحبوب",
    },

    {
        name_en: "Fresh mozzarella with sun-dried tomato and pesto sauce (G/D)",
        name_ar: "موزاريلا طازجة مع طماطم مجففة و صلصة البيستو",
    }
]

// ---------------------------------- Afternoon Tea - FULL MENU - PASTRY SECTION --------------------------//
const full_menu_pastry_ensemble = [

    {
        name_en: "Gâteau au safran (G/D/N)",
        name_ar: "كيكة الزعفران",
    },

    {
        name_en: "Tarte aux mirtilles (G/D/N)",
        name_ar: "تارت بلوبيري",
    },

    {
        name_en: "Gâteau aux cerises (G/D/N)",
        name_ar: "كيكة الكرز",
    },

    {
        name_en: "Tarte au chocolate (G/D/N)",
        name_ar: "تارت الشوكولاته",
    },

    {
        name_en: "Mousse au cafe (G/D/N)",
        name_ar: "موس القهوة",
    },

    {
        name_en: "Gâteau à la pistache (G/D/N)",
        name_ar: "كيكة الفستق",
    },

    {
        name_en: "Gâteau au amandes (G/D/N)",
        name_ar: "كيكة اللوز",
    },

    {
        name_en: "Mille-feuilles (G/D)",
        name_ar: "ميل فاي",
    },

    {
        name_en: "Madeleine (G/D)",
        name_ar: "مادلين",
    },
]

// ---------------------------------- Afternoon Tea - FULL MENU - SCONES SECTION --------------------------//
const full_menu_scones = [
    
    {
        name_en: "Plain / Raisin (G/D)",
        name_ar: "سادة \ زبيب",
    },

    {
        name_en: "Served with clotted cream, strawberry and apricot jams preserves",
        name_ar: "تقدم مع الكريمة المخثرة، مربى الفراولة والمشمش",
    },
]


// ---------------------------------- Afternoon Tea - FULL MENU - premium tea SECTION --------------------------//
const full_menu_selection_of_premium_tea_coffee = [

    {
        name_en: "Breakfast Tea | Jasmine Premium | Chamomile | Green tea with mint | Jeoncha",
        name_ar: "بريكفاست تي  | جازمين بريميوم   | بابونج   | شاي اخضر مع نعناع | جونشا",
    },

    {
        name_en: "Verbena mint | Darjeeling | Earl Grey | Citrus tea | Oolong",
        name_ar: "لويزه بالنعناع   |   شاي إرل غري   |   شاي الحمضيات   |   أولونغ",
    },

    {
        name_en: "Espresso | Americano | Flat White | Cappuccino | Latte",
        name_ar: "اسبرسو   |   اميريكانو   |   فلات وايت   |   كابتشينو   |   لاتيه",
    },
]

// ---------------------------------- Afternoon Tea - ENHANCED MENU - enhanced section --------------------------//
const enhanced_menu_afternoon_tea_expereince = [

    {
        name_en: "Complete your experience with a choice of our signature",
        name_ar: "اكمل تجربتك مع اختيارك من تخصصاتنا المميزة",
    },

    {
        name_en: "Cream Caramel | Cheesecake | Vanilla Meringue",
        name_ar: "كريم كراميل   |   تشيز كيك   |   ميرانج الفانيلا",
    },
]


export {
    soft_drinks,
    premium_tea,
    water,
    afternoon_full_menu,
    full_menu_pastry_ensemble,
    full_menu_scones,
    full_menu_selection_of_premium_tea_coffee,
    enhanced_menu_afternoon_tea_expereince,
}