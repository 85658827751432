import styled from "styled-components";


const Test = styled.div`
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    inset:0;
    background-color: #011e41;
    z-index:1;

    img {
    }

    @media only screen and (min-width: 500px) {
        img {
            /* max-width: 500px;
            left:20%; */
            top: -10%;
        }
    }
`

const ItemContainerStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    color: #dbbc74;
`

const ItemImageContainerStyled = styled.div`
    position: relative;
    width: 100%;

    img {
        width: 100%;
    }

`

const EnglishArabicFlexStyled = styled.div`
    position: relative;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 100px;
`

const ItemDescriptionContainerStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const ItemEnglishTitleStyled = styled.div`
    position: relative;
    width: 100%;
    font-family: "Palatino";
    font-size: 16px;
    margin-bottom: 0px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    //font-family: "" ask gen 
`

const PriceContainerStyled = styled.div`
    position: absolute;
    right: 5%;
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 16px;
    span {
        margin-left: 1px;
        font-size: 10px;
        
    }
`

const ItemEnglishDescriptionStyled = styled.div`
    position: relative;
    width: 50%;
    text-align: center;
    font-family: "Palatino-Italic";
    font-size: 11px;

    p {
        margin: 0;
    }
`

const ArabicContainerStyled = styled(ItemContainerStyled)`
    text-align: center;
    margin-top: 20px;
    width: 50%;
`


const ItemArabicTitleStyled = styled.div`

    font-family: "URWGeometricArabic";
    font-size: 16px;
    
`

const ItemArabicDescriptionStyled = styled.div`
    font-family: "URWGeometricArabic-Reg";
    font-size: 12px;
`

export {
    Test,
    ItemContainerStyled,
    ItemImageContainerStyled,
    EnglishArabicFlexStyled,
    ItemDescriptionContainerStyled,
    ItemEnglishTitleStyled,
    PriceContainerStyled,
    ItemEnglishDescriptionStyled,
    ArabicContainerStyled,
    ItemArabicDescriptionStyled,
    ItemArabicTitleStyled,
}