import emailjs from "emailjs-com"
import React from "react"
import styled from "styled-components"

function ContactForm() {

    // function sendEmail(e) {
    //     e.preventDefault();

    //     emailjs.sendForm('service_5v2x54q', 'template_56ef8qf', form.current, 'n_d5YRcySPRSawL8p')
    //       .then((result) => {
    //           console.log(result.text);
    //       }, (error) => {
    //           console.log(error.text);
    //       });
        
    //       //reset form after submit
    //       e.target.reset()
    // }

    return (
        <>
            <FormContainerStyled>
                {/* <form onSubmit ={sendEmail}> */}
                <div>
                    <input type = "text" className = "form-control" placeholder= "Name" name = "name"/>
                </div>
                <div>
                    <input type = "email" className = "form-control" placeholder= "Email Address" name = "name"/>
                </div>
                <div>
                    <input type = "text" className = "form-control" placeholder= "Subject" name = "subject"/>
                </div>
                <div>
                    <textarea className = "form-control" id = "" cols = "30" rows = "8" placeholder = "Your message" name = "message"></textarea>
                </div>
                <ButtonContainerStyled>
                <input type = "submit" className = "form-control"/>
                </ButtonContainerStyled>
                {/* </form> */}
            </FormContainerStyled>           
        </>
    )
}

const FormContainerStyled = styled.div`
    position: relative;
    background-color: skyblue;
    margin-top: 50px;
`

const ButtonContainerStyled = styled.div`
    position: relative;

`
export default ContactForm