import {CarteSoupsGrid, CarteSaladsGrid, CarteHotStarter, CarteSandwiches, CarteMainCourseGrid, CartePastaAndRisotto, CarteDesserts, CarteCakes, CarteMocktails, CarteCoffee, CarteDrinks, CarteWater, CarteTea} from "./"
import {useEffect, useRef} from "react"
import styled from "styled-components"

function CarteComps({carteEls, navBarTwoRef}) {
    const carteSections = useRef([])
    let categoriesIndices = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']


    //tracks the section id as we scroll
    function windowScrollTracker(currentScroll) {
        var sectionFromTop;
        var sectionId;

        //get the id of the current active cateogry as we scroll
        
        carteSections.current.forEach((section) => {
            sectionFromTop = section.getBoundingClientRect().top;

            if (sectionFromTop <= 10) {
                sectionId = section.getAttribute("id");
                console.log("the section id is: ", sectionId)
            }            
        })

        //pass the current active section's id to the nav bar tracker function
        navBarTracker(sectionId)
    }

    //changes the colour of the active category
    function activeCategoryHandler(sectionId) {
        var index = sectionId - 1;
        console.log(index);
        carteEls.current[index].style.color = "#F5A800"

        categoriesIndices.map((item) => {
            if(item != index) {
                carteEls.current[item].style.color = "white"
            }
        })
    }

    useEffect(() => {
        function handleScroll(e) {
            var currentScroll = window.pageYOffset;
            windowScrollTracker(currentScroll)
        }     

        var timer = null;

        function scrollHandler() {
            if(timer !== null) {
                clearTimeout(timer);
            }
            timer = setTimeout(function () {
                handleScroll()
            }, 50)
        }

        window.addEventListener('scroll', scrollHandler)

        //cleanup event listener
        return () => {
            window.removeEventListener('scroll', scrollHandler )
        }

    }, [])

    //activates the current active section based on id
    async function navBarTracker(sectionId) {
       
        switch(sectionId) {
            //activates the soup category
            case '1':
                navBarTwoRef.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates the salad category
            case '2':
                navBarTwoRef.current.scrollLeft = 50;
                await activeCategoryHandler(sectionId)
            break;

            //activates the hot starter category
            case '3':
                navBarTwoRef.current.scrollLeft = 50;
                await activeCategoryHandler(sectionId)
            break;

            //activates the sandwiches category
            case '4':
                navBarTwoRef.current.scrollLeft = 190;
                await activeCategoryHandler(sectionId)
            break;

            //activates the main course category
            case '5':
                navBarTwoRef.current.scrollLeft = 340;
                await activeCategoryHandler(sectionId)
            break;

            //activates the pasta & risotto category
            case '6':
                navBarTwoRef.current.scrollLeft = 510;
                await activeCategoryHandler(sectionId)
            break;

            //activates the dessert category
            case '7':
                navBarTwoRef.current.scrollLeft = 640;
                await activeCategoryHandler(sectionId)
            break;

            //activates the cakes category
            case '8':
                navBarTwoRef.current.scrollLeft = 740;
                await activeCategoryHandler(sectionId)
            break;

            //activates the mocktails category
            case '9':
                navBarTwoRef.current.scrollLeft = 840;
                await activeCategoryHandler(sectionId)
            break;

            //activates the soft drinks category
            case '10':
                navBarTwoRef.current.scrollLeft = 970;
                await activeCategoryHandler(sectionId)
            break;

            //activates the tea category
            case '11':
                navBarTwoRef.current.scrollLeft = 1110;
                await activeCategoryHandler(sectionId)
            break;

            //activates the coffee category
            case '12':
                navBarTwoRef.current.scrollLeft = 1300;
                await activeCategoryHandler(sectionId)
            break;

            //activates the water category
            case '13':
                navBarTwoRef.current.scrollLeft = 1500;
                await activeCategoryHandler(sectionId)
            break;

            //activates the water category
          
        }

        return "resolved"
    }


    return (
        <>
            <section id = "1" ref = {(el) => carteSections.current[0] = el}>
                <CarteSoupsGrid/>
            </section>
            <section id = "2" ref = {(el) => carteSections.current[1] = el}>
                <CarteSaladsGrid/>
            </section>
            <section id = "3" ref = {(el) => carteSections.current[2] = el}>
                <CarteHotStarter/>
            </section>
            <section id = "4" ref = {(el) => carteSections.current[3] = el}>
                <CarteSandwiches/>
            </section>
            <section id = "5" ref = {(el) => carteSections.current[4] = el}>
                <CarteMainCourseGrid/>
            </section>
            <section id = "6" ref = {(el) => carteSections.current[5] = el}>
                <CartePastaAndRisotto/>
            </section>
            <section id = "7" ref = {(el) => carteSections.current[6] = el}>
                <CarteDesserts/>
            </section>
            <section id = "8" ref = {(el) => carteSections.current[7] = el}>
                <CarteCakes/>
            </section>
            <section id = "9" ref = {(el) => carteSections.current[8] = el}>
                <CarteMocktails/>
            </section>
            <section id = "10" ref = {(el) => carteSections.current[9] = el}>
                <CarteDrinks/>
            </section>
            <section id = "11" ref = {(el) => carteSections.current[10] = el}>
                <CarteTea/>
            </section>
            <section id = "12" ref = {(el) => carteSections.current[11] = el}>
                <CarteCoffee/>
            </section>
            <section id = "13" ref = {(el) => carteSections.current[12] = el}>
                <CarteWater/>
            </section>
        </>
    )
}



export default CarteComps