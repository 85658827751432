import {BoutiqueBaguette, BoutiqueCroissant, BoutiqueScones, BoutiqueSourdough, BoutiqueDanish} from "./";
import { SearchBarContainerStyled, AddItemButtonStyled, HideItemButtonStyled, SearchTextFieldStyled, SearchIconStyled, CopyRightStyled, LogoutContainerStyled, GeneralButtonStyled } from "../CommonStyled";
import {useState, useEffect, useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark, faEye, faPlus, faEyeSlash} from "@fortawesome/free-solid-svg-icons"
import axios from "axios";
import {Link} from "react-router-dom";
import {TailSpin} from "react-loader-spinner";
import styled from "styled-components";
import {ThemeProvider} from "@mui/material/styles";
import {Button} from "@mui/material";

function Boutique({boutiqueEls, navBarTwoRef, auth, handleLogout}) {
    const [boutiqueItems, setBoutiqueItems] = useState({
        "SOURDOUGH": [],
        "BAGUETTE": [],
        "DANISH": [],
        "CROISSANT": [],
        "SCONES": [],

})

    const [searchQuery, setSearchQuery] = useState("");
    const [showSearch, setShowSearch] = useState(false);
    const [showHiddenItems, setShowHiddenItems] = useState(false);
    const url = "https://www.lovelysunnydayapi.com/api/v1/testMenus";
    const [loading, setLoading] = useState(true);

    let categoriesIndices = ['0', '1', '2', '3', '4' ]
    const boutiqueSections = useRef([]);

    //tracks the sections id as we scroll
    function windowScrollTracker(currentScroll) {
        var sectionFromTop;
        var sectionId;

        //get id of the current active category as we scroll
        boutiqueSections.current.forEach((section) => {
            sectionFromTop = section.getBoundingClientRect().top;

            if(sectionFromTop <= 10) {
                sectionId = section.getAttribute("id");
            }
        })

        //pass the current active section's id to the nav bar tracker function
        navBarTracker(sectionId)
    }

    //changes the colour of the active category
    function activeCategoryHandler(sectionId) {
        var index = sectionId - 1;
        boutiqueEls.current[index].style.color = "#F5A800"

        categoriesIndices.map((item) => {
            if(item != index) {
                boutiqueEls.current[item].style.color = "white"
            }
        })
    }

    //activates the current active section based on id
    async function navBarTracker(sectionId) {
       
        switch(sectionId) {
            //activates the sourdough category
            case '1':
                navBarTwoRef.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates the baguette category
            case '2':
                navBarTwoRef.current.scrollLeft = 20;
                await activeCategoryHandler(sectionId)
            break;

            //activates the danish category
            case '3':
                navBarTwoRef.current.scrollLeft = 130;
                await activeCategoryHandler(sectionId)
            break;

            //activates the croissant category
            case '4':
                navBarTwoRef.current.scrollLeft = 300;
                await activeCategoryHandler(sectionId)
            break;

            //activates the scones category
            case '5':
                await activeCategoryHandler(sectionId)
            break;
        }

        return "resolved"
    }

    //triggers the search bar when the user clicks on the search icon
    function handleSearchBar() {
        setShowSearch(true);
    }


    //function that sets the
    function handleSearchQuery(event) {
        setSearchQuery(event.target.value);
    }

    function handleShowHidden() {
        setShowHiddenItems(!showHiddenItems);
    }

    async function getAllItems() {
        try {
            const link = `${url}?search=${searchQuery}&category=BOUTIQUE`
            const {data} = await axios.get(link, {
                withCredentials: true,
            })


            setBoutiqueItems({
                "SOURDOUGH": [],
                "BAGUETTE": [],
                "DANISH": [],
                "CROISSANT": [],
                "SCONES": [],
            })

            data.items.map((item) => {
                switch(item.sub_category) {
                    case "SOURDOUGH":
                        setBoutiqueItems(prevItems => ({
                            ...prevItems,
                            "SOURDOUGH": [...prevItems["SOURDOUGH"], item]
                        }))
                    break;

                    case "BAGUETTE":
                        setBoutiqueItems(prevItems => ({
                            ...prevItems,
                            "BAGUETTE": [...prevItems["BAGUETTE"], item]
                        }))
                    break;

                    case "DANISH":
                        setBoutiqueItems(prevItems => ({
                            ...prevItems,
                            "DANISH": [...prevItems["DANISH"], item]
                        }))
                    break;

                    case "CROISSANT":
                        setBoutiqueItems(prevItems => ({
                            ...prevItems,
                            "CROISSANT": [...prevItems["CROISSANT"], item]
                        }))
                    break;

                    case "SCONES":
                        setBoutiqueItems(prevItems => ({
                            ...prevItems,
                            "SCONES": [...prevItems["SCONES"], item]
                        }))
                    break;
                }
            })
            setLoading(false);
        } catch (error) {
            console.log("encountered an error");
        }
    }

       //fetches the items from the database on first render and whenever we change the search query
       useEffect(() => {
        getAllItems();
    }, [searchQuery])

    useEffect(() => {
        function handleScroll(e) {
            var currentScroll = window.pageYOffset;
            windowScrollTracker(currentScroll)
        }     

        var timer = null;

        function scrollHandler() {
            if(timer !== null) {
                clearTimeout(timer);
            }
            timer = setTimeout(function () {
                handleScroll()
            }, 50)
        }

        window.addEventListener('scroll', scrollHandler)

        //cleanup event listener
        return () => {
            window.removeEventListener('scroll', scrollHandler )
        }

    }, [])

    if(loading) {
        return (
            <>
                <LoadingContainerStyled>
                    <TailSpin
                        visible={true}
                        height="40"
                        width="40"
                        color="#006580"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                    >
                    </TailSpin>
                </LoadingContainerStyled>
            </>
        )
    }

    if(!loading) {
        return (
            <>
                <Link
                    to = "/restaurants/menus/le-bleu/admin/add-item"
                    target = "_blank"
                >
                    {auth.role === "le-bleu" && <AddItemButtonStyled>
                        <FontAwesomeIcon icon = {faPlus} style = {{fontSize: "20px", padding: "0"}} />
                    </AddItemButtonStyled>}
                </Link>
                {auth.role === "le-bleu" && <HideItemButtonStyled onClick = {handleShowHidden}>
                    <FontAwesomeIcon icon = {!showHiddenItems ? faEyeSlash : faEye} style = {{fontSize: "20px", padding: "0"}}/>
                </HideItemButtonStyled>}
                {auth.role === "le-bleu" && <SearchBarContainerStyled showSearch={showSearch} onChange={handleSearchQuery} value={searchQuery}>
                {!showSearch ? <SearchIconStyled onClick = {handleSearchBar} 
                >
                        <FontAwesomeIcon
                            icon = {faMagnifyingGlass} style = {{fontSize: "20px", padding: "0"}}
                        />
                       
                    </SearchIconStyled> : <SearchIconStyled>
                        <FontAwesomeIcon icon = {faXmark} onClick = {() => setShowSearch(false)} />
                        </SearchIconStyled>}
                    {showSearch && <SearchTextFieldStyled placeholder = "Search here..."/>}
                </SearchBarContainerStyled>}
                { boutiqueItems["SOURDOUGH"] && boutiqueItems["SOURDOUGH"].length != 0 &&
                    <section id = "1" ref = {(el) => boutiqueSections.current[0] = el}>
                        <BoutiqueSourdough boutiqueSourdough = {boutiqueItems["SOURDOUGH"]} showHiddenItems = {showHiddenItems} auth = {auth}/>
                    </section>}
                { boutiqueItems["BAGUETTE"] && boutiqueItems["BAGUETTE"].length != 0 &&
                    <section id = "2" ref = {(el) => boutiqueSections.current[1] = el}>
                        <BoutiqueBaguette boutiqueBaguette = {boutiqueItems["BAGUETTE"]} showHiddenItems = {showHiddenItems} auth = {auth}/>
                    </section>}
                { boutiqueItems["DANISH"] && boutiqueItems["DANISH"].length != 0 &&
                    <section id = "3" ref = {(el) => boutiqueSections.current[2] = el}>
                        <BoutiqueDanish boutiqueDanish = {boutiqueItems["DANISH"]} showHiddenItems = {showHiddenItems} auth = {auth}/>
                    </section>}
                { boutiqueItems["CROISSANT"] && boutiqueItems["CROISSANT"].length != 0 &&
                    <section id = "4" ref = {(el) => boutiqueSections.current[3] = el}>
                        <BoutiqueCroissant boutiqueCroissant = {boutiqueItems["CROISSANT"]} showHiddenItems = {showHiddenItems} auth = {auth}/>
                    </section>}
                { boutiqueItems["SCONES"] && boutiqueItems["SCONES"].length != 0 &&
                    <section id = "5" ref = {(el) => boutiqueSections.current[4] = el}>
                        <BoutiqueScones boutiqueScones = {boutiqueItems["SCONES"]} showHiddenItems = {showHiddenItems} auth = {auth}/>
                    </section>}
                <CopyRightStyled style = {{marginTop: "110px"}}>
                    <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                        <img src = "/Developed-By-LSD.svg"/>
                    </a>
                    <LogoutContainerStyled>
                        <ThemeProvider theme = {GeneralButtonStyled}>
                            <Button
                                onClick = {handleLogout}
                                variant = "contained"
                            >
                                {auth.role === "le-bleu" ? <span>LOG OUT</span> : <span>ADMIN</span>}
                            </Button>
                        </ThemeProvider>
                    </LogoutContainerStyled>
                </CopyRightStyled>
            </>
        )
    }

    
}

const LoadingContainerStyled = styled.div`
    position: fixed;
    inset: 0;
    top: 120px;
    background-color: white;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    z-index:10;
`

export default Boutique;