import styled from "styled-components";


const CategoryContainerStyled = styled.div`
    position: relative;
    top: 120px;
    margin-top: 30px;
`

const ArabicContainerStyled = styled.div`
    position: relative;
    margin-top: 20px;
`

const DrinksArabicContainerStyled = styled.div`
    position: relative;
    width: 80%;
    margin: 0 auto;
`

const DrinksArabicTextStyled = styled.div`
    position: relative;
    font-family: "GE SS Text";
    font-size: 12px;
`

const CategoryHeaderStyled = styled.div`
    position: relative;
    font-family: "Professor";
    font-size: 20px;
    width: 100%;
    text-align: center;

    span {

    }
`

const ItemContainerStyled = styled.div`
    position: relative;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const ItemContainerTeaStyled = styled(ItemContainerStyled)`
    flex-direction: row;
    justify-content: space-around;
`

const ItemImageContainerStyled = styled.div`
    position: relative;
    width: 100%;

    img {
        width: 100%;
    }
`

const ItemEnglishTitleStyled = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    font-family: "Futura Std Book";
    font-size: 12px;

    span { 
    }
`

const ItemEnglishTeaTitleStyled = styled(ItemEnglishTitleStyled)`
    justify-content: flex-start;
`

const ItemArabicTitleStyled = styled(ItemEnglishTitleStyled)`
    font-family: "GE SS Text";
    margin-top: -10px;
`

const PriceContainerStyled = styled.div`
    position: absolute;
    right: 5px;
    font-weight: bold;
    font-family: "Futura Std Book";
    font-size: 12px;
    
    span {
        margin-left: 1px;
        font-size: 8px;
        font-weight: 500;
    }
`
const ItemEnglishDescriptionStyled = styled.div`
    position: relative;
    width: 100%;
    text-align: center;
    font-family: "Futura Std Book";
    font-size: 10px;
    
    p { 
        margin: 0;
    }
`

const ItemArabicDescriptionStyled = styled(ItemEnglishDescriptionStyled)`
    font-family: "GE SS Text";
`



const FillerContainerStyled = styled.div`
    position: relative;
    width: 100%;
    height: 50px;
`
export {
    CategoryHeaderStyled,
    ItemContainerStyled,
    ItemContainerTeaStyled,
    ItemEnglishTeaTitleStyled,
    ItemImageContainerStyled,
    ItemEnglishTitleStyled,
    PriceContainerStyled,
    ItemEnglishDescriptionStyled,
    CategoryContainerStyled,
    ArabicContainerStyled,
    ItemArabicTitleStyled,
    ItemArabicDescriptionStyled,
    DrinksArabicContainerStyled,
    DrinksArabicTextStyled,
    FillerContainerStyled,
}