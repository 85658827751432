const specialityCuts = [

    {
        name: "Angus Rib eye",
        name_ar: "ضلع لحم أنغوس",
        price: "215",
        image_path: "/habra-menu-designs/speciality cuts/Habra Menu- Wagyu Ribeye.jpg",
    },

    {
        name: "Angus tenderloin",
        name_ar: "أنجوس تندرلوين",
        price: "160",
        image_path: "/habra-menu-designs/speciality cuts/Habra Menu- Angus Tenderloin-2.jpg",
    },

    {
        name: "Wagyu rib eye",
        name_ar: "ضلع لحم واغيو",
        price: "310",
        image_path: "/habra-menu-designs/speciality cuts/Habra Menu- Wagyu Ribeye.jpg",
    },

    {
        name: "Wagyu tenderloin",
        name_ar: "واغيو تندرلوين",
        price: "195",
        image_path: "/habra-menu-designs/speciality cuts/Habra Menu- Wagyu Tenderloin.jpg",
    },
]

export default specialityCuts;