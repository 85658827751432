import {Grid} from "@mui/material";
import { MainCategoryContainerStyled, CategoryContainerStyled, CategoryHeaderStyled, CategoryHeaderArStyled,  CategorySubHeaderStyled, CategorySubHeaderArStyled, CategoryGridStyled, ItemContainerStyled, ItemImageContainer, ItemBoxTwoStyled, ItemDetailsContainer,  ItemPriceContainer, ItemPriceContainerAr, ItemNameStyled, ItemNameArStyled } from "./generalStyles";
import {drinks} from "../../../data/habraCategories"



function Drinks({triggerLanguage}) {

    const reversedPairs = drinks.reduce((acc, _, index, arr)=> {
        if(index %2 === 1) {
            acc.push(arr[index], arr[index-1]);
        }
        return acc
    }, []);

    if(triggerLanguage == "en") {
        return (
            <MainCategoryContainerStyled>
                    <CategoryContainerStyled>
                        <span>DRINKS</span>
                    </CategoryContainerStyled>
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {4}>
                            {drinks.map(({name, price, image_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {image_path}/>
                                            </ItemImageContainer>
                                                <ItemBoxTwoStyled>
                                                    <ItemDetailsContainer>
                                                        <div>
                                                            <ItemNameStyled>
                                                                    {name}
                                                            </ItemNameStyled>
                                                        </div>
                                                    </ItemDetailsContainer>
                                                    <ItemPriceContainer>
                                                        {price} <span>QR</span>
                                                </ItemPriceContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </CategoryGridStyled>
                </MainCategoryContainerStyled>
        )   
    }

    if(triggerLanguage == "ar") {
        return (
              <MainCategoryContainerStyled>
                <CategoryContainerStyled>
                    <CategoryHeaderArStyled>
                   مشروبات
                    </CategoryHeaderArStyled>
                    {/* <CategorySubHeaderStyled>
                        Sub Header one
                    </CategorySubHeaderStyled> */}
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {4}>
                        {
                            reversedPairs.map(({name_ar, price, image_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {image_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemPriceContainerAr>
                                                    <div>{price}</div>
                                                    <div><span>ر.ق</span></div>   
                                                </ItemPriceContainerAr>
                                                <ItemDetailsContainer style = {{textAlign: "right"}}>
                                                    <div>
                                                        <ItemNameArStyled>
                                                            {name_ar}
                                                        </ItemNameArStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
            </MainCategoryContainerStyled>
        )
      
    }

}

export default Drinks;