const mainCourse = [

    {
        img_src: "/blt-menu-designs/Main Course JPG/Carousel JPG/Baby-chicken-1072x801px.jpg",
        name_en: "BABY CHICKEN W / CANDIED LEMON",
        description_en: "Roasted baby chicken, marinated with lemon confit, green chili, salt, garlic, herbs de province and honey, served with rice",
        name_ar: "دجاج صغير بنكهة الليمون المخلل",
        description_ar: "دجاج صغير محمر ومتبل بالليمون، فلفل أخضر، زعتر،  ملح، ثوم، عشبة دي بروفانس وعسل يقدم مع الأرز",
        price: "95",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Main Course JPG/Carousel JPG/Black-Angus-1072x801px.jpg",
        name_en: "BLACK ANGUS TENDERLOIN",
        description_en: "US black Angus tenderloin, with mashed potato and pepper sauce",
        name_ar: "شريحة الفيليه أنغوس المشوية",
        description_ar: "لحم بلاك أنجوس أمريكي، مع بطاطس مهروسة وصلصة الفلفل",
        price: "270",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Main Course JPG/Carousel JPG/Duck-1072x801px.jpg",
        name_en: "DUCK LEG CONFIT",
        description_en: "Duck leg cooked sous vide for 36 hours, mashed potato, balsamic sauce and green olive",
        name_ar: "فخذ البط",
        description_ar: "فخذ البط مطهو على طريقة السوسفيد لمدة ٣٦ ساعة، بطاطس مهروسة، صلصة البلسميك والزيتون الأخضر",
        price: "105",
        currency: "QR",

    },


    {
        img_src: "/blt-menu-designs/Main Course JPG/Carousel JPG/Panang-1072x801px.jpg",
        name_en: "PANANG CHICKEN/SHRIMP",
        description_en: "Chicken breast or shrimp, panang sauce, cooking cream, parsley, red chili and basil",
        name_ar: "دجاج بانانج / روبيان",
        description_ar: "صدر دجاج أو روبيان، صلصة بانانج، كريمة طبخ، بقدونس، فلفل أحمر و ريحان",
        price: "96",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Main Course JPG/Carousel JPG/Seabass-1072x801px.jpg",
        name_en: "SEABASS FILLET",
        description_en: "Seabass fillet, beurre blanc sauce, fresh french green beans, asparagus and chips",
        name_ar: "فيليه السيباس",
        description_ar: "فيليه السيباس، صوص البور بلانك، فاصوليا خضراء طازجة، هليون ورقائق البطاطس",
        price: "180",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Main Course JPG/Carousel JPG/Short-ribs-1072x801px.jpg",
        name_en: "SHORT RIBS",
        description_en: "US angus shortribs cooked Sous Vide for 36 hours, oyster sauce, sesame oil, ginger, mashed potato",
        name_ar: "ضلوع قصيرة",
        description_ar: "ضلوع قصيرة مطهو بطريقة السوسفيد لمدة ٣٦ ساعة،  صوص المحار، زيت السمسم، زنجبيل، بطاطس مهروسة",
        price: "158",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Main Course JPG/Carousel JPG/Waguy-Tenderloin-1072x801px.jpg",
        name_en: "WAGYU TENDERLOIN",
        description_en: "Oyster sauce, soya and garlic with potato puree, pepper sauce",
        name_ar: "فيليه الواغيو",
        description_ar: "صلصة المحار, صويا وثوم, بطاطا مهروسة, صلصة الفلفل الاحمر",
        price: "320",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Main Course JPG/Carousel JPG/Miso-1072x801px.jpg",
        name_en: "MISO SALMON",
        description_en: "Grilled Norwegian salmon filet, marinated in miso sauce, baby carrots with honey mustard dressing, fresh grilled asparagus, rosemary sauce with steamed french rice on side",
        name_ar: "ميسو سلمون",
        description_ar: "فيليه سلمون نرويجي مشوي، متبل بصلصة ميسو، جزر صغير مع صلصة خردل بالعسل، هليون مشوي طازج، صلصة روزماري مع أرز فرنسي مطهو على البخار على الجانب",
        price: "107",
        currency: "QR",

    },
]

export default mainCourse;