import styled from "styled-components";
import { LeBleuPageStyled } from "../styles";
import { useEffect, useRef, useState} from "react";
import CoverContainer from "../components/MenuGrids/lbMenuNew/CoverContainer"
import { Breakfast } from "../components/MenuGrids/lbMenuNew/BreakfastCompsNew";
import { Carte } from "../components/MenuGrids/lbMenuNew/CarteCompsNew";
import { Boutique } from "../components/MenuGrids/lbMenuNew/BoutiqueCompsNew";
import {Afternoon} from "../components/MenuGrids/lbMenuNew/AfternoonCompsNew";
import {useNavigate, useLocation} from "react-router-dom";
import useAuth from "../hooks/useAuth";


function LeBleuMenuNew({}) {

    const [showCover, setShowCover] = useState(true);
    const [subNavBar, setSubNavBar] = useState('1');
    const [triggerCategory, setTriggerCategory] = useState("a1");
    const pagePositionRef = useRef({});
    const navBarRef = useRef({});
    const navBarTwoRef = useRef({});
    const sliderContainerRef = useRef({});
    const sliderContainerTwoRef = useRef({});

    const navEls = useRef({});
    const breakfastEls = useRef({});
    const carteEls = useRef({});
    const boutiqueEls = useRef({});
    const afternoon = useRef({});

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathnam || "/restaurants/menus/login";

    const {auth} = useAuth();
   
    // function windowScrollTracker(currentScroll) {
    //     if(navBarRef.current.getBoundingClientRect().top < 0) {
    //         navBarRef.current.style.position = "fixed";
    //         navBarRef.current.style.inset = "0";
    //         sliderContainerRef.current.style.position = "fixed";
    //         sliderContainerTwoRef.current.style.position = "fixed";
    //     }

    //     if(pagePositionRef.current.getBoundingClientRect().top > 0) {
    //         navBarRef.current.style.position = "relative";
    //         sliderContainerRef.current.style.position = "absolute";
    //         sliderContainerTwoRef.current.style.position = "absolute";
    //     }
    // }

    // function scrollToTop(elementRef) {
    //     const elementRect = elementRef.current.getBoundingClientRect();
    //     const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    //     const topPosition = elementRect.top + scrollTop;

    //     window.scrollTo({
    //         top: topPosition,
    //         behavior: "smooth"
    //     });
    // }

    //function to handle logging out
    async function handleLogout(e) {
        if(auth.role === "le-bleu") {
            e.preventDefault();
            try {
                await fetch("https://www.lovelysunnydayapi.com/api/v1/auth/logout", {
                    credentials: "include",
                })
                localStorage.removeItem("auth")
                navigate(from, {replace: true})
            } catch (error) {

            }
        }
        else {
            //navigate to login page if admin is not logged in
            navigate(from, {replace: true});
        }
    }

    function handleNavBar(id) {
        // scrollToTop(navBarRef)
        navEls.current[id].style.color = "#F5A800";
        for (const otherKey in navEls.current) {
            if(otherKey !== id) {
                navEls.current[otherKey].style.color = "white";
            }
        }
        switch(id) {
            case '0':
                setSubNavBar('0'); 
               
                setTriggerCategory("breakfast");
                setTimeout(() => {
                    breakfastEls.current[0].style.color = "#F5A800";
                }, 0)
                
            break;

            case '1':
                setSubNavBar('1');
  
                setTriggerCategory("carte");
                setTimeout(() => {
                    carteEls.current[0].style.color = "#F5A800";
                }, 0)
            break;

            case '2':
                setSubNavBar('2');
      
                setTriggerCategory("boutique");
                setTimeout(() => {
                    boutiqueEls.current[0].style.color = "#F5A800";
                }, 0)
            break;

            case '3':
                //show the navbar
                setSubNavBar('3');
              
                setTriggerCategory("afternoon");
                setTimeout(() => {
                    afternoon.current[0].style.color = "#F5A800";
                })
                
        }
    }

    function handleCategory(category, index) {
        // scrollToTop(navBarRef)
      
        if(category == "breakfast") {
            breakfastEls.current[index].style.color = "#F5A800";
            for(const otherKey in breakfastEls.current) {
                if(otherKey != index) {
                    breakfastEls.current[otherKey].style.color = "white";
                }
            }

            switch(index) {
                case 0:
                    navBarTwoRef.current.scrollLeft = 0;
                    setTriggerCategory("a1");
                break;

                case 1:
                    navBarTwoRef.current.scrollLeft = 100;
                    setTriggerCategory("a2");
                break;

                case 2:
                    navBarTwoRef.current.scrollLeft = 100;
                    setTriggerCategory("a3");
                break;

                case 3:
                    setTriggerCategory("a4");
                break;
            }
        }

        if(category == "carte") {
            carteEls.current[index].style.color = "#F5A800";
            for(const otherKey in carteEls.current) {
                if(otherKey != index) {
                   carteEls.current[otherKey].style.color = "white";
                }
            }
            switch(index) {

                //soup
                case 0:
                navBarTwoRef.current.scrollLeft = 0;
                setTriggerCategory("b1");
                break;

                //salad
                case 1:
                    navBarTwoRef.current.scrollLeft = 50;
                    setTriggerCategory("b2");
                break;


                //hot starter
                case 2:
                    navBarTwoRef.current.scrollLeft = 50;
                    setTriggerCategory("b3");
                break;


                // sandwiches
                case 3:
                    navBarTwoRef.current.scrollLeft = 190;
                    setTriggerCategory("b4");
                break;

                //main course
                case 4:
                    navBarTwoRef.current.scrollLeft = 340;
                    setTriggerCategory("b5");
                break

                //pasta & risotto
                case 5:
                    navBarTwoRef.current.scrollLeft = 510;
                    setTriggerCategory("b6");
                break

                //dessert
                case 6:
                    navBarTwoRef.current.scrollLeft = 640;
                    setTriggerCategory("b7");
                break;

                //cakes
                case 7:
                    navBarTwoRef.current.scrollLeft = 740;
                    setTriggerCategory("b8");
                break;


                //mocktails
                case 8:
                    navBarTwoRef.current.scrollLeft = 840;
                    setTriggerCategory("b9");
                break;

                //soft drinks
                case 9:
                    navBarTwoRef.current.scrollLeft = 970;
                    setTriggerCategory("b10");
                break;

                //premium tea
                case 10:
                    navBarTwoRef.current.scrollLeft = 1110;
                    setTriggerCategory("b11");
                break;

                //premium coffee
                case 11:
                    navBarTwoRef.current.scrollLeft = 1300;
                    setTriggerCategory("b12");
                break

                //water
                case 12:
                    navBarTwoRef.current.scrollLeft = 1500;
                    setTriggerCategory("b13");
                break;
        }
                }
                
        if(category == "boutique") {
            boutiqueEls.current[index].style.color = "#F5A800";
            for(const otherKey in boutiqueEls.current) {
                if(otherKey != index) {
                   boutiqueEls.current[otherKey].style.color = "white";
                }
            }
            switch(index) {

                //sourdough
                case 0:
                    navBarTwoRef.current.scrollLeft = 0;
                    setTriggerCategory("c1");
                break;
                
                //baguette
                case 1:
                    navBarTwoRef.current.scrollLeft = 20;
                    setTriggerCategory("c2");
                break;
                
                //danish
                case 2:
                    navBarTwoRef.current.scrollLeft = 130;
                    setTriggerCategory("c3");
                break;
                
                //croissant
                case 3:
                    navBarTwoRef.current.scrollLeft = 300;
                    setTriggerCategory("c4");
                break;

                //scones
                case 4:
                    setTriggerCategory("c5");
                break;
            }
        }

        if(category == "afternoon") {
            afternoon.current[index].style.color = "#F5A800";
            for(const otherKey in afternoon.current) {
                if(otherKey != index) {
                   afternoon.current[otherKey].style.color = "white";
                }
            }
        
           switch(index) {
            //full menu
            case 0:
                navBarTwoRef.current.scrollLeft = 0;
                setTriggerCategory("d1");
            break;

            //enhanced menu
            case 1:
                navBarTwoRef.current.scrollLeft = 100;
                setTriggerCategory("d2");
            break;

            //cream tea
            case 2:
                navBarTwoRef.current.scrollLeft = 100;
                setTriggerCategory("d3");
            break;
            }
        }
    }

    useEffect(()=> {
        if(!showCover) {
            setTimeout(() => {
                handleNavBar('0');
                handleCategory("breakfast", 0);
            }, 10)
        }
    }, [showCover])

    useEffect(() => {

        window.scrollTo(0, 0)
    }, [triggerCategory])

    if(showCover) {
        return (
            <>
                <CoverContainer setShowCover = {setShowCover}/>
            </>
        )
    }


    if(!showCover) {
        return (
            <>
                {/* <CoverContainer setShowCover = {setShowCover}/> */}
                <LeBleuPageStyled ref = {pagePositionRef}>
                    
                    <NavImageContainerStyled ref = {navBarRef}>
                        <img src = "/leBleu-menu-designs/lb-navBar.png"/>
                    </NavImageContainerStyled>
                    <SliderContainerStyled ref = {sliderContainerRef}>
                        <NavBarLayerOneStyled>
                            <NavBarOneTextStyled  key = "1" onClick = {() => handleNavBar('0')} ref = {(element) => navEls.current[0] = element}>
                                <span>breakfast</span>
                            </NavBarOneTextStyled>
                            <NavBarImageContainerStyled>
                                <img src = "/leBleu-menu-designs/LB-flower element.png" alt = "flower"/>
                            </NavBarImageContainerStyled>
                            <NavBarOneTextStyled  key = "2" onClick = {() => handleNavBar('1')} ref = {(element) => navEls.current[1] = element}>
                                <span>A LA CARTE</span>
                            </NavBarOneTextStyled>
                            <NavBarImageContainerStyled>
                                <img src = "/leBleu-menu-designs/LB-flower element.png" alt = "flower"/>
                            </NavBarImageContainerStyled>
                            <NavBarOneTextStyled  key = "3" onClick = {() => handleNavBar('2')} ref = {(element) => navEls.current[2] = element}>
                                <span>boutique</span>
                            </NavBarOneTextStyled>
                            <NavBarImageContainerStyled>
                                <img src = "/leBleu-menu-designs/LB-flower element.png" alt = "flower"/>
                            </NavBarImageContainerStyled>
                            <NavBarOneTextStyled  key = "4" onClick = {() => handleNavBar('3')} ref = {(element) => navEls.current[3] = element}>
                                <span>Afternoon Tea</span>
                            </NavBarOneTextStyled>
                        </NavBarLayerOneStyled>
                    </SliderContainerStyled>
                    <SliderContainerTwoStyled ref = {sliderContainerTwoRef}>
                        {subNavBar == '0' && <NavBarLayerTwoStyled ref = {navBarTwoRef}>
                                <NavBarTwoTextStyled ref = {(element) => breakfastEls.current[0] = element}>
                                    <a href = "#1">
                                        <span>Eggs</span>
                                    </a>
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled ref = {(element) => breakfastEls.current[1] = element}>
                                    <a href = "#2">
                                        <span>Toasts</span>                                    </a>
                                </NavBarTwoTextStyled>
                                {/* <NavBarTwoTextStyled onClick = {() => handleCategory("breakfast", 2)} ref = {(element) => breakfastEls.current[2] = element}>
                                    <span>Muffins</span>
                                </NavBarTwoTextStyled> */}
                                <NavBarTwoTextStyled ref = {(element) => breakfastEls.current[2] = element}>
                                    <a href = "#3">
                                        <span>Pudding & Yogurt</span>
                                    </a>
                                </NavBarTwoTextStyled>
                            </NavBarLayerTwoStyled>}
                        {subNavBar == '1' && <NavBarLayerTwoStyled ref = {navBarTwoRef}>
                                <NavBarTwoTextStyled ref = {(element) => carteEls.current[0] = element}>
                                    <a href = "#1">
                                        <span>Soup</span> 
                                    </a>
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled ref = {(element) => carteEls.current[1] = element}> 
                                <a href = "#2">
                                        <span>Salad</span> 
                                </a>   
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled ref = {(element) => carteEls.current[2] = element}>
                                <a href = "#3">
                                        <span>Hot Starter</span> 
                                </a>  
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled ref = {(element) => carteEls.current[3] = element}>
                                <a href = "#4">
                                        <span>Sandwiches</span> 
                                </a>   
                                </NavBarTwoTextStyled> 
                                <NavBarTwoTextStyled ref = {(element) => carteEls.current[4] = element}>
                                <a href = "#5">
                                        <span>Main course</span> 
                                </a>   
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled ref = {(element) => carteEls.current[5] = element}>
                                <a href = "#6">
                                        <span>Pasta & Risotto</span> 
                                </a>   
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled ref = {(element) => carteEls.current[6] = element}>
                                <a href = "#7">
                                        <span>Dessert</span> 
                                </a>    
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled ref = {(element) => carteEls.current[7] = element}>
                                <a href = "#8">
                                        <span>Cakes</span> 
                                </a>    
                                </NavBarTwoTextStyled> 
                                <NavBarTwoTextStyled ref = {(element) => carteEls.current[8] = element}>
                                <a href = "#9">
                                        <span>Mocktails</span> 
                                </a>   
                                </NavBarTwoTextStyled> 
                                <NavBarTwoTextStyled  ref = {(element) => carteEls.current[9] = element}>
                                <a href = "#10">
                                        <span>Soft Drinks</span> 
                                </a>    
                                </NavBarTwoTextStyled> 
                                <NavBarTwoTextStyled ref = {(element) => carteEls.current[10] = element}>
                                <a href = "#11">
                                        <span>Premium Tea</span> 
                                </a>    
                                </NavBarTwoTextStyled> 
                                <NavBarTwoTextStyled ref = {(element) => carteEls.current[11] = element}>
                                <a href = "#12">
                                        <span>Premium Coffee</span> 
                                </a>    
                                </NavBarTwoTextStyled> 
                                <NavBarTwoTextStyled ref = {(element) => carteEls.current[12] = element}>
                                <a href = "#13">
                                        <span>Water</span> 
                                </a>    
                                </NavBarTwoTextStyled>     
                            </NavBarLayerTwoStyled>}
                            {subNavBar == '2' && <NavBarLayerTwoStyled ref = {navBarTwoRef}>
                                <NavBarTwoTextStyled  ref = {(element) => boutiqueEls.current[0] = element}>
                                    <a href = "#1">
                                        <span>Sourdough</span> 
                                    </a>  
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled  ref = {(element) => boutiqueEls.current[1] = element}>
                                    <a href = "#2">
                                        <span>Baguette</span> 
                                    </a>   
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled  ref = {(element) => boutiqueEls.current[2] = element}>
                                    <a href = "#3">
                                        <span>Danish</span> 
                                    </a>  
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled  ref = {(element) => boutiqueEls.current[3] = element}>
                                    <a href = "#4">
                                        <span>Croissant</span> 
                                    </a>    
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled  ref = {(element) => boutiqueEls.current[4] = element}>
                                    <a href = "#5">
                                        <span>Scones</span> 
                                    </a>    
                                </NavBarTwoTextStyled>        
                            </NavBarLayerTwoStyled>}
                            {subNavBar == '3' && <NavBarLayerTwoStyled ref = {navBarTwoRef} style = {{gap: "20px"}}>
                                <NavBarTwoTextStyled  ref = {(element) => afternoon.current[0] = element}>
                                    <a href = "#1">
                                        <span>FULL MENU</span> 
                                    </a>  
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled  ref = {(element) => afternoon.current[1] = element}>
                                    <a href = "#2">
                                        <span>ENHANCED MENU</span> 
                                    </a>  
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled ref = {(element) => afternoon.current[2] = element}>
                                    <a href = "#3">
                                        <span>CREAM TEA</span> 
                                    </a>  
                                </NavBarTwoTextStyled>
                                </NavBarLayerTwoStyled>}
                    </SliderContainerTwoStyled>
                    {/* { triggerCategory == "breakfast" && <BreakfastComps breakfastEls = {breakfastEls} navBarTwoRef = {navBarTwoRef} />}
                    {triggerCategory == "carte" && <CarteComps carteEls = {carteEls} navBarTwoRef = {navBarTwoRef}/>}
                    {triggerCategory == "boutique" && <BoutiqueComps boutiqueEls = {boutiqueEls} navBarTwoRef = {navBarTwoRef}/>}
                    {triggerCategory == "afternoon" && <AfternoonComps afternoon = {afternoon} navBarTwoRef = {navBarTwoRef}/>} */}
                    {triggerCategory == "breakfast" && <Breakfast breakfastEls = {breakfastEls} navBarTwoRef={navBarTwoRef} auth = {auth} handleLogout = {handleLogout}/>}
                    {triggerCategory == "carte" && <Carte carteEls = {carteEls} navBarTwoRef = {navBarTwoRef} auth = {auth} handleLogout = {handleLogout}/>}
                    {triggerCategory == "boutique" && <Boutique boutiqueEls = {boutiqueEls} navBarTwoRef = {navBarTwoRef} auth = {auth} handleLogout = {handleLogout}/>}
                    {triggerCategory == "afternoon" && <Afternoon afternoon = {afternoon} navBarTwoRef = {navBarTwoRef} auth = {auth} handleLogout = {handleLogout}/>}
                </LeBleuPageStyled>
            </>
        )
    }

    
}


const NavImageContainerStyled = styled.div`
    position: fixed;
    inset: 0;
    width: 100%;
    max-height: 120px;
    z-index: 2;
    img {
        width: 100%;
        max-height: 120px;
    }
`


const SliderContainerStyled = styled.div`
    position: fixed;
    inset: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    z-index: 2;

`

const SliderContainerTwoStyled = styled.div`
    position: fixed;
    inset: 0;
    display:flex;
    z-index: 2;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 28px;
    height: 80px;

`

const NavBarLayerOneStyled = styled.div`
    position: fixed;
    display: flex;
    gap: 10px; //change later
    align-items: center;
    cursor: pointer;
    z-index: 2;

`

const NavBarOneTextStyled = styled.div`
    position: relative;
    color: white;
    cursor: pointer;

    span {
        font-size: 11px;
        
        font-family: "Futura Std Book";
        font-weight: 200;
        text-transform: uppercase;
    }
`

const NavBarLayerTwoStyled = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: scroll;
    scroll-behavior: smooth;
    gap: 40px;
    padding: 0px 30px;


    ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background-color: transparent;
    }
`

const NavBarTwoTextStyled = styled.div`
    position: relative;
    white-space: nowrap;
    color: white;
    text-transform: uppercase;

    span {
        font-size: 16px;
        
        /* font-family: "Professor"; */
        font-family: "Futura Std Book";
    }
`

const NavBarImageContainerStyled = styled.div`
    position: relative;
    width: 6px;
    img {
        width:100%;
    }
`

const LogoutContainerStyled = styled.div`
    position: relative;
    left: 45%;
    margin-bottom: 20px;
    transform: translateX(-50%);
`


export default LeBleuMenuNew;
