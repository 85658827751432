const sandwiches = [

    {
        img_src: "/blt-menu-designs/Sandwiches JPG/Carousel JPG/LTB-burger-1072x801px.jpg",
        name_en: "LTB MAC BURGER",
        description_en: "Thin beef patty, our homemade mac sauce and cheddar cheese",
        name_ar: "سلطة سيزر بالدجاج",
        description_ar: "شريحة لحم بقري رفيعة,صلصة ماك الخاصة بنا، وجبنة شيدر",
        price: "69",
        currency: "QR",

    },

]

export default sandwiches;