const tea_en = [
    {
        name_en: "Karak",
        description_en: "",
        price: "11",
        img_path: "/five-am-menu-designs/tea/15-Tea-Karak-72dpi.jpg"
    },

    {
        name_en: "Tea Breakfast English",
        description_en: "",
        price: "17",
        img_path: "/five-am-menu-designs/tea/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_en: "Green Tea",
        description_en: "",
        price: "17",
        img_path: "/five-am-menu-designs/tea/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_en: "Chammomile",
        description_en: "",
        price: "17",
        img_path: "/five-am-menu-designs/tea/01-bread&dips-Tannour Bread-72dpi.jpg"
    },
]

const tea_ar = [
    {
        name_ar: "إنغلش بريكفاست تي",
        description_ar: "",
        price: "17",
        img_path: "/five-am-menu-designs/tea/01-bread&dips-Tannour Bread-72dpi.jpg"

    },

    {
        name_ar: "كرك",
        description_ar: "",
        price: "11",
        img_path: "/five-am-menu-designs/tea/15-Tea-Karak-72dpi.jpg"

    },

    {
        name_ar: "شاي بابونج",
        description_ar: "",
        price: "17",
        img_path: "/five-am-menu-designs/tea/01-bread&dips-Tannour Bread-72dpi.jpg"

    },

    {
        name_ar: "شاي أخضر",
        description_ar: "",
        price: "17",
        img_path: "/five-am-menu-designs/tea/01-bread&dips-Tannour Bread-72dpi.jpg"

    },
]

export {
    tea_en,
    tea_ar,
}