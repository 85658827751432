import styled from "styled-components";
import {CategoryHeadingStyled, SingleFoodItemIndexStyled, FoodItemImageContainer, SingleFoodItemDescription, FoodTextContainerStyled, ItemNameEn, ItemNameAr, ItemPriceStyled} from "./ChariotCommonStyled";

function AllDayGrid({}) {
    return (
        <>
            <CategoryHeadingStyled>
                <img src = "/chariot-menu-designs/All Day PNG/Chariot-AllDay-V1-04.png" alt = "All Day Category chariot menu"/>
            </CategoryHeadingStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/Chariot-AllDay-V1-05.png" alt = "CORNDOG WITH creamy sweet chili sauce"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>CORNDOG WITH
                            <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> creamy sweet chili sauce</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div>
                         كورن دوغ <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                         مع صلصة الفلفل الحلو     
                        </span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>23 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/allday- breaded cheddar fingers.png" alt = "chariot all day breaded cheddar fingers"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>BREADED CHEDDAR FINGERS
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div style = {{fontWeight: "500"}}> اصابع جبنة الشيدر
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>27 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/Chariot-AllDay-V1-06.png" alt = "SWEET KALE SALAD with fruits fried cheese"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                    <div>SWEET KALE SALAD
                            <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> with fruits fried cheese</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                    <div>
                       سلطة الكيل <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                    مع الفاكهة الحلوة والجبنة المقلية  
                        </span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>29 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/Chariot-AllDay-V1-03.png" alt = "DOHA-TEXAS BRISKET SANDWICH MUSTARD AND KEWEPI"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>DOHA-TEXAS BRISKET SANDWICH MUSTARD AND KEWEPI</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span >ساندويش دوحة-تكساس برسكت</span>
                    </ItemNameAr>
                    <span style = {{fontFamily: "Futura Std", color: "red", fontSize: "8px"}}>ADD CHEESE - 5 QR</span>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>61 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/Chariot-AllDay-V1-07.png" alt = "COUNTRY FRIED CHICKEN SANDWICH"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>COUNTRY FRIED CHICKEN SANDWICH</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>ساندويش كانتري الدجاج المقلي</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>47 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/allday- country chicken.png" alt = "COUNTRY FRIED CHICKEN CAESAR SANDWICH"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>COUNTRY FRIED CHICKEN CAESAR SANDWICH</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>ساندويش سيزر كانتري الدجاج المقلي</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>48 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/Chariot-AllDay-V1-12.png" alt = "MAC & CHEESE"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>MAC & CHEESE</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>ماك اند تشيز</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>25 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/Chariot-AllDay-V1-13.png" alt = "BRISKET MAC & CHEESE"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>BRISKET MAC & CHEESE</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>برسكت ماك اند تشيز</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>33 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/allday- creamy pesto pasta.png" alt = "chariot all day creamy pesto"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>CREAMY PESTO PASTA
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div> باستا بكريمة الحبق
                        </div>
                        <span style = {{fontFamily: "Futura Std", color: "red", fontSize: "8px"}}>ADD FRIED CHICKEN - 19 QR</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>35 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/allday- babyshark.png" alt = "BABY SHARK"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>BABY SHARK
                                <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> Fresh fried chicken & fries</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div> بيبي شارك
                            <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                            دجاج مقلي وبطاطس  </span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>29 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/Chariot-AllDay-V1-10.png" alt = "OLD STAR CHEESE BURGER"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>OLD STAR CHEESE BURGER</span>
                    </ItemNameEn>
                    <ItemNameAr>
                        <span>أولد ستار تشيزبرجر</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>47 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img  style = {{ borderRadius: "10px"}} src = "/chariot-menu-designs/All Day PNG/Chariot-AllDay-V1-09.jpg" alt = "area 52 burger"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>AREA 52 BURGER
                            <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> mushroom & swiss</span>
                        </div>
                        </ItemNameEn>
                    <ItemNameAr>
                        <div> المنطقه ٢٥
                        <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                           مشروم وسويس   
                        </span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>52 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img style = {{ borderRadius: "10px"}} src = "/chariot-menu-designs/All Day PNG/Chariot-AllDay-V1-08.jpg" alt = "early 50s burger"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                    <div>EARLY 50s BURGER
                            <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> lettuce, tomato, pickles, sauce</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                    <div>      أوائل الخمسينيات    
                          <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                         خس، طماطم، مخلل، صوص
                        </span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>47 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/allday- mini burger.png" alt = "chariot all day mini burger"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>MINI BURGER
                                <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> cheese & sauce</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div> ميني برجر
                            <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                            جبنة وصوص</span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>39 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/allday- carnival burger.png" alt = "chariot all day carnival burger"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>CARNIVAL BURGER
                                <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> brioche bun with beef patty, cheddar cheese,</span>
                                <br/>
                                <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> sauce either spicy or not, french fries  & coleslaw on the side</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div> كرنيفال برجر
                            <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                            ،كرنيفال برجر
خبز البريوش مع شريحة لحم بقريجبنة شيدر</span>
                            <br/>
                            <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>صلصة حارة أو غير حارة، بطاطس مقلية وكول سلو على الجانب</span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>49 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/allday- grilled chicken burger.png" alt = "chariot all day grilled chicken burger"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>GRILLED CHICKEN BURGER
                                <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> lettuce, tomato, pickles, sauce</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div>برجر الدجاج المشوي
                            <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                            خس، طماطم، مخلل، صوص</span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>47 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/allday- ultimate open steak sandwich.png" alt = "chariot all day ultimate open steak sandwich"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>ULTIMATE OPEN STEAK SANDWICH
                            <br/>
                                <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> Ribeye steak with creamy mushrooms, onions, <br/> and rosemary on sourdough bread</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div> ألتيمت ستيك ساندويتش
                            <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                            ستيك مع الفطر الكريمي
                            </span>
                            <br/>
                            <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>والبصل وإكليل الجبل على خبز العجين</span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>61 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/allday- chicken burito.png" alt = "chariot all day chicken burito"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>CHICKEN BURITO
                                <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> Grilled chicken, guacamole, mashed black beans,</span>
                                <br/>
                                <span style ={{fontFamily: "Futura Std", fontWeight: "300", fontSize: "12px"}}> corn, mixed cheese & sauce</span>
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div> بوريتو الدجاج
                            <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>
                            ،دجاج مشوي، جوكمولي
                            </span>
                            <br/>
                            <span style ={{fontFamily: "ntaqat", fontWeight: "300", fontSize: "12px", paddingRight: "3px"}}>فاصوليا
  سوداء مهروسة، ذرة، جبنة مشكلة وصوص</span>
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>61 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/All Day PNG/allday- tuna melt sandwich.png" alt = "chariot tuna melt sandwich"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <div>TUNA MELT SANDWICH
                        </div>
                    </ItemNameEn>
                    <ItemNameAr>
                        <div>تونا ملت
                        
                        </div>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>39 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
 
        </>
    )
}

export default AllDayGrid;