import { Grid } from "@mui/material"
import { useState } from "react";
import soups from "../../../data/ltbCategories/soups";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Test, ItemContainerStyled, ItemImageContainerStyled, EnglishArabicFlexStyled, ItemDescriptionContainerStyled, ItemEnglishTitleStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, ArabicContainerStyled, ItemArabicDescriptionStyled, ItemArabicTitleStyled} from "./generalStyles"
import $ from "jquery";

function SoupsGrid() {

    const [showDish, setShowDish] = useState(-1);
    function handleClick(id) {
        setShowDish(id);
    }

    if(showDish < 0) {
        return (
            <Grid container spacing = {1} rowSpacing = {0} paddingTop = {3} paddingBottom = {3} width = {"95%"}>
                <Grid item xs = {6}>
                    <div  onClick = {() => handleClick("0")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Soups JPG/Grid JPG/Chicken soup.jpg"/>
                    <div className="dish-title">Chicken Soup</div>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div onClick = {() => handleClick("1")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Soups JPG/Grid JPG/French onion.jpg"/>
                    <div className="dish-title">French Soup</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "3" onClick = {() => handleClick("2")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Soups JPG/Grid JPG/Tomato soup.jpg"/>
                    <div className="dish-title">Tomato Soup</div>
                    </div>
                </Grid>
            </Grid>
        )
    }

    if(showDish >= 0) {
        return (
            <Test id = "blt-testing">
                <div className = "dish-heading">
                    Soup
                </div>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = {soups[showDish].img_src}/>
                    </ItemImageContainerStyled>
                    <EnglishArabicFlexStyled>
                        <ItemDescriptionContainerStyled>
                            <ItemEnglishTitleStyled>
                                {soups[showDish].name_en}
                                <PriceContainerStyled>
                                    {soups[showDish].price} <span>QR</span>
                                </PriceContainerStyled>
                            </ItemEnglishTitleStyled>
                            <ItemEnglishDescriptionStyled>
                                {soups[showDish].description_en}
                            </ItemEnglishDescriptionStyled>
                            <ArabicContainerStyled>
                                <ItemArabicTitleStyled>
                                    {soups[showDish].name_ar}
                                </ItemArabicTitleStyled>
                                <ItemArabicDescriptionStyled>
                                    {soups[showDish].description_ar}
                                </ItemArabicDescriptionStyled>
                            </ArabicContainerStyled>
                        </ItemDescriptionContainerStyled>
                    </EnglishArabicFlexStyled>
                </ItemContainerStyled>
                <div className = "dish-close-icon" onClick ={()=>setShowDish(-1)}>
                    <FontAwesomeIcon icon = {faXmark}/>
                </div>
            </Test>
        )
    }
}

export default SoupsGrid;