import styled from "styled-components";
import { CategoryHeaderStyled, ItemContainerStyled, ItemEnglishTitleStyled, ItemImageContainerStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, CategoryContainerStyled, ArabicContainerStyled, ItemArabicTitleStyled, ItemArabicDescriptionStyled, FillerContainerStyled } from "./CommonStyled";


function BreakfastEggs({}) {
    return (
        <>
        <CategoryContainerStyled>
            {/* <CategoryHeaderStyled>
                <span>Eggs</span>
            </CategoryHeaderStyled> */}
            <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-croque madame.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        Croque Madame
                    <PriceContainerStyled>
                        76
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Whole wheat toast with smoked turkey, cheesy bechamel, fried egg</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        كروك مدام
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        توست قمح كامل مع ديك رومي مدخن، بشاميل جبنة، بيض مقلي
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-asparagus labneh.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        Asparagus Labneh
                    <PriceContainerStyled>
                        66
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Cereal bread, asparagus, labneh, cheesy cream sauce, pine nuts, fried egg</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        اسباراغس مع اللبنه
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        خبز الحبوب، نبات الهليون، لبنة، صوص كريمة الجبنة، جنب الماعز، الصنوبر، بيض مقلي
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-Shakshuka Du Matin.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        Shakshuka Du Matin
                    <PriceContainerStyled>
                       59
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Organic egg with home made tomato paste, feta cheese, five herbs</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        شكشوكه الصباح
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        بيض عضوي مع صلصة طماطم الخاصة، جبنة الفيتا، اعشاب
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-falafel waffle.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Falafel Waffle
                    <PriceContainerStyled>
                       59
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Falafel waffle with organic egg, avocado puree, tahina sauce and spicy tahina sauce</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        وافل الفلافل
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        وافل فلافل مع بيض، افوكادو، صوص الطحينة، صوص طحينة الرسيراتشا
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-omelet cheese.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Omelet with Cheese
                    <PriceContainerStyled>
                       41
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Egg, cheddar cheese, butter</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        اومليت مع الجبن
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        بيض، جبنة الشيدر، زبدة
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-omelet mushroom.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Omelet with Mushroom
                    <PriceContainerStyled>
                       36
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Egg, mushroom, butter</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        اومليت مع المشروم
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        بيض، مشروم، زبدة
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-omelet spinach.png" alt = "omelet with spinach picture"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Omelet with Spinach
                    <PriceContainerStyled>
                       39
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Egg, baby spinach, butter</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        اومليت مع السبانخ
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        بيض، زبدة، سبانخ
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-pd terre benedict.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    P. D. Terre Benedic
                    <PriceContainerStyled>
                       43
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Crispy parmesan potato round, poached egg, spinach with hollandaise sauce</p>
                        <p>Add: Bacon / 12 QR • Turkey / 12 QR • Salmon / 16 QR</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        بطاطس البنديكت
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        بطاطس البرمزيان المحمرة مع البيض العضوي، سبانخ، صوص الهولندز
                        <p>اضافة: لحم مقدد / 12 ر.ق • ديك رومي مدخن / 12 ر.ق • سلمون مدخن / ١٦ ر.ق</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-lever croissant.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Lever D.S croissant
                    <PriceContainerStyled>
                       59
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Freshly baked croissant, creamy scrambled eggs, matured cheddar cheese, sriracha dip, chives</p>
                        <p>Add: Avocado / 12 QR • Smoked Salmon / 16 QR</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                       كرواسون شروق الشمس
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                كرواسون محمص مع البيض المخفوق، الجبنة الشيدر، صوص السيراتشا بالمايونزي  
                        <p>اضافة: افوكادو / 12 ر.ق • ديك رومي مدخن / ١٦ ر.ق</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
         
            </CategoryContainerStyled>
            
        </>
    )
}


export default BreakfastEggs;