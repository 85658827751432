const salads = [
    {
        img_src: "/blt-menu-designs/Salad JPG/Carousel JPG/Burrata-salad-1072x801px.jpg",
        name_en: "BURRATA",
        description_en: "Burrata cheese, cherry tomato, sherry vinaigrette, baby basil extra virgin olive oil",
        name_ar: "سلطة البوراتا",
        description_ar: "جبن بوراتا، طماطم كرزية، خل الشيري، أوراق ريحان صغيرة، وزيت زيتون",
        price: "96",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Salad JPG/Carousel JPG/Beetroot-salad-1072x801px.jpg",
        name_en: "BEETROOT SALAD",
        description_en: "Purple beetroots, yellow beetroots, mascarpone cheese, goat cheese, avocado, horse radish, coriander stamp, red chili, parsnip chips with passion fruit dressing",
        name_ar: "سلطة الشمندر",
        description_ar: "شمندر أرجواني، شمندر أصفر، جبنة ماسكاربوني، جبن ماعز, أفوكادو، فجل، كزبرة، فلفل أحمر، رقائق الجزر الأبيض مع  صلصة باشن فروت",
        price: "76",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Salad JPG/Carousel JPG/Josper-corn-salad-1072x801px.jpg",
        name_en: "JOSPER CORN SALAD",
        description_en: "Fresh sweet corn, grilled in the josper mixed with avocado, red grill pepper, mint, coriander, cherry tomato and parsley with sweet vinegar sauce",
        name_ar: "سلطة ذرة جوسبر",
        description_ar: "ذرة حلوة مشوية، أفوكادو، طماطم كرزية، فلفل أحمر، نعناع، بقدونس، كزبرة، مع صلصة الخل الحلو",
        price: "59",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Salad JPG/Carousel JPG/Chicken-caesar-1072x801px.jpg",
        name_en: "CHICKEN CAESAR SALAD",
        description_en: "Romaine lettuce, grilled chicken, garlic crouton, parmesan cheese with caesar dressing",
        name_ar: "سلطة سيزر بالدجاج",
        description_ar: "خس روماني، دجاج، كروتون الثوم، جبنة بارميزان وصوص سيزر",
        price: "53",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Salad JPG/Carousel JPG/Shrimp-caesar-1072x801px.jpg",
        name_en: "SHRIMP CAESAR SALAD",
        description_en: "Romaine lettuce, grilled shrimp, garlic crouton, parmesan cheese with caesar dressing",
        name_ar: "سلطة سيزر روبيان",
        description_ar: "خس روماني، روبيان، كروتون الثوم، جبنة بارميزان وصوص سيزر",
        price: "59",
        currency: "QR",

    },
]

export default salads;