import styled from "styled-components";

function PeinirliEn({}) {
    return (
        <>
            <CategoryTitleStyled>
                <span>Stuffed dough with cheese</span>
            </CategoryTitleStyled>
            <div style = {{marginTop: "60px"}}>
                <div>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- sakshuka.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>
                                Peinirli with sakshuka sauce <br/>
                                <ItemDescriptionParStyled>
                                    <TextItalicBoxStyled>feta cheese, cheddar & poached eggs</TextItalicBoxStyled>
                                </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>65 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- greek salad.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>
                                Greek salad Peinirli <br/>
                                <ItemDescriptionParStyled>
                                    <TextItalicBoxStyled>
                                        with feta feta cheese, olives, tomato & poached eggs
                                    </TextItalicBoxStyled>
                                </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>59 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- chicken beef.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>
                                Peinirli with chicken <br/>
                                <ItemDescriptionParStyled>
                                    <TextItalicBoxStyled>
                                        beef bacon, cheddar & poached eggs
                                    </TextItalicBoxStyled>
                                </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>65 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- fresh cheese.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>
                                Peinirli with fresh cheese <br/>
                                <ItemDescriptionParStyled>
                                    <TextItalicBoxStyled>
                                        turkey, gruyere, truffle oil & poached eggs
                                    </TextItalicBoxStyled>
                                </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>69 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- shrimps.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>
                                Saganaki Shrimp <br/>
                                <ItemDescriptionParStyled>
                                    <TextItalicBoxStyled>
                                    with chorizo, green & spicy red peppers, tomato sauce & feta cheese
                                    </TextItalicBoxStyled>
                                </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>78 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- lahmatzoun.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>
                                Lahmatzoun <br/>
                                <ItemDescriptionParStyled>
                                    <TextItalicBoxStyled>
                                    with semi-hard cheese, lamb & beef mince & cream cheese
                                    </TextItalicBoxStyled>
                                </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>65 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- vitello tonnato.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>
                                Vitello Tonnato <br/>
                                <ItemDescriptionParStyled>
                                    <TextItalicBoxStyled>
                                    with semi-hard cheese cheese, smoked tuna, onion, cured beef & fried capers
                                    </TextItalicBoxStyled>
                                </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>63 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- hunkar.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>
                                Hunkar Begendi <br/>
                                <ItemDescriptionParStyled>
                                    <TextItalicBoxStyled>
                                    with slow cooked short ribs, eggplant puree, smoked cheese from Metsovo &
                                    feta cheese
                                    </TextItalicBoxStyled>
                                </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>67 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- pistachio pesto.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>
                                Pistachio pesto <br/>
                                <ItemDescriptionParStyled>
                                    <TextItalicBoxStyled>
                                    feta, sun dried tomato & rocket
                                    </TextItalicBoxStyled>
                                </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>78 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- lahmatzon vegan.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>
                                Vegan Lahmatzoun <br/>
                                <ItemDescriptionParStyled>
                                    <TextItalicBoxStyled>
                                        with vegan cheese, beyond meat, tomato sauce vegan cream cheese &
                                        chili peppers
                                    </TextItalicBoxStyled>
                                </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>65 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- chicken souvlaki.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>
                                Chicken souvlaki <br/>
                                <ItemDescriptionParStyled>
                                    <TextItalicBoxStyled>
                                        tomato & tzatziki, cream cheese, parmesan & gruyere
                                    </TextItalicBoxStyled>
                                </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>69 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                </div>
                <div>
                </div>
            </div>
            
        </>
    )
}

function PeinirliAr({}) {
    return (
        <>
            <CategoryContainerStyledAr>
                <span>عجينة محشوة بالجبن</span>
            </CategoryContainerStyledAr>
            <div style = {{marginTop: "60px"}}>
                <div>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- sakshuka.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>
                            بينيرلي مع صلصة ساكشوكا <br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>وجبنة فيتا والشيدر والبيض المسلوق</TextItalicBoxStyled>
                            </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٦٥ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- greek salad.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>
                            بينيرلي السلطة اليونانية<br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>
                                مع زيتون جبنة فيتا
وطماطم وبيض مسلوق
                                </TextItalicBoxStyled>
                                </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٥٩ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- chicken beef.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>
                            بينيرلي مع الدجاج <br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>
                               
                                بقري مقدد، والشيدر والبيض المسلوق
                                </TextItalicBoxStyled>
                            </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٦٥ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- fresh cheese.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>
                            بينيرلي مع الجبن الطازج <br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>
                                والديك الرومي،
وجبنة الغرويير وزيت الكمأة والبيض المسلوق
                                </TextItalicBoxStyled>
                                </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٦٩ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- shrimps.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>
                                روبيان السغاناغي <br/>
                                <TextItalicBoxStyled>
                                مع الشوريزو،
فليفلة خضرة وحمرا، صلصة البندورة
وجبنة الفيتا
                                </TextItalicBoxStyled>
                            </p>
                            <PriceBoxStyled>٧٨ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- lahmatzoun.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>
                            لحمتزان <br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>
                                لحم بقري وخروف مفروم،
مع جبنة الغروير، وجبنة الكريمة
                                </TextItalicBoxStyled>
                                </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٦٥ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- vitello tonnato.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>
                            توناتو فيتيلو<br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>
                                مع جبنة شبه صلبة،
لحم تونة مدخن، بصل مع اللحم المقدد
والكبر المحمص
                                </TextItalicBoxStyled>
                                </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٦٣ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- hunkar.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>
                            هونكار بيجندي <br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>
                                
                                لحم الضلوع القصيرة المطبوخ ببطىء،
معجون الباذنجان، جبنة مدخنة
وجبنة الفيتا
                                </TextItalicBoxStyled>
                                </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٦٧ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- pistachio pesto.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>
                            بيستو الفستق <br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>
                                
                                جبنة الفيتا، طماطم
مجففة والجرجير
                                </TextItalicBoxStyled>
                                </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٧٨ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- lahmatzon vegan.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>
                            لحمتزان فيغان <br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>
                                مع جبنة
الفيغان، صلصة البندورة، جبنة
الكريمة والفلفل الأخضر
                                </TextItalicBoxStyled>
                                </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٦٥ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                            <img src = "/ergon-menu-desgins/Peinirli Pics/peinirli- chicken souvlaki.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>
                            دجاج السوفلاكي <br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>
                                طماطم
وتزاتزيكي، جبنة الكريمة،
بارميزان وجبنة الغروير
                                </TextItalicBoxStyled>
                                </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٦٩ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                </div>
                <div>
                </div>
            </div>
            
        </>
    )
}

const CategoryTitleStyled = styled.div`
   
    margin-bottom: 34px;
     text-align: center;
     margin-top: -30px;
    span {
        font-family: "Gotham Greek Book";
        font-style: italic;
        font-size: 14px;
    }
`

const CategoryContainerStyledAr = styled(CategoryTitleStyled)`
    font-size: 15px;
    font-family: "GE SS Text";
`

const SingleItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
`

const SingleItemContainerStyledAr = styled(SingleItemContainerStyled)`
    flex-direction: row-reverse;
`

const ImageContainerStyled = styled.div`
    position: relative;
    max-width: 100px;
    width: 100%;
    img {
        width: 100%;
      
    }
`

const ImageContainerStyledAr = styled(ImageContainerStyled)`
    transform: scaleX(-1);
`

const ItemDescriptionStyled = styled.div`
    font-family: "Gotham Greek Book";
    /* padding-right: 40px; */
    font-size: 14px;
    p {
        margin: 0px;
        line-height: 15px;
    }
`

const ItemDescriptionParStyled = styled.div`
    padding-right: 20px;
`

const ItemDescriptionParArStyled = styled.div`
    padding-left: 20px;
`

const ItemDescriptionArStyled = styled(ItemDescriptionStyled)`
    font-family: "GE SS Text";
    font-size: 15px;
    text-align: right;
`

const TextItalicBoxStyled = styled.span`
    font-weight: lighter;
    font-style: italic;
    font-size: 12px;
`
const PriceBoxStyled = styled.div`
    position: relative;
    margin-top: 4px;
    font-weight: bold;
    font-size: 12px;
`

const PriceBoxStyledAr = styled(PriceBoxStyled)`
    text-align: right;
`

export {
    PeinirliEn,
    PeinirliAr,
}