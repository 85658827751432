const beverages_en = [
    {
        name_en: "Watermelon & Lychee",
        description_en: "",
        price: "28",
        img_path: "/five-am-menu-designs/beverages/01-Beverages-Watermelon&lychee-72dpi.jpg"
    },

    {
        name_en: "Rose Cocktail",
        description_en: "",
        price: "31",
        img_path: "/five-am-menu-designs/beverages/02-Beverages-Rose cocktail-72dpi.jpg"
    },

    {
        name_en: "Peach Cocktail",
        description_en: "",
        price: "31",
        img_path: "/five-am-menu-designs/beverages/03-Beverages-Peach cocktail-72dpi.jpg"
    },

    {
        name_en: "Mix Berries Cocktail",
        description_en: "",
        price: "31",
        img_path: "/five-am-menu-designs/beverages/04-Beverages-Mix berries-72dpi.jpg"
    },

    {
        name_en: "Orange Juice",
        description_en: "",
        price: "25",
        img_path: "/five-am-menu-designs/beverages/05-Beverages-Orange-72dpi.jpg"
    },

    {
        name_en: "Lemon Mint",
        description_en: "",
        price: "25",
        img_path: "/five-am-menu-designs/beverages/06-Beverages-LEmon mint-72dpi.jpg"
    },

    {
        name_en: "Lemonade Blossom",
        description_en: "",
        price: "25",
        img_path: "/five-am-menu-designs/beverages/07-Beverages-Lemonade Blossom-72dpi.jpg"
    },

    {
        name_en: "Apple, Carrot, Orange",
        description_en: "",
        price: "28",
        img_path: "/five-am-menu-designs/beverages/08-Beverages-AppleCarrotOrange-72dpi.jpg"
    },

    {
        name_en: "Passsion Fruit Iced Tea",
        description_en: "",
        price: "25",
        img_path: "/five-am-menu-designs/beverages/09-Beverages-Passion iced tea-72dpi.jpg"
    },

    {
        name_en: "Peach Iced Tea",
        description_en: "",
        price: "25",
        img_path: "/five-am-menu-designs/beverages/10-Beverages-Peach iced tea-72dpi.jpg"
    },

    {
        name_en: "Martini Coffee Cream",
        description_en: "",
        price: "31",
        img_path: "/five-am-menu-designs/beverages/11-Beverages-Martini coffee cream-72dpi.jpg"
    },

    {
        name_en: "Watermelon",
        description_en: "",
        price: "29",
        img_path: "/five-am-menu-designs/beverages/12-Beverages-Watermelon-72dp.jpg"
    },

    {
        name_en: "Virgin Mojito",
        description_en: "",
        price: "34",
        img_path: "/five-am-menu-designs/beverages/13-Beverages-Virgin mojito-72dpi.jpg"
    },

    {
        name_en: "Passion Fruit Mojito",
        description_en: "",
        price: "34",
        img_path: "/five-am-menu-designs/beverages/14-Beverages-Passion fruit mojito-72dpi.jpg"
    },


    {
        name_en: "Strawberry Mojito",
        description_en: "",
        price: "34",
        img_path: "/five-am-menu-designs/beverages/15-Beverages-Strawberry mojito-72dpi.jpg"
    },

    {
        name_en: "Berry Mojito",
        description_en: "",
        price: "34",
        img_path: "/five-am-menu-designs/beverages/16-Beverages-Berry mojito-72dpi.jpg"
    },

    {
        name_en: "Fanta Orange",
        description_en: "",
        price: "15",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_en: "Coca Cola / Diet",
        description_en: "",
        price: "15",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_en: "Sparkling Water 0.33 ml",
        description_en: "",
        price: "12",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_en: "Fanta Strawberry",
        description_en: "",
        price: "15",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_en: "Still Water 0.33 ml",
        description_en: "",
        price: "12",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_en: "Sparkling Water 0.75 ml",
        description_en: "",
        price: "18",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_en: "Sprite / Diet",
        description_en: "",
        price: "15",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_en: "Still Water 0.75 ml",
        description_en: "",
        price: "18",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },
]

const beverages_ar = [
    {
        name_ar: "كوكتيل روز",
        description_ar: "",
        price: "31",
        img_path: "/five-am-menu-designs/beverages/02-Beverages-Rose cocktail-72dpi.jpg"

    },

    {
        name_ar: "البطيخ والليتشي",
        description_ar: "",
        price: "28",
        img_path: "/five-am-menu-designs/beverages/01-Beverages-Watermelon&lychee-72dpi.jpg"

    },

    {
        name_ar: "كوكتيل ميكس بيري",
        description_ar: "",
        price: "31",
        img_path: "/five-am-menu-designs/beverages/03-Beverages-Peach cocktail-72dpi.jpg"
    },

    {
        name_ar: "كوكتيل خوخ",
        description_ar: "",
        price: "31",
        img_path: "/five-am-menu-designs/beverages/04-Beverages-Mix berries-72dpi.jpg"
    },

    {
        name_ar: "ليمون نعناع",
        description_ar: "",
        price: "25",
        img_path: "/five-am-menu-designs/beverages/06-Beverages-LEmon mint-72dpi.jpg"
    },

    {
        name_ar: "عصير برتقال",
        description_ar: "",
        price: "25",
        img_path: "/five-am-menu-designs/beverages/05-Beverages-Orange-72dpi.jpg"
    },

    {
        name_ar: "التفاح، الجزر، البرتقال",
        description_ar: "",
        price: "28",
        img_path: "/five-am-menu-designs/beverages/08-Beverages-AppleCarrotOrange-72dpi.jpg"
    },

    {
        name_ar: "ليمون نعناع بماء الزهر",
        description_ar: "",
        price: "25",
        img_path: "/five-am-menu-designs/beverages/07-Beverages-Lemonade Blossom-72dpi.jpg"
    },

    {
        name_ar: "شاي مثلج بالخوخ",
        description_ar: "",
        price: "25",
        img_path: "/five-am-menu-designs/beverages/10-Beverages-Peach iced tea-72dpi.jpg"
    },

    {
        name_ar: "شاي مثلج بالباشن فروت",
        description_ar: "",
        price: "25",
        img_path: "/five-am-menu-designs/beverages/09-Beverages-Passion iced tea-72dpi.jpg"
    },

    {
        name_ar: "بطيخ",
        description_ar: "",
        price: "29",
        img_path: "/five-am-menu-designs/beverages/12-Beverages-Watermelon-72dp.jpg"
    },

    {
        name_ar: "مارتيني القهوة بالكريمة",
        description_ar: "",
        price: "31",
        img_path: "/five-am-menu-designs/beverages/11-Beverages-Martini coffee cream-72dpi.jpg"
    },

    {
        name_ar: "موهيتو باشن فروت",
        description_ar: "",
        price: "34",
        img_path: "/five-am-menu-designs/beverages/14-Beverages-Passion fruit mojito-72dpi.jpg"
    },

    {
        name_ar: "موهيتو",
        description_ar: "",
        price: "34",
        img_path: "/five-am-menu-designs/beverages/13-Beverages-Virgin mojito-72dpi.jpg"
    },

    {
        name_ar: "موهيتو بيري",
        description_ar: "",
        price: "34",
        img_path: "/five-am-menu-designs/beverages/16-Beverages-Berry mojito-72dpi.jpg"
    },

    {
        name_ar: "موهيتو فراولة",
        description_ar: "",
        price: "34",
        img_path: "/five-am-menu-designs/beverages/15-Beverages-Strawberry mojito-72dpi.jpg"
    },

    {
        name_ar: "كوكا كولا / دايت ",
        description_ar: "",
        price: "15",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_ar: "فانتا برتقال",
        description_ar: "",
        price: "15",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_ar: "فانتا فراولة",
        description_ar: "",
        price: "15",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_ar: "ماء غازية 0.33 مل",
        description_ar: "",
        price: "12",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_ar: "ماء غازية 0.75 مل",
        description_ar: "",
        price: "18",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_ar: "مياه معدنية 0.33 مل",
        description_ar: "",
        price: "12",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_ar: "ماء معدنية 0.75 مل",
        description_ar: "",
        price: "18",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },

    {
        name_ar: "سبرايت / دايت",
        description_ar: "",
        price: "15",
        img_path: "/five-am-menu-designs/beverages/01-bread&dips-Tannour Bread-72dpi.jpg"
    },
]

export {
    beverages_en,
    beverages_ar,
}