const sauces = [
    {
        name: "Boiled chilli sauce",
        name_ar: "صلصة الفلفل الحار المغلية",
        price: "10",
        image_path: "/habra-menu-designs/sauces/Habra Menu- Boiled Chilli Sauce.jpg",
    },

    {
        name: "Chimichurri",
        name_ar: "تشيميشوري",
        price: "10",
        image_path: "/habra-menu-designs/sauces/Habra Menu- Chimichurri.jpg",
    },

    {
        name: "Demi glace cream",
        name_ar: "كريم ديمي جلاس",
        price: "12",
        image_path: "/habra-menu-designs/sauces/Habra Menu- Demi Glass Cream.jpg",
    },

    {
        name: "Mushroom sauce",
        name_ar: "صلصة الفطر",
        price: "12",
        image_path: "/habra-menu-designs/sauces/Habra Menu- Mushroom Sauce.jpg",
    },

    {
        name: "Ponzu mayo",
        name_ar: "بونزو مايو",
        price: "10",
        image_path: "/habra-menu-designs/sauces/Habra Menu- Ponzo Mayo.jpg",
    },
]

export default sauces;