import styled from "styled-components";
import { FillerContainerStyled, CategoryHeaderStyled, ItemContainerStyled, ItemEnglishTitleStyled, ItemImageContainerStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, CategoryContainerStyled, ArabicContainerStyled, ItemArabicTitleStyled, ItemArabicDescriptionStyled } from "./CommonStyled";

function CartePastaAndRisotto({}) {
    return (
        <>
            <CategoryContainerStyled>
                {/* <CategoryHeaderStyled>
                    <span>Pasta and risotto</span>
                </CategoryHeaderStyled> */}
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/pasta & risotto/le bleu-pasta-penne pasta white sauce.png" alt = "penne pasta white sauce"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Penne Pasta White Sauce
                    <PriceContainerStyled>
                        53
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Penne pasta in creamy white sauce and oyster mushroom and parmesan cheese</p>
                        <p>Add: Chicken / 18 QR • Shrimps / 20 QR • Veg / 12 QR</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        بيني باستا بالصلصه البيضاء
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        بينى باستا, بصلصة الكريمة البيضاء، فطر مع جبنة بارميزان
                        <p>اضافة: الدجاج / ١٨ ر.ق • الروبيان / ٢٠ر.ق • خضروات / 12 ر.ق</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/pasta & risotto/le bleu-pasta-penne pasta pink sauce.png" alt = "penne pasta pink sauce dish"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Penne Pasta Pink Sauce
                    <PriceContainerStyled>
                        53
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Penne pasta in crispy pink sauce, parmesan cheese and mushroom</p>
                        <p>Add: Chicken / 18 QR • Shrimps / 20 QR • Veg / 12QR</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        بيني باستا بالصلصه الوردية
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        بينى باستا بالصلصه الوردية، فطر و بارميزان
                        <p>اضافة: الدجاج / ١٨ ر.ق • الروبيان / ٢٠ر.ق • خضروات / 12 ر.ق</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/pasta & risotto/le bleu-pasta-truffle penne.png" alt = "penne pasta truffle penne dish"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Truffle Penne
                    <PriceContainerStyled>
                        95
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Penne pasta with truffle cream sauce, top up with fresh white truffle</p>
                        <p>Add: Chicken / 18 QR • Shrimps / 20 QR • Veg / 12 QR</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        بيني الكمأة
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        مكرونة بيني مع صلصة كريمة الكمأة، مضاف إليها الكمأ الابيض الطازج
                        <p>اضافة: الدجاج / ١٨ ر.ق • الروبيان / ٢٠ر.ق • خضروات / 12 ر.ق</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/pasta & risotto/le bleu-pasta-duck risotto.png" alt = "penne pasta duck risotto"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Duck Risotto
                    <PriceContainerStyled>
                        155
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Risotto with fresh pan fried duck breast, spicy honey teriyaki sauce, with parmesan cheese</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        ريزوتو البط 
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        ريزوتو مع صدر البط المقلي الطازج، صوص ترياكي بالعسل الحار، مع جبنة البارميزان
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/pasta & risotto/le bleu-pasta-shrimp risotto.png" alt = "penne pasta shrimp risotto"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Shrimp Risotto
                    <PriceContainerStyled>
                        103
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Risotto with sauted shrimp, spicy honey teriyaki sauce with parmesan cheese</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        ريزوتو الروبيان
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        ريزوتو مع روبيان سوتيه، صوص ترياكي بالعسل الحار، مع جبن بارميزان
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/pasta & risotto/le bleu-pasta-chicken risotto.png" alt = "penne pasta chicken risotto"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Chicken Risotto
                    <PriceContainerStyled>
                        96
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Risotto with grilled chicken breast, spicy honey teriyaki sauce, parmesan cheese</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        ريزوتو الدجاج
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        ريزوتو مع صدور الدجاج المشوي، صوص ترياكي بالعسل الحار، مع جبنة البارميزان
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/pasta & risotto/le bleu-pasta-beef stroganoff pasta.png" alt = "penne pasta beef stronganoff pasta"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Beef Stroganoff Pasta
                    <PriceContainerStyled>
                        125
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Beef stroganoff, button mushroom, white onion, garlic, dijon mustard, pappardelle pasta</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        باستا بالحم ستروجانوف
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        بيف ستروجانوف، فطر زر، بصل أبيض، ثوم، خردل ديجون، باستا بابارديل
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/pasta & risotto/le bleu-pasta-lasagna.png" alt = "penne pasta beef lasagna"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Beef Lasagna
                    <PriceContainerStyled>
                        69
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Beef, bechamel, onion, cheese</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        لزانيا باللحم
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        لحم، صوص البيشاميل، بصل، جبن
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
            </CategoryContainerStyled>
        </>
    )
}

export default CartePastaAndRisotto;