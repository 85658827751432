import styled from "styled-components";
import { FillerContainerStyled, CategoryHeaderStyled, ItemContainerTeaStyled, ItemContainerStyled,  ItemEnglishTitleStyled, ItemEnglishTeaTitleStyled, ItemImageContainerStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, CategoryContainerStyled, ArabicContainerStyled, ItemArabicTitleStyled, ItemArabicDescriptionStyled, DrinksArabicContainerStyled, DrinksArabicTextStyled } from "./CommonStyled";
import {soft_drinks} from "../../../data/lbCategories/a-la-carte/menuItems";


function CarteDrinks({}) {
    return (
        <>
        <CategoryContainerStyled>
            {/* <CategoryHeaderStyled>
                <span>Soft Drinks</span>
            </CategoryHeaderStyled> */}
            {
                soft_drinks.map(({name_en, name_ar, price, currency}) => {
                    return (
                        <>
                            <ItemContainerTeaStyled>
                                <ItemEnglishTeaTitleStyled>
                                    {name_en}
                                    <PriceContainerStyled>
                                        {price}
                                        <span>{currency}</span>
                                    </PriceContainerStyled>
                                </ItemEnglishTeaTitleStyled>
                            </ItemContainerTeaStyled>
                            <DrinksArabicContainerStyled>
                                <DrinksArabicTextStyled>
                                    {name_ar}
                                </DrinksArabicTextStyled>
                            </DrinksArabicContainerStyled>
                            <FillerContainerStyled/>
                        </>
                    )
                   
                })
            }
            </CategoryContainerStyled>
        </>
    )
}




export default CarteDrinks;