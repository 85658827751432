import styled from "styled-components";

function ColdHotMezesEn() {
    return (
        <>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/ColdHotMezza Pics/coldHotMezza-AssortedSpreads.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Assorted spreads with pita bread
                    </p>
                    <PriceBoxStyled>69 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/ColdHotMezza Pics/coldHotMezza-pitaBread.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Basket bread
                    </p>
                    <PriceBoxStyled>25 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/ColdHotMezza Pics/coldHotMezza-sourdoughOlive.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Sourdough toasts with olive oil & sea salt
                    </p>
                    <PriceBoxStyled>25 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/ColdHotMezza Pics/coldHotMezza-sourdoughFeta.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Sourdough toasts <br/>
                        <ItemDescriptionParStyled>
                            <TextItalicBoxStyled>with fresh tomato, feta cheese, olive oil & sea salt</TextItalicBoxStyled>
                        </ItemDescriptionParStyled>
                    </p>
                    <PriceBoxStyled>41 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
            <SingleItemContainerStyled>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/ColdHotMezza Pics/coldHotMezza-Bougiourdi.png"/>
                </ImageContainerStyled>
                <ItemDescriptionStyled>
                    <p>
                        Bougiourdi <br/>
                        <ItemDescriptionParStyled>
                            <TextItalicBoxStyled>grilled feta, gruyere & paprika</TextItalicBoxStyled>
                        </ItemDescriptionParStyled>
                    </p>
                    <PriceBoxStyled>55 QR</PriceBoxStyled>
                </ItemDescriptionStyled>
            </SingleItemContainerStyled>
        </>
    )
}

function ColdHotMezesAr() {
    return (
        <>
            <SingleItemContainerStyledAr>
                <ImageContainerStyledAr>
                    <img src = "/ergon-menu-desgins/ColdHotMezza Pics/coldHotMezza-AssortedSpreads.png"/>
                </ImageContainerStyledAr>
                <ItemDescriptionArStyled>
                    <p>
                    تغميسات منوعة مع خبز البيتا
                    </p>
                    <PriceBoxStyled>٦٩ ر.ق</PriceBoxStyled>
                </ItemDescriptionArStyled>
            </SingleItemContainerStyledAr>
            <SingleItemContainerStyledAr>
                <ImageContainerStyledAr>
                    <img src = "/ergon-menu-desgins/ColdHotMezza Pics/coldHotMezza-pitaBread.png"/>
                </ImageContainerStyledAr>
                <ItemDescriptionArStyled>
                    <p>
                    سلة الخبز
                    </p>
                    <PriceBoxStyled>٢٥ ر.ق</PriceBoxStyled>
                </ItemDescriptionArStyled>
            </SingleItemContainerStyledAr>
            <SingleItemContainerStyledAr>
                <ImageContainerStyledAr>
                    <img src = "/ergon-menu-desgins/ColdHotMezza Pics/coldHotMezza-sourdoughOlive.png"/>
                </ImageContainerStyledAr>
                <ItemDescriptionArStyled>
                    <p>
                    توست الساوردو مع زيتون وملح بحر
                    </p>
                    <PriceBoxStyled>٢٥ ر.ق</PriceBoxStyled>
                </ItemDescriptionArStyled>
            </SingleItemContainerStyledAr>
            <SingleItemContainerStyledAr>
                <ImageContainerStyledAr>
                    <img src = "/ergon-menu-desgins/ColdHotMezza Pics/coldHotMezza-sourdoughFeta.png"/>
                </ImageContainerStyledAr>
                <ItemDescriptionArStyled>
                    <p>
                    توست الساوردو <br/>
                    <ItemDescriptionParArStyled>
                        <TextItalicBoxStyled>مع الطماطم الطازجة
وجبنة الفيتا وزيت الزيتون وملح البحر</TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                    </p>
                    <PriceBoxStyled>٤١ ر.ق</PriceBoxStyled>
                </ItemDescriptionArStyled>
            </SingleItemContainerStyledAr>
            <SingleItemContainerStyledAr>
                <ImageContainerStyledAr>
                    <img src = "/ergon-menu-desgins/ColdHotMezza Pics/coldHotMezza-Bougiourdi.png"/>
                </ImageContainerStyledAr>
                <ItemDescriptionArStyled>
                    <p>
                    بوجوردي <br/>
                    <ItemDescriptionParArStyled>
                        <TextItalicBoxStyled>جبنة فيتا مشوية
مع غرويير وبابريكا</TextItalicBoxStyled>
                    </ItemDescriptionParArStyled>
                    </p>
                    <PriceBoxStyled>٥٥ ر.ق</PriceBoxStyled>
                </ItemDescriptionArStyled>
            </SingleItemContainerStyledAr>
        </>
    )
}

const SingleItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
`

const SingleItemContainerStyledAr = styled(SingleItemContainerStyled)`
    flex-direction: row-reverse;
`

const ImageContainerStyled = styled.div`
    position: relative;
    max-width: 100px;
    width: 100%;
    img {
        width: 100%;
      
    }
`

const ImageContainerStyledAr = styled(ImageContainerStyled)`
    transform: scaleX(-1);
`

const ItemDescriptionStyled = styled.div`

    font-family: "Gotham Greek Book";
    font-size: 14px;
    p {
        margin: 0px;
        line-height: 15px;
    }
`

const ItemDescriptionArStyled = styled(ItemDescriptionStyled)`
    font-family: "GE SS Text";
    font-size: 15px;
    text-align: right;
`

const ItemDescriptionParStyled = styled.div`
    padding-right: 20px;
`

const ItemDescriptionParArStyled = styled.div`
    padding-left: 20px;
`

const TextItalicBoxStyled = styled.span`
    font-weight: lighter;
    font-style: italic;
    font-size: 12px;
`
const PriceBoxStyled = styled.div`
    position: relative;
    margin-top: 4px;
    font-weight: bold;
    font-size: 12px;
`

export {
    ColdHotMezesEn,
    ColdHotMezesAr,
};