import styled from "styled-components";
import { GridPageStyled, GridContainerStyled, GridTitleContainerStyled, GridImageContainerStyled, ButtonContainerStyled, ComingSoonTempStyled } from "./GridCommanStyles";
import {Grid} from "@mui/material";

function FiveAmGrid({setTriggerGrid, setIsClicked, isClicked}) {

    //close the grid and change the logo 
    function handleTriggerfn() {
        document.body.style.overflow = 'auto'
        setTriggerGrid(false);
        setIsClicked(false);
   }

    return (
        <>
          <GridPageStyled>
            <GridTitleContainerStyled>
              Five-AM Eatery
            </GridTitleContainerStyled>
            <GridContainerStyled>
              <Grid container spacing = {2} rowSpacing = {2}>
                <Grid item xs = {12} sm = {12}>
                  <GridImageContainerStyled>
                    <img src = "/lsd_pics/fiveam-grid/1-LSD-Website-fiveam.jpg"/>
                  </GridImageContainerStyled>
                </Grid>
                <Grid item xs = {12} sm = {6}>
                  <GridImageContainerStyled>
                    <img src = "/lsd_pics/fiveam-grid/2-LSD-Website-fiveam.jpg"/>
                  </GridImageContainerStyled>
                </Grid>
                <Grid item xs = {12} sm = {6}>
                  <GridImageContainerStyled>
                    <img src = "/lsd_pics/fiveam-grid/3-LSD-Website-fiveam.jpg"/>
                  </GridImageContainerStyled>
                </Grid>
                <Grid item xs = {12} sm = {8}>
                  <GridImageContainerStyled>
                    <img src = "/lsd_pics/fiveam-grid/4-LSD-Website-fiveam.jpg"/>
                  </GridImageContainerStyled>
                </Grid>
                <Grid item xs = {12} sm = {4}>
                  <GridImageContainerStyled>
                    <img src = "/lsd_pics/fiveam-grid/5-LSD-Website-fiveam.jpg"/>
                  </GridImageContainerStyled>
                </Grid>
                <Grid item xs = {12} sm = {12}>
                  <GridImageContainerStyled>
                    <img src = "/lsd_pics/fiveam-grid/6-LSD-Website-fiveam.jpg"/>
                  </GridImageContainerStyled>
                </Grid>
                <Grid item xs = {12} sm = {8}>
                  <GridImageContainerStyled>
                    <img src = "/lsd_pics/fiveam-grid/7-LSD-Website-fiveam.jpg"/>
                  </GridImageContainerStyled>
                </Grid>
                <Grid item xs = {12} sm = {4}>
                  <GridImageContainerStyled>
                    <img src = "/lsd_pics/fiveam-grid/8-LSD-Website-fiveam.jpg"/>
                  </GridImageContainerStyled>
                </Grid>
                <Grid item xs = {12} sm = {12}>
                  <GridImageContainerStyled>
                    <img src = "/lsd_pics/fiveam-grid/9-LSD-Website-fiveam.jpg"/>
                  </GridImageContainerStyled>
                </Grid>
                <Grid item xs = {12} sm = {12}>
                  <GridImageContainerStyled>
                    <img src = "/lsd_pics/fiveam-grid/10-LSD-Website-fiveam.jpg"/>
                  </GridImageContainerStyled>
                </Grid>
                <Grid item xs = {12} sm = {8}>
                  <GridImageContainerStyled>
                    <img src = "/lsd_pics/fiveam-grid/11-LSD-Website-fiveam.jpg"/>
                  </GridImageContainerStyled>
                </Grid>
                <Grid item xs = {12} sm = {4}>
                  <GridImageContainerStyled>
                    <img src = "/lsd_pics/fiveam-grid/12-LSD-Website-fiveam.jpg"/>
                  </GridImageContainerStyled>
                </Grid>
              </Grid>
            </GridContainerStyled>
          </GridPageStyled>
        </>
    )
}



export default FiveAmGrid;