import styled from "styled-components";
// import {Link} from "react-scroll";
import {NavLink} from "react-router-dom";
import {useState, useEffect, useRef} from "react";
import {Work, WorkBrandIdentity, WorkMotion, WorkWeb, WorkEnvironmental, WorkAdvertising, WorkPhotography} from "../components/"
// import $ from 'jquery';
import {motion} from "framer-motion";




//the bone of the website
function LsdAbout({}){

    const [isDesktop, setIsDesktop] = useState(true);
    const [triggerMobileNavBar, setTriggerMobileNavBar] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [triggerSubCategory, setTriggetSubCategory] = useState(false);


    function handleSubCategory(action) {
        setTriggetSubCategory(action);
    }

    //controls when to switch to mobile
    function updateScreenSize() {
        if(window.innerWidth <= 700) setIsDesktop(false);
        else setIsDesktop(true);
    }

    //to open mobile menu bar
    function handleClick() {
        if(!isDesktop) {
             setTriggerMobileNavBar(true)
            setIsClicked(prevIsClicked => !prevIsClicked);
            if(isClicked) {
                setTriggerMobileNavBar(false);
            }
     
        }
    }

    //to close menu mobile bar
    function closeMobileMneu() {
        setTriggerMobileNavBar(false);
        setIsClicked(prevIsClicked => !prevIsClicked);
        if(isClicked) {
            setTriggerMobileNavBar(false);
        }
    }

    useEffect(() => {
        if(window.innerWidth <= 700) setIsDesktop(false);
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    }, [triggerMobileNavBar])
    
        return(
            <>
                 <nav>
                        {!isDesktop && <MobileTestBar/>}
                        {isDesktop && <LsdNavBarStyled>
                            <ul style = {{display: "flex", gap: "30px", height: "100%", fontFamily: "Recoleta", fontSize: "35px", paddingTop: "0px",  marginTop: "30px"}}>
                                <li>
                                    <NavLink to = "/home" className = {({isActive}) => isActive? "lsd-active-link": ""}  onClick = {() => handleSubCategory(false)}> 
                                        Work
                                    </NavLink>
                                </li>
                               <li>
                                    <NavLink to = "/about" className = {({isActive}) => isActive? "lsd-active-link": ""}  onClick = {() => handleSubCategory(false)} >
                                        About
                                    </NavLink>
                               </li>
                                <li>
                                    <NavLink  to = "/contact-us" className = {({isActive}) => isActive? "lsd-active-link": ""}  onClick = {() => handleSubCategory(false)}>
                                        Contact
                                    </NavLink>
                                </li>
                                <NavBarSocialsContainerStyled>
                                    <div>
                                        <li style = {{marginLeft: "-16px", marginTop: "14px", fontSize: "20px"}} >
                                        <a href = "https://www.instagram.com/lovelysunnydaymena/" target = "_blank">
                                            Instagram
                                        </a>
                                        </li>   
                                    </div>
                                    {/* <div>
                                        <li>
                                        <a>
                                            FacebookFlogo
                                            
                                        </a>
                                        </li>   
                                    </div>
                                    <div>
                                        <li>
                                        <a>
                                            Linkedin
                                        </a>
                                        </li>
                                    </div> */}
                                </NavBarSocialsContainerStyled>
                            </ul>
                        </LsdNavBarStyled>}
                        {triggerSubCategory && <SubNavBarStyled>
                            <ul style = {{marginTop: "15px", padding: "0px 50px", display: "flex", gap: "20px", fontSize: "11px", fontFamily: "Recoleta Alt"}}>
                                <li>
                                    <a href = "#category_1.1" alt = "Lovely Sunny Day">
                                        SELECTED
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.2" alt = "Lovely Sunny Day Brand Identity projects">
                                        BRAND IDENTITY
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.3" alt = "Lovely Sunny Day Motion projects">
                                        MOTION
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.4" alt = "Lovely Sunny Day WEB projects">
                                        WEB
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.5" alt = "Lovely Sunny Day environmental projects">
                                        ENVIRONMENTAL
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.6" alt = "Lovely Sunny Day advertising projects">
                                        ADVERTISING
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.7" alt = "Lovely Sunny Day photography projects">
                                        PHOTOGRAPHY
                                    </a>
                                </li>
                            </ul>
                        </SubNavBarStyled>}
                    </nav>
                {/* <motion.div initial = {{height:0}} animate = {{height: "100%"}} exit = {{x: window.innerWidth, transition: {duration: 2}}}> */}
                <PageStyled>
                    {triggerMobileNavBar && <MobileNavBarStyled>
                        <MobileNavBarFlexBoxStyled>
                           <NavBarBlockStyled>
                                <li>
                                    <NavLink to = "/home" alt = "lovely sunny day studio work section" onClick = {closeMobileMneu}>
                                        <span>Work</span>
                                    </NavLink>
                                </li>
                           </NavBarBlockStyled>
                           <NavBarBlockStyled>
                                <li>
                                    <NavLink to = "/about" alt = "lovely sunny day studio about section" onClick = {closeMobileMneu}>
                                        <span>About</span>
                                    </NavLink>
                                </li>
                           </NavBarBlockStyled>
                           <NavBarBlockStyled>
                                <li>
                                    <NavLink to = "/contact-us" alt = "lovely sunny day studio contact section" onClick = {closeMobileMneu}>
                                        <span>Contact</span>
                                    </NavLink>
                                </li>
                           </NavBarBlockStyled>
                           <MobileSocialMediaContainerStyled>
                            <div>
                                <li>
                                    <a href = "" alt = "lovely sunny day studio social media instagram link">
                                        <span>Instagram</span>
                                    </a>
                                </li>
                            </div>
                            {/* <div>
                                <li>
                                    <a href = "" alt = "lovely sunny day studio social media facebook link">
                                        <span>Facebook</span>
                                    </a>
                                </li>
                            </div>
                            <div>
                                <li>
                                    <a href = "" alt = "lovely sunny day studio social media linkedin link">
                                        <span>Linkedin</span>
                                    </a>
                                </li>
                            </div> */}
                           </MobileSocialMediaContainerStyled>
                        </MobileNavBarFlexBoxStyled>
                    </MobileNavBarStyled>}
                    <LogoContainerStyled isClickable = {!isDesktop} onClick = {handleClick} isClicked = {isClicked}>
                        <ImageContainerFrontStyled>
                            <img src = "lsd_pics/LSD-burger-1.svg"/>
                        </ImageContainerFrontStyled>
                        <ImageContainerBackStyled>
                            <img src = "lsd_pics/LSD-burger-2.svg"/>
                        </ImageContainerBackStyled>
                    </LogoContainerStyled>
                    <AboutContainerStyled>
                        <FlexBoxOneStyled>
                            <p>Lovely Sunny Day is a multi-disciplinary design studio established in Qatar in the year 2020, focused on
                                brand transformation locally and throughout the MENA region.
                            </p>
                            <p>
                                Embracing the culture of simplicity, we always advocate for streamlined design. Beyond the pursiut of aesthetic beauty, our
                                team is dedicated to infusing each project with a distinctive essence, ensuring effective communication of the desired message to its intended audience.
                            </p>
                            <p>
                                Within our spectrum of services, we offer comprehensive solutions encompassing brand development and identity creation, strategic
                                brand positioning, packaging design, motion graphics, photography, immersive indoor and outdoor environmental installations, expertly crafted web development, and social
                                media management.
                            </p>
                        </FlexBoxOneStyled>
                        <FlexBoxTwoStyled>
                            <img src = "lsd_pics/chris_about_page_2.jpg"/>
                        </FlexBoxTwoStyled>
                    </AboutContainerStyled>
                </PageStyled>
                {/* </motion.div> */}
            </>
        )
}

const LsdNavBarStyled = styled.div`
width: 100%;
z-index: 1;
position: fixed;
inset: 0;
background-color: white;
height: 140px;
padding: 0px 10px;
`

const MobileTestBar = styled.div`
    position: fixed;
    width: 100%;
    height: 115px;
    background-color: white;
    z-index: 1;
`

const NavBarSocialsContainerStyled = styled.div`
    position: relative;
    display: flex;
    gap: 18px;
   
    margin-left: 20px;

    li {
        font-size: 16px;
    }
`

const MobileNavBarStyled = styled.div`
    position: absolute;
    inset: 0;
    width: 100%;
    background-color: #a8d9d6;
    z-index: 1;
`

const MobileNavBarFlexBoxStyled = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: -15px;
    justify-content: center;
    gap: 3px;
    align-items: center;
`

const NavBarBlockStyled = styled.div`
    position: relative;

    span {
        font-family: "Recoleta Alt";
        font-size: 50px;
    }
`

const MobileSocialMediaContainerStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 8px;
    text-align: center;

    span {
        font-family: "Recoleta Alt";
        font-size: 25px;
    }
`

const AboutContainerStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin-top: 115px;
    width: 100%;
    padding: 10px 0px;
    line-height: 26px;

    p {
        margin-bottom: 25px;
        margin-top: 0px;
        font-size: 20px;
    }

    @media(max-width: 700px) {
        flex-direction: column-reverse;
        justify-content: center;
        padding: 0px 0px;
        p {
            font-size: 16px;
        }
    }
`
const FlexBoxOneStyled = styled.div`
    position: relative;
    font-family: "Brandon Grotesque";
    width: 50%;

    @media (max-width: 700px) {
        width: 100%;
    }
`
const FlexBoxTwoStyled = styled.div`
    position: relative;
    width: 50%;
    img {
        width: 100%;
    }

    @media (max-width: 700px) {
        width: 100%;
    }

`

const PageStyled = styled.div`
    position: relative;
    width: 100%;
    padding: 10px 55px;

    @media(max-width: 700px) {
        padding: 10px 24px;
    }
`

const LogoContainerStyled = styled.div`
    position: fixed;
    right: 55px;
    top: 36px;
    width: 65px;
    height: 65px;
    z-index: 10;
    transform-style: preserve-3d;
    transition: transform .5s;

    ${({isClicked}) => 
        isClicked &&
        `
          transform: rotateY(180deg);
        `
    }

    @media(max-width: 700px) {
        right: 24px;
        top: 25px;
    }
`

const ImageContainerFrontStyled = styled.div`
    position: absolute;
   
    width: 100%;
    backface-visibility: hidden;

    img {
        width: 100%;
    }
`

const ImageContainerBackStyled = styled.div`
    position: absolute;
 
    backface-visibility: hidden;
    transform: rotateY(180deg);

    img {
        width: 100%;
    }
`
const SubNavBarStyled = styled.div`
    position: fixed;
    top: 40px;
    z-index: 1;

    a {
        cursor: pointer;
    }
`

export {
    LsdAbout,
    LogoContainerStyled,
    ImageContainerFrontStyled,
    ImageContainerBackStyled,
}