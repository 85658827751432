import styled from "styled-components";
import {useState} from "react";
import {Grid} from "@mui/material";
import {motion} from "framer-motion";
import { WhiteCoffeeSpotGrid, ChariotGrid, SekGrid, LeBleuGrid, KababElDeeraGrid, FiveAmGrid, FlatStoneGrid, MockingBirdGrid, ArrmGrid, ShamiyaGrid, DarLawFirmGrid, AssamGrid, IbisGrid } from "./lsdWorkGrids"
import { GridImageContainerStyled } from "./lsdWorkGrids/GridCommanStyles";


function Work({setIsFlipped, isFlipped, triggerGrid, setTriggerGrid}) {
    const [isHovered, setIsHovered] = useState(null);
    const [triggerNum, setTriggerNum] = useState(0);


    //controls which grid to render on the page
    function handleTriggerGrid(action) {
        // setTimeout(() => {
        //     setIsFlipped(true);
        // }, 100)

        setIsFlipped(true);

        document.body.style.overflow = 'hidden'
        setTriggerGrid(true)
         switch(action) {
            case 1:
                
                setTriggerNum(1);
            break;

            case 2:
                setTriggerNum(2);
            break;

            case 3:
                setTriggerNum(3);
            break;

            case 4:
                setTriggerNum(4);
            break;

            case 5:
                setTriggerNum(5);
            break;

            case 6:
                setTriggerNum(6);
            break;

            case 7:
                setTriggerNum(7);
            break;

            case 8:
                setTriggerNum(8);
            break;

            case 9:
                setTriggerNum(9);
            break;

            case 10:
                setTriggerNum(10);
            break;

            case 11:
                setTriggerNum(11);
            break;

            case 12:
                setTriggerNum(12);
            break;

            case 13:
                setTriggerNum(13);
            break;
         }
    }

        return (
            <FlexContainerStlyed>
                {triggerNum == 1 && triggerGrid == true && <WhiteCoffeeSpotGrid setTriggerGrid = {setTriggerGrid} setIsFlipped = {setIsFlipped} isFlipped = {isFlipped}/>}
                {triggerNum == 2 && triggerGrid == true && <FiveAmGrid setTriggerGrid = {setTriggerGrid} setIsFlipped = {setIsFlipped} isFlipped = {isFlipped}/>}
                {triggerNum == 3 &&  triggerGrid == true && <ChariotGrid setTriggerGrid = {setTriggerGrid} setIsFlipped = {setIsFlipped} isFlipped = {isFlipped}/>}
                {triggerNum == 4 && triggerGrid == true &&  <SekGrid setTriggerGrid = {setTriggerGrid} setIsFlipped = {setIsFlipped} isFlipped = {isFlipped}/>}
                {triggerNum == 5 && triggerGrid == true && <LeBleuGrid setTriggerGrid = {setTriggerGrid} setIsFlipped = {setIsFlipped} isFlipped = {isFlipped}/>}
                {triggerNum == 6 && triggerGrid == true && <AssamGrid setTriggerGrid = {setTriggerGrid} setIsFlipped = {setIsFlipped} isFlipped = {isFlipped}/>}
                {triggerNum == 7 && triggerGrid == true && <FlatStoneGrid setTriggerGrid = {setTriggerGrid} setIsFlipped = {setIsFlipped} isFlipped = {isFlipped}/>}
                {triggerNum == 8 && triggerGrid == true && <MockingBirdGrid setTriggerGrid = {setTriggerGrid} setIsFlipped = {setIsFlipped} isFlipped = {isFlipped}/>}
                {triggerNum == 9 && triggerGrid == true && <ArrmGrid setTriggerGrid = {setTriggerGrid} setIsFlipped = {setIsFlipped} isFlipped = {isFlipped}/>}
                {triggerNum == 10 && triggerGrid == true && <ShamiyaGrid setTriggerGrid = {setTriggerGrid} setIsFlipped = {setIsFlipped} isFlipped = {isFlipped}/>}
                {triggerNum == 11 && triggerGrid == true && <DarLawFirmGrid setTriggerGrid = {setTriggerGrid} setIsFlipped = {setIsFlipped} isFlipped = {isFlipped}/>}
                {triggerNum == 12 && triggerGrid == true && <KababElDeeraGrid setTriggerGrid = {setTriggerGrid} setIsFlipped = {setIsFlipped} isFlipped = {isFlipped}/>}
                {triggerNum == 13 && triggerGrid == true && <IbisGrid setTriggerGrid = {setTriggerGrid} setIsFlipped = {setIsFlipped} isFlipped = {isFlipped}/>}
                <Grid container spacing = {2} rowSpacing = {0}>
                    <Grid item xs = {12} sm = {6}
                          onClick = {() => handleTriggerGrid(1)}
                            >
                        <GridImageContainerStyled
                        >
                            {<CircleContainerStyled>
                                <CircleStyled className = "circle_test animate__animated animate__fadeIn">
                                    <TextInCircleStyled>
                                        <span>White Coffee Spot</span>
                                    </TextInCircleStyled>
                                </CircleStyled>
                            </CircleContainerStyled>}
                            <img src = "lsd_pics/work_pics/Lsd-Website-Home-Images-01.jpg" alt = "lovely sunny day white coffee spot"/>
                        </GridImageContainerStyled>
                    </Grid>
                    <Grid item xs = {12} sm = {6} 
                          onClick = {() => handleTriggerGrid(2)}
                          >
                        <GridImageContainerStyled
                        >
                            {<CircleContainerStyled>
                                <CircleStyled className = "circle_test animate__animated animate__fadeIn">
                                    <TextInCircleStyled>
                                        <span>Five-AM Eatery</span>
                                    </TextInCircleStyled>
                                </CircleStyled>
                            </CircleContainerStyled>}
                            <img src = "lsd_pics/work_pics/Lsd-Website-Home-Images-02.jpg" alt = "lovely sunny day white coffee spot"/>
                        </GridImageContainerStyled>
                    </Grid>
                    <Grid item xs = {12} sm = {6} 
                          onClick = {() => handleTriggerGrid(3)}
                          >
                        <GridImageContainerStyled>
                            {<CircleContainerStyled>
                                <CircleStyled className = "circle_test animate__animated animate__fadeIn">
                                    <TextInCircleStyled>
                                        <span>Chariot Diner</span>
                                    </TextInCircleStyled>
                                </CircleStyled>
                            </CircleContainerStyled>}
                            <img src = "lsd_pics/work_pics/Lsd-Website-Home-Images-03.jpg" alt = "lovely sunny day shawarma el khebbez"/>
                        </GridImageContainerStyled>
                    </Grid>
                    <Grid item xs = {12} sm = {6}  
                          onClick = {() => handleTriggerGrid(4)}
                          >
                        <GridImageContainerStyled>
                            {<CircleContainerStyled>
                                <CircleStyled className = "circle_test animate__animated animate__fadeIn">
                                    <TextInCircleStyled>
                                        <span>Shawarma El Khebbez</span>
                                    </TextInCircleStyled>
                                </CircleStyled>
                            </CircleContainerStyled>}
                            <img src = "lsd_pics/work_pics/Lsd-Website-Home-Images-04.jpg" alt = "lovely sunny day chariot"/>
                        </GridImageContainerStyled>
                    </Grid>
                    <Grid item xs = {12} sm = {6} 
                          onClick = {() => handleTriggerGrid(5)}
                          >
                        <GridImageContainerStyled>
                            {<CircleContainerStyled>
                                <CircleStyled className = "circle_test animate__animated animate__fadeIn">
                                    <TextInCircleStyled>
                                        <span>Le Bleu Restaurant</span>
                                    </TextInCircleStyled>
                                </CircleStyled>
                            </CircleContainerStyled>}
                            <img src = "lsd_pics/work_pics/Lsd-Website-Home-Images-05.jpg" alt = "lovely sunny day le bleu"/>
                        </GridImageContainerStyled>
                    </Grid>
                    <Grid item xs = {12} sm = {6} 
                          onClick = {() => handleTriggerGrid(6)}
                          >
                        <GridImageContainerStyled>
                            {<CircleContainerStyled>
                                <CircleStyled className = "circle_test animate__animated animate__fadeIn">
                                    <TextInCircleStyled>
                                        <span>Assam Fragrance</span>
                                    </TextInCircleStyled>
                                </CircleStyled>
                            </CircleContainerStyled>}
                            <img src = "lsd_pics/assam-grid/Lsd-Website-Assam-01.jpg" alt = "lovely sunny day le bleu"/>
                        </GridImageContainerStyled>
                    </Grid>
                    <Grid item xs = {12} sm = {6}  
                          onClick = {() => handleTriggerGrid(7)}
                          >
                        <GridImageContainerStyled>
                            {<CircleContainerStyled>
                                <CircleStyled className = "circle_test animate__animated animate__fadeIn">
                                    <TextInCircleStyled>
                                        <span>Flatstone Burger Joint</span>
                                    </TextInCircleStyled>
                                </CircleStyled>
                            </CircleContainerStyled>}
                            <img src = "lsd_pics/work_pics/Lsd-Website-Home-Images-06.jpg" alt = "lovely sunny day flatstone"/>
                        </GridImageContainerStyled>
                    </Grid>
                    <Grid item xs = {12} sm = {6} 
                          onClick = {() => handleTriggerGrid(8)}
                          >
                        <GridImageContainerStyled>
                            {  <CircleContainerStyled>
                                <CircleStyled className = "circle_test animate__animated animate__fadeIn">
                                    <TextInCircleStyled>
                                        <span>Mockingbird</span>
                                    </TextInCircleStyled>
                                </CircleStyled>
                            </CircleContainerStyled>}
                            <img src = "lsd_pics/work_pics/Lsd-Website-Home-Images-07.jpg" alt = "lovely sunny day mockingbird"/>
                        </GridImageContainerStyled>
                    </Grid>
                    <Grid item xs = {12} sm = {6}  
                          onClick = {() => handleTriggerGrid(9)}
                          >
                        <GridImageContainerStyled>
                            {<CircleContainerStyled>
                                <CircleStyled className = "circle_test animate__animated animate__fadeIn">
                                    <TextInCircleStyled>
                                        <span>Al Rayyan Restaurant Management</span>
                                    </TextInCircleStyled>
                                </CircleStyled>
                            </CircleContainerStyled>}
                            <img src = "lsd_pics/work_pics/Lsd-Website-Home-Images-08.jpg" alt = "lovely sunny day jd"/>
                        </GridImageContainerStyled>
                    </Grid>
                    <Grid item xs = {12} sm = {6}  
                          onClick = {() => handleTriggerGrid(10)}
                          >
                        <GridImageContainerStyled>
                            {<CircleContainerStyled>
                                <CircleStyled className = "circle_test animate__animated animate__fadeIn">
                                    <TextInCircleStyled>
                                        <span>Al Sihamiya</span>
                                    </TextInCircleStyled>
                                </CircleStyled>
                            </CircleContainerStyled>}
                            <img src = "lsd_pics/work_pics/Lsd-Website-Home-Images-09.jpg" alt = "lovely sunny day al rayyan restaurant management"/>
                        </GridImageContainerStyled>
                    </Grid>
                    <Grid item xs = {12} sm = {6}  
                          onClick = {() => handleTriggerGrid(11)}
                          >
                        <GridImageContainerStyled>
                            {  <CircleContainerStyled>
                                <CircleStyled className = "circle_test animate__animated animate__fadeIn">
                                    <TextInCircleStyled>
                                        <span>Dar Law Firm</span>
                                    </TextInCircleStyled>
                                </CircleStyled>
                            </CircleContainerStyled>}
                            <img src = "lsd_pics/work_pics/Lsd-Website-Home-Images-10.jpg" alt = "Dar Law Firm lovely sunny day"/>
                        </GridImageContainerStyled>
                    </Grid>
                    <Grid item xs = {12} sm = {6}  
                          onClick = {() => handleTriggerGrid(12)}
                          >
                        <GridImageContainerStyled>
                            {  <CircleContainerStyled>
                                <CircleStyled className = "circle_test animate__animated animate__fadeIn">
                                    <TextInCircleStyled>
                                        <span>Kabab Al Deera</span>
                                    </TextInCircleStyled>
                                </CircleStyled>
                            </CircleContainerStyled>}
                            <img src = "lsd_pics/work_pics/Lsd-Website-Home-Images-11.jpg" alt = "lovely sunny day kabab el deera"/>
                        </GridImageContainerStyled>
                    </Grid>
                    <Grid item xs = {12} sm = {6}  
                          onClick = {() => handleTriggerGrid(13)}
                          >
                        <GridImageContainerStyled>
                            {  <CircleContainerStyled>
                                <CircleStyled className = "circle_test animate__animated animate__fadeIn">
                                    <TextInCircleStyled>
                                        <span>Ibis & Adagio Hotel</span>
                                    </TextInCircleStyled>
                                </CircleStyled>
                            </CircleContainerStyled>}
                            <img src = "lsd_pics/ibis-grid/10-LSD-Website-Ibis.jpg" alt = "Ibis & Adagio Hotel"/>
                        </GridImageContainerStyled>
                    </Grid>
                </Grid>
            </FlexContainerStlyed>
        )

    // if(triggerGrid) {
    //     return (
    //         <>
    //            {triggerNum == 2 && <FiveAmGrid setTriggerGrid = {setTriggerGrid} setIsFlipped = {setIsFlipped} isFlipped = {isFlipped}/>}
    //            
    //            
    //
    //   
    //        
    //            
    //            
    //            
    //            
    //         </>
    //     )
    // }
}

const CircleContainerStyled = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  

    &:hover {
        .circle_test {
            display: block;
        }
    }
`

const CircleStyled = styled.div`
    position: absolute;
    background-color: yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 0;
    padding-bottom: 50%;
    border-radius: 50%;
    display: flex;
    display: none;
    cursor: pointer;
`

const TextInCircleStyled = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: black;

    span {
        font-family: "Recoleta Alt";
        font-size: 16px;
        letter-spacing: 0.4px;
    }

    @media(max-width: 700px) {
 
        span {
            font-size: 14px;
        }
    }
`

const FlexContainerStlyed = styled.div`
    position: relative;

   
    width: 100%;
    padding: 10px 55px;
   

    @media(max-width: 700px) {
 
        padding: 0px 24px;
    }
`



export default Work;