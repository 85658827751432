let breakfast = [

    {
        name: "COFFEE SRIRACHA EGGS ",
        description: " SOFT ROLL, EGGS, CHEDDAR CHEESE, COFFEE SRIRACHA SAUCE",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-01-coffee_sirarcha_eggs.jpg",
    },

    {
        name: "YOGONUT VEGAN ",
        description: " ORGANIC COCONUT YOGHURT, GRANOLA, FRESH COCONUT, HONEY",
        price_en: "35",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-02-yogonut_vegan.jpg",
    },

    {
        name: "EGG & DUKKAH ",
        description: "SOFT BRAIDED SESAME ROLL, LABNEH, DUKKAH, FRIED EGG, HARISSA OIL",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-03-egg_dukkah.jpg",
    },

    {
        name: "EGG & CHEESE ",
        description: "SOFT BRAIDED ROLL, ONION JAM, FRIED EGG, SPRING ONION,MELTED CHEESE, SPICED OIL",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-04-egg_cheese.jpg",
    },

    {
        name: "CHICKEN ROLL CHEESE ",
        description: "SOFT ROLL BREAD, CHICKEN BREAST MARINATED, CHEDDAR CHESSE, COFFEE SRIRACHA SAUCE, MIXED SALAD WITH ARUGOLA DRESSING",
        price_en: "36",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-05-chicken_roll_cheese.jpg",
    },

    {
        name: "COFFEE SRIRACHA EGGS CROISSANT ",
        description: "PLAIN CROISSANT, EGGS, CHEDDAR CHEESE, COFFEE SRIRACHA SAUCE",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-06-coffee_sriracha_egg_croissant.jpg",
    },

    {
        name: "CHICKEN ROLL CHEESE CROISSANT ",
        description: "PLAIN CROISSANT, CHICKEN BREAST MARINATED, CHEDDAR CHESSE, COFFEE SRIRACHA SAUCE, MIXED SALAD WITH ARUGOLA DRESSING",
        price_en: "38",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-07-chicken_roll_cheese_croissant.jpg",
    },

    {
        name: "EGG & DUKKAH CROISSANT ",
        description: "PLAIN CROISSANT, LABNEH, DUKKAH, FRIED EGG, HARISSA OIL",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-08-egg_dukkah_croissant.jpg",
    },

    {
        name: "AVOCADO TOAST ",
        description: "SOURDOUGH BREAD, AVOCADO, SUNFLOWER SEEDS",
        price_en: "31",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-09-avocado_toast.jpg",
    },

    {
        name: "EGG AVOCADO TOAST ",
        description: "SOURDOUGH BREAD, AVOCADO, SUNFLOWER SEEDS, EGG, DUKKAH, FETA CHEESE",
        price_en: "35",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-10-egg_avocado_toast.jpg",
    },

    {
        name: "PLAIN CROISSANT ",
        description: "",
        price_en: "15",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-11-plain_croissant.jpg",
    },

    {
        name: "CROQUE MADAME ",
        description: "TOASTED BRIOCHE, TURKEY BREAST, BECHAMEL, CHEDDAR CHEESE, FRIED EGG",
        price_en: "33",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-12-croque_madame.jpg",
    },

    {
        name: "CROQUE MONSIEUR ",
        description: "BAKED BRIOCHE, TURKEY BREAST, BECHAMEL, CHEDDAR CHEESE, WORCESTERSHIRE SAUCE",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-13-croque_monsieur.jpg",
    },

    {
        name: "GREEK YOGO ",
        description: "GREEK YOGHURT, GRANOLA, FRESH COCONUT, HONEY",
        price_en: "35",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-14-greek_yogo.jpg",
    },
]

let breakfastAr = [

    {
        name_ar: "يوغونت ",
        description_ar: "بن جوز الهند العضوي، غرانولا، جوز الهند الطازج، عسل",
        price_en: "35",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-02-yogonut_vegan.jpg",
    },

    {
        name_ar: "ساندويتش البيض وقهوة السريراتشا ",
        description_ar: "سوفت رول، بيض، جبنة شيدر، صلصة قهوة سريراتشا",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-01-coffee_sirarcha_eggs.jpg",
    },

    {
        name_ar: "بيض مع الجبن ",
        description_ar: "لفائف طرية، مربى البصل، بيض مقلي، بصل اخضر، جبنة ذائبة، زيت متبل",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-04-egg_cheese.jpg",
    },

    {
        name_ar: "بيض مع دقَّة ",
        description_ar: "لفائف السمسم الطرية، لبنة، دقَّ ة، بيض مقلي،  زيت الهريسة المتبل",
        price_en: "25",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-03-egg_dukkah.jpg",
    },

    {
        name_ar: "كرواسون البيض وقهوة السريراتشا ",
        description_ar: "كرواسون، بيض، جبنة شيدر، صلصة قهوة سريراتشا",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-06-coffee_sriracha_egg_croissant.jpg",
    },

    {
        name_ar: "ساندويتش رول الدجاج والجبن ",
        description_ar: "خبز ناعم، صدر دجاج متبل، جبنة شيدر، صلصة قهوة سر يراتشا، سلطة مشكلة مع صلصة جرجير",
        price_en: "36",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-05-chicken_roll_cheese.jpg",
    },

    {
        name_ar: " كرواسون البيض ودقه",
        description_ar: "كرواسون، لبنة، دقَّ ة، بيض مقلي،زيت الهريسة المتبل",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-08-egg_dukkah_croissant.jpg",
    },

    {
        name_ar: "كرواسون الدجاج والجبن ",
        description_ar: "كرواسون، صدر دجاج متبل، جبنة شيدر، صلصة قهوة سريراتشا",
        price_en: "38",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-07-chicken_roll_cheese_croissant.jpg",
    },

    {
        name_ar: "افوكادو توست مع بيض ",
        description_ar: "خبز العجين المخمر، افوكادو، بذور زهرة عباد الشمس،بيض، دوقَّ ة، جبنة فيتا",
        price_en: "35",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-10-egg_avocado_toast.jpg",
    },

    {
        name_ar: "افوكادو توست ",
        description_ar: "خبز العجين المخمر، افوكادو، بذور زهرة عباد الشمس",
        price_en: "31",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-09-avocado_toast.jpg",
    },

    {
        name_ar: "كروك مدام ",
        description_ar: "بريوش محمص، صدر الحبش، بشاميل، جبنة الشيدر، بيض مقلي",
        price_en: "33",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-12-croque_madame.jpg",
    },

    {
        name_ar: "كرواسون ",
        description_ar: "",
        price_en: "15",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-11-plain_croissant.jpg",
    },

    {
        name_ar: "يوغو يوناني ",
        description_ar: "لبن يوناني، غرانولا، جوز الهند الطازج، عسل",
        price_en: "35",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-14-greek_yogo.jpg",
    },

    {
        name_ar: "كروك موسيو ",
        description_ar: "برويوش مخبوز، صدر الحبش، بشاميل، جبنة الشيدر، روستيشر صوص",
        price_en: "27",
        img_path: "/wcsNew-menu.designs/breakfast/Breakfast-13-croque_monsieur.jpg",
    },


]

export {
    breakfast,
    breakfastAr
} 