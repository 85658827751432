const pm_en = [
    {
        name_en: "Chicken Waldorf Salad ",
        description_en: " Apple, grapes, pecan nuts, chicken, mayo & lemon",
        price: "36",
        img_path: "/five-am-menu-designs/pm/01-PM-Chicken Waldorf Salad-72dpi.jpg"
    },

    {
        name_en: "Burrata Vinaigrette Balsamique ",
        description_en: " & Grilled Grapes",
        price: "41",
        img_path: "/five-am-menu-designs/pm/02-PM-Burrata Balsamique-72dpi.jpg"
    },

    {
        name_en: "5 Spiced Peach ",
        description_en: " with peach, feta, lettuce & sweet dressing",
        price: "29",
        img_path: "/five-am-menu-designs/pm/03-PM-5Spices Peach-72dpi.jpg"
    },

    {
        name_en: "Chicken Pastilla Roll ",
        description_en: " with Chili Sauce",
        price: "23",
        img_path: "/five-am-menu-designs/pm/04-PM-Chicken Pastilla Roll-72dpi.jpg"
    },

    {
        name_en: "Pistachio Kafta Balls ",
        description_en: " with Sumac & Yogurt",
        price: "25",
        img_path: "/five-am-menu-designs/pm/05-PM-Pistachio Kafta Balls-72dpi.jpg"
    },

    {
        name_en: "Chicken Tangdi Skewer ",
        description_en: " Tahini, Pickled Onion, Chili Garlic Sauce",
        price: "23",
        img_path: "/five-am-menu-designs/pm/06-PM-Chicken Tangdi Skewer-72dpi.jpg"
    },

    {
        name_en: "Baby Chicken Marbella ",
        description_en: " Date Majdoul, Green Olives, Preserved Lemon",
        price: "29",
        img_path: "/five-am-menu-designs/pm/07-PM-Baby Chicken Marbella-72dpi.jpg"
    },

    {
        name_en: "Grilled Lamb Tenderloin ",
        description_en: " with chili garlic shatta, pickled onion, mushroom sauce, mashed potato",
        price: "68",
        img_path: "/five-am-menu-designs/pm/08-PM-Grilled Lamb Tenderloin-72dpi.jpg"
    },

    {
        name_en: "Penne Pink Sauce ",
        description_en: " with tomato sauce, whipped cream & parmesan cheese",
        price: "52",
        img_path: "/five-am-menu-designs/pm/09-PM-Penne Pink Sauce-72dpi.jpg"
    },

    {
        name_en: "Five AM Burger ",
        description_en: " Charcoal grilled beef patty, cheddar cheese, mac sauce",
        price: "58",
        img_path: "/five-am-menu-designs/pm/10-PM-FIVEAM Burger-72dpi.jpg"
    },

    {
        name_en: "Slow Cooked Short Ribs ",
        description_en: " with Cumin, Greek Yogurt, Chili Garlic Shatta",
        price: "58",
        img_path: "/five-am-menu-designs/pm/11-PM-Slow Cooked Short Ribs-72dpi.jpg"
    },

    {
        name_en: "Jospered Fresh Lamb Chops ",
        description_en: " Jalapeno Sauce, Pickled Kohlrabi, Eggplant Pure",
        price: "46",
        img_path: "/five-am-menu-designs/pm/12-PM-Josper Lamb-72dpi.jpg"
    },

    {
        name_en: "Fresh Hamour Tandoori ",
        description_en: " Tandoori Cream, Cumin Rice, Chili Garlic Sauce",
        price: "59",
        img_path: "/five-am-menu-designs/pm/13-PM-Fresh Hamour-72dpi.jpg"
    },

    {
        name_en: "Grilled Tandoori Prawn Skewer ",
        description_en: " Grilled lime, Aleppo Pepper, Tandoori cream",
        price: "33",
        img_path: "/five-am-menu-designs/pm/14-PM-Grilled Tandoori Prawn-72dpi.jpg"
    },

    {
        name_en: "Charcoal Grilled Asparagus ",
        description_en: "with Romesco Sauce",
        price: "21",
        img_path: "/five-am-menu-designs/pm/15-PM-Charcoal Asparagus-72dpi.jpg"
    },

    {
        name_en: "Cumin Rice ",
        description_en: "",
        price: "13",
        img_path: "/five-am-menu-designs/pm/16-PM-Cumin Rice-72dpi.jpg"
    },

    {
        name_en: "French Fries ",
        description_en: "",
        price: "13",
        img_path: "/five-am-menu-designs/pm/17-PM-French Fries-72dpi.jpg"
    },

    {
        name_en: "Broccoli",
        description_en: "",
        price: "13",
        img_path: "/five-am-menu-designs/pm/18-PM-Broccoli-72dpi.jpg"
    },

    {
        name_en: "Asparagus",
        description_en: "",
        price: "13",
        img_path: "/five-am-menu-designs/pm/19-PM-Asparagus-72dpi.jpg"
    },

]

const pm_ar = [
    {
        name_ar: "بوراتا بخل البلسميك ",
        description_ar: "بوراتا بخل البلسميك ",
        price: "41",
        img_path: "/five-am-menu-designs/pm/02-PM-Burrata Balsamique-72dpi.jpg"
    },

    {
        name_ar: "سلطة ولدورف بالدجاج ",
        description_ar: "تفاح، عنب، مكسرات البقان، دجاج، ميونيز، حامض ",
        price: "36",
        img_path: "/five-am-menu-designs/pm/01-PM-Chicken Waldorf Salad-72dpi.jpg"
    },

    {
        name_ar: "رول دجاج الباستيلا ",
        description_ar: "مع مربى الحار ",
        price: "23",
        img_path: "/five-am-menu-designs/pm/04-PM-Chicken Pastilla Roll-72dpi.jpg"
    },

    {
        name_ar: "سلطة الخمس بهارات مع الخوخ ",
        description_ar: "الخوخ، فيتا، الخس وصلصلة الحلوة ",
        price: "29",
        img_path: "/five-am-menu-designs/pm/03-PM-5Spices Peach-72dpi.jpg"
    },

    {
        name_ar: "سيخ دجاج تانجدي ",
        description_ar: "الطحينة، البصل المخلل، صلصة الثوم والفلفل الحار ",
        price: "23",
        img_path: "/five-am-menu-designs/pm/06-PM-Chicken Tangdi Skewer-72dpi.jpg"
    },

    {
        name_ar: "كفتة الفستق الحلبي ",
        description_ar: "مع الروب والسماق ",
        price: "25",
        img_path: "/five-am-menu-designs/pm/05-PM-Pistachio Kafta Balls-72dpi.jpg"
    },

    {
        name_ar: "تندرلوين مشوي عالفحم بالجوسبر ",
        description_ar: "شطة البصل المخلل، صلصة فطر و فلفل بطاطا مهروسة ",
        price: "68",
        img_path: "/five-am-menu-designs/pm/08-PM-Grilled Lamb Tenderloin-72dpi.jpg"
    },

    {
        name_ar: "دجاج ماربيلا ",
        description_ar: "بتمر المجدول، زيتون أخضر، ليمون المخلل ",
        price: "29",
        img_path: "/five-am-menu-designs/pm/07-PM-Baby Chicken Marbella-72dpi.jpg"
    },

    {
        name_ar: "فايف اي ام برغر ",
        description_ar: "شريحة لحم مشوية عالفحم، جبنة شيدر، ماك صوص ",
        price: "58",
        img_path: "/five-am-menu-designs/pm/10-PM-FIVEAM Burger-72dpi.jpg"
    },

    {
        name_ar: "بيني مع الصلصة الزهرية ",
        description_ar: "طماطم، كريمة طبخ، جبنة البارميزان ",
        price: "52",
        img_path: "/five-am-menu-designs/pm/09-PM-Penne Pink Sauce-72dpi.jpg"
    },

    {
        name_ar: "لحم الضأن المشوي بالجوسبير ",
        description_ar: "مع صلصة الجالابينو، اللفت المخلل، بيوري الباذنجان ",
        price: "46",
        img_path: "/five-am-menu-designs/pm/12-PM-Josper Lamb-72dpi.jpg"
    },

    {
        name_ar: "الأضلاع القصيرة المطهوة ببطء ",
        description_ar: "مع الكمون، زبادي يوناني، شطة الثوم والفلفل الحار ",
        price: "58",
        img_path: "/five-am-menu-designs/pm/11-PM-Slow Cooked Short Ribs-72dpi.jpg"
    },

    {
        name_ar: "سيخ روبيان تندوري مشوي ",
        description_ar: "مع ليمون، فلفل حلبي، كريمة التندوري ",
        price: "33",
        img_path: "/five-am-menu-designs/pm/14-PM-Grilled Tandoori Prawn-72dpi.jpg"
    },

    {
        name_ar: "تندوري هامور طازج ",
        description_ar: "،مع كريمة التندوري، أرز الكمون صلصة الثوم والفلفل الحار ",
        price: "59",
        img_path: "/five-am-menu-designs/pm/13-PM-Fresh Hamour-72dpi.jpg"
    },

    {
        name_ar: "رز الكمون ",
        description_ar: "",
        price: "13",
        img_path: "/five-am-menu-designs/pm/16-PM-Cumin Rice-72dpi.jpg"
    },

    {
        name_ar: "الهليون المشوي عالفحم ",
        description_ar: "مع صلصة الرومسكو ",
        price: "21",
        img_path: "/five-am-menu-designs/pm/15-PM-Charcoal Asparagus-72dpi.jpg"
    },

    {
        name_ar: "بروكلي ",
        description_ar: "",
        price: "13",
        img_path: "/five-am-menu-designs/pm/18-PM-Broccoli-72dpi.jpg"
    },

    {
        name_ar: "بطاطس المقلية ",
        description_ar: "",
        price: "13",
        img_path: "/five-am-menu-designs/pm/17-PM-French Fries-72dpi.jpg"
    },

    {
        name_ar: "الهليون",
        description_ar: "",
        price: "13",
        img_path: "/five-am-menu-designs/pm/19-PM-Asparagus-72dpi.jpg"
    },
]

export {
    pm_en,
    pm_ar,
}