import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({requiredRole}) => {
   
    const { auth } = useAuth(); //we get the value of the global auth
    console.log(auth);
    const location = useLocation();
    const hasRequiredRole = auth?.role === requiredRole;

    return (
        hasRequiredRole 
            ? <Outlet/>
            : <Navigate to = "/restaurants/menus/login" state = {{from: location}} replace />
    )
}

//this outlet component is the father of all the child route components that we are protecting
//Navigate will render the user to the log in page if the user is not authenticated 
//state attribute in the Navigate component will replace the login page in the to attribute with the location of
//where the user was at

export default RequireAuth;

