import styled from "styled-components";

function PitaMeltsEn({}) {
    return (
        <>
            <div>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Pita Pics/pita- chicken.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Chicken pita bread <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>
                                    chicken, cheddar & feta cheese & sundried tomato
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>40 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Pita Pics/pita- halloumi.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>
                            Halloumi pita bread <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>
                                    halloumi, tomato & basil pesto
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>32 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
            </div>
        </>
    )
}

function PitaMeltsAr({}) {
    return (
        <>
            <div>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Pita Pics/pita- chicken.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        خبز البيتا مع دجاج <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>
                            جبنة شيدر وفيتا،
طماطم مجففة
                            </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٤٠ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Pita Pics/pita- halloumi.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>
                        خبز البيتا مع جبنة حلومي <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>
                            طماطم مع بيستو الريحان
                            </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٣٢ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
            </div>
        </>
    )
}

const SingleItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
    /* img {
        width: 60%;
    } */
`

const SingleItemContainerStyledAr = styled(SingleItemContainerStyled)`
    flex-direction: row-reverse;
`

const ImageContainerStyled = styled.div`
    position: relative;
    max-width: 100px;
    width: 100%;
    img {
        width: 100%;
      
    }
`

const ImageContainerStyledAr = styled(ImageContainerStyled)`
    transform: scaleX(-1);
`

const ItemDescriptionStyled = styled.div`



    font-family: "Gotham Greek Book";
    font-size: 14px;
    p {
        margin: 0px;
        line-height: 15px;
    }
`

const ItemDescriptionArStyled = styled(ItemDescriptionStyled)`
    font-family: "GE SS Text";
    font-size: 15px;
    text-align: right;
`

const ItemDescriptionParStyled = styled.div`
    padding-right: 20px;
`

const ItemDescriptionParArStyled = styled.div`
    padding-left: 20px;
`

const TextItalicBoxStyled = styled.span`
    font-weight: lighter;
    font-style: italic;
    font-size: 12px;
`
const PriceBoxStyled = styled.div`
    position: relative;
    margin-top: 4px;
    font-weight: bold;
    font-size: 12px;
   font-weight: bold;
`
export {
    PitaMeltsEn,
    PitaMeltsAr,
    
};