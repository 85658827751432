import { Grid } from "@mui/material"
import { useState } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Test, ItemContainerStyled, ItemImageContainerStyled, EnglishArabicFlexStyled, ItemDescriptionContainerStyled, ItemEnglishTitleStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, ArabicContainerStyled, ItemArabicDescriptionStyled, ItemArabicTitleStyled} from "./generalStyles"
import pasta from "../../../data/ltbCategories/pasta";


function PastaARisottoGrid() {
    
    const [showDish, setShowDish] = useState(-1);
    function handleClick(id) {
        setShowDish(id);
    }

    if(showDish < 0) {
        return (
            <Grid container spacing = {1} rowSpacing = {0} paddingTop = {3} paddingBottom = {3} width = {"95%"}>
                <Grid item xs = {6}>
                    <div  onClick = {() => handleClick("0")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Pasta & Rissotto JPG/Grid JPG/Fettuchini truffle Pasta&risotto.jpg"/>
                    <div className="dish-title">Fettuccine Truffle</div>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div onClick = {() => handleClick("1")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Pasta & Rissotto JPG/Grid JPG/Lasagna Pasta&risotto.jpg"/>
                    <div className="dish-title">Lasagna</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "3" onClick = {() => handleClick("2")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Pasta & Rissotto JPG/Grid JPG/Safron shrimp Pasta&risotto.jpg"/>
                    <div className="dish-title">Saffron Risotto Shrimp</div>
                    </div>
                </Grid>
            </Grid>
        )
    }

    if(showDish >= 0) {
        return (
            <Test>
            <div className = "dish-heading">
                Pasta & Risotto
            </div>
            <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = {pasta[showDish].img_src}/>
                    </ItemImageContainerStyled>
                    <EnglishArabicFlexStyled>
                        <ItemDescriptionContainerStyled>
                            <ItemEnglishTitleStyled>
                                {pasta[showDish].name_en}
                                <PriceContainerStyled>
                                    {pasta[showDish].price} <span>QR</span>
                                </PriceContainerStyled>
                            </ItemEnglishTitleStyled>
                            <ItemEnglishDescriptionStyled>
                                {pasta[showDish].description_en}
                            </ItemEnglishDescriptionStyled>
                            <ArabicContainerStyled>
                                <ItemArabicTitleStyled>
                                    {pasta[showDish].name_ar}
                                </ItemArabicTitleStyled>
                                <ItemArabicDescriptionStyled>
                                    {pasta[showDish].description_ar}
                                </ItemArabicDescriptionStyled>
                            </ArabicContainerStyled>
                        </ItemDescriptionContainerStyled>
                    </EnglishArabicFlexStyled>
                </ItemContainerStyled>
                <div className = "dish-close-icon" onClick ={()=>setShowDish(-1)}>
                    <FontAwesomeIcon icon = {faXmark}/>
                </div>
        </Test>
        )
    }
}

export default PastaARisottoGrid;