import styled from "styled-components";
import { FillerContainerStyled, CategoryHeaderStyled, ItemContainerStyled, ItemEnglishTitleStyled, ItemImageContainerStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, CategoryContainerStyled, ArabicContainerStyled, ItemArabicTitleStyled, ItemArabicDescriptionStyled } from "./CommonStyled";
import {hotStarters} from "../../../data/lbCategories/a-la-carte/menuItems";

function CarteHotStarter({}) {
    return(
        <>
            <CategoryContainerStyled>
                {/* <CategoryHeaderStyled>
                    <span>Hot Starter</span>
                </CategoryHeaderStyled> */}
                    {
                        hotStarters.map(({item_img_src, name_en, description_en, name_ar, description_ar, price, currency}) => {
                            return (
                                <ItemContainerStyled>
                                    <ItemImageContainerStyled>
                                        <img src = {item_img_src} alt = {name_en}/>
                                    </ItemImageContainerStyled>
                                    <ItemEnglishTitleStyled>
                                        {name_en}
                                        <PriceContainerStyled>
                                            {price}
                                            <span>{currency}</span>
                                        </PriceContainerStyled>
                                    </ItemEnglishTitleStyled>
                                    <ItemEnglishDescriptionStyled>
                                        <p>
                                            {description_en}
                                        </p>
                                    </ItemEnglishDescriptionStyled>
                                    <ArabicContainerStyled>
                                        <ItemArabicTitleStyled>
                                            {name_ar}
                                        </ItemArabicTitleStyled>
                                        <ItemArabicDescriptionStyled>
                                            <p>
                                                {description_ar}
                                            </p>
                                        </ItemArabicDescriptionStyled>
                                    </ArabicContainerStyled>
                                    <FillerContainerStyled/>
                                </ItemContainerStyled>
                            )
                        })
                    }

            </CategoryContainerStyled>
        </>
    )
}

export default CarteHotStarter;