const hotStarters = [
    {
        img_src: "/blt-menu-designs/Hot Starters JPG/Carousel JPG/Snails-1072x801px.jpg",
        name_en: "BURGUNDY SNAIL",
        description_en: "Snail butter, dill tarragon, parsley, espellette powder, parmesan cheese",
        name_ar: "حلزون بورجينيون",
        description_ar:"حلزون, زبدة, شبت, نبات الطرخون, بقدونس, مسحوق فلفل حار, جبنة بارميزان",
        price: "125",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Hot Starters JPG/Carousel JPG/Calamari-1072x801px.jpg",
        name_en: "FRIED CALAMARI",
        description_en: "Japanese crumb coated calamari, oregano, mustard and fresh dill mayo sauce",
        name_ar: "كالاماري مقلي",
        description_ar: "كالاماري مغطاة بفتات الخبز الياباني، أوريجانو، شبت وصوص المايونيز",
        price: "61",
        currency: "QR",
    },

    {
        img_src: "/blt-menu-designs/Hot Starters JPG/Carousel JPG/Shrimp-Fried-1072x801px.jpg",
        name_en: "FRIED SHRIMP",
        description_en: "Fried jumbo prawn coated with corn cereal, radish and citrus salad, paprika and lemon relish, lime cheek and orange segment",
        name_ar: "روبيان مقلي",
        description_ar:"روبيان جامبو مقلي مغطى بحبوب الذرة، الفجل وسلطة الحمضيات والفلفل الحلو ومذاق الليمون والخل الحامض وشريحة البرتقال",
        price: "89",
        currency: "QR",

    },
]

export default hotStarters;