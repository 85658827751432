const sugar_en = [
    {
        name_en: "Basbusa with Carmelized Popcorn",
        description_en: "",
        price: "33",
        img_path: "/five-am-menu-designs/Sugar Rush/01-Sugar Rush-Basbusa-72dpi.jpg"
    },

    {
        name_en: "Sesame Tahini Cigarillo",
        description_en: "",
        price: "33",
        img_path: "/five-am-menu-designs/Sugar Rush/02-Sugar Rush-Seasame tahini cigarillo-72dpi.jpg"
    },

    {
        name_en: "Date Pudding with Tahini Ice Cream",
        description_en: "",
        price: "36",
        img_path: "/five-am-menu-designs/Sugar Rush/03-Sugar Rush-Date pudding-72dpi.jpg"
    },

    {
        name_en: "Rosey Cream Pancake",
        description_en: "Avocado & Spiced Cherry Tomato",
        price: "31",
        img_path: "/five-am-menu-designs/Sugar Rush/04-Sugar Rush-Rosey cream pancake-72dpi.jpg"
    },

    {
        name_en: "Chocolate Molasses Pancake",
        description_en: "White Choco, Dark choco, Kharob Molasses, Halawa",
        price: "31",
        img_path: "/five-am-menu-designs/Sugar Rush/05-Sugar Rush-Chocolate molasses pancake-72dpi.jpg"
    },

    {
        name_en: "Fondant Melt in Danish",
        description_en: "",
        price: "31",
        img_path: "/five-am-menu-designs/Sugar Rush/06-Sugar Rush-Fondant melt-72dpi.jpg"
    },
]

const sugar_ar = [
    {
        name_ar: "سيجار السمسم بالطحينة الحلوة",
        description_ar: "",
        price: "33",
        img_path: "/five-am-menu-designs/Sugar Rush/02-Sugar Rush-Seasame tahini cigarillo-72dpi.jpg"

    },

    {
        name_ar: "بسبوسة مع فشار مكرمل وايس كريم",
        description_ar: "",
        price: "33",
        img_path: "/five-am-menu-designs/Sugar Rush/01-Sugar Rush-Basbusa-72dpi.jpg"
    },

    {
        name_ar: "بانكيك الكريمة بالورد",
        description_ar: "مربى الورد، أشتا، رمان جاف ",
        price: "31",
        img_path: "/five-am-menu-designs/Sugar Rush/04-Sugar Rush-Rosey cream pancake-72dpi.jpg"

    },

    {
        name_ar: "بودينغ التمر مع طحينية ايس كريم",
        description_ar: "",
        price: "36",
        img_path: "/five-am-menu-designs/Sugar Rush/03-Sugar Rush-Date pudding-72dpi.jpg"
    },

    {
        name_ar: "الفوندت الذائب بالدانش",
        description_ar: "",
        price: "",
        img_path: "/five-am-menu-designs/Sugar Rush/06-Sugar Rush-Fondant melt-72dpi.jpg"
    },

    {
        name_ar: " بانكيك الشوكولاتة بالدبس",
        description_ar: "شوكو أبيض، شوكو دارك، دبس خروب، حلاو",
        price: "",
        img_path: "/five-am-menu-designs/Sugar Rush/05-Sugar Rush-Chocolate molasses pancake-72dpi.jpg"

    },
]

export  {
    sugar_en,
    sugar_ar,
}