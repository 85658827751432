import styled from "styled-components";
import { GridPageStyled, GridContainerStyled, GridTitleContainerStyled, GridImageContainerStyled } from "./GridCommanStyles";
import {Grid} from "@mui/material";
import {LogoContainerStyled, ImageContainerFrontStyled, ImageContainerBackStyled} from "../../pages/Lsd";


function LeBleuGrid({setTriggerGrid, setIsClicked, isClicked}) {

    //close the grid and change the logo 
    function handleTriggerfn() {
        document.body.style.overflow = 'auto'
        setTriggerGrid(false);
        setIsClicked(false);
   }

    return (
        <>

                <GridPageStyled>
                    <GridTitleContainerStyled>
                            Le Bleu Restaurant
                    </GridTitleContainerStyled>
                    <GridContainerStyled>
                        <Grid container spacing = {2} rowSpacing={2}>
                            <Grid item xs = {12} sm = {12}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/1-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {8}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/2-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/3-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/4-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {8}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/5-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {12}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/6-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {5}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/7-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {7}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/8-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/9-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/10-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/11-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {8}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/12-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/13-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {5}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/14-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {7}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/15-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {6}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/16-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {6}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/17-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {12}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/LeBleu_grid/18-LSD-Website-LB.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                        </Grid>
                    </GridContainerStyled>
                </GridPageStyled>
        </>
    )
}

export default LeBleuGrid;