const habra_plates = [
    {
        name: "Korean short Rib",
        name_ar : "ضلع قصير على الطريقة الكورية",
        price: "145",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Korean Short Ribs.jpg",
    },

    {
        name: "Beef back ribs",
        name_ar: "أضلاع لحم البقر",
        price: "145",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Beef Back Ribs.jpg",
    },

    {
        name: "Burger bong",
        name_ar : "برجر بونغ",
        price: "85",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Burger Bong.jpg",
    },

    {
        name: "Chef burger",
        name_ar : "برغر الشيف",
        price: "135",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Chef Burger.jpg",
    },

    {
        name: "Fried chicken burger, house curry mayo",
        name_ar : "برجر الدجاج المقلي، مايونيز الكاري المنزلي",
        price: "75",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Fried Chicken Burger.jpg",
    },

    {
        name: "Deconstructed gyros",
        name_ar : "الجايروس المفككة (لشخصين)",
        price: "210",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Gyros.jpg",
    },

    {
        name: "Hoisin pulled ribs",
        name_ar : "أضلاع هويسين المسحوبة",
        price: "120",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Hoisin Pulled Ribs.jpg",
    },

    {
        name: "Sliced beef fillet, jus, spinach pottage",
        name_ar : "شرائح لحم بقري، جوس، بوتاج السبانخ",
        price: "190",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Sliced Beef Fillet Jus Spinach Pottage.jpg",
    },

    {
        name: `Snack plate`,
        name_ar : `طبق سناك`,
        price: "98",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Snack Plate.jpg",
    },

    {
        name: "Spinach & ricotta stuffed chicken",
        name_ar : "الدجاج المحشو بالسبانخ والريكوتا",
        price: "85",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Chicken Spinach Ricotta.jpg",
    },
]

export default habra_plates;