import styled from "styled-components";
import { CategoryHeaderStyled, ItemContainerStyled, ItemEnglishTitleStyled, ItemImageContainerStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, CategoryContainerStyled, ArabicContainerStyled, ItemArabicTitleStyled, ItemArabicDescriptionStyled, FillerContainerStyled } from "../lbMenu/CommonStyled";


function BreakfastEggsEv({}) {
    return (
        <>
        <CategoryContainerStyled>
            <CategoryHeaderStyled>
                <span>Eggs</span>
            </CategoryHeaderStyled>
            <ItemContainerStyled style = {{marginTop: "50px"}}>
                    <ItemEnglishTitleStyled>
                    Plain Omelette
                    <PriceContainerStyled>
                       29
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Egg, butter</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        اومليت عادي
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        بيض، زبدة
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-omelet cheese.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Omelet with Cheese
                    <PriceContainerStyled>
                       41
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Egg, cheddar cheese, butter</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        اومليت مع الجبن
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        بيض، جبنة الشيدر، زبدة
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-lever croissant.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Lever D.S croissant
                    <PriceContainerStyled>
                       59
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Freshly baked croissant, creamy scrambled eggs, matured cheddar cheese, sriracha dip, chives</p>
                        <p>Add: Avocado / 12 QR • Smoked Salmon / 16 QR</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                       كرواسون شروق الشمس
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                كرواسون محمص مع البيض المخفوق، الجبنة الشيدر، صوص السيراتشا بالمايونزي  
                        <p>اضافة: افوكادو / 12 ر.ق • ديك رومي مدخن / ١٦ ر.ق</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
            </CategoryContainerStyled>
            
        </>
    )
}


export default BreakfastEggsEv;