import styled from "styled-components";
import { FillerContainerStyled, CategoryHeaderStyled, ItemContainerStyled, ItemEnglishTitleStyled, ItemImageContainerStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, CategoryContainerStyled, ArabicContainerStyled, ItemArabicTitleStyled, ItemArabicDescriptionStyled } from "./CommonStyled";
import {cakes} from "../../../data/lbCategories/a-la-carte/menuItems";

function BreakfastToasts({}) {
    return (
        <>
        <CategoryContainerStyled>
            {/* <CategoryHeaderStyled>
                <span>Toasts</span>
            </CategoryHeaderStyled> */}
            <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-toast lb.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        LB Toast
                    <PriceContainerStyled>
                        49
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Healthy bloomer bread, with avocado puree top it with goat cheese puree, mix sesame seeds</p>
                        <p>Add: Egg / 12 QR • Mushroom / 12 QR</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        توست لو بلو
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        خبز صحي مع االفوكادو و جبنة الماعز، السمسم و الاعشاب
                        <p>اضافة: بيض / ١٢ ر.ق • فطر / ١٢ ر.ق</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-toast greenpeace mushroom.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        Toast with Greenpeace & Mushroom
                    <PriceContainerStyled>
                        49
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Peas Green, sourdough bread, pesto mushroom,parsly olive oil</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        توست مع البازلاء والفطر
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        بازلاء، توست اسمر، معجون الفطر، زيت زيتون، بقدونس
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-toast fig.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        Toast with Fig
                    <PriceContainerStyled>
                       62
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Sourdough bread, figs fresh, goat cheese, pecan honey</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        توست مع التين
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        خبز اسمر مع، فاكهة التين، جبن الماعز، عسل البقان
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
              
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/Breakfast/le bleu-breakfast-toast labne salmon.png"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                    Toast with Labneh & Smoked Salmon
                    <PriceContainerStyled>
                       56
                        <span>QR</span>
                    </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Sourdough bread, tarragon, dill, labneh,cucumber, smoked salmon, onion, sherry vinegar, capers</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        توست السالمون المدخن مع اللبنة
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        توست اسمرمع السالمون المدخن، لبنة، خيار، بصل، نبات الكبر، خل الكرز
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
            </CategoryContainerStyled>
        </>
    )
}

export default BreakfastToasts;