// ---------------------------------- BREAKFAST - eggs section --------------------------//
const muffins = [

    {
        item_img_src: "/leBleu-menu-designs/Breakfast/le bleu-breakfast-Oats Muffin pistachio.png",
        name_en: "Oats Muffin Pistachio",
        description_en: "Oats, cinnamon powder, fennel powder, banana, honey, pistachio powder, white chocolate, cooking cream",
        name_ar: "مافن الشوفان والبيستاشيو",
        description_ar: "شوفان، قرفة، موز، عسل، بودرة البيستاشيو، شكولاتة البيضاء، كريمة",
        price: "31",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Breakfast/le bleu-breakfast-Oats Muffin peanut butter.png",
        name_en: "Oats Muffin Peanut Butter",
        description_en: "Oats, cinnamon powder, banana, honey, peanut butter cream",
        name_ar: "مافن الشوفان وزبدة الفول السوداني",
        description_ar: "شوفان، قرفة، موز، عسل، زبدة لفول السوداني",
        price: "12",
        currency: "QR",
    },
]

// ---------------------------------- BREAKFAST - pudding & yogurt section --------------------------//
const puddingYogurt = [

    {
        item_img_src: "/leBleu-menu-designs/Breakfast/le bleu-breakfast-chia pudding berry.png",
        name_en: "Chia Pudding with Berry Compote",
        description_en: "Chia seeds, almond milk, maple syrup, berry compote",
        name_ar: "شيا بودنغ مع التوت",
        description_ar: "بذور الشي، حليب اللوز، كمبوت التوت",
        price: "42",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Breakfast/le bleu-breakfast-chia pudding mango.png",
        name_en: "Mango chia pudding",
        description_en: "Chia in almond milk, fresh mango puree, coconut powder",
        name_ar: "مانغو شيا بودينغ",
        description_ar: " شيا مع حليب اللوز، هريس المانجو الطازج، مسحوق جوز الهند",
        price: "39",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Breakfast/le bleu-breakfast-baked oats pudding.png",
        name_en: "Baked oats pudding", 
        description_en: "Oats, cinnamon powder, almond milk, banana, flaxseeds brown, blueberry fresh & compote",
        name_ar: "بودينغ الشوفان والتوت",
        description_ar: "شوفان، قرفة، حليب اللوز، موز، فاكهة التوت وبدور الكتان",
        price: "65",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Breakfast/le bleu-breakfast-granola yogurt.png",
        name_en: "Granola Yogurt",
        description_en: "Slow roasted granola, nuts, seeds, greek yoghurt, honey comb, mixed berris compote",
        name_ar: "جرانوال مع روب يونانى",
        description_ar: "جرانوال محمصة ببطء مع مكرسات وبذور، زبادي يوناني، قرص العسل، كومبوت التوت المشكل",
        price: "49",
        currency: "QR",
    },
]

// ---------------------------------- A LA CARTE - soups section --------------------------//
const soups = [
    {
        item_img_src: "/leBleu-menu-designs/A la carte/soup/le bleu-soup-lentil soup.png",
        name_en: "Cheesy Lentil Soup",
        description_en: "Traditional red lentil soup, fine blend of spices, red leicester cheese, emmental cheese and cream cheese",
        name_ar: "حساء العدس بالكريمة والجبن",
        description_ar: "العدس الاحمر التقليدي مع جبنة امينتال، جبنة الكريمة، جبنة الشيدر",
        price: "45",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/soup/le bleu-soup-wild mushoom soup.png",
        name_en: "Wild Mushroom Soup",
        description_en: "Wild mushroom soup, pickles shamiji, truffle cream",
        name_ar: "حساء الفطر البري",
        description_ar: "فطر بري وفطر الشميجي كريمة الكماه ",
        price: "47",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/soup/le bleu-soup-cream chicken soup.png",
        name_en: "Cream of Chicken Soup",
        description_en: "Tender chicken with onion, herbs, leek, carrots and cream",
        name_ar: "حساء الدجاج بالكريمه",
        description_ar: "دجاج مع الاعشاب والكرات جزر كريمة",
        price: "47",
        currency: "QR",
    },
]

// ---------------------------------- A LA CARTE - hot starters section --------------------------//
const hotStarters = [

    {
        item_img_src: "/leBleu-menu-designs/A la carte/hot starters/le bleu-hot starters-angus beef slider.png",
        name_en: "Angus Beef Sliders",
        description_en: "Angus beef, mild cheddar, onion, jalapeno aioli, ketchup",
        name_ar: "سلايدر اللحم",
        description_ar: "لحم انغوس مفروم مع جبنة الشيدر وكراميل البصل، هالابينو أيولي والكاتشب",
        price: "55",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/hot starters/le bleu-hot starters-chicken croquette.png",
        name_en: "Chicken Croquette Caesar",
        description_en: "Organic chicken breast, white cheesy sauce, pine nut, caesar sauce",
        name_ar: "كروكيت سيزار",
        description_ar: "كرات صدر الدجاج العضوي المقلية، صوص الجبن الابيض، صنوبر مع سيزار صوص",
        price: "39",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/hot starters/le bleu-hot starters-mozarella tarte.png",
        name_en: "Mozzarella Tarte",
        description_en: "Fresh mozzarella, fresh tomato, pesto sauce, sun dried tomato in puff pastry vole au vent",
        name_ar: "تارت موزاريل",
        description_ar: "جبنة الموتزاريلا الطازجة، الطماطم الطازجة، صوص البيستو، الطماطم المجففة في عجين الفطير الفولوفون",
        price: "43",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/hot starters/le bleu-hot starters-bruschetta.png",
        name_en: "Bruschetta",
        description_en: "Seasonal bruschetta, confit mushroom, basil pesto aioli, grilled chicken, creamy sauce",
        name_ar: "بروشيتا",
        description_ar: "بروشيتا الموسمية، كونفيت مشروم، ريحان بيستو أيولي، دجاج مشوي، صلصة كريمة.",
        price: "49",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/hot starters/le bleu-hot starters-butter milk chicken fried slider.png",
        name_en: "Butter milk fried chicken slider",
        description_en: "Fried chicken, jalapeno aioli, mild cheddar cheese ",
        name_ar: "سلايدر الدجاج ",
        description_ar: "دجاج مشوي بالزبدة، هالابينو مع المايونيز و جبنة الشيدر",
        price: "42",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/hot starters/le bleu-hot starters-siracha shrimp.png",
        name_en: "Sriracha Shrimp",
        description_en: "Crispy sriacha shrimp, crispy wonton, balsamic reduction",
        name_ar: "روبيان بصلصه السيراتشا",
        description_ar: "روبيان بصلصه السريراتشا الحارة ، رقائق محمصة ، صلصه البلسميك",
        price: "53",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/hot starters/le bleu-hot starters-crispy potato salad.png",
        name_en: "Crispy Potato Salad",
        description_en: "Crispy potato salad, with cheese sauce, sriracha mayo sauce",
        name_ar: "سلطه البطاطس المقرمشه",
        description_ar: "بطاطس مقرمشة مع صوص الجبنة، مايونيز السيراتشا",
        price: "37",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/hot starters/le bleu-hot starters-sweetcorn ravioli.png",
        name_en: "Sweetcorn Ravioli",
        description_en: "Sweet corn ravioli, crispy bacon, maple butter, panko herbs",
        name_ar: "رافيولي الذره الحلوه",
        description_ar: "رافيولي الذرة الحلوة، لحم مقدد مقرمش، أعشاب البانكو",
        price: "58",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/hot starters/le bleu-hot starters-snails.png",
        name_en: "Burgundy Snail",
        description_en: "Roasted snails in shells with parsley butter and cream with herbs",
        name_ar: "حلزون بصلصة البقدونس",
        description_ar: "حلزون مشوي مع الزبدة، البقدونس والكريمة بالأعشاب",
        price: "125",
        currency: "QR",
    },
]

// ---------------------------------- A LA CARTE - sandwiches section --------------------------//
const sandwiches = [

    {
        item_img_src: "/leBleu-menu-designs/A la carte/sandwiches/le bleu-sandwiches- club sandwich tuna.png",
        name_en: "Club Sandwich Tuna",
        description_en: "Tuna, red radish, fresh tomato, chives, avocado slice, siracha mayo",
        name_ar: "كلوب سندويش تونا",
        description_ar: "تونا، فجل، طماطم، افوكادو، صوص سيراتشا",
        price: "39",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/sandwiches/le bleu-sandwiches-club sandwich chicken.png",
        name_en: "Club Sandwich Chicken",
        description_en: "Chicken, eggs, jalapano mayo, cucumber, ham breast, lettuce",
        name_ar: "كلوب سندويش الدجاج",
        description_ar: "دجاج، بيض، صلصة الهالبينو، خيار، خس، ترك",
        price: "47",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/sandwiches/le bleu-sandwiches-mac burger.png",
        name_en: "Mac Burger",
        description_en: "Beef, Cheddar, cheese, burger bun,sweet relish sauce, white onion",
        name_ar: "ماك برغر",
        description_ar: "لحم، جبن الشيدر، بصل ابيض، صوص الريليش الحلوة",
        price: "69",
        currency: "QR",
    },
]

// ---------------------------------- A LA CARTE - main_course section --------------------------//
const main_course = [

    {
        item_img_src: "/leBleu-menu-designs/A la carte/main course/le bleu-main course-fillet green  paper.png",
        name_en: "Fillet with Green Pepper Sauce",
        description_en: "Black angus beef fillet, mustard pepper sauce, mashed potato",
        name_ar: "شرائح لحم بالفلفل",
        description_ar: "شرائح لحم مع صلصة الفلفل الأخضر 200 جرام فيليه لحم البقر،يقدم مع صلصة الفلفل الأخضر والبطاطس المهروسه",
        price: "152",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/main course/le bleu-main course- filet au champignon.png",
        name_en: "Filet Au Champignon",
        description_en: "Black angus beef fillet, mushroom sauce, mashed potato",
        name_ar: "شرائح لحم بصوص المشروم",
        description_ar: "شرائح لحم بقر 200 جرام، يقدم مع صلصة الفطر والبطاطس المهروسه",
        price: "146",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/main course/le bleu-main course-lemon organic chicken.png",
        name_en: "Lemon Organic Chicken",
        description_en: "Organic chicken escalopes lemon sauce, tagliatelle pasta",
        name_ar: "صدر دجاج مع الليمون",
        description_ar: "إسكالوب الدجاج المحمر مع صلصة ليمون و تالياتيلي باستا",
        price: "73",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/main course/le bleu-main course-coconut mustard.png",
        name_en: "Coconut Mustard Baby Chicken",
        description_en: "Grilled baby chicken, coconut mustard sauce, sauted mushroom with smashed baby potato",
        name_ar: "دجاج بالخردل",
        description_ar: "دجاج مشوي، مع صلصة جوز الهند ماسترد، مشروم سوتيه مع بطاطا صغيرة مهروسه",
        price: "89",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/main course/le bleu-main course-baby chicken cured lemon.png",
        name_en: "Baby Chicken With Cured Lemon",
        description_en: "Roasted baby chicken marinated in lemon, thyme, garlic, lemon confit, de provence herbs and honey served with white rice",
        name_ar: "فروج بنكهة الليمون المخلل",
        description_ar: "دجاج صغير مشوي متبل بالليمون، زعتر، ثوم، كونفيت الليمون، أعشاب دي بروفانس و عسل يقدم مع أرز أبيض",
        price: "95",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/main course/le bleu-main course-miso salmon.png",
        name_en: "Miso Salmon",
        description_en: "Norwegian salmon marinated in miso, quinoa and rosemary sauce",
        name_ar: "سلمون مشوي ميزو",
        description_ar: "سلمون النرويجي مع صلصة الميزو، الكينوا مع صوص اكليل الجبل",
        price: "96",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/main course/le bleu-main course- tom yum shrimp.png",
        name_en: "Tom Yum Shrimp",
        description_en: "Grilled fresh shrimp with Tom Yum sauce served with steamed white rice",
        name_ar: "توم يام بالروبيان",
        description_ar: "روبيان طازج مشوي مع صوص توم يام يقدم مع أرز أبيض مطهو على البخار",
        price: "87",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/main course/le bleu-main course-Beef tenderloin with black oysters.png",
        name_en: "Beef Tenderloin with Black Oysters",
        description_en: "Beef filet, oyster mushroom, dijon mustard, green beans",
        name_ar: "لحم تندرليون بالمحار الاسود",
        description_ar: "فيليه بقري، فطر محار، خردل ديجون، فاصوليا خضراء",
        price: "139",
        currency: "QR",
    },
]

// ---------------------------------- A LA CARTE - Dessert section --------------------------//
const dessert = [
    
    {
        item_img_src: "/leBleu-menu-designs/A la carte/dessert/le bleu-dessert-saffron cream caramel.png",
        name_en: "Saffron Cream Caramel",
        description_en: "Freshly baked custard with finest saffron, caramelized sugar served with heavy cream",
        name_ar: "كريم كراميل بالزعفران",
        description_ar: "كاسترد طازج مع أجود أنواع الزعفران والسكر المكرمل يقدم مع الكريمة الثقيلة",
        price: "52",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/dessert/le bleu-dessert-vanilla cream caramel.png",
        name_en: "Vanilla Cream Caramel",
        description_en: "Freshly baked custard with vanilla flavor, caramelized sugar served with heavy cream",
        name_ar: "فانيلا كريم كراميل",
        description_ar: "كاسترد مخبوز طازج بنكهة الفانيليا، سكر مكرمل يقدم مع كريمة ثقيلة",
        price: "52",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/dessert/le bleu-dessert-mango cheese cake.png",
        name_en: "Mango Cheesecake",
        description_en: "",
        name_ar: "مانجو شيز كيك",
        description_ar: "",
        price: "55",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/dessert/le bleu-dessert-blueberry cheese cake.png",
        name_en: "Blueberry Cheesecake",
        description_en: "",
        name_ar: "بلو بيري شيز كيك",
        description_ar: "",
        price: "55",
        currency: "QR",
    },

  

    {
        item_img_src: "/leBleu-menu-designs/A la carte/dessert/le bleu-dessert-milk saffron cake.png",
        name_en: "Milk Saffron Cake",
        description_en: "Saffron sponge, cheese saffron cream",
        name_ar: "كعكة الحليب بالزعفران",
        description_ar: "اسفنجة الزعفران، كريمة الزعفران بالجبن",
        price: "45",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/dessert/le bleu-dessert-french toast.png",
        name_en: "French Toast",
        description_en: "Caramelized soaked brioche in spiced egg mixture, caramel sauce, fresh berries, vanilla ice ceam",
        name_ar: "فرينش توست",
        description_ar: "بريوش بالكراميل مع صوص كراميل، توت طازج، آيس كريم فانيليا",
        price: "52",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/dessert/le bleu-dessert-tiramisu.png",
        name_en: "Tiramisu",
        description_en: "Mascarpone cream, coffe, sugar, whipping cream",
        name_ar: "تيراميسو",
        description_ar: "كريمة ماسكاربوني، قهوة، سكر، كريمة خفق",
        price: "42",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/dessert/le bleu-dessert-ice cream.png",
        name_en: "Ice Cream",
        description_en: "Strawberry ice cream, mango ice cream or, chocolate ice cream",
        name_ar: "يس كريم",
        description_ar: "آيس كريم فراولة، آيس كريم مانجو، آيس كريم شوكولاتة",
        price: "15",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/dessert/le bleu-dessert-sorbet.png",
        name_en: "Sorbet",
        description_en: "Watermelon sorbet, classic mojito sorbet",
        name_ar: "صوربة",
        description_ar: "شربات البطيخ كلاسيك، موهيتو شربات",
        price: "15",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/dessert/le bleu-dessert-chocolate mousse.png",
        name_en: "Chocolate Mousse",
        description_en: "Dark chocolate, mascarpone cheese, whipping cream",
        name_ar: "موس الشوكولاته",
        description_ar: "كريمة خفق بجبنة الماسكاربوني الداكنة بالشوكولاتة",
        price: "33",
        currency: "QR",
    },
]

// ---------------------------------- A LA CARTE - Cakes section --------------------------//
const cakes = [

    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-coconut.png",
        name_en: "Coconut Cake",
        description_en: "Coconut sponge, coconut cheese cream, dray raspberry toping",
        name_ar: "كيكة جوز الهند",
        description_ar: " إسفنج جوز الهند، كريمة جبن جوز الهند، كريمة توت العليق",
        price: "38",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-chocolate peanuts.png",
        name_en: "Chocolate Peanut Butter Cake",
        description_en: "Chocolate mousse, nutty cream, almond chocolate sponge, chocolate glaze, peanuts cream",
        name_ar: "كيكة زبدة الفول السوداني بالشوكولاته",
        description_ar: "موس الشوكولاته، كريم الجوز، اللوز إسفنج الشوكولاتة، صقيل الشوكولاتة، كريم الفول السوداني",
        price: "42",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-paris brest.png",
        name_en: "Paris Brest",
        description_en: "Choux éclair, sugar crumble, almond hazelnut praline cream, vanilla biscuit, cocoa powder jell",
        name_ar: "باريس بريست",
        description_ar: "كريم اللوز والبندق واللوز، بسكويت الفانيليا، بودرة الكاك",
        price: "52",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-toffee.png",
        name_en: "Toffee Cake",
        description_en: "Vanilla sponge, caramel cream, cheese cream, feuilletine",
        name_ar: "كيكة التوفي",
        description_ar: "اسفنج الفانيليا، كريم الكراميل، كريم الجبن",
        price: "33",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-meringue vanilla.png",
        name_en: "Vanilla Meringue",
        description_en: "Vanilla sponge, whiping cream, swiss meringue",
        name_ar: "ميرانج بالفانيليا",
        description_ar: "اسفنجة الفانيليا - المرينج السويسري",
        price: "43",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-meringue berry.png",
        name_en: "Berry Meringue",
        description_en: "Berry, whiping cream, swiss meringue",
        name_ar: "ميرانج بالتوت",
        description_ar: "اسفنجة الفانيليا ، المرينج السويسري والتوت",
        price: "47",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-angelo.png",
        name_en: "Angelo",
        description_en: "Chocolate sponge with almond duja, chocolate mousse, nutty cream, milk chocolate ganache",
        name_ar: "انجيلو كيك",
        description_ar: "اسفنجة شوكولاتة مع اللوز، موس شوكولاتة، كريمة البندق، غاناش شوكولاتة الحليب",
        price: "52",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-pistachio.png",
        name_en: "Pistachio Cake",
        description_en: "Pistachio sponge, pistachio cheese cream, pistachio paste, pistachio slice, pistachio powder",
        name_ar: "كيكة الفستق",
        description_ar: "اسفنجة الفستق، كريمة الجبن بالفستق، عجينة الفستق، شريحة الفستق، بودرة الفستق",
        price: "39",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-eclair.png",
        name_en: "Éclair Milk Chocolate",
        description_en: "Éclair choux, sugar crumble, milk chocolate cream, ganache monte, feuilletine",
        name_ar: "اكلير ميلك شوكليت",
        description_ar: "سكر كرامبل، كريمة شوكولاتة الحليب",
        price: "33",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-eclair-chocolate.png",
        name_en: "Chocolate Éclair",
        description_en: "",
        name_ar: "اكلير الشوكولاته",
        description_ar: "",
        price: "29",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-eclair-peanuts.png",
        name_en: "Peanuts Éclair",
        description_en: "",
        name_ar: "اكلير بينات",
        description_ar: "",
        price: "33",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-eclair-lemon.png",
        name_en: "Lemon Éclair",
        description_en: "",
        name_ar: "اكلير الليمون",
        description_ar: "",
        price: "29",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-victoria.png",
        name_en: "Victoria Cake",
        description_en: "cheese cream, whiping cream, vanilla, strawberry jam, fresh blackberry, fresh rasberry",
        name_ar: "فيكتوريا كيك",
        description_ar: "كريمة الجبن، فانيليا، مكس التوت",
        price: "43",
        currency: "QR",
    },

    // {
    //     item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-banana bread.png",
    //     name_en: "Banana Bread Cake",
    //     description_en: "Fresh banana, white sugar",
    //     name_ar: "كعكة خبز الموز",
    //     description_ar: "سكر أبيض بالموز الطازج",
    //     price: "25",
    //     currency: "QR",
    // },
]

// ---------------------------------- A LA CARTE - mocktails section --------------------------//
const mocktails = [

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-berry.png",
        name_en: "Berry",
        description_en: "Blueberry, blackberry, blackberry syrup, apple juice",
        name_ar: "توت",
        description_ar: "توت، ثمر العليق، شراب ثمر العليق، عصير تفاح",
        price: "30",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-virgin mojito.png",
        name_en: "Mojito Classic",
        description_en: "Lime, sugar syrup, sprite soda, mint",
        name_ar: "موهيتو",
        description_ar: "ليمون، نعناع، شراب سكر، سبرايت صودا",
        price: "34",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-strawberry mojito.png",
        name_en: "Mojito Strawberry",
        description_en: "Lime, strawberrry, sugar syrup, sprite soda, mint",
        name_ar: "موهيتو فراوله",
        description_ar: "فرولة، نعناع، شراب سكر، سبرايت صودا",
        price: "34",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-passion fruit mojito.png",
        name_en: "Mojito Passion Fruit",
        description_en: "Lime, passion fruits, sugar syrup, sprite soda, mint",
        name_ar: "موهيتو باشن فروت",
        description_ar: "باشن فروت، نعناع، شراب سكر، سبرايت صودا",
        price: "34",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-berry mojito.png",
        name_en: "Mojito Berry",
        description_en: "Lime, berry, sugar syrup, sprite soda, mint",
        name_ar: "موهيتو بيري",
        description_ar: "نعناع، شراب سكر، سبرايت صودا، بيري",
        price: "34",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-lemon mint.png",
        name_en: "Lemon with Mint",
        description_en: "Sweetened lemon with refreshing mint",
        name_ar: "ليمون بالنعناع",
        description_ar: "ليمون محلي، بالنعناع المنعش",
        price: "30",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-watermelon with lemonade.png",
        name_en: "Watermelon with Lemonada",
        description_en: "Watermelon, lemon juice, watermelon syrup",
        name_ar: "بطيخ مع ليمون",
        description_ar: "بطيخ مع عصير ليمون وشراب البطيخ",
        price: "32",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-orange.png",
        name_en: "Orange",
        description_en: "Fresh orange",
        name_ar: "برتقال",
        description_ar: "عصير برتقال طازج",
        price: "29",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-summer freshener.png",
        name_en: "Summer Freshener Drink",
        description_en: "Rose syrup, orange and sprite soda",
        name_ar: "معطر الصيف مشروب",
        description_ar: "شراب الورد، البرتقال و سبرايت صود",
        price: "36",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-ice tea passion fruit.png",
        name_en: "Ice Tea Passion Fruit",
        description_en: "Passion fruit syrup, sugar syrup and tea",
        name_ar: "شاي مثلج باشن فروت",
        description_ar: "شراب الباشن فروت، شراب السكر و شاي",
        price: "25",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-ice tea peach.png",
        name_en: "Ice Tea Peach",
        description_en: "Peach syrup, sugar syrup and tea",
        name_ar: "شاي مثلج بالخوخ",
        description_ar: "شراب الخوخ، شراب السكر و الشاي",
        price: "25",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-le bleu.png",
        name_en: "Le Bleu",
        description_en: "Peach syrup, wild mint, lemon juice",
        name_ar: "لو بلو",
        description_ar: "غرينادين، سبرايت، ليمون، نعناع",
        price: "37",
        currency: "QR",
    },

    // {
    //     item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-red tulip.png",
    //     name_en: "Red Tulip",
    //     description_en: "Grenadine, sprite, lime, mint",
    //     name_ar: "رد توليب",
    //     description_ar: "غرينادين، سبرايت، ليمون، نعناع",
    //     price: "29",
    //     currency: "QR",
    // },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-peach lemonade.png",
        name_en: "Peach Lemonade",
        description_en: "Peach syrup, lemon juice, grenadine",
        name_ar: "ليمون بالخوخ",
        description_ar: "شراب الخوخ، عصير الليمون، غرينادين",
        price: "32",
        currency: "QR",
    },

    // {
    //     item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-vitamin c.png",
    //     name_en: "Vitamin C",
    //     description_en: "Passion syrup, orange juice, mango syrup, lemon juice",
    //     name_ar: "فيتامين سي",
    //     description_ar: "شراب باشن، عصير برتقال، شراب مانجو، عصير ليمون",
    //     price: "33",
    //     currency: "QR",
    // },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-pina colada.png",
        name_en: "Pina colada",
        description_en: "Fresh pineapple, coconut milk, coconut cream",
        name_ar: "بينا كولادا",
        description_ar: "أناناس طازج، حليب جوز الهند، كريمة جوز الهند",
        price: "31",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-tropical fruit.png",
        name_en: "Tropical Fruits",
        description_en: "Fresh strawberry, orange juice, passion fruit",
        name_ar: "تروبيكال فروت",
        description_ar: "الفراولة الطازجة، عصير البرتقال، باشن فروت",
        price: "38",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-double pp.png",
        name_en: "Double PP",
        description_en: "Fresh pineapple, passion fruits",
        name_ar: "دابل بي بي",
        description_ar: "أناناس طازج، باشن فروت",
        price: "28",
        currency: "QR",
    },
]

// ---------------------------------- A LA CARTE - coffee section --------------------------//
const premium_coffee = [

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-espresso.png",
        name_en: "Espresso",
        name_ar: "اسبرسو",
        price: "18",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-espresso.png",
        name_en: "Espresso Double",
        name_ar: "اسبرسو دابل",
        price: "25",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le bleu-coffee-capp.png",
        name_en: "Cappucino",
        name_ar: "كابوتشينو",
        price: "30",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le bleu-coffee-capp.png",
        name_en: "Latte (hot/cold)",
        name_ar: " (باردة/ ساخنة)لاتيه ",
        price: "33",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-americano.png",
        name_en: "Americano",
        name_ar: "امريكانو",
        price: "28",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-flat-white-vanilla-latte.png",
        name_en: "Flat white (hot/cold)",
        name_ar: " (باردة/ ساخنة) فلات وايت",
        price: "30",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-flat-white-vanilla-latte.png",
        name_en: "Spanish Latte (hot/cold)",
        name_ar: " (باردة/ ساخنة)سبانيش لاتيه ",
        price: "32",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-flat-white-vanilla-latte.png",
        name_en: "Rose Latte (hot/cold)",
        name_ar: "(باردة/ ساخنة) روز لاتيه",
        price: "32",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-carmel-macchiato.png",
        name_en: "Macchiato",
        name_ar: "ماكياتو",
        price: "28",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-carmel-macchiato.png",
        name_en: "Caramel macchiato",
        name_ar: "ماكياتو كاراميل",
        price: "35",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-flat-white-vanilla-latte.png",
        name_en: "Vanilla latte",
        name_ar: "فانيلا لاتيه",
        price: "35",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-flat-white-vanilla-latte.png",
        name_en: "Caramel latte",
        name_ar: "كاراميل لاتيه",
        price: "35",
        currency: "QR",
    },

    // {
    //     item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-hot-chocolate-hazelnut-latte-mocha.png",
    //     name_en: "Hot chocolate",
    //     name_ar: "شوكولاته ساخنة",
    //     price: "33",
    //     currency: "QR",
    // },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-flat-white-vanilla-latte.png",
        name_en: "Hazelnut latte",
        name_ar: "لاتيه البندق",
        price: "35",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le bleu-coffee-capp.png",
        name_en: "Decaffeinated",
        name_ar: "بدون كافيين",
        price: "35",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-cortado.png",
        name_en: "Cortado",
        name_ar: "كورتادو",
        price: "25",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le bleu-coffee-capp.png",
        name_en: "Mocha",
        name_ar: "موكا",
        price: "33",
        currency: "QR",
    },


    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-cortado.png",
        name_en: "Mini flat white",
        name_ar: "ميني فلات وايت",
        price: "25",
        currency: "QR",
    },

      {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-turkish-coffee.png",
        name_en: "Turkish Coffee",
        name_ar: "قهوة تركية",
        price: "25",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-arabic.png",
        name_en: "Arabic Coffee",
        name_ar: "قهوة عربية",
        price: "47",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-v60.png",
        name_en: "V60",
        name_ar: "في ٦٠",
        price: "33",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le-bleu-coffee-matcha-latte.png",
        name_en: "Matcha latte",
        name_ar: "متشا لاتيه",
        price: "29",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/coffee/le bleu-coffee-karak.png",
        name_en: "Karak",
        name_ar: "كرك",
        price: "11",
        currency: "QR",
    },
]


// ---------------------------------- BOUTIQUE - Sourdough section --------------------------//
const sourdough = [

    {
        item_img_src: "/leBleu-menu-designs/Boutique/sourdough/le bleu-boutique-sourdough-sourdough olive.png",
        name_en: "Sourdough Olive",
        name_ar: "خبز الساوردو مع الزيتون",
        price: "25",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/sourdough/le bleu-boutique-sourdough-sourdough white.png",
        name_en: "Sourdough White",
        name_ar: "خبز الساوردو الابيض",
        price: "21",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/sourdough/le bleu-boutique-sourdough-sourdough brown.png",
        name_en: "Sourdough Brown",
        name_ar: "خبز الساوردو الاسمر",
        price: "23",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/sourdough/le bleu-boutique-sourdough-focaccia.png",
        name_en: "Focaccia",
        name_ar: "فوكاشيا",
        price: "25",
        currency: "QR",
    },
]

// ---------------------------------- BOUTIQUE - Baguette section --------------------------//
const baguette = [

    {
        item_img_src: "/leBleu-menu-designs/Boutique/baguette/le bleu-boutique-bagutte-traditional baguette.png",
        name_en: "Traditional Baguette",
        name_ar: "خبز فرنسي",
        price: "13",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/baguette/le bleu-boutique-bagutte-multi cereal baguette.png",
        name_en: "Multi Cereal Baguette",
        name_ar: "خبز فرنسى متعدد الحبوب",
        price: "17",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/baguette/le bleu-boutique-bagutte-traditional baguette.png",
        name_en: "Brown Baguette",
        name_ar: "خبز فرنسي اسمر",
        price: "15",
        currency: "QR",
    },
]

// ---------------------------------- BOUTIQUE - Danish section --------------------------//
const danish = [

    {
        item_img_src: "/leBleu-menu-designs/Boutique/danish/le bleu-boutique-danish-spinach danish.png",
        name_en: "Spinach Danish",
        description_en: "Spinach, feta cheese, cauliflower",
        name_ar: "دانش السبانخ",
        description_ar: "سبانخ جبنة ، الفتة ، قرنبيط",
        price: "25",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/danish/le bleu-boutique-danish-tomato danish.png",
        name_en: "Tomato Danish",
        description_en: "Cherry Tomato, mozzarella cheese, oregano",
        name_ar: "دانش الطماطم",
        description_ar: "جبنة الموزريلا مع طماطم كرزية والاوريجانو",
        price: "25",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/danish/le bleu-boutique-danish-berries danish.png",
        name_en: "Berries Danish",
        description_en: "Cream, mix berry fresh fruits",
        name_ar: "دانش الميكس بيري",
        description_ar: "كريم، مزيج فواكه طازجة",
        price: "25",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/danish/le bleu-boutique-danish-banoffee.png",
        name_en: "Cream Brule Danish",
        description_en: "",
        name_ar: "كريم برولي دانش",
        description_ar: "",
        price: "23",
        currency: "QR",
    },

    

    {
        item_img_src: "/leBleu-menu-designs/Boutique/danish/le bleu-boutique-danish-cream brule.png",
        name_en: "Banoffee Danish",
        description_en: "",
        name_ar: "دانيش بالموز",
        description_ar: "",
        price: "18",
        currency: "QR",
    },
]

// ---------------------------------- BOUTIQUE - Croissant section --------------------------//
const croissant = [

    {
        item_img_src: "/leBleu-menu-designs/Boutique/croissant/le bleu-boutique-croissant-plain croissant.png",
        name_en: "Plain Croissant",
        name_ar: "كرواسون سادة",
        price: "18",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/croissant/le bleu-boutique-croissant-zattar croissant.png",
        name_en: "Zaatar Croissant",
        name_ar: "زعتر كرواسون",
        price: "20",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/croissant/le bleu-boutique-croissant-pain au chocolat.png",
        name_en: "Pain Au Chocolat",
        name_ar: "شوكليت كرواسون",
        price: "22",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/croissant/le bleu-boutique-croissant-cheese criossant.png",
        name_en: "cheese Croissant",
        name_ar: "كرواسون بالجبن",
        price: "22",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/croissant/le bleu-boutique-croissant-almond croissant.png",
        name_en: "Almond Croissant",
        name_ar: "كرواسون باللوز",
        price: "22",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/croissant/le bleu-boutique-croissant-strawberry croissant.png",
        name_en: "Strawberry Croissant",
        name_ar: "كرواسون بالفراولة",
        price: "24",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/croissant/le bleu-boutique-croissant-pistachio croissant.png",
        name_en: "Pistachio Croissant",
        name_ar: " كرواسون بالفستق",
        price: "26",
        currency: "QR",
    },
]

// ---------------------------------- BOUTIQUE - selection of premium tea section --------------------------//
const premium_tea = [

    {
        name_en: "White tea",
        description: "Jasmine Pearls",
        name_ar: "شاي أبيض",
        price: "45",
        currency: "QR",
    },

    {
        name_en: "Green tea",
        description: "Green Tea with Mint, Green Jasmine Premium",
        name_ar: "شاي أخضر",
        price: "35",
        currency: "QR",
    },

    {
        name_en: "Oolong tea",
        description: "Lime high Grown Oolong",
        name_ar: "أولونغ",
        price: "30",
        currency: "QR",
    },

    {
        name_en: "Black Tea",
        description: "Darjeeling Early Gray, Le Bleu breakfast tea, Flowery Early Grey",
        name_ar: "شاي أسود",
        price: "30",
        currency: "QR",
    },

    {
        name_en: "Herbal tea",
        description: "Orderant verbana",
        name_ar: "شاي هربل",
        price: "32",
        currency: "QR",
    },
]

// ---------------------------------- BOUTIQUE - scones section --------------------------//
const scones = [

    {
        item_img_src: "/leBleu-menu-designs/Boutique/scones/le bleu-scones-plain.png",
        name_en: "Plain Scone",
        name_ar: "سكونز سادة",
        price: "11",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/scones/le bleu-scones-raisin.png",
        name_en: "Raisin Scone",
        name_ar: "سكونز زبيب",
        price: "11",
        currency: "QR",
    },
]

// ---------------------------------- A LA CARTE - Water section --------------------------//
const water = [

    {
        name_en: "Still water large",
        name_ar: "مياه معدنية كبيرة",
        price: "24",
        currency: "QR",
    },

    {
        name_en: "Still water small",
        name_ar: "مياه معدنية صغيرة",
        price: "16",
        currency: "QR",
    },

    {
        name_en: "Large sparkling water",
        name_ar: "مياه غازية كبيرة",
        price: "24",
        currency: "QR",
    },

    {
        name_en: "Small sparkling water",
        name_ar: "مياه غازية صغيرة",
        price: "16",
        currency: "QR",
    },
]

// ---------------------------------- A LA CARTE - soft drinks section --------------------------//
const soft_drinks = [

    {
        name_en: "Coca Cola",
        name_ar: "كولا",
        price: "18",
        currency: "QR",
    },

    {
        name_en: "Diet Coke",
        name_ar: "كولا دايت",
        price: "18",
        currency: "QR",
    },

    {
        name_en: "Orange Fanta",
        name_ar: "فانتا البرتقال",
        price: "18",
        currency: "QR",
    },

    {
        name_en: "Strawberry Fanta",
        name_ar: "فانتا الفراولة",
        price: "18",
        currency: "QR",
    },

    {
        name_en: "Sprite",
        name_ar: "سبرايت",
        price: "18",
        currency: "QR",
    },
]

// ---------------------------------- Afternoon Tea - FULL MENU SECTION --------------------------//
const afternoon_full_menu = [

    {
        name_en: "Spicy Chicken mayo in white bread (G)",
        name_ar: "الدجاج الحار بالمايونيز مع الخبز الابيض",
    },

    {
        name_en: "Salted cucumber carpaccio with cream cheese and chives mousse on spinach bread (G/D)",
        name_ar: "خبز السبانخ مغطى بلحاف من رقائق الخيار المملحة مع كريمة الجبنة و مووس الثوم",
    },

    {
        name_en: "Free-range egg mayo with radish cress on plain whole loaf bread (G)",
        name_ar: "الفجل والخردل مع مايونيز البيض البلدي على رغيف حبوب القمح الكاملة",
    },

    {
        name_en: "Slow roasted beef, mayo horseradish and arugula on multi cereal loaf (G)",
        name_ar: "لحم عجل مشوي على نار هادئة، مقدمة مع الفجل الحار والجرجر على رغيف خبز الحبوب المتنوعه",
    },

    {
        name_en: "Cashew cheese and sauerkraut on multi cereal loaf",
        name_ar: "جبنة الكاجو والملفوف المخلل على خبز متعدد الحبوب",
    },

    {
        name_en: "Fresh mozzarella with sun-dried tomato and pesto sauce (G/D)",
        name_ar: "موزاريلا طازجة مع طماطم مجففة و صلصة البيستو",
    }
]

// ---------------------------------- Afternoon Tea - FULL MENU - PASTRY SECTION --------------------------//
const full_menu_pastry_ensemble = [

    {
        name_en: "Gâteau au safran (G/D/N)",
        name_ar: "كيكة الزعفران",
    },

    {
        name_en: "Tarte aux mirtilles (G/D/N)",
        name_ar: "تارت بلوبيري",
    },

    {
        name_en: "Gâteau aux cerises (G/D/N)",
        name_ar: "كيكة الكرز",
    },

    {
        name_en: "Tarte au chocolate (G/D/N)",
        name_ar: "تارت الشوكولاته",
    },

    {
        name_en: "Mousse au cafe (G/D/N)",
        name_ar: "موس القهوة",
    },

    {
        name_en: "Gâteau à la pistache (G/D/N)",
        name_ar: "كيكة الفستق",
    },

    {
        name_en: "Gâteau au amandes (G/D/N)",
        name_ar: "كيكة اللوز",
    },

    {
        name_en: "Mille-feuilles (G/D)",
        name_ar: "ميل فاي",
    },

    {
        name_en: "Madeleine (G/D)",
        name_ar: "مادلين",
    },
]

// ---------------------------------- Afternoon Tea - FULL MENU - SCONES SECTION --------------------------//
const full_menu_scones = [
    
    {
        name_en: "Plain / Raisin (G/D)",
        name_ar: "سادة \ زبيب",
    },

    {
        name_en: "Served with clotted cream, strawberry and apricot jams preserves",
        name_ar: "تقدم مع الكريمة المخثرة، مربى الفراولة والمشمش",
    },
]


// ---------------------------------- Afternoon Tea - FULL MENU - premium tea SECTION --------------------------//
const full_menu_selection_of_premium_tea_coffee = [

    {
        name_en: "Breakfast Tea | Jasmine Premium | Chamomile | Green tea with mint | Jeoncha",
        name_ar: "بريكفاست تي  | جازمين بريميوم   | بابونج   | شاي اخضر مع نعناع | جونشا",
    },

    {
        name_en: "Verbena mint | Darjeeling | Earl Grey | Citrus tea | Oolong",
        name_ar: "لويزه بالنعناع   |   شاي إرل غري   |   شاي الحمضيات   |   أولونغ",
    },

    {
        name_en: "Espresso | Americano | Flat White | Cappuccino | Latte",
        name_ar: "اسبرسو   |   اميريكانو   |   فلات وايت   |   كابتشينو   |   لاتيه",
    },
]

// ---------------------------------- Afternoon Tea - ENHANCED MENU - enhanced section --------------------------//
const enhanced_menu_afternoon_tea_expereince = [

    {
        name_en: "Complete your experience with a choice of our signature",
        name_ar: "اكمل تجربتك مع اختيارك من تخصصاتنا المميزة",
    },

    {
        name_en: "Cream Caramel | Cheesecake | Vanilla Meringue",
        name_ar: "كريم كراميل   |   تشيز كيك   |   ميرانج الفانيلا",
    },
]

// ---------------------------------- A LA CARTE - Dessert event section --------------------------//
const dessertEv = [

    {
        item_img_src: "/leBleu-menu-designs/A la carte/dessert/le bleu-dessert-french toast.png",
        name_en: "French Toast",
        description_en: "Caramelized soaked brioche in spiced egg mixture, caramel sauce, fresh berries, vanilla ice ceam",
        name_ar: "فرينش توست",
        description_ar: "بريوش بالكراميل مع صوص كراميل، توت طازج، آيس كريم فانيليا",
        price: "52",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/dessert/le bleu-dessert-vanilla cream caramel.png",
        name_en: "Vanilla Cream Caramel",
        description_en: "Freshly baked custard with vanilla flavor, caramelized sugar served with heavy cream",
        name_ar: "فانيلا كريم كراميل",
        description_ar: "كاسترد مخبوز طازج بنكهة الفانيليا، سكر مكرمل يقدم مع كريمة ثقيلة",
        price: "52",
        currency: "QR",
    },
    
    {
        item_img_src: "/leBleu-menu-designs/A la carte/dessert/le bleu-dessert-saffron cream caramel.png",
        name_en: "Saffron Cream Caramel",
        description_en: "Freshly baked custard with finest saffron, caramelized sugar served with heavy cream",
        name_ar: "كريم كراميل بالزعفران",
        description_ar: "كاسترد طازج مع أجود أنواع الزعفران والسكر المكرمل يقدم مع الكريمة الثقيلة",
        price: "52",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/dessert/le bleu-dessert-cheesecake mix berries.png",
        name_en: "Cheesecake with Mixed Berries Compote",
        description_en: "Baked rich cream cheese in crushed cookies dough, mixed berries compote, vanilla sour cream",
        name_ar: "تشيز كيك مع كومبوت توت مشكل",
        description_ar: "جبنة كريمية غنية مخبوزة في عجينة الكوكيز المهروسة، كومبوت التوت المشكل، كريمة الفانيليا ",
        price: "55",
        currency: "QR",
    },

]

// ---------------------------------- A LA CARTE - Cakes event section --------------------------//
const cakesEv = [

    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-meringue vanilla.png",
        name_en: "Vanilla Meringue",
        description_en: "Vanilla sponge, whiping cream, swiss meringue",
        name_ar: "ميرانج بالفانيليا",
        description_ar: "اسفنجة الفانيليا - المرينج السويسري",
        price: "43",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-meringue berry.png",
        name_en: "Berry Meringue",
        description_en: "Berry, whiping cream, swiss meringue",
        name_ar: "ميرانج بالتوت",
        description_ar: "اسفنجة الفانيليا ، المرينج السويسري والتوت",
        price: "47",
        currency: "QR",
    },


    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-pistachio.png",
        name_en: "Pistachio Cake",
        description_en: "Pistachio sponge, pistachio cheese cream, pistachio paste, pistachio slice, pistachio powder",
        name_ar: "كيكة الفستق",
        description_ar: "اسفنجة الفستق، كريمة الجبن بالفستق، عجينة الفستق، شريحة الفستق، بودرة الفستق",
        price: "39",
        currency: "QR",
    },


    {
        item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-victoria.png",
        name_en: "Victoria Cake",
        description_en: "cheese cream, whiping cream, vanilla, strawberry jam, fresh blackberry, fresh rasberry",
        name_ar: "فيكتوريا كيك",
        description_ar: "كريمة الجبن، فانيليا، مكس التوت",
        price: "43",
        currency: "QR",
    },

    // {
    //     item_img_src: "/leBleu-menu-designs/A la carte/cakes/le bleu-cakes-banana bread.png",
    //     name_en: "Banana Bread Cake",
    //     description_en: "Fresh banana, white sugar",
    //     name_ar: "كعكة خبز الموز",
    //     description_ar: "سكر أبيض بالموز الطازج",
    //     price: "25",
    //     currency: "QR",
    // },
]

// ---------------------------------- A LA CARTE - mocktails event section --------------------------//
const mocktailsEv = [

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-berry.png",
        name_en: "Berry",
        description_en: "Blueberry, blackberry, blackberry syrup, apple juice",
        name_ar: "توت",
        description_ar: "توت، ثمر العليق، شراب ثمر العليق، عصير تفاح",
        price: "30",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-virgin mojito.png",
        name_en: "Mojito Classic",
        description_en: "Lime, sugar syrup, sprite soda, mint",
        name_ar: "موهيتو",
        description_ar: "ليمون، نعناع، شراب سكر، سبرايت صودا",
        price: "34",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-strawberry mojito.png",
        name_en: "Mojito Strawberry",
        description_en: "Lime, strawberrry, sugar syrup, sprite soda, mint",
        name_ar: "موهيتو فراوله",
        description_ar: "فرولة، نعناع، شراب سكر، سبرايت صودا",
        price: "34",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-passion fruit mojito.png",
        name_en: "Mojito Passion Fruit",
        description_en: "Lime, passion fruits, sugar syrup, sprite soda, mint",
        name_ar: "موهيتو باشن فروت",
        description_ar: "باشن فروت، نعناع، شراب سكر، سبرايت صودا",
        price: "34",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-berry mojito.png",
        name_en: "Mojito Berry",
        description_en: "Lime, berry, sugar syrup, sprite soda, mint",
        name_ar: "موهيتو بيري",
        description_ar: "نعناع، شراب سكر، سبرايت صودا، بيري",
        price: "34",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-lemon mint.png",
        name_en: "Lemon with Mint",
        description_en: "Sweetened lemon with refreshing mint",
        name_ar: "ليمون بالنعناع",
        description_ar: "ليمون محلي، بالنعناع المنعش",
        price: "30",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-watermelon with lemonade.png",
        name_en: "Watermelon with Lemonada",
        description_en: "Watermelon, lemon juice, watermelon syrup",
        name_ar: "بطيخ مع ليمون",
        description_ar: "بطيخ مع عصير ليمون وشراب البطيخ",
        price: "32",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-orange.png",
        name_en: "Orange",
        description_en: "Fresh orange",
        name_ar: "برتقال",
        description_ar: "عصير برتقال طازج",
        price: "29",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-summer freshener.png",
        name_en: "Summer Freshener Drink",
        description_en: "Rose syrup, orange and sprite soda",
        name_ar: "معطر الصيف مشروب",
        description_ar: "شراب الورد، البرتقال و سبرايت صود",
        price: "36",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-ice tea passion fruit.png",
        name_en: "Ice Tea Passion Fruit",
        description_en: "Passion fruit syrup, sugar syrup and tea",
        name_ar: "شاي مثلج باشن فروت",
        description_ar: "شراب الباشن فروت، شراب السكر و شاي",
        price: "25",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/A la carte/mocktails/le bleu-mocktails-ice tea peach.png",
        name_en: "Ice Tea Peach",
        description_en: "Peach syrup, sugar syrup and tea",
        name_ar: "شاي مثلج بالخوخ",
        description_ar: "شراب الخوخ، شراب السكر و الشاي",
        price: "25",
        currency: "QR",
    },
]

// ---------------------------------- A LA CARTE - sandwiches event section --------------------------//
const sandwichesEv = [

    {
        name_en: "Veggie",
        description_en: "Eggplant, zucchini, walnut chutney, mozarella, multiseed loaf",
        name_ar: "فيجي",
        description_ar: "باذنجان، كوسى، صلصة الجوز، جبنة الموزاريلا، خبز متعدد الحبوب",
        price: "23",
        currency: "QR",
    },

    {
        name_en: "Chilli Chicken",
        description_en: "Qatari chicken, espellete, chilli, japanese mayo, white loaf",
        name_ar: "دجاج حار",
        description_ar: "دجاج بلدي، فلفل الاسبيليت، مايو ياباني، خبز ابيض",
        price: "23",
        currency: "QR",
    },

    {
        name_en: "Tuna",
        description_en: "Spanish tuna, tonnato sauce, multiseed loaf",
        name_ar: "تونا",
        description_ar: "تونا إسباني، توناتو صوص الإيطالي، خبز متعدد الحبوب",
        price: "23",
        currency: "QR",
    },

    {
        name_en: "Turkey & Cheese",
        description_en: "Smoked turkey, mozzarella cheese, emmental cheese with mayo mustard sauce",
        name_ar: "ساندويتش حبش وجبنة",
        description_ar: "صدر ديك رومي مدخن، جبنة موزاريلا، جبنة امنتال فرنسي ",
        price: "41",
        currency: "QR",
    },

    {
        name_en: "Caesar Sandwich",
        description_en: "Chicken breast, caesar sauce, parmesn cheese in french baguette",
        name_ar: "ساندويتش سيزار دجاج",
        description_ar: "صدر دجاج، صلصة السيزر، جبنة البارميزان، الخبز الفرنسي الاساسي ",
        price: "43",
        currency: "QR",
    },
]

// ---------------------------------- Afternoon Tea - FULL MENU - PASTRY event SECTION --------------------------//
const full_menu_pastry_ensembleEv = [

    {
        name_en: "Pistachio éclair (G/D/N)",
        name_ar: "اكلير الفستق الحلبي",
    },

    {
        name_en: "Black Berry Tart (G/D/N)",
        name_ar: "تارت بلاك بيري",
    },

    {
        name_en: "Raspberry Cheese Cake (G/D/N)",
        name_ar: "تشيز كيك بالتوت العليق",
    },

    {
        name_en: "Signature mille feuille (G/D)",
        name_ar: "ميل فويل المميزة",
    },

    {
        name_en: "Pain de genes (G/D)",
        name_ar: "بان دو جين",
    },

    {
        name_en: "Coconut Cake (G/D)",
        name_ar: "كيكة جوز الهند",
    },

    {
        name_en: "Saffron Madeline (G/D)",
        name_ar: "مادلين الزعفران",
    },

    {
        name_en: "Milk Chocolate Choux (G/D)",
        name_ar: "شو شوكولاتة بالحليب",
    },

    {
        name_en: "Caramel Chocolate Tart (G/D)",
        name_ar: "تارت كرامل الشوكولاتة",
    },

    {
        name_en: "Peanut Butter Tart (G/D)",
        name_ar: "تارت زبدة الفول السوداني",
    },
]

// ---------------------------------- BOUTIQUE - bakery event section --------------------------//
const bakeryEv = [

    {
        item_img_src: "/leBleu-menu-designs/Boutique/croissant/le bleu-boutique-croissant-plain croissant.png",
        name_en: "Plain Croissant",
        name_ar: "كرواسون سادة",
        price: "18",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/croissant/le bleu-boutique-croissant-almond croissant.png",
        name_en: "Almond Croissant",
        name_ar: "كرواسون باللوز",
        price: "22",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/croissant/le bleu-boutique-croissant-pain au chocolat.png",
        name_en: "Pain Au Chocolat",
        name_ar: "شوكليت كرواسون",
        price: "22",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/danish/le bleu-boutique-danish-berries danish.png",
        name_en: "Berries Danish",
        description_en: "Cream, mix berry fresh fruits",
        name_ar: "دانش الميكس بيري",
        description_ar: "كريم، مزيج فواكه طازجة",
        price: "25",
        currency: "QR",
    },

    {
        item_img_src: "/leBleu-menu-designs/Boutique/danish/le bleu-boutique-danish-tomato danish.png",
        name_en: "Tomato Danish",
        description_en: "Cherry Tomato, mozzarella cheese, oregano",
        name_ar: "دانش الطماطم",
        description_ar: "جبنة الموزريلا مع طماطم كرزية والاوريجانو",
        price: "25",
        currency: "QR",
    },

    
    {
        item_img_src: "/leBleu-menu-designs/Boutique/danish/le bleu-boutique-danish-spinach danish.png",
        name_en: "Spinach Danish",
        description_en: "Spinach, feta cheese, cauliflower",
        name_ar: "دانش السبانخ",
        description_ar: "سبانخ جبنة ، الفتة ، قرنبيط",
        price: "25",
        currency: "QR",
    },

]




export {
    muffins,
    puddingYogurt,
    soups,
    hotStarters,
    sandwiches,
    main_course,
    dessert,
    cakes,
    mocktails,
    premium_coffee,
    water,
    sourdough,
    baguette,
    danish,
    croissant,
    premium_tea,
    soft_drinks,
    scones,
    afternoon_full_menu,
    full_menu_pastry_ensemble,
    full_menu_scones,
    full_menu_selection_of_premium_tea_coffee,
    enhanced_menu_afternoon_tea_expereince,
    dessertEv,
    cakesEv,
    mocktailsEv,
    sandwichesEv,
    full_menu_pastry_ensembleEv,
    bakeryEv,
    
}