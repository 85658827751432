import { Grid } from "@mui/material"

import { useState } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import salads from "../../../data/ltbCategories/salads";
import {Test, ItemContainerStyled, ItemImageContainerStyled, EnglishArabicFlexStyled, ItemDescriptionContainerStyled, ItemEnglishTitleStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, ArabicContainerStyled, ItemArabicDescriptionStyled, ItemArabicTitleStyled} from "./generalStyles"

function SaladsGrid() {
    const [showDish, setShowDish] = useState(-1);

    function handleClick(id) {
        setShowDish(id);
    }

    if(showDish < 0) {
        return (
            <Grid container spacing = {1} rowSpacing = {0} paddingTop = {3} paddingBottom = {3} width = {"95%"}>
                {/* <Grid item xs = {6}>
                    <div  onClick = {() => handleClick("0")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Salad JPG/Grid JPG/Artichoke salad.jpg"/>
                    <div className="dish-title">Artichoke Salad</div>
                    </div>
                </Grid> */}
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div onClick = {() => handleClick("1")} >
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Salad JPG/Grid JPG/Beetroot salad.jpg"/>
                    <div className="dish-title">Beetroot Salad</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "2" onClick = {() => handleClick("0")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Salad JPG/Grid JPG/Burrata salad.jpg"/>
                    <div className="dish-title">Burrata</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "3" onClick = {() => handleClick("3")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Salad JPG/Grid JPG/Chicken ceaser.jpg"/>
                    <div className="dish-title">Chicken Caesars</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "4" onClick = {() => handleClick("2")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Salad JPG/Grid JPG/Josper salad.jpg"/>
                    <div className="dish-title">Josper Corn</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "5" onClick = {() => handleClick("4")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Salad JPG/Grid JPG/Shrimp salad.jpg"/>
                    <div className="dish-title">Shrimp Caesars</div>
                    </div>
                </Grid>
            </Grid>
        )
    }

    if(showDish >= 0) {
        return (
            <Test>
                <div className = "dish-heading">
                    Salads
                </div>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = {salads[showDish].img_src}/>
                    </ItemImageContainerStyled>
                    <EnglishArabicFlexStyled>
                        <ItemDescriptionContainerStyled>
                            <ItemEnglishTitleStyled>
                                {salads[showDish].name_en}
                                <PriceContainerStyled>
                                    {salads[showDish].price} <span>QR</span>
                                </PriceContainerStyled>
                            </ItemEnglishTitleStyled>
                            <ItemEnglishDescriptionStyled>
                                {salads[showDish].description_en}
                            </ItemEnglishDescriptionStyled>
                            <ArabicContainerStyled>
                                <ItemArabicTitleStyled>
                                    {salads[showDish].name_ar}
                                </ItemArabicTitleStyled>
                                <ItemArabicDescriptionStyled>
                                    {salads[showDish].description_ar}
                                </ItemArabicDescriptionStyled>
                            </ArabicContainerStyled>
                        </ItemDescriptionContainerStyled>
                    </EnglishArabicFlexStyled>
                </ItemContainerStyled>
                <div className = "dish-close-icon" onClick ={()=>setShowDish(-1)}>
                    <FontAwesomeIcon icon = {faXmark}/>
                </div>
            </Test>
        )
    }
}



export default SaladsGrid;