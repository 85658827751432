const dessert = [

    {
        name: "Chocolate fondant",
        name_ar: "شوكولاتة فندان",
        price: "68",
        image_path: "/habra-menu-designs/dessert/Habra Menu- Chocolate fondant.jpg",
    },

    {
        name: "Chocolate mousse",
        name_ar: "موس الشوكولاتة",
        price: "35",
        image_path: "/habra-menu-designs/dessert/Habra Menu- Chocolate Mousse.jpg",
    },

    {
        name: "Coffee sabayon temple",
        name_ar: "معبد القهوة",
        price: "49",
        image_path: "/habra-menu-designs/dessert/Habra Menu- Coffee Sabayan Temple.jpg",
    },

    {
        name: "P&P brownie",
        name_ar: "P&P براوني",
        price: "52",
        image_path: "/habra-menu-designs/dessert/Habra Menu- P&P brownie.jpg",
    },

    {
        name: "Signature custard",
        name_ar: "الكاسترد المميز",
        price: "54",
        image_path: "/habra-menu-designs/dessert/Habra Menu- Signature Custard.jpg",
    },
]

export default dessert;