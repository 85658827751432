let pizzaEn = [

    {
        name: "SHRIMP, ",
        details: " SHAWARMA BUTTER, SAFFRON YOGHURT, SOFT HERBS",
        price: "70",
        img_path: "/baron-menu-designs-2/pizza/01 SHRIMP-1072x800px.jpg"
    },

    {
        name: "MUSAKHAN, ",
        details: " CHICKEN CONFIT, SUMAC, PICKLED ONION, SOFT HERBS, PINE NUTS",
        price: "70",
        img_path: "/baron-menu-designs-2/pizza/02 MUSAKHAN-1072x800px.jpg"
    },

    {
        name: "SOUTZOUKAKI, ",
        details: " BEEF SAUSAGE, JIBNE, SOFT HERBS, MINT, LEMON",
        price: "70",
        img_path: "/baron-menu-designs-2/pizza/03 SOUTZOUKAKI-1072x800px.jpg"
    },
]

let pizzaAr = [

    {
        name: "مسخن، ",
        details: "دجاج كونفيت، سماق، بصل مخلل، أعشاب طازجة، صنوبر ",
        price: "70",
        img_path: "/baron-menu-designs-2/pizza/02 MUSAKHAN-1072x800px.jpg"
    },

    {
        name: "روبيان، ",
        details: "زبدة شاورما، زبادي بالزعفران، أعشاب طازجة ",
        price: "70",
        img_path: "/baron-menu-designs-2/pizza/01 SHRIMP-1072x800px.jpg"
    },

    {
        name: "سوجوكاكي، ",
        details: "سجق لحم بقري، جبنة، أعشاب طازجة، نعناع، ليمون ",
        price: "70",
        img_path: "/baron-menu-designs-2/pizza/03 SOUTZOUKAKI-1072x800px.jpg"
    },
    
]

export {
    pizzaEn,
    pizzaAr,
}