import styled from "styled-components";

function MainCourseEn({}) {
    return (
        <>
            <div>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Main Course Pics/mainCourse- gemista vegan.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>Gemista vegetarian <br/>
                        <ItemDescriptionParStyled>
                            <TextItalicBoxStyled>stuffed tomatoes & capsicum with rice</TextItalicBoxStyled>
                        </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>56 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Main Course Pics/mainCourse- gemista beef.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>Gemista beef<br/>
                        <ItemDescriptionParStyled>
                            <TextItalicBoxStyled>stuffed tomatoes & capsicum with beef</TextItalicBoxStyled>
                        </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>65 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
                <SingleItemContainerStyled>
                    <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Main Course Pics/mainCourse- musaka.png"/>
                    </ImageContainerStyled>
                    <ItemDescriptionStyled>
                        <p>Musaka<br/>
                        <ItemDescriptionParStyled>
                            <TextItalicBoxStyled>potato, egg plant, minced beef & bechamel sauce</TextItalicBoxStyled>
                        </ItemDescriptionParStyled>
                        </p>
                        <PriceBoxStyled>69 QR</PriceBoxStyled>
                    </ItemDescriptionStyled>
                </SingleItemContainerStyled>
            </div>
        </>
    )
}

function MainCourseAr({}) {
    return (
        <>
            <div>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Main Course Pics/mainCourse- gemista vegan.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>غيمستا خضار <br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>طماطم و فلفل حلو محشو بالرز، يقدم مع صلصة الطماطم </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٥٦ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Main Course Pics/mainCourse- gemista beef.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>غيمستا اللحم<br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>طماطم و فلفل حلو محشو بااللحم، يقدم مع صلصة الطماطم </TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٦٥ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
                <SingleItemContainerStyledAr>
                    <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Main Course Pics/mainCourse- musaka.png"/>
                    </ImageContainerStyledAr>
                    <ItemDescriptionArStyled>
                        <p>موساكا<br/>
                        <ItemDescriptionParArStyled>
                            <TextItalicBoxStyled>بطاطا، باذنجان، لحم مفروم و صلصة البيشاميل</TextItalicBoxStyled>
                        </ItemDescriptionParArStyled>
                        </p>
                        <PriceBoxStyled>٦٩ ر.ق</PriceBoxStyled>
                    </ItemDescriptionArStyled>
                </SingleItemContainerStyledAr>
            </div>
        </>
    )
}

const SingleItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
`

const SingleItemContainerStyledAr = styled(SingleItemContainerStyled)`
    flex-direction: row-reverse;
`

const ImageContainerStyled = styled.div`
    position: relative;
    max-width: 100px;
    width: 100%;
    img {
        width: 100%;
      
    }
`
const ImageContainerStyledAr = styled(ImageContainerStyled)`
    transform: scaleX(-1);
`

const ItemDescriptionStyled = styled.div`
    font-family: "Gotham Greek Book";
    font-size: 14px;
    p {
        margin: 0px;
        line-height: 15px;
    }
`
const ItemDescriptionArStyled = styled(ItemDescriptionStyled)`
    font-family: "GE SS Text";
    font-size: 15px;
    text-align: right;
`

const ItemDescriptionParStyled = styled.div`
    padding-right: 20px;
`

const ItemDescriptionParArStyled = styled.div`
    padding-left: 20px;
`

const TextItalicBoxStyled = styled.span`
    font-weight: lighter;
    font-style: italic;
    font-size: 12px;
`
const PriceBoxStyled = styled.div`
    position: relative;
    margin-top: 4px;
    font-weight: bold;
    font-size: 12px;
`

export {
    MainCourseEn,
    MainCourseAr,

};