import styled from "styled-components";
import { GridPageStyled, GridContainerStyled, GridTitleContainerStyled, GridImageContainerStyled, ButtonContainerStyled, ComingSoonTempStyled } from "./GridCommanStyles";
import {Grid} from "@mui/material";
import {LogoContainerStyled, ImageContainerFrontStyled, ImageContainerBackStyled} from "../../pages/Lsd";


function DarLawFirmGrid({}) {

  return (
    <>
            <GridPageStyled>
                <GridTitleContainerStyled>
                Dar Law Firm
                </GridTitleContainerStyled>
                <GridContainerStyled>
                    <Grid container spacing = {2} rowSpacing={2}>
                        <Grid item xs = {12} sm = {12}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/dlf-grid/LSD-Website-DLF-01.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {5}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/dlf-grid/LSD-Website-DLF-04.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {7}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/dlf-grid/LSD-Website-DLF-05.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {7}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/dlf-grid/LSD-Website-DLF-03.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {5}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/dlf-grid/LSD-Website-DLF-02.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {6}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/dlf-grid/LSD-Website-DLF-06.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {6}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/dlf-grid/LSD-Website-DLF-07.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {12}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/dlf-grid/LSD-Website-DLF-08.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                      </Grid>
                </GridContainerStyled>
            </GridPageStyled>
    </>
)
}

export default DarLawFirmGrid;