import { Grid } from "@mui/material";

function SandwichesGrid() {
    return (
        <Grid container spacing = {0.1}>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-menu-designs/Menu-Title-JPG/Menu-Title-01.jpg"/>
                </div>
            </Grid>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}}  src = "/sek-shaqab-menu-designs/Sandwiches JPG/Sandwich-01.jpg"/>
                </div>
            </Grid>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-shaqab-menu-designs/Sandwiches JPG/Sandwich-02.jpg"/>
                </div>
            </Grid>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-shaqab-menu-designs/Sandwiches JPG/Sandwich-03.jpg"/>
                </div>
            </Grid>
        </Grid>
    )
}

export default SandwichesGrid;