const dessert = [

    {
        img_src: "/blt-menu-designs/Dessert JPG/Carousel JPG/Vanilla-Creme-Caramel-1072x801px.jpg",
        name_en: "VANILLA CREAM CARAMEL",
        description_en: "Freshly baked custard with Madagascar vanilla, caramelized sugar served with heavy cream",
        name_ar: "كريم كراميل الفانيليا",
        description_ar: "كاسترد مخبوز مع فانيليا مدغشقر والسكر المكرمل، يقدم مع كريمة ثقيلة",
        price: "52",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Dessert JPG/Carousel JPG/Creme-Brule-1072x801px.jpg",
        name_en: "CREAM BRULEE",
        description_en: "Heavy cooking cream, egg, vanilla extract, caramelized sugar",
        name_ar: "كريم بروليه",
        description_ar: "كريمة طبخ ثقيلة، بيض، خلاصة فانيليا، سكر مكرمل",
        price: "59",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Dessert JPG/Carousel JPG/French-toast-1072x801px.jpg",
        name_en: "FRENCH TOAST",
        description_en: "Caramelized soaked brioche in spiced egg mixture, caramel sauce, fresh berries, vanilla ice cream",
        name_ar: "فرينش توست",
        description_ar: "بريوش بالكراميل منقوع في خليط بيض متبل، صوص كراميل، توت طازج، آيس كريم فانيليا",
        price: "52",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Dessert JPG/Carousel JPG/Cheesecake-Blueberry-1072x801px.jpg",
        name_en: "BLUEBERRY CHEESECAKE",
        description_en: "Baked rich cream cheese in crushed cookies dough, vanilla sour cream, blueberries compote",
        name_ar: "تشيز كيك بالتوت الأزرق",
        description_ar: "جبنة كريمية غنية مخبوزة في عجينة البسكويت المطحونة، كريمة الفانيليا الحامضة وكومبوت التوت الأزرق",
        price: "55",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Dessert JPG/Carousel JPG/Cheesecake-Mango-1072x801px.jpg",
        name_en: "MANGO CHEESECAKE",
        description_en: "Baked rich cream cheese in crushed cookies dough, mango sour cream, mango compote",
        name_ar: "مانجو تشيز كيك",
        description_ar: "جبنة كريمية غنية مخبوزة في عجينة كوكيز مطحونة، كريمة مانجو حامضة وكومبوت مانجو",
        price: "55",
        currency: "QR",

    },

]

export default dessert;