import styled from "styled-components";
import {createTheme} from "@mui/material/styles";


const CategoryContainerStyled = styled.div`
    position: relative;
    top: 120px;
    margin-top: 30px;
`

const GeneralButtonStyled = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "#626262", 
                    color: "white",
                    fontWeight: "600",
                    borderRadius: "15px",
                    transition: "300ms",
                    
                    '&:hover': {
                        backgroundColor: "#ffed00", //lsd yellow
                        color: "#626262",
                    }
                }
            }
        }
    }
})



const ItemButtonsContainerStyled = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-around;
    width: 120px;
    padding: 0px 10px;
    height: 40px;
    right: 8%;
    top: 4%;
    gap: 10px;
    z-index: 1;
    align-items: center;
    transition: 300ms;
`

const ItemButtonStyled = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 40px;
    padding: 10px 10px;
    background-color: white;
    border-radius: 30px;
    scale: 1;
    transition: 300ms;
    color: #585858;
    
  
    &:hover {
        scale: 1.05;
        color:#ffed00;
        background-color:rgb(80,80,80);
    }
`
const ClosePopUpStyled = styled.div`
    position: fixed;
    inset: 0;
    margin:auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 50%;
    height: 50vh;
    background-color: white;
    border-radius: 32px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 2px 10px;
    padding: 10px;
`
const ClosePopButtonStyled = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    top: 0;
    right: 0px;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    background-color: rgb(220, 220, 220); //grey colour
    border-radius: 50%;
    transition:  300ms;
    &:hover {
        color: white;
        background-color: rgb(255, 0, 0); // red color
    }
`

const PopTextStyled = styled.div`
    position: absolute;
    text-align: center;
    min-width: max-content;
    bottom: 120%;
    margin-left: -60px;
    left: 160%;
    z-index: 1;
    display: block;
    padding: 8px 12px !important;
    font-size: 10px !important;
    font-weight: 500;
    line-height: 16px;
    background: rgba(15.16,20,0.7);
    color: #fff;
    border-color: #252833;
    border-radius: 10px;
    border-width: 1px;
    text-align: center;
`
const PopTextCloseStyled = styled(PopTextStyled)`
    bottom: 5%;
    left: 20%;
`

const ArabicContainerStyled = styled.div`
    position: relative;
    margin-top: 20px;
`

const DrinksArabicContainerStyled = styled.div`
    position: relative;
    width: 80%;
    margin: 0 auto;
`

const DrinksArabicTextStyled = styled.div`
    position: relative;
    font-family: "GE SS Text";
    font-size: 12px;
`

const CategoryHeaderStyled = styled.div`
    position: relative;
    font-family: "Professor";
    font-size: 20px;
    width: 100%;
    text-align: center;

    span {

    }
`

const ItemContainerStyled = styled.div`
    position: relative;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const ItemContainerTeaStyled = styled(ItemContainerStyled)`
    flex-direction: row;
    justify-content: space-around;
`

const ItemImageContainerStyled = styled.div`
    position: relative;
    width: 100%;

    img {
        width: 100%;
    }
`

const ItemEnglishTitleStyled = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    font-family: "Futura Std Book";
    font-size: 12px;

    span { 
    }
`

const ItemEnglishTeaTitleStyled = styled(ItemEnglishTitleStyled)`
    justify-content: flex-start;
`

const ItemArabicTitleStyled = styled(ItemEnglishTitleStyled)`
    font-family: "GE SS Text";
    margin-top: -10px;
`

const PriceContainerStyled = styled.div`
    position: absolute;
    right: 5px;
    font-weight: bold;
    font-family: "Futura Std Book";
    font-size: 12px;
    
    span {
        margin-left: 1px;
        font-size: 8px;
        font-weight: 500;
    }
`
const ItemEnglishDescriptionStyled = styled.div`
    position: relative;
    width: 100%;
    text-align: center;
    font-family: "Futura Std Book";
    font-size: 10px;
    
    p { 
        margin: 0;
    }
`

const ItemArabicDescriptionStyled = styled(ItemEnglishDescriptionStyled)`
    font-family: "GE SS Text";
`

const FillerContainerStyled = styled.div`
    position: relative;
    width: 100%;
    height: 50px;
`

//forms stuff
const ButtonWrapperStyled = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 40px 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
`

const FormContainerStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;  
`

const BottomContainerStyled = styled.div`
    position: relative;
    width: 100%;
    scale: 1;
    transition: all 300ms;
    text-align: center;
    
    &:hover {
        scale: 1.05
    }
`

const InputContainerWrapperStyled = styled.div`
    position: relative;
    display: flex;
    gap: 50px;

`

const InputContainerStyled = styled.div`
    position: relative;

`

const FileInput = styled.input`
    display: none;
`

const FileInputImageContainerStyled = styled.div`
    position: relative;
    width: 100px;

    img {
        position: absolute;
        inset: 0;
        width: 100%;
    }
`

const AddOnContainerStyled = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
`

const AddOnFlexBoxStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    gap: 4px;
`

const ArabicAddOnTextStyled = styled.div`
    direction: rtl;
    text-align: right;
`

const SearchBarContainerStyled = styled.div`
    position: fixed;
    display: flex;
    margin: 20px;
    z-index: 10;
    background-color: #626262; //grey colour
    top: 120px;
    width: ${props => props.showSearch ? "200px": "50px"};
    height: 50px;
    box-sizing: border-box;
    border-radius: 25px;
    transition: all 300ms;
    cursor: pointer;
    color: white;

    &:hover {
        color: #626262;
        background-color: #ffed00; //yellow color
        scale: 1.05;
    }
`
const AddItemButtonStyled = styled(SearchBarContainerStyled)`
    top: 190px;
    justify-content: center;
    align-items: center;
`

const HideItemButtonStyled = styled(SearchBarContainerStyled)`
    top: 270px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
`


const SearchTextFieldStyled = styled.input`
    
    width: 100%;
    height: 42.5px;
    line-height: 30px;
    outline: 0;
    border: 0;
    transition: all 300ms;
    font-size: 1em;
    border-radius: 20px;
    padding: 0 20px;
    background-color: rgb(220, 220, 220);

    &:focus {
        border: 1px solid grey;
    }
`

const SearchIconStyled = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    width: 42.5px;
    height: 42.5px;
    padding: 15px 0px;
    padding-right: 5px;
`

const CopyRightStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    padding: 40px 0px;
    margin-top: 80px;
    width: 100%;
    img {
        width: 45%;
    }
`

const LogoutContainerStyled = styled.div`
    position: relative;
    left: 45%;
    transform: translateX(-50%);
    margin-top: 15px;

    span {
        font-size: 14px;
    }
`


export {
    CategoryHeaderStyled,
    ItemContainerStyled,
    ItemContainerTeaStyled,
    ItemEnglishTeaTitleStyled,
    ItemImageContainerStyled,
    ItemEnglishTitleStyled,
    PriceContainerStyled,
    ItemEnglishDescriptionStyled,
    CategoryContainerStyled,
    ItemButtonsContainerStyled,
    ArabicContainerStyled,
    ItemArabicTitleStyled,
    ItemArabicDescriptionStyled,
    DrinksArabicContainerStyled,
    DrinksArabicTextStyled,
    FillerContainerStyled,
    GeneralButtonStyled,
    ItemButtonStyled,
    ClosePopUpStyled,
    ClosePopButtonStyled,
    PopTextStyled,
    PopTextCloseStyled,
    ButtonWrapperStyled,
    FormContainerStyled,
    BottomContainerStyled,
    InputContainerWrapperStyled,
    InputContainerStyled,
    FileInput,
    FileInputImageContainerStyled,
    AddOnContainerStyled,
    AddOnFlexBoxStyled,
    ArabicAddOnTextStyled,
    SearchBarContainerStyled,
    AddItemButtonStyled,
    HideItemButtonStyled,
    SearchTextFieldStyled,
    SearchIconStyled,
    CopyRightStyled,
    LogoutContainerStyled,
}