let pastaDumplingsEn = [
    {
        name: "CAPELLINI, ",
        details: " KEFTEDAKI-MEATBALLS, TOMATO, CREAM, PARMESAN, OREGANO",
        price: "85",
        img_path: "/baron-menu-designs-2/pasta&dumplings/01 CAPELLINI-1072x800px.jpg"
    },

    {
        name: "CAVATELLI, ",
        details: " MUSHROOM, PARMESAN, CHEESE SAUCE, CHIVES",
        price: "70",
        img_path: "/baron-menu-designs-2/pasta&dumplings/02 CAVATELLI-1072x800px.jpg"
    },

    {
        name: "GNIOCCHI, ",
        details: " SWEET POTATO, BEEF BACON, PARMESAN",
        price: "70",
        img_path: "/baron-menu-designs-2/pasta&dumplings/03 GNIOCCHI-1072x800px.jpg"
    },

    {
        name: "GNUDI, ",
        details: " HAND-MADE RICOTTA GNOCCHI, BOLOGNESE, TOMATO SAUCE, PARMESAN",
        price: "85",
        img_path: "/baron-menu-designs-2/pasta&dumplings/04 GNUDI-1072x800px.jpg"
    },

    {
        name: "SHISH BARAK, ",
        details: " BAHARAT BEEF DUMPLINGS, YOGHURT, TOMATO, PINE NUTS",
        price: "75",
        img_path: "/baron-menu-designs-2/pasta&dumplings/05 Shish barak-72dpi.jpg"
    },

    {
        name: "MOMOS, ",
        details: " BAHARAT CHICKEN DUMPLINGS, TOMATO HARISSA SAUCE, CRISP SHALLOT",
        price: "55",
        img_path: "/baron-menu-designs-2/pasta&dumplings/06 MOMOS-1072x800px.jpg"
    },

    {
        name: "RISOTTO, ",
        details: " TRUFFLE, MUSHROOM, PARMESAN, CRISP SHALLOT, CHIVES",
        price: "85",
        img_path: "/baron-menu-designs-2/pasta&dumplings/07 RISOTTO-1072x800px.jpg"
    },
]

let pastaDumplingsAr = [
    
    {
        name: "كافاتيللي، ",
        details: "مشروم، بارميزان، صلصة الجبن، بصل ",
        price: "70",
        img_path: "/baron-menu-designs-2/pasta&dumplings/02 CAVATELLI-1072x800px.jpg"
    },

    {
        name: "كابيليني، ",
        details: "كرات لحم كيفتداكي، صلصة الطماطم، الكريمة، بارميزان، أوريجانو ",
        price: "85",
        img_path: "/baron-menu-designs-2/pasta&dumplings/01 CAPELLINI-1072x800px.jpg"
    },

    {
        name: "نودي، ",
        details: "ريكوتا محلي الصنع، بولونيز، صلصة الطماطم، بارميزان ",
        price: "85",
        img_path: "/baron-menu-designs-2/pasta&dumplings/04 GNUDI-1072x800px.jpg"
    },

    {
        name: "نيوكي، ",
        details: "بطاطا حلوة، لحم بقري، بارميزان ",
        price: "70",
        img_path: "/baron-menu-designs-2/pasta&dumplings/03 GNIOCCHI-1072x800px.jpg"
    },

    {
        name: "موموس، ",
        details: "دجاج مبهر، صلصة طماطم، هريسة صوص، بصل كويزات مقرمش ",
        price: "55",
        img_path: "/baron-menu-designs-2/pasta&dumplings/06 MOMOS-1072x800px.jpg"
    },

    {
        name: "شيش برك، ",
        details: "لحمة مبهرة، لبن مدخن، طماطم، صنوبر ",
        price: "75",
        img_path: "/baron-menu-designs-2/pasta&dumplings/05 Shish barak-72dpi.jpg"
    },

    {
        name: "ريزوتو ",
        details: "كمأة، مشروم، بارميزان، كراث مقرمش، بصل أخضر ",
        price: "85",
        img_path: "/baron-menu-designs-2/pasta&dumplings/07 RISOTTO-1072x800px.jpg"
    },
]

export {
    pastaDumplingsAr,
    pastaDumplingsEn,
}