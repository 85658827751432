const soups = [
    {
        img_src: "/blt-menu-designs/Soups JPG/Carousel JPG/Chicken-Soup-1072x801px.jpg",
        name_en: "CHICKEN SOUP",
        description_en: "Potato, thyme, chicken breast, shiitake mushroom, mustard, chicken stock",
        name_ar: "شوربة الدجاج",
        description_ar: "بطاطس، زعتر, صدر دجاج، فطر شيتاكي, خردل، مرق دجاج",
        price: "58",
        currency: "QR",

    },

    {
        img_src: "/blt-menu-designs/Soups JPG/Carousel JPG/French-Soup-1072x801px.jpg",
        name_en: "FRENCH SOUP",
        description_en: "Baked french onion soup with beef stock, chicken stock, melted emmental cheese",
        name_ar: "شوربة البصل الفرنسية",
        description_ar: "شوربة بصل فرنسي, مرق لحمة، مرق دجاج, جبن امنتال ذائبة",
        price: "56",
        currency: "QR",
        
    },

    {
        img_src: "/blt-menu-designs/Soups JPG/Carousel JPG/Tomato-Soup-1072x801px.jpg",
        name_en: "TOMATO SOUP",
        description_en: "Tomato soup with yuzu reduction, whipping cream",
        name_ar: "شوربة الطماطم",
        description_ar: "شوربة الطماطم مع اليوزو والكريمة",
        price: "56",
        currency: "QR",
        
    }
]


export default soups;