import {  GeneralButtonStyled, PopTextStyled, ItemContainerStyled, ItemEnglishTitleStyled, ItemImageContainerStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, CategoryContainerStyled, ArabicContainerStyled, ItemArabicTitleStyled, ItemArabicDescriptionStyled, FillerContainerStyled, ItemButtonsContainerStyled, ItemButtonStyled, ClosePopUpStyled, ClosePopButtonStyled, AddOnFlexBoxStyled, ArabicAddOnTextStyled } from "../CommonStyled";
import {useState} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faPenToSquare, faXmark, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons"
import {Link} from "react-router-dom";
import axios from "axios"
import { Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";


function CarteCakes({carteCakes, showHiddenItems, auth}) {

    const url = "https://www.lovelysunnydayapi.com/api/v1/testMenus"; //make this link dynamic

    const [popUpOpen, setPopUpOpen] = useState(false);
    const [itemID, setItemID] = useState("");
    const [itemDelete, setItemDelete] = useState("");

    const[triggerToolTip, setTriggerToolTip] = useState({
        editTool: false,
        deleteTool: false,
        hideTool: false,
    });

  
    //function to trigger the pop up screen of the corresponding item
    function handleItemPopUp(_id, itemName) {
        //opens the pop up
        setPopUpOpen(true);
        setItemDelete(itemName);
        //set id here
        setItemID(_id)
    }

    //function to close pop up screen
    function handleClosePopUp() {
        setPopUpOpen(false);
    }

    //function to toggle the tooltips on hover
    function handleToolTip(visible, tooltip) {
        switch (tooltip) {
            case "delete":
                setTriggerToolTip(prevState => ({
                    ...prevState,
                    deleteTool: visible? "true" : false
                }))
            break;

            case "edit":
                setTriggerToolTip(prevState => ({
                    ...prevState,
                    editTool:visible? "true" : false
                }))
            break;

            case "hide":
                setTriggerToolTip(prevState => ({
                    ...prevState,
                    hideTool: visible? "true" : false
                }))
            break;
        }
    }
    
    //updates the hide field of the item in the database
    async function handleHideItem(item_id, show) {
        const link = `${url}/${item_id}`
        const item_to_change = carteCakes.find(item => item._id === item_id)
        item_to_change.hide = show;
        const response = await axios.patch(link, item_to_change, {
            headers: {
                'Content-Type': `application/json`,
            },
            withCredentials: true
        })
    }

    //this function will delete an item when a user clicks on the close button
    async function handleDeleteItem() {
        const link = `${url}/${itemID}`
        const response = await axios.delete(link, {
            withCredentials: true,
        })
        console.log("item deleted successfully", response.data) //maybe add a pop up or something
        setPopUpOpen(false);
    }

    return (
        <>
            {popUpOpen && <ClosePopUpStyled>
                    Are you sure you want to delete {itemDelete}?
                  
                    <ThemeProvider theme = {GeneralButtonStyled}>
                        <Button
                            variant = "contained"
                            color = "primary"
                            onClick = {handleDeleteItem}
                        >
                            YES
                        </Button>
                    </ThemeProvider>
                    <ClosePopButtonStyled 
                        onClick = {handleClosePopUp}
                        onMouseEnter={() => handleToolTip(true, "close")}
                        onMouseLeave={() => handleToolTip(false, "close")}
                    >
                        <FontAwesomeIcon icon = {faXmark} style = {{fontSize: "20px", padding: "0"}}>
                        </FontAwesomeIcon>
                    </ClosePopButtonStyled>
            </ClosePopUpStyled>}
            {!showHiddenItems && <CategoryContainerStyled>
                {
                    carteCakes.map(({_id, name_en, name_ar, description_en, description_ar, price, image, add_ons, hide}) => {
                        return (
                            !hide && <ItemContainerStyled>
                                {auth.role === "le-bleu" && <ItemButtonsContainerStyled>
                                <ItemButtonStyled
                                        onMouseEnter = {() => handleToolTip(true, "hide")}
                                        onMouseLeave = {() => handleToolTip(false, "hide")}
                                        onClick = {() => handleHideItem(_id, true)}
                                    >
                                        {triggerToolTip.hideTool && <PopTextStyled  className = "animate__animated animate__fadeInUp animate__faster">hide</PopTextStyled>}
                                        <FontAwesomeIcon icon = {faEyeSlash} style = {{fontSize: "20px", padding: "0"}}/>
                                    </ItemButtonStyled>
                                    <ItemButtonStyled
                                        onMouseEnter = {() => handleToolTip(true, "edit")}
                                        onMouseLeave = {() => handleToolTip(false, "edit")}
                                    >
                                        {triggerToolTip.editTool && <PopTextStyled  className = "animate__animated animate__fadeInUp animate__faster">edit</PopTextStyled>}
                                        <Link to = {`/restaurants/menus/le-bleu/admin/edit-item/${_id}`} target = "_blank">
                                            <FontAwesomeIcon icon = {faPenToSquare} style = {{fontSize: "20px", padding: "0"}}/>
                                        </Link>
                                    </ItemButtonStyled>
                                    <ItemButtonStyled 
                                        onClick = {() => handleItemPopUp(_id, name_en)}
                                        onMouseEnter = {() => handleToolTip(true, "delete")}
                                        onMouseLeave = {() => handleToolTip(false, "delete")}
                                    >
                                        {triggerToolTip.deleteTool && <PopTextStyled className = "animate__animated animate__fadeInUp animate__faster" >delete</PopTextStyled>}
                                        <FontAwesomeIcon icon = {faTrashCan}  style = {{fontSize: "20px", padding: "0px"}}/>
                                    </ItemButtonStyled>
                                </ItemButtonsContainerStyled>}
                                <ItemImageContainerStyled>
                                    <img src = {image} alt = {name_en} />
                                </ItemImageContainerStyled>
                                <ItemEnglishTitleStyled>
                                    {name_en}
                                    <PriceContainerStyled>
                                    {price}
                                    <span>QR</span>
                                </PriceContainerStyled>
                                </ItemEnglishTitleStyled>
                                <ItemEnglishDescriptionStyled>
                                    {description_en}
                                    
                                    {add_ons.length != 0 && <AddOnFlexBoxStyled>
                                        Add: 
                                        {add_ons.map(({name_en, price}) => {
                                            return (
                                                <div>{name_en} / {price} QR •</div>
                                            )
                                        })}
                                    </AddOnFlexBoxStyled>}
                                </ItemEnglishDescriptionStyled>
                                <ArabicContainerStyled>
                                    <ItemArabicTitleStyled>
                                        {name_ar}
                                    </ItemArabicTitleStyled>
                                    <ItemArabicDescriptionStyled>
                                        {description_ar}
                                        {add_ons.length != 0 && <AddOnFlexBoxStyled>
                                          
                                        {add_ons.map(({name_ar, price}) => {
                                            return (
                                                <ArabicAddOnTextStyled>{name_ar} / {price} ر.ق • </ArabicAddOnTextStyled>
                                            )
                                        })}
                                          :اضافة
                                    </AddOnFlexBoxStyled>}
                                    </ItemArabicDescriptionStyled>
                                </ArabicContainerStyled>
                                <FillerContainerStyled/>
                            </ItemContainerStyled>
                        )
                    })
                }
            </CategoryContainerStyled>}
            {showHiddenItems && <CategoryContainerStyled>
                {
                    carteCakes.map(({_id, name_en, name_ar, description_en, description_ar, price, image, add_ons, hide}) => {
                        return (
                            hide && <ItemContainerStyled>
                                {auth.role === "le-bleu" && <ItemButtonsContainerStyled>
                                <ItemButtonStyled
                                        onMouseEnter = {() => handleToolTip(true, "hide")}
                                        onMouseLeave = {() => handleToolTip(false, "hide")}
                                        onClick = {() => handleHideItem(_id, false)}
                                    >
                                        {triggerToolTip.hideTool && <PopTextStyled  className = "animate__animated animate__fadeInUp animate__faster">recover</PopTextStyled>}
                                        <FontAwesomeIcon icon = {faEye} style = {{fontSize: "20px", padding: "0"}}/>
                                    </ItemButtonStyled>
                                    <ItemButtonStyled
                                        onMouseEnter = {() => handleToolTip(true, "edit")}
                                        onMouseLeave = {() => handleToolTip(false, "edit")}
                                    >
                                        {triggerToolTip.editTool && <PopTextStyled  className = "animate__animated animate__fadeInUp animate__faster">edit</PopTextStyled>}
                                        <Link to = {`/restaurants/menus/le-bleu/admin/edit-item/${_id}`} target = "_blank">
                                            <FontAwesomeIcon icon = {faPenToSquare} style = {{fontSize: "20px", padding: "0"}}/>
                                        </Link>
                                    </ItemButtonStyled>
                                    <ItemButtonStyled 
                                        onClick = {() => handleItemPopUp(_id)}
                                        onMouseEnter = {() => handleToolTip(true, "delete")}
                                        onMouseLeave = {() => handleToolTip(false, "delete")}
                                    >
                                        {triggerToolTip.deleteTool && <PopTextStyled className = "animate__animated animate__fadeInUp animate__faster" >delete</PopTextStyled>}
                                        <FontAwesomeIcon icon = {faTrashCan}  style = {{fontSize: "20px", padding: "0px"}}/>
                                    </ItemButtonStyled>
                                </ItemButtonsContainerStyled>}
                                <ItemImageContainerStyled>
                                    <img src = {image} alt = {name_en} />
                                </ItemImageContainerStyled>
                                <ItemEnglishTitleStyled>
                                    {name_en}
                                    <PriceContainerStyled>
                                    {price}
                                    <span>QR</span>
                                </PriceContainerStyled>
                                </ItemEnglishTitleStyled>
                                <ItemEnglishDescriptionStyled>
                                    {description_en}
                                    
                                    {add_ons.length != 0 && <AddOnFlexBoxStyled>
                                        Add: 
                                        {add_ons.map(({name_en, price}) => {
                                            return (
                                                <div>{name_en} / {price} QR •</div>
                                            )
                                        })}
                                    </AddOnFlexBoxStyled>}
                                </ItemEnglishDescriptionStyled>
                                <ArabicContainerStyled>
                                    <ItemArabicTitleStyled>
                                        {name_ar}
                                    </ItemArabicTitleStyled>
                                    <ItemArabicDescriptionStyled>
                                        {description_ar}
                                        {add_ons.length != 0 && <AddOnFlexBoxStyled>
                                          
                                        {add_ons.map(({name_ar, price}) => {
                                            return (
                                                <ArabicAddOnTextStyled>{name_ar} / {price} ر.ق • </ArabicAddOnTextStyled>
                                            )
                                        })}
                                          :اضافة
                                    </AddOnFlexBoxStyled>}
                                    
                                    </ItemArabicDescriptionStyled>
                                </ArabicContainerStyled>
                                <FillerContainerStyled/>
                            </ItemContainerStyled>
                        )
                    })
                }
            </CategoryContainerStyled>}
        </>
    )
}

export default CarteCakes;