import {CarteCakes, CarteCoffee, CarteDessert, CarteHotStarter, CarteMainCourse, CarteMocktails, CartePasta, CarteSalad, CarteSandwiches, CarteSoftDrinks, CarteSoup, CarteTea, CarteWater} from "./"
import { SearchBarContainerStyled, AddItemButtonStyled, HideItemButtonStyled, SearchTextFieldStyled, SearchIconStyled, CopyRightStyled, LogoutContainerStyled, GeneralButtonStyled } from "../CommonStyled";
import {useState, useEffect, useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark, faEye, faPlus, faEyeSlash} from "@fortawesome/free-solid-svg-icons"
import axios from "axios";
import {Link} from "react-router-dom";
import styled from "styled-components";
import { TailSpin } from "react-loader-spinner";
import {ThemeProvider} from "@mui/material/styles"
import {Button} from "@mui/material";

function Carte({carteEls, navBarTwoRef, auth, handleLogout}) {

    const [carteItems, setCarteItems] = useState({
        "SOUP": [],
        "SALAD": [],
        "HOT STARTER": [],
        "SANDWICHES": [],
        "MAIN COURSE": [],
        "PASTA & RISOTTO": [],
        "DESSERT": [],
        "CAKES": [],
        "MOCKTAILS": [],
        "PREMIUM COFFEE": [],
    })

    const [searchQuery, setSearchQuery] = useState("");
    const [showSearch, setShowSearch] = useState(false);
    const [showHiddenItems, setShowHiddenItems] = useState(false);
    const url = "https://www.lovelysunnydayapi.com/api/v1/testMenus";
    const [loading, setLoading] = useState(true);

    let categoriesIndices = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', ]
    const carteSections = useRef([]);

    //tracks the sections id as we scroll
    function windowScrollTracker(currentScroll) {
        var sectionFromTop;
        var sectionId;
        //get id of the current active category as we scroll
        carteSections.current.forEach((section) => {
            sectionFromTop = section.getBoundingClientRect().top;

            if(sectionFromTop <= 10) {
                sectionId = section.getAttribute("id");
            }
        })

        //pass the current active section's id to the nav bar tracker function
        navBarTracker(sectionId)
    }

    //changes the colour of the active category
    function activeCategoryHandler(sectionId) {
        var index = sectionId - 1;
        carteEls.current[index].style.color = "#F5A800"

        categoriesIndices.map((item) => {
            if(item != index) {
                carteEls.current[item].style.color = "white"
            }
        })
    }

    async function navBarTracker(sectionId) {
       
        switch(sectionId) {
            //activates the soup category
            case '1':
                navBarTwoRef.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates the salad category
            case '2':
                navBarTwoRef.current.scrollLeft = 50;
                await activeCategoryHandler(sectionId)
            break;

            //activates the hot starter category
            case '3':
                navBarTwoRef.current.scrollLeft = 50;
                await activeCategoryHandler(sectionId)
            break;

            //activates the sandwiches category
            case '4':
                navBarTwoRef.current.scrollLeft = 190;
                await activeCategoryHandler(sectionId)
            break;

            //activates the main course category
            case '5':
                navBarTwoRef.current.scrollLeft = 340;
                await activeCategoryHandler(sectionId)
            break;

            //activates the pasta & risotto category
            case '6':
                navBarTwoRef.current.scrollLeft = 510;
                await activeCategoryHandler(sectionId)
            break;

            //activates the dessert category
            case '7':
                navBarTwoRef.current.scrollLeft = 640;
                await activeCategoryHandler(sectionId)
            break;

            //activates the cakes category
            case '8':
                navBarTwoRef.current.scrollLeft = 740;
                await activeCategoryHandler(sectionId)
            break;

            //activates the mocktails category
            case '9':
                navBarTwoRef.current.scrollLeft = 840;
                await activeCategoryHandler(sectionId)
            break;

            //activates the soft drinks category
            case '10':
                navBarTwoRef.current.scrollLeft = 970;
                await activeCategoryHandler(sectionId)
            break;

            //activates the tea category
            case '11':
                navBarTwoRef.current.scrollLeft = 1110;
                await activeCategoryHandler(sectionId)
            break;

            //activates the coffee category
            case '12':
                navBarTwoRef.current.scrollLeft = 1300;
                await activeCategoryHandler(sectionId)
            break;

            //activates the water category
            case '13':
                navBarTwoRef.current.scrollLeft = 1500;
                await activeCategoryHandler(sectionId)
            break;

            //activates the water category
          
        }

        return "resolved"
    }

    //triggers the search bar when the user clicks on the search icon
    function handleSearchBar() {
        setShowSearch(true);
    }


    //function that sets the
    function handleSearchQuery(event) {
        setSearchQuery(event.target.value);
    }

    function handleShowHidden() {
        setShowHiddenItems(!showHiddenItems);
    }

    async function getAllItems() {
        try {
            const link = `${url}?search=${searchQuery}&category=A LA CARTE`
            const {data} = await axios.get(link, {
                withCredentials: true,
            })

            setCarteItems({
                "SOUP": [],
                "SALAD": [],
                "HOT STARTER": [],
                "SANDWICHES": [],
                "MAIN COURSE": [],
                "PASTA & RISOTTO": [],
                "DESSERT": [],
                "CAKES": [],
                "MOCKTAILS": [],
                "PREMIUM COFFEE": [],
            })

            data.items.map((item) => {
                switch(item.sub_category) {
                    case "SOUP":
                        setCarteItems(prevItems => ({
                            ...prevItems,
                            "SOUP": [...prevItems["SOUP"], item]
                        }))
                    break;

                    case "SALAD":
                        setCarteItems(prevItems => ({
                            ...prevItems,
                            "SALAD": [...prevItems["SALAD"], item]
                        }))
                    break;

                    case "HOT STARTER":
                        setCarteItems(prevItems => ({
                            ...prevItems,
                            "HOT STARTER": [...prevItems["HOT STARTER"], item]
                        }))
                    break;

                    case "SANDWICHES":
                        setCarteItems(prevItems => ({
                            ...prevItems,
                            "SANDWICHES": [...prevItems["SANDWICHES"], item]
                        }))
                    break;

                    case "MAIN COURSE":
                        setCarteItems(prevItems => ({
                            ...prevItems,
                            "MAIN COURSE": [...prevItems["MAIN COURSE"], item]
                        }))
                    break;

                    case "PASTA & RISOTTO":
                        setCarteItems(prevItems => ({
                            ...prevItems,
                            "PASTA & RISOTTO": [...prevItems["PASTA & RISOTTO"], item]
                        }))
                    break;

                    case "DESSERT":
                        setCarteItems(prevItems => ({
                            ...prevItems,
                            "DESSERT": [...prevItems["DESSERT"], item]
                        }))
                    break;

                    case "CAKES":
                        setCarteItems(prevItems => ({
                            ...prevItems,
                            "CAKES": [...prevItems["CAKES"], item]
                        }))
                    break;

                    case "MOCKTAILS":
                        setCarteItems(prevItems => ({
                            ...prevItems,
                            "MOCKTAILS": [...prevItems["MOCKTAILS"], item]
                        }))
                    break;

                    case "PREMIUM COFFEE":
                        setCarteItems(prevItems => ({
                            ...prevItems,
                            "PREMIUM COFFEE": [...prevItems["PREMIUM COFFEE"], item]
                        }))
                    break;

                }
            })

            setLoading(false);

        } catch (error) {
            console.log("encountered an error");
        }
    }

       //fetches the items from the database on first render and whenever we change the search query
       useEffect(() => {
        getAllItems();
    }, [searchQuery])

    useEffect(() => {
        function handleScroll(e) {
            var currentScroll = window.pageYOffset;
            windowScrollTracker(currentScroll)
        }

        var timer = null;

        function scrollHandler() {
            if(timer !== null) {
                clearTimeout(timer);
            }
            timer = setTimeout(function () {
                handleScroll()
            }, 50)
        }

        window.addEventListener("scroll", scrollHandler)

        //clean event
        return () => {
            window.removeEventListener("scroll", scrollHandler)
        }
    }, [])

    if(loading) {
        return (
            <>
                <LoadingContainerStyled>
                    <TailSpin
                            visible={true}
                            height="40"
                            width="40"
                            color="#006580"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                        >
                        </TailSpin>
                </LoadingContainerStyled>
            </>
        )
    }

    if(!loading) {
        return (
            <>
                <Link
                    to = "/restaurants/menus/le-bleu/admin/add-item"
                    target = "_blank"
                >
                    {auth.role === "le-bleu" && <AddItemButtonStyled>
                        <FontAwesomeIcon icon = {faPlus} style = {{fontSize: "20px", padding: "0"}} />
                    </AddItemButtonStyled>}
                </Link>
                {auth.role === "le-bleu" && <HideItemButtonStyled onClick = {handleShowHidden}>
                    <FontAwesomeIcon icon = {!showHiddenItems ? faEyeSlash : faEye} style = {{fontSize: "20px", padding: "0"}}/>
                </HideItemButtonStyled>}
                {auth.role === "le-bleu" && <SearchBarContainerStyled showSearch={showSearch} onChange={handleSearchQuery} value={searchQuery}>
                {!showSearch ? <SearchIconStyled onClick = {handleSearchBar} 
                >
                        <FontAwesomeIcon
                            icon = {faMagnifyingGlass} style = {{fontSize: "20px", padding: "0"}}
                        />
                       
                    </SearchIconStyled> : <SearchIconStyled>
                        <FontAwesomeIcon icon = {faXmark} onClick = {() => setShowSearch(false)} />
                        </SearchIconStyled>}
                    {showSearch && <SearchTextFieldStyled placeholder = "Search here..."/>}
                </SearchBarContainerStyled>}
                { carteItems["SOUP"] && carteItems["SOUP"].length !=0 && 
                    <section id = "1" ref = {(el) => carteSections.current[0] = el}>
                         <CarteSoup carteSoup = {carteItems["SOUP"]} showHiddenItems= {showHiddenItems} auth = {auth}/>
                    </section>}
                { carteItems["SALAD"] && carteItems["SALAD"].length !=0 && 
                    <section id = "2" ref = {(el) => carteSections.current[1] = el}>
                            <CarteSalad carteSalad = {carteItems["SALAD"]} showHiddenItems= {showHiddenItems} auth = {auth}/>
                    </section>}
                { carteItems["HOT STARTER"] && carteItems["HOT STARTER"].length !=0 && 
                <section id = "3" ref = {(el) => carteSections.current[2] = el}>
                        <CarteHotStarter carteHotStarter = {carteItems["HOT STARTER"]} showHiddenItems= {showHiddenItems} auth = {auth}/>
                </section>}
                { carteItems["SANDWICHES"] && carteItems["SANDWICHES"].length !=0 && 
                <section id = "4" ref = {(el) => carteSections.current[3] = el}>
                        <CarteSandwiches carteSandwiches = {carteItems["SANDWICHES"]} showHiddenItems= {showHiddenItems} auth = {auth}/>
                </section>}
                { carteItems["MAIN COURSE"] && carteItems["MAIN COURSE"].length !=0 && 
                <section id = "5" ref = {(el) => carteSections.current[4] = el}>
                        <CarteMainCourse carteMainCourse = {carteItems["MAIN COURSE"]} showHiddenItems= {showHiddenItems} auth = {auth}/>
                </section>}
                { carteItems["PASTA & RISOTTO"] && carteItems["PASTA & RISOTTO"].length !=0 && 
                <section id = "6" ref = {(el) => carteSections.current[5] = el}>
                        <CartePasta cartePasta = {carteItems["PASTA & RISOTTO"]} showHiddenItems= {showHiddenItems} auth = {auth}/>
                </section>}
                { carteItems["DESSERT"] && carteItems["DESSERT"].length !=0 && 
                <section id = "7" ref = {(el) => carteSections.current[6] = el}>
                        <CarteDessert carteDessert = {carteItems["DESSERT"]} showHiddenItems= {showHiddenItems} auth = {auth}/>
                </section>}
                { carteItems["CAKES"] && carteItems["CAKES"].length !=0 && 
                <section id = "8" ref = {(el) => carteSections.current[7] = el}>
                        <CarteCakes carteCakes = {carteItems["CAKES"]} showHiddenItems= {showHiddenItems} auth = {auth}/>
                </section>}
                { carteItems["MOCKTAILS"] && carteItems["MOCKTAILS"].length !=0 && 
                <section id = "9" ref = {(el) => carteSections.current[8] = el}>
                        <CarteMocktails carteMocktails = {carteItems["MOCKTAILS"]} showHiddenItems= {showHiddenItems} auth = {auth}/>
                </section>}
                <section id = "10" ref = {(el) => carteSections.current[9] = el}>
                        <CarteSoftDrinks/>
                </section>
                <section id = "11" ref = {(el) => carteSections.current[10] = el}>
                        <CarteTea/>
                </section>
                { carteItems["PREMIUM COFFEE"] && carteItems["PREMIUM COFFEE"].length !=0 && 
                <section id = "12" ref = {(el) => carteSections.current[11] = el}>
                        <CarteCoffee carteCoffee = {carteItems["PREMIUM COFFEE"]} showHiddenItems= {showHiddenItems} auth = {auth}/>
                </section>}
                <section id = "13" ref = {(el) => carteSections.current[12] = el}>
                        <CarteWater/>
                </section>
                <CopyRightStyled style = {{marginTop: "110px"}}>
                    <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                        <img src = "/Developed-By-LSD.svg"/>
                    </a>
                    <LogoutContainerStyled>
                        <ThemeProvider theme = {GeneralButtonStyled}>
                            <Button
                                onClick = {handleLogout}
                                variant = "contained"
                            >
                                {auth.role === "le-bleu" ? <span>LOG OUT</span> : <span>ADMIN</span>}
                            </Button>
                        </ThemeProvider>
                    </LogoutContainerStyled>
                </CopyRightStyled>
            </>
        )
    }
}

const LoadingContainerStyled = styled.div`
    position: fixed;
    inset: 0;
    top: 120px;
    display: flex;
    height: 100vh;
    background-color: white;
    justify-content: center;
    align-items: center;
    z-index:10;

`

export default Carte;