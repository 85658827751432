import styled from "styled-components";
import { GridPageStyled, GridContainerStyled, GridTitleContainerStyled, GridImageContainerStyled, ButtonContainerStyled, ComingSoonTempStyled } from "./GridCommanStyles";
import {Grid} from "@mui/material";
import {LogoContainerStyled, ImageContainerFrontStyled, ImageContainerBackStyled} from "../../pages/Lsd";


function AssamGrid({setTriggerGrid, setIsClicked, isClicked}) {

  return (
    <>
            <GridPageStyled>
                <GridTitleContainerStyled>
                Assam Fragrance
                </GridTitleContainerStyled>
                <GridContainerStyled>
                    <Grid container spacing = {2} rowSpacing={2}>
                        <Grid item xs = {12} sm = {12}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/assam-grid/LSD-Website-Assam-01.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {8}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/assam-grid/LSD-Website-Assam-02.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {4}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/assam-grid/LSD-Website-Assam-03.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {4}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/assam-grid/LSD-Website-Assam-04.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {8}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/assam-grid/LSD-Website-Assam-05.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {12}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/assam-grid/LSD-Website-Assam-06.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {6}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/assam-grid/LSD-Website-Assam-07.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {6}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/assam-grid/LSD-Website-Assam-08.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {12}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/assam-grid/LSD-Website-Assam-09.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        {/* <Grid item xs = {12} sm = {12}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/assam-grid/LSD-Website-Assam-" style = {{marginBottom: "20px"}}/>
                            </GridImageContainerStyled>
                        </Grid> */}
                      </Grid>
                </GridContainerStyled>
            </GridPageStyled>
    </>
)
}



export default AssamGrid;