import styled from "styled-components";
import { LeBleuPageStyled } from "../styles";
import { BreakfastEggs, BreakfastToasts, BreakfastMuffins, BreakfastYogurt, CarteSoupsGrid, CarteHotStarter, CarteSandwiches, CarteMainCourseGrid, CartePastaAndRisotto, CarteDesserts, CarteCakes, CarteMocktails, CarteDrinks, CarteTea, CarteCoffee, CarteWater, BoutiqueSourdough, BoutiqueBaguette, BoutiqueDanish, BoutiqueCroissant, BoutiqueScones, CoverContainer, AfternoonFullMenu, AfternoonEnhancedMenu, AfternoonCreamMenu} from "../components/MenuGrids/lbMenu";
import {CarteSaladEv, CarteDessertEv, CarteCakeEv, CarteMocktailsEv, CarteSandwichesEv, CarteBakery, CoverContainerEv, BreakfastEggsEv, BreakFastToastsEv} from "../components/MenuGrids/lbEventMenu"
import { useEffect, useRef, useState} from "react";
import { FillerContainerStyled } from "../components/MenuGrids/lbMenu/CommonStyled";


function LeBleuMenuEv({}) {

    const [showCover, setShowCover] = useState(true);
    const [subNavBar, setSubNavBar] = useState('1');
    const [triggerCategory, setTriggerCategory] = useState("a1");
    const pagePositionRef = useRef({});
    const navBarRef = useRef({});
    const navBarTwoRef = useRef({});
    const sliderContainerRef = useRef({});
    const sliderContainerTwoRef = useRef({});

    const navEls = useRef({});
    const breakfastEls = useRef({});
    const carteEls = useRef({});
    const boutiqueEls = useRef({});
    const afternoon = useRef({});

    // function windowScrollTracker(currentScroll) {
    //     if(navBarRef.current.getBoundingClientRect().top < 0) {
    //         navBarRef.current.style.position = "fixed";
    //         navBarRef.current.style.inset = "0";
    //         sliderContainerRef.current.style.position = "fixed";
    //         sliderContainerTwoRef.current.style.position = "fixed";
    //     }

    //     if(pagePositionRef.current.getBoundingClientRect().top > 0) {
    //         navBarRef.current.style.position = "relative";
    //         sliderContainerRef.current.style.position = "absolute";
    //         sliderContainerTwoRef.current.style.position = "absolute";
    //     }
    // }

    // function scrollToTop(elementRef) {
    //     const elementRect = elementRef.current.getBoundingClientRect();
    //     const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    //     const topPosition = elementRect.top + scrollTop;

    //     window.scrollTo({
    //         top: topPosition,
    //         behavior: "smooth"
    //     });
    // }

    function handleNavBar(id) {
        // scrollToTop(navBarRef)
        console.log(id);
        navEls.current[id].style.color = "#F5A800";
        for (const otherKey in navEls.current) {
            console.log("the other key is: ", otherKey);
            if(otherKey !== id) {
                navEls.current[otherKey].style.color = "white";
            }
        }
        switch(id) {

            case '0':
                setSubNavBar('0'); 
                setTriggerCategory("a1");
                setTimeout(() => {
                    breakfastEls.current[0].style.color = "#F5A800";
                }, 0)

            break;
            
            case '1':
                setSubNavBar('1');
  
                setTriggerCategory("b1");
                setTimeout(() => {
                    carteEls.current[0].style.color = "#F5A800";
                }, 0)
            break;

            case '2':
                setSubNavBar('2');
      
                setTriggerCategory("c1");
                setTimeout(() => {
                    boutiqueEls.current[0].style.color = "#F5A800";
                }, 0)
            break;

            case '3':
                //show the navbar
                setSubNavBar('3');
              
                setTriggerCategory("d1");
                setTimeout(() => {
                    afternoon.current[0].style.color = "#F5A800";
                })
                
        }
    }

    function handleCategory(category, index) {
        // scrollToTop(navBarRef)
     
        if(category == "breakfast") {
            breakfastEls.current[index].style.color = "#F5A800";
            for(const otherKey in breakfastEls.current) {
                if(otherKey != index) {
                    breakfastEls.current[otherKey].style.color = "white";
                }
            }

            switch(index) {
                case 0:
                    navBarTwoRef.current.scrollLeft = 0;
                    setTriggerCategory("a1");
                break;

                case 1:
                    navBarTwoRef.current.scrollLeft = 100;
                    setTriggerCategory("a2");
                break;

                case 2:
                    navBarTwoRef.current.scrollLeft = 100;
                    setTriggerCategory("a3");
                break;

                case 3:
                    setTriggerCategory("a4");
                break;
            }
        }

        if(category == "carte") {
            carteEls.current[index].style.color = "#F5A800";
            for(const otherKey in carteEls.current) {
                if(otherKey != index) {
                   carteEls.current[otherKey].style.color = "white";
                }
            }
            switch(index) {

                //soup
                case 0:
                navBarTwoRef.current.scrollLeft = 0;
                setTriggerCategory("b1");
                break;

                //salad
                case 1:
                    navBarTwoRef.current.scrollLeft = 50;
                    setTriggerCategory("b2");
                break;


                //hot starter
                case 2:
                    navBarTwoRef.current.scrollLeft = 50;
                    setTriggerCategory("b3");
                break;


                // sandwiches
                case 3:
                    navBarTwoRef.current.scrollLeft = 190;
                    setTriggerCategory("b4");
                break;

                //main course
                case 4:
                    navBarTwoRef.current.scrollLeft = 340;
                    setTriggerCategory("b5");
                break

                //pasta & risotto
                case 5:
                    navBarTwoRef.current.scrollLeft = 510;
                    setTriggerCategory("b6");
                break

                //dessert
                case 6:
                    navBarTwoRef.current.scrollLeft = 640;
                    setTriggerCategory("b7");
                break;

                //cakes
                case 7:
                    navBarTwoRef.current.scrollLeft = 740;
                    setTriggerCategory("b8");
                break;


                //mocktails
                case 8:
                    navBarTwoRef.current.scrollLeft = 840;
                    setTriggerCategory("b9");
                break;

                //soft drinks
                case 9:
                    navBarTwoRef.current.scrollLeft = 970;
                    setTriggerCategory("b10");
                break;

                //premium tea
                case 10:
                    navBarTwoRef.current.scrollLeft = 1110;
                    setTriggerCategory("b11");
                break;

                //premium coffee
                case 11:
                    navBarTwoRef.current.scrollLeft = 1300;
                    setTriggerCategory("b12");
                break

                //water
                case 12:
                    navBarTwoRef.current.scrollLeft = 1500;
                    setTriggerCategory("b13");
                break;
        }
                }
                
        if(category == "boutique") {
            boutiqueEls.current[index].style.color = "#F5A800";
            for(const otherKey in boutiqueEls.current) {
                if(otherKey != index) {
                   boutiqueEls.current[otherKey].style.color = "white";
                }
            }
            switch(index) {

                //sourdough
                case 0:
                    navBarTwoRef.current.scrollLeft = 0;
                    setTriggerCategory("c1");
                break;
                
                //baguette
                case 1:
                    navBarTwoRef.current.scrollLeft = 20;
                    setTriggerCategory("c2");
                break;
                
                //danish
                case 2:
                    navBarTwoRef.current.scrollLeft = 130;
                    setTriggerCategory("c3");
                break;
                
                //croissant
                case 3:
                    navBarTwoRef.current.scrollLeft = 300;
                    setTriggerCategory("c4");
                break;

                //scones
                case 4:
                    setTriggerCategory("c5");
                break;
            }
        }

        if(category == "afternoon") {
            afternoon.current[index].style.color = "#F5A800";
            for(const otherKey in afternoon.current) {
                if(otherKey != index) {
                   afternoon.current[otherKey].style.color = "white";
                }
            }
            
           switch(index) {
            //full menu
            case 0:
                navBarTwoRef.current.scrollLeft = 0;
                setTriggerCategory("d1");
            break;

            //enhanced menu
            case 1:
                navBarTwoRef.current.scrollLeft = 100;
                setTriggerCategory("d2");
            break;

            //cream tea
            case 2:
                navBarTwoRef.current.scrollLeft = 100;
                setTriggerCategory("d3");
            break;
            }
        }
    }

    useEffect(()=> {
        if(!showCover) {
            setTimeout(() => {
                handleNavBar('0');
                handleCategory("breakfast", 0);
            }, 10)
        }
    }, [showCover])

    if(showCover) {
        return (
            <>
                <CoverContainerEv setShowCover = {setShowCover}/>
            </>
        )
    }

    if(!showCover) {
        return (
            <>
                {/* <CoverContainer setShowCover = {setShowCover}/> */}
                <LeBleuPageStyled ref = {pagePositionRef}>
                    <NavImageContainerStyled ref = {navBarRef}>
                        <img src = "/leBleu-menu-designs/lb-navBar.png"/>
                    </NavImageContainerStyled>
                    <SliderContainerStyled ref = {sliderContainerRef}>
                        <NavBarLayerOneStyled>
                            <NavBarOneTextStyled  key = "1" onClick = {() => handleNavBar('0')} ref = {(element) => navEls.current[0] = element}>
                                <span>breakfast</span>
                            </NavBarOneTextStyled>
                              <NavBarImageContainerStyled>
                                <img src = "/leBleu-menu-designs/LB-flower element.png" alt = "flower"/>
                            </NavBarImageContainerStyled>
                            <NavBarOneTextStyled  key = "2" onClick = {() => handleNavBar('1')} ref = {(element) => navEls.current[1] = element}>
                                <span>A LA CARTE</span>
                            </NavBarOneTextStyled>
                            {/* <NavBarImageContainerStyled>
                                <img src = "/leBleu-menu-designs/LB-flower element.png" alt = "flower"/>
                            </NavBarImageContainerStyled> */}
                            {/* <NavBarOneTextStyled  key = "3" onClick = {() => handleNavBar('2')} ref = {(element) => navEls.current[2] = element}>
                                <span>boutique</span>
                            </NavBarOneTextStyled> */}
                            <NavBarImageContainerStyled>
                                <img src = "/leBleu-menu-designs/LB-flower element.png" alt = "flower"/>
                            </NavBarImageContainerStyled>
                            <NavBarOneTextStyled  key = "4" onClick = {() => handleNavBar('3')} ref = {(element) => navEls.current[3] = element}>
                                <span>Afternoon Tea</span>
                            </NavBarOneTextStyled>
                        </NavBarLayerOneStyled>
                    </SliderContainerStyled>
                    <SliderContainerTwoStyled ref = {sliderContainerTwoRef}>
                        {subNavBar == '0' && <NavBarLayerTwoStyled ref = {navBarTwoRef}>
                                <NavBarTwoTextStyled onClick = {() => handleCategory("breakfast", 0)} ref = {(element) => breakfastEls.current[0] = element}>
                                    <span>Eggs</span>
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled onClick = {() => handleCategory("breakfast", 1)} ref = {(element) => breakfastEls.current[1] = element}>
                                    <span>Toasts</span>
                                </NavBarTwoTextStyled>
                                {/* <NavBarTwoTextStyled onClick = {() => handleCategory("breakfast", 2)} ref = {(element) => breakfastEls.current[2] = element}>
                                    <span>Muffins</span>
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled onClick = {() => handleCategory("breakfast", 3)} ref = {(element) => breakfastEls.current[3] = element}>
                                    <span>Pudding & Yogurt</span>
                                </NavBarTwoTextStyled> */}
                            </NavBarLayerTwoStyled>}
                        {subNavBar == '1' && <NavBarLayerTwoStyled ref = {navBarTwoRef}>
                                <NavBarTwoTextStyled onClick = {()=> handleCategory("carte", 0)} ref = {(element) => carteEls.current[0] = element}>
                                    <a href = "#1">
                                        <span>Bakery</span> 
                                    </a>
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled onClick = {()=> handleCategory("carte", 1)} ref = {(element) => carteEls.current[1] = element}> 
                                <a href = "#2">
                                        <span>Salad</span> 
                                </a>   
                                </NavBarTwoTextStyled>
                                {/* <NavBarTwoTextStyled onClick = {()=> handleCategory("carte", 2)} ref = {(element) => carteEls.current[2] = element}>
                                <a href = "#2">
                                        <span>Hot Starter</span> 
                                </a>  
                                </NavBarTwoTextStyled> */}
                                <NavBarTwoTextStyled onClick = {()=> handleCategory("carte", 3)} ref = {(element) => carteEls.current[3] = element}>
                                <a href = "#3">
                                        <span>Sandwiches</span> 
                                </a>   
                                </NavBarTwoTextStyled> 
                                {/* <NavBarTwoTextStyled onClick = {()=> handleCategory("carte", 4)} ref = {(element) => carteEls.current[4] = element}>
                                <a href = "#4">
                                        <span>Main course</span> 
                                </a>   
                                </NavBarTwoTextStyled> */}
                                {/* <NavBarTwoTextStyled onClick = {()=> handleCategory("carte", 5)} ref = {(element) => carteEls.current[5] = element}>
                                <a href = "#5">
                                        <span>Pasta & Risotto</span> 
                                </a>   
                                </NavBarTwoTextStyled> */}
                                <NavBarTwoTextStyled onClick = {()=> handleCategory("carte", 6)} ref = {(element) => carteEls.current[6] = element}>
                                <a href = "#6">
                                        <span>Dessert</span> 
                                </a>    
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled onClick = {()=> handleCategory("carte", 7)} ref = {(element) => carteEls.current[7] = element}>
                                <a href = "#7">
                                        <span>Cakes</span> 
                                </a>    
                                </NavBarTwoTextStyled> 
                                <NavBarTwoTextStyled onClick = {()=> handleCategory("carte", 8)} ref = {(element) => carteEls.current[8] = element}>
                                <a href = "#8">
                                        <span>Mocktails</span> 
                                </a>   
                                </NavBarTwoTextStyled> 
                                <NavBarTwoTextStyled onClick = {()=> handleCategory("carte", 9)} ref = {(element) => carteEls.current[9] = element}>
                                <a href = "#9">
                                        <span>Soft Drinks</span> 
                                </a>    
                                </NavBarTwoTextStyled> 
                                <NavBarTwoTextStyled onClick = {()=> handleCategory("carte", 10)} ref = {(element) => carteEls.current[10] = element}>
                                <a href = "#10">
                                        <span>Premium Tea</span> 
                                </a>    
                                </NavBarTwoTextStyled> 
                                <NavBarTwoTextStyled onClick = {()=> handleCategory("carte", 11)} ref = {(element) => carteEls.current[11] = element}>
                                <a href = "#11">
                                        <span>Premium Coffee</span> 
                                </a>    
                                </NavBarTwoTextStyled> 
                                <NavBarTwoTextStyled onClick = {()=> handleCategory("carte", 12)} ref = {(element) => carteEls.current[12] = element}>
                                <a href = "#12">
                                        <span>Water</span> 
                                </a>    
                                </NavBarTwoTextStyled>     
                            </NavBarLayerTwoStyled>}
                            {subNavBar == '2' && <NavBarLayerTwoStyled ref = {navBarTwoRef}>
                                {/* <NavBarTwoTextStyled onClick = {()=> handleCategory("boutique", 0)} ref = {(element) => boutiqueEls.current[0] = element}>
                                    <a href = "#13">
                                        <span>Sourdough</span> 
                                    </a>  
                                </NavBarTwoTextStyled> */}
                                {/* <NavBarTwoTextStyled onClick = {()=> handleCategory("boutique", 1)} ref = {(element) => boutiqueEls.current[1] = element}>
                                    <a href = "#14">
                                        <span>Baguette</span> 
                                    </a>   
                                </NavBarTwoTextStyled> */}
                                {/* <NavBarTwoTextStyled onClick = {()=> handleCategory("boutique", 2)} ref = {(element) => boutiqueEls.current[2] = element}>
                                    <a href = "#15">
                                        <span>Danish</span> 
                                    </a>  
                                </NavBarTwoTextStyled> */}
                                <NavBarTwoTextStyled onClick = {()=> handleCategory("boutique", 3)} ref = {(element) => boutiqueEls.current[3] = element}>
                                    <a href = "#16">
                                        <span>Croissant</span> 
                                    </a>    
                                </NavBarTwoTextStyled>
                                {/* <NavBarTwoTextStyled onClick = {()=> handleCategory("boutique", 4)} ref = {(element) => boutiqueEls.current[4] = element}>
                                    <a href = "#17">
                                        <span>Scones</span> 
                                    </a>    
                                </NavBarTwoTextStyled>         */}
                            </NavBarLayerTwoStyled>}
                            {subNavBar == '3' && <NavBarLayerTwoStyled ref = {navBarTwoRef} style = {{gap: "20px"}}>
                                <NavBarTwoTextStyled onClick = {()=> handleCategory("afternoon", 0)} ref = {(element) => afternoon.current[0] = element}>
                                    <a href = "#18">
                                        <span>FULL MENU</span> 
                                    </a>  
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled onClick = {()=> handleCategory("afternoon", 1)} ref = {(element) => afternoon.current[1] = element}>
                                    <a href = "#19">
                                        <span>ENHANCED MENU</span> 
                                    </a>  
                                </NavBarTwoTextStyled>
                                <NavBarTwoTextStyled onClick = {()=> handleCategory("afternoon", 2)} ref = {(element) => afternoon.current[2] = element}>
                                    <a href = "#20">
                                        <span>CREAM TEA</span> 
                                    </a>  
                                </NavBarTwoTextStyled>
                                </NavBarLayerTwoStyled>}
                    </SliderContainerTwoStyled>
                    {triggerCategory == 'a1' && <BreakfastEggsEv/>}
                    {triggerCategory == "a2" && <BreakFastToastsEv/>}
                    {/* {triggerCategory == "a3" && <BreakfastMuffins/>}
                    {triggerCategory == "a4" && <BreakfastYogurt/>} */}
                    {/* {triggerCategory == 'b1' && <CarteSoupsGrid/>} */}
                    {triggerCategory == 'b1' && <CarteBakery/>}
                    {triggerCategory == 'b2' && <CarteSaladEv/>}
                    {triggerCategory == 'b3' && <CarteHotStarter/>}
                    {triggerCategory == 'b4' && <CarteSandwichesEv/>}
                    {triggerCategory == 'b5' && <CarteMainCourseGrid/>}
                    {triggerCategory == 'b6' && <CartePastaAndRisotto/>}
                    {triggerCategory == 'b7' && <CarteDessertEv/>}
                    {triggerCategory == 'b8' && <CarteCakeEv/>}
                    {triggerCategory == 'b9' && <CarteMocktailsEv/>}
                    {triggerCategory == "b10" && <CarteDrinks/>}
                    {triggerCategory == 'b11' && <CarteTea/>}
                    {triggerCategory == 'b12' && <CarteCoffee/>}
                    {triggerCategory == 'b13' && <CarteWater/>}
                    {/* {triggerCategory == 'c1' && <BoutiqueSourdough/>}
                    {triggerCategory == 'c2' && <BoutiqueBaguette/>}
                    {triggerCategory == 'c3' && <BoutiqueDanish/>} */}
                    {/* {triggerCategory == 'c4' && <BoutiqueCroissant/>} */}
                    {/* {triggerCategory == 'c5' && <BoutiqueScones/>} */}
                    {triggerCategory == "d1" && <AfternoonFullMenu/>}
                    {triggerCategory == "d2" && <AfternoonEnhancedMenu/>}
                    {triggerCategory == "d3" && <AfternoonCreamMenu/>}
                </LeBleuPageStyled>
            </>
        )
    }

    
}

const AfternoonImageCoverStyled = styled.div`
    position: fixed;
    inset: 0;
    z-index: 10;
    width: 100%;
    background-color: white;
    top: 80px;
    height: 100vh;
  
    img {
        width: 100%;
    }
`

const TestContainerStyled = styled.div`
    position: fixed;
    background-color: red;
`
const NavImageContainerStyled = styled.div`
    position: fixed;
    inset: 0;
    width: 100%;
    max-height: 120px;
    z-index: 1;
    img {
        width: 100%;
        max-height: 120px;
    }
`


const SliderContainerStyled = styled.div`
    position: fixed;
    inset: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    z-index: 1;

`

const SliderContainerTwoStyled = styled.div`
    position: fixed;
    inset: 0;
    display:flex;
    z-index: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 28px;
    height: 80px;

`

const NavBarLayerOneStyled = styled.div`
    position: fixed;
    display: flex;
    gap: 10px; //change later
    align-items: center;

`

const NavBarOneTextStyled = styled.div`
    position: relative;
    color: white;
    span {
        font-size: 11px;
        
        font-family: "Futura Std Book";
        font-weight: 200;
        text-transform: uppercase;
    }
`

const NavBarLayerTwoStyled = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: scroll;
    scroll-behavior: smooth;
    gap: 40px;
    padding: 0px 30px;


    ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        background-color: transparent;
    }
`

const NavBarTwoTextStyled = styled.div`
    position: relative;
    white-space: nowrap;
    color: white;
    text-transform: uppercase;

    span {
        font-size: 16px;
        
        /* font-family: "Professor"; */
        font-family: "Futura Std Book";
    }
`

const NavBarImageContainerStyled = styled.div`
    position: relative;
    width: 6px;
    img {
        width:100%;
    }
`

export default LeBleuMenuEv;