import styled from "styled-components";
import { CategoryHeadingStyled } from "./ChariotCommonStyled";
import { chariot_drinks , chariot_coffees, chariot_water} from "../../../data/foodMenuCategories";
import { ItemNameAr, ItemNameEn ,ItemPriceStyled} from "./ChariotCommonStyled";

function DrinksGrid({}) {
    return (
        <>
            <CategoryHeadingStyled>
                <img src = "/chariot-menu-designs/Drinks PNG/Chariot-Drinks-V1-02.png" alt = "Ice Cream Category chariot menu"/>
            </CategoryHeadingStyled>
            <FlexRowContainer>
                <CategoryFlexBox>
                    <CategoryHeading>
                        COFFEES
                        <br/>
                        <span>SERVED HOT & COLD</span>
                    </CategoryHeading>
                    {
                        chariot_coffees.map(({item_en, item_ar, price}) => {
                            return (
                               <CategoryItemFlex>
                                    <div>
                                        <ItemNameEn style = {{fontSize: "9px"}}>
                                            {item_en}
                                        </ItemNameEn>
                                        <ItemNameAr style = {{fontSize: "10px"}}>
                                            {item_ar}
                                        </ItemNameAr>
                                    </div>
                                    <ItemPriceStyled style = {{fontFamily: "Futura Std", fontSize: "10px", fontWeight: "bolder", paddingLeft: "10px"}}>
                                        {price}
                                    </ItemPriceStyled>
                               </CategoryItemFlex> 
                            )
                        })
                    }
                    <CategoryItemFlex>
                        <div>
                            <ItemNameEn style = {{fontSize: "9px"}}>
                                VANILLA ICE CREAM
                                <br/>
                                FRAPPUCCINO
                            </ItemNameEn>
                            <ItemNameAr style = {{fontSize: "10px"}}>
                            فانيلا فرابوتشينو
                            </ItemNameAr>
                           
                        </div>
                        <ItemPriceStyled style = {{fontSize: "10px", fontWeight: "bolder", paddingLeft: "10px"}}>
                                    S 27 QR
                                    <br/>
                                    L 32 QR
                        </ItemPriceStyled>
                        <div>
                            
                        </div>
                    </CategoryItemFlex>
                    <CategoryItemFlex>
                        <ItemNameEn style = {{fontSize: "9px", color: "#ea0029"}}>
                            MILK ALTERNATIVE
                        </ItemNameEn>
                        <ItemPriceStyled style = {{fontSize: "10px", fontWeight: "bolder", paddingLeft: "10px"}}>
                            4 QR
                        </ItemPriceStyled>
                    </CategoryItemFlex>
                    <CategoryItemFlex style = {{marginTop: "-8px", marginBottom: "80px"}}>
                        <ItemNameEn style = {{fontSize: "9px", color: "#ea0029"}}>
                            TEA SELECTION
                        </ItemNameEn>
                        <ItemPriceStyled style = {{fontSize: "10px", fontWeight: "bolder", paddingLeft: "10px"}}>
                            18 QR
                        </ItemPriceStyled>
                    </CategoryItemFlex>
                </CategoryFlexBox>
                <CategoryFlexBox>
                    <CategoryHeading>
                        DRINKS
                    </CategoryHeading>
                    {
                        chariot_drinks.map(({item_en, item_ar, price}) => {
                            return (
                                <CategoryItemFlex>
                                    <div>
                                        <ItemNameEn style = {{fontSize: "9px"}}>
                                            {item_en}
                                        </ItemNameEn>
                                        <ItemNameAr style = {{fontSize: "10px"}}>
                                            {item_ar}
                                        </ItemNameAr>
                                    </div>
                                    <ItemPriceStyled style = {{fontSize: "10px", fontWeight: "bolder", paddingLeft: "20px"}}>
                                        {price}
                                    </ItemPriceStyled>
                                </CategoryItemFlex>
                            )
                        })
                    }
                    <CategoryHeading style = {{marginTop: "10px"}}>
                        WATER
                    </CategoryHeading>
                    {
                        chariot_water.map(({item_en, item_ar, price}) => {
                            return (
                                <CategoryItemFlex>
                                    <div>
                                        <ItemNameEn style = {{fontSize: "9px"}}>
                                            {item_en}
                                        </ItemNameEn>
                                        <ItemNameAr style = {{fontSize: "10px"}}>
                                            {item_ar}
                                        </ItemNameAr>
                                    </div>
                                    <ItemPriceStyled style = {{fontSize: "10px", fontWeight: "bolder", paddingLeft: "10px"}}>
                                        {price}
                                    </ItemPriceStyled>
                                </CategoryItemFlex>
                            )
                        })
                    }
                    
                </CategoryFlexBox>
            </FlexRowContainer>
        </>
    )
}

const FlexRowContainer = styled.div`
    position: relative;
    padding: 0px 15px;
    display: flex;
    gap: 40px;
`

const CategoryFlexBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
`
const CategoryHeading = styled.div`
    position: relative;
    font-size: 10px;
    font-weight: bold;
    color: #ea0029;
    margin-bottom: 3px;

    span {
        font-size: 8px;
        font-weight: normal;
    }

`
const CategoryItemFlex = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
`



export default DrinksGrid;