import styled from "styled-components";
import { GridPageStyled, GridContainerStyled, GridTitleContainerStyled, GridImageContainerStyled, ButtonContainerStyled, ComingSoonTempStyled } from "./GridCommanStyles";
import {Grid} from "@mui/material";
import {LogoContainerStyled, ImageContainerFrontStyled, ImageContainerBackStyled} from "../../pages/Lsd";


function ShamiyaGrid({setTriggerGrid, setIsClicked, isClicked}) {

    //close the grid and change the logo 
    function handleTriggerfn() {
        document.body.style.overflow = 'auto'
        setTriggerGrid(false);
        setIsClicked(false);
   }

    return (
        <>
                <GridPageStyled>
                  <ComingSoonTempStyled>
                    <span>
                        Coming Soon.
                    </span>
                  </ComingSoonTempStyled>
                </GridPageStyled>
        </>
    )
}



export default ShamiyaGrid;