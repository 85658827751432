import styled from "styled-components";
import { FillerContainerStyled, CategoryHeaderStyled, ItemContainerTeaStyled, ItemContainerStyled,  ItemEnglishTitleStyled, ItemEnglishTeaTitleStyled, ItemImageContainerStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, CategoryContainerStyled, ArabicContainerStyled, ItemArabicTitleStyled, ItemArabicDescriptionStyled, DrinksArabicContainerStyled, DrinksArabicTextStyled } from "./CommonStyled";
import {premium_tea} from "../../../data/lbCategories/a-la-carte/menuItems";

function CarteTea({}) {
    return (
        <>
        <CategoryContainerStyled>
            {/* <CategoryHeaderStyled>
                <span>Selection of Premium Tea</span>
            </CategoryHeaderStyled> */}
            {
                premium_tea.map(({name_en, name_ar, price, currency, description}) => {
                    return (
                        <>
                            <ItemContainerTeaStyled>
                                <ItemEnglishTeaTitleStyled>
                                    {name_en}                            
                                    <PriceContainerStyled>
                                        {price}
                                        <span>{currency}</span>
                                    </PriceContainerStyled>
                                </ItemEnglishTeaTitleStyled>
                            </ItemContainerTeaStyled>
                            <DrinksArabicContainerStyled>
                                <TextStyled>
                                    {description}
                                </TextStyled>
                            </DrinksArabicContainerStyled>
                            <ArabicContainerStyled style = {{width: "80%", margin: "0 auto", marginTop: "6px"}}>
                                <DrinksArabicTextStyled>
                                    {name_ar}
                                </DrinksArabicTextStyled>
                            </ArabicContainerStyled>
                            <FillerContainerStyled/>
                        </>
                    )
                   
                })
            }
            </CategoryContainerStyled>
        </>
    )
}

const TextStyled = styled.div`
    position: relative;
    font-family: "Futura Std Book";
    font-size: 8px;
    font-style: italic;
    margin-top: -4px;
`


export default CarteTea;