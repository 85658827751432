import styled from "styled-components";

function SaladsEn({}) {
    return (
        <>
            <div>
                <div>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                            <img src = "/ergon-menu-desgins/Salad Pics/salad- chicken.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>Chicken salad <br/>
                            <ItemDescriptionParStyled>
                               <TextItalicBoxStyled>
                                Mixed baby greens with grilled chicken, red peppers, kalamata olives, croutons &
                                honey mustard from Thessaloniki
                               </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>55 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                </div>
                <div>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Salad Pics/salad- greek.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>Greek salad <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>
                                    with tomato, cucumber, peppers, olives, reta cheese & pita bread
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>59 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                </div>
                <div>
                    <SingleItemContainerStyled>
                        <ImageContainerStyled>
                        <img src = "/ergon-menu-desgins/Salad Pics/salad- feta.png"/>
                        </ImageContainerStyled>
                        <ItemDescriptionStyled>
                            <p>Feta salad <br/>
                            <ItemDescriptionParStyled>
                                <TextItalicBoxStyled>
                                Cherry tomatoes, avocado, feta, lime
                                </TextItalicBoxStyled>
                            </ItemDescriptionParStyled>
                            </p>
                            <PriceBoxStyled>50 QR</PriceBoxStyled>
                        </ItemDescriptionStyled>
                    </SingleItemContainerStyled>
                </div>
            </div>
            
        </>
    )
}

function SaladsAr({}) {
    return (
        <>
            <div>
                <div>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                            <img src = "/ergon-menu-desgins/Salad Pics/salad- chicken.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>سلطة الدجاج <br/>
                            <ItemDescriptionParArStyled>
                               <TextItalicBoxStyled>
                               خضروات صغيرة مشكلة مع الدجاج، الفلفل
الأحمر و زيتون كالاماتا، الخبز المحمص،
الخردل بالعسل من سالونيك
                               </TextItalicBoxStyled>
                               </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٥٥ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                </div>
                <div>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Salad Pics/salad- greek.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>سلطة يونانية<br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>
                                مع طماطم، خيار، فلفل،
زيتون، جبنة فيتا و خبز بيتا
                                </TextItalicBoxStyled>
                                </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٥٩ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                </div>
                <div>
                    <SingleItemContainerStyledAr>
                        <ImageContainerStyledAr>
                        <img src = "/ergon-menu-desgins/Salad Pics/salad- feta.png"/>
                        </ImageContainerStyledAr>
                        <ItemDescriptionArStyled>
                            <p>سلطة الفيتا<br/>
                            <ItemDescriptionParArStyled>
                                <TextItalicBoxStyled>
                                طماطم كرزية، أفوكادو، جبنة فيتا، ليمون
                                </TextItalicBoxStyled>
                                </ItemDescriptionParArStyled>
                            </p>
                            <PriceBoxStyled>٥٠ ر.ق</PriceBoxStyled>
                        </ItemDescriptionArStyled>
                    </SingleItemContainerStyledAr>
                </div>
            </div>
            
        </>
    )
}


const SingleItemContainerStyled = styled.div`
    position: relative;
    display:flex;
    gap: 15px;
    margin-bottom: 20px;
    align-items: center;
    /* img {
        width: 60%;
    } */
`

const SingleItemContainerStyledAr = styled(SingleItemContainerStyled)`
    flex-direction: row-reverse;
`

const ImageContainerStyled = styled.div`
    position: relative;
    max-width: 82px;
    width: 100%;
    img {
        width: 100%;
      
    }
`

const ImageContainerStyledAr = styled(ImageContainerStyled)`
    transform: scaleX(-1);
`

const ItemDescriptionStyled = styled.div`
    font-family: "Gotham Greek Book";
    font-size: 14px;
    p {
        margin: 0px;
        line-height: 15px;
    }
`
const ItemDescriptionParStyled = styled.div`
    padding-right: 20px;
`

const ItemDescriptionParArStyled = styled.div`
    padding-left: 20px;
`

const ItemDescriptionArStyled = styled(ItemDescriptionStyled)`
    font-family: "GE SS Text";
    font-size: 15px;
    text-align: right;
`

const TextItalicBoxStyled = styled.span`
    font-weight: lighter;
    font-style: italic;
    font-size: 12px;
`
const PriceBoxStyled = styled.div`
    position: relative;
    margin-top: 4px;
    font-weight: bold;
    font-size: 12px;
`

export {
    SaladsEn,
    SaladsAr,
};