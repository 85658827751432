//This file will contain an array for each category of the ltb menu


//------------ SOUPS CATEGORY -------------------------
let ltb_soups = [
    "blt-menu-designs/Soups JPG/Carousel JPG/ltb-soups-chicken-soup.jpg",
    "blt-menu-designs/Soups JPG/Carousel JPG/ltb-soups-french-soup.jpg",
    "blt-menu-designs/Soups JPG/Carousel JPG/ltb-soups-tomato-soup.jpg",
]

let ltb_salads = [
    "blt-menu-designs/Salad JPG/Carousel JPG/ltb-salads-artichoke.jpg",
    "blt-menu-designs/Salad JPG/Carousel JPG/ltb-salads-beetroot.jpg",
    "blt-menu-designs/Salad JPG/Carousel JPG/ltb-salads-burrata.jpg",
    "blt-menu-designs/Salad JPG/Carousel JPG/ltb-salads-chicken caesars.jpg",
    "blt-menu-designs/Salad JPG/Carousel JPG/ltb-salads-josper corn.jpg",
    "blt-menu-designs/Salad JPG/Carousel JPG/ltb-salads-shrimp caesars.jpg",
]

let ltb_hot_starters = [
    "blt-menu-designs/Hot Starters JPG/Carousel JPG/ltb-hot-starters-burgundy snail.jpg",
    "blt-menu-designs/Hot Starters JPG/Carousel JPG/ltb-hot-starters-fried calamari.jpg",
    "blt-menu-designs/Hot Starters JPG/Carousel JPG/ltb-hot-starters-fried shrimp.jpg",
    "blt-menu-designs/Hot Starters JPG/Carousel JPG/ltb-hot-starters-foie gras.jpg",
    "blt-menu-designs/Hot Starters JPG/Carousel JPG/ltb-hot-starters-josper truffle pizza.jpg",
    "blt-menu-designs/Hot Starters JPG/Carousel JPG/ltb-hot-starters-josper shrimp skewers.jpg",

]

let ltb_hot_main_course = [
    "blt-menu-designs/Main Course JPG/Carousel JPG/ltb-main-course-baby chicken w candied lemon.jpg",
    "blt-menu-designs/Main Course JPG/Carousel JPG/ltb-main-course-black angus tenderloin.jpg",
    "blt-menu-designs/Main Course JPG/Carousel JPG/ltb-main-course-duck leg confit.jpg",
    "blt-menu-designs/Main Course JPG/Carousel JPG/ltb-main-course-ltb mac burger.jpg",
    "blt-menu-designs/Main Course JPG/Carousel JPG/ltb-main-course-panang chicken shrimp.jpg",
    "blt-menu-designs/Main Course JPG/Carousel JPG/ltb-main-course-seabass fillet.jpg",
    "blt-menu-designs/Main Course JPG/Carousel JPG/ltb-main-course-short ribs.jpg",
    "blt-menu-designs/Main Course JPG/Carousel JPG/ltb-main-course-vegan garden steak.jpg",
    "blt-menu-designs/Main Course JPG/Carousel JPG/ltb-main-course-wagyu tenderloin.jpg",
    "blt-menu-designs/Main Course JPG/Carousel JPG/ltb-main-course-whole chicken stuffed.jpg",
    "blt-menu-designs/Main Course JPG/Carousel JPG/ltb-main-course-miso salmon.jpg",
]

let ltb_pasta = [
    "blt-menu-designs/Pasta & Rissotto JPG/Carousel JPG/ltb-pasta-fettuccine truffle.jpg",
    "blt-menu-designs/Pasta & Rissotto JPG/Carousel JPG/ltb-pasta-lasagna.jpg",
    "blt-menu-designs/Pasta & Rissotto JPG/Carousel JPG/ltb-pasta-linguine with lobster.jpg",
    "blt-menu-designs/Pasta & Rissotto JPG/Carousel JPG/ltb-pasta-saffron risotto shrimp.jpg"

]

let ltb_side_dishes = [
    "blt-menu-designs/Side Dishes JPG/Carousel JPG/ltb-side-dishes-french fries.jpg",
    "blt-menu-designs/Side Dishes JPG/Carousel JPG/ltb-side-dishes-gratin potato.jpg",
    "blt-menu-designs/Side Dishes JPG/Carousel JPG/ltb-side-dishes-green asparagus.jpg",
    "blt-menu-designs/Side Dishes JPG/Carousel JPG/ltb-side-dishes-green beans.jpg",
    "blt-menu-designs/Side Dishes JPG/Carousel JPG/ltb-side-dishes-mashed potato.jpg",
    "blt-menu-designs/Side Dishes JPG/Carousel JPG/ltb-side-dishes-steamed french rice.jpg",
]

let ltb_dessert = [
    "blt-menu-designs/Dessert JPG/Carousel JPG/ltb-dessert- cheesecake.jpg",
    "blt-menu-designs/Dessert JPG/Carousel JPG/ltb-dessert- vanilla cream caramel.jpg",
    "blt-menu-designs/Dessert JPG/Carousel JPG/ltb-dessert- cream brulee.jpg",
    "blt-menu-designs/Dessert JPG/Carousel JPG/ltb-dessert- french toast.jpg",
    "blt-menu-designs/Dessert JPG/Carousel JPG/ltb-dessert-blueberry cheesecake.jpg",
    "blt-menu-designs/Dessert JPG/Carousel JPG/ltb-dessert-mango cheesecake.jpg",
]

let ltb_cake = [
    {
        name_en: "COCONUT CAKE",
        name_ar: "كيكة جوز الهند",
        price: "38",
    },

    {
        name_en: "PISTACHIO CAKE",
        name_ar: "كيكة الفستق",
        price: "39",
    },

    {
        name_en: "LAVENDER CAKE",
        name_ar: "كيكة اللافندر",
        price: "45",
    },

    {
        name_en: "VANILLA MERINGUE CAKE SMALL",
        name_ar: "كيكة ميرانج الفانيليا صغير",
        price: "43",
    },

    {
        name_en: "PARIS BREST",
        name_ar: "باريس بريست",
        price: "52",
    },

    {
        name_en: "ANGELO",
        name_ar: "انجيلو",
        price: "52",
    },

    {
        name_en: "STRAWBERRY CAKE",
        name_ar: "كيكة الفراولة",
        price: "38",
    },

    {
        name_en: "TOFFEE CAKE",
        name_ar: "كعكة التوفي",
        price: "35",
    },

    {
        name_en: "BLACK FOREST",
        name_ar: "بلاك فورست",
        price: "85",
    },

    {
        name_en: "CHOCOLATE CAKE",
        name_ar: "كيكة الشوكولاتة",
        price: "42",
    },

    {
        name_en: "CREAM CARAMEL BOX",
        name_ar: "بوكس كريم كراميل (٨) قطع",
        price: "365",
    },

    {
        name_en: "CHEESECAKE BOX",
        name_ar: "بوكس تشيز كيك (٨) قطع",
        price: "385",
    },

    {
        name_en: "VANILLA MERINGUE CAKE LARGE",
        name_ar: "كيك ميرانج فانيليا كبير",
        price: "295",
    },
]

let ltb_drinks_mocktails = [

    {
        name_en: "PINK LADY",
        name_ar: "بينك ليدي",
        price: "38",
    },

    {
        name_en: "VIRGIN MOJITO",
        name_ar: "موهيتو فرجن",
        price: "36",
    },

    {
        name_en: "STRAWBERRY MOJITO",
        name_ar: "موهيتو فرولة",
        price: "38",
    },

    {
        name_en: "LEMON WITH MINT",
        name_ar: "ليمون بالنعناع",
        price: "34",
    },

    {
        name_en: "WATERMELON WITH LEMONADE",
        name_ar: "بطيخ مع ليمون",
        price: "36",
    },

    {
        name_en: "PASSION FRUIT MOJITO",
        name_ar: "باشن فروت مهيتو",
        price: "38",
    },

    {
        name_en: "SUMMER FRESHNER",
        name_ar: "سمر فريشنر",
        price: "36",
    },

    {
        name_en: "PEACH MOJITO",
        name_ar: "موهيتو الخوخ",
        price: "38",
    },

    {
        name_en: "BERRY MOJITO",
        name_ar: "موهيتو التوت",
        price: "38",
    },
]

let ltb_drinks_fresh_fruit_juice = [
    {
        name_en: "ORANGE",
        name_ar: "برتقال",
        price: "32",
    },
]

let ltb_drinks_fresh_soft_drinks = [

    {
        name_en: "COCA COLA",
        name_ar: "كولا",
        price: "18",
    },

    {
        name_en: "DIET COKE",
        name_ar: "كولا دايت",
        price: "18",
    },

    {
        name_en: "STRAWBERRY FANTA",
        name_ar: "فانتا الفراولة",
        price: "18",
    },

    {
        name_en: "ORANGE FANTA",
        name_ar: "فانتا برتقال",
        price: "18",
    },

    {
        name_en: "SPRITE",
        name_ar: "سبرايت",
        price: "18",
    },
]

let ltb_drinks_hot_cold = [

    {
        name_en: "ESPRESSO",
        name_ar: "إسبريسو",
        price: "18",
    },

    {
        name_en: "ESPRESSO (DOUBLE)",
        name_ar: "دابل إسبريسو",
        price: "25",
    },

    {
        name_en: "MACCHIATO",
        name_ar: "ماكياتو",
        price: "28",
    },

    {
        name_en: "CAPPUCINO",
        name_ar: "كابوتشينو",
        price: "33",
    },

    {
        name_en: "LATTE (HOT / COLD)",
        name_ar: "لاتيه (ساخن أو بارد)",
        price: "30",
    },

    {
        name_en: "AMERICANO",
        name_ar: "أمريكانو",
        price: "28",
    },

    {
        name_en: "FLAT WHITE",
        name_ar: "فلات وايت",
        price: "33",
    },

    {
        name_en: "CORTADO",
        name_ar: "كورتادو",
        price: "30",
    },

    {
        name_en: "MOCHA",
        name_ar: "موكا",
        price: "33",
    },

    {
        name_en: "SPANISH LATTE (HOT / COLD)",
        name_ar: "سبانش لاتيه (ساخن أو بارد)",
        price: "35",
    },

    {
        name_en: "ROSE LATTE (HOT / COLD)",
        name_ar: "روز لاتيه (ساخن أو بارد)",
        price: "35",
    },

    {
        name_en: "VANILLA LATTE",
        name_ar: "فانيلا لاتيه",
        price: "35",
    },

    {
        name_en: "CARAMEL LATTE",
        name_ar: "لاتيه الكراميل",
        price: "35",
    },

    {
        name_en: "HOT CHOCOLATE",
        name_ar: "هوت شوكولاتة",
        price: "33",
    },

    {
        name_en: "HAZELNUT LATTE",
        name_ar: "لاتيه البندق",
        price: "35",
    },

    {
        name_en: "DECAFFEINATED",
        name_ar: "ديكافينيتد",
        price: "28",
    },
]

let ltb_drinks_teas = [
    {
        name: "WHITE TEAS",
        types_en: ["Jasmine Pearls"],
        types_ar: ["شاي ابيض", "شاي بالياسمين"],
        price: "45"
    },
    
    {
        name: "GREEN TEAS",
        types_en: ["Green Tea with Mint", "Flowers Tea", "Green Jasmine Premium"],
        types_ar: ["شاي اخضر", "شاي اخضر بالنعناع", "شاي الزهور", "شاي اخضر بالياسمين"],
        price: "35",
    },

    {
        name: "BLACK TEAS",
        types_en: ["Le Train Bleu’s Breakfast ", "Flowery Earl Grey"],
        types_ar: ["شاي اسود", "شاي لو تران بلو", "شاي زهور ايرل غراي"],
        price: "30",
    },

    {
        name: "HERBAL TEAS",
        types_en: ["Le Train Bleu’s Herbal Tea"],
        types_ar: ["شاي بالاعشاب", "شاي لو تران بلو بالاعشاب"],
        price: "32",
    }
]

let ltb_drinks_water = [

    {
        name_en: "MINERAL WATER S/L",
        name_ar: "مياه معدنية صغير / كبير",
        price: "18/28",
    },

    {
        name_en: "SPARKLING WATER S/L",
        name_ar: "مياه غازية صغير / كبير",
        price: "18/28",
    },
]

let ltb_flex_aft_menu_one = [

    {
        name_ar: "خبز الزبيب متوج بالدجاج المدخن من حطب شجر التفاح",
        name_en: "Coronation apple wood smoked chicken in raisin bread (G)",
    },

    {
        name_ar: "صدر دجاج مدخن مع الزبيب، مايونيز، كاري، ملح فلفل",
        name_en: "Smoked chicken breast, mixed with raisins, mayo, curry powder salt and pepper (G)",
    },

    {
        name_ar: "جبنة الموزاريلا طازجة مع طماطم مجففة وصلصة البيستو",
        name_en: "Fresh mozzarella with sun-dried tomato and pesto sauce (G/D)",
    },

    {
        name_ar: "كرباتشو الخيار المملح مع كريم الجبنة، موس الثوم المعمر وخبـز السبانخ",
        name_en: "Salted cucumber carpaccio with cream cheese and chives mousse on spinach bread (G/D)",
    },

    {
        name_ar: "البيض مع المايونيز والفجل على رغيف حبوب القمح الكاملة",
        name_en: "Free-range egg mayonnaise with radish cress on plain whole loaf bread (G)",
    },

    {
        name_ar: "لحم عجل مشوي على نار هادئة، مقدمة مع الفجل الحار والجرجير على رغيف خبز الحبوب المتنوعة",
        name_en: "Slow roasted beef, mayo horseradish and arugula on multi cereal loaf (G)",
    },
]

let ltb_flex_aft_menu_two = [

    {
        name_ar: "كيكة الزعفران",
        name_en: "Gâteau au safran (G/D/N)"
    },

    {
        name_ar: "تارت بلوبيري",
        name_en: "Tarte aux mirtilles (G/D/N)"
    },

    {
        name_ar: "كيكة الكرز",
        name_en: "Gâteau aux cerises (G/D/N)"
    },

    {
        name_ar: "تارت الشوكولاته",
        name_en: "Tarte au chocolate (G/D/N)"
    },

    {
        name_ar: "موس القهوة",
        name_en: "Mousse au cafe (G/D/N)"
    },

    {
        name_ar: "كيكة الفستق",
        name_en: "Gâteau à la pistache (G/D/N)"
    },

    {
        name_ar: "كيكة اللوز",
        name_en: "Gâteau au amandes (G/D/N)"
    },

    {
        name_ar: "ميل فاي",
        name_en: "Mille-feuilles (G/D)"
    },

    {
        name_ar: "مادلين",
        name_en: "Madeleine (G/D)"
    },
]

let ltb_flex_aft_scones_menu = [

    {
        name_ar: "سادة / زبيب",
        name_en: "Plain / Raisin (G/D)",
    },

    {
        name_ar: "تقدم مع الكريمة المخثرة، مربى الفراولة والمشمش",
        name_en: "Served with clotted cream, strawberry and apricot jams preserves",

    }
]

let ltb_flex_aft_tea_coffee = [

    {
        name_ar: "بريكفاست تي | جازمين بريميوم | بابونج | شاي اخضر مع نعناع | جونشا",
        name_en: "Breakfast Tea | Jasmine Premium | Chamomile | Green tea with mint | Jeoncha",
    },

    {
        name_ar: "لويزه بالنعناع | شاي إرل غري | شاي الحمضيات | أولونغ",
        name_en: "Verbena mint | Darjeeling | Earl Grey | Citrus tea | Oolong",
    },

    {
        name_ar: "اسبرسو | اميريكانو | فلات وايت | كابتشينو | لاتيه",
        name_en: "Espresso | Americano | Flat White | Cappuccino | Latte",
    },
]

let ltb_flex_aft_tea_coffee_enhanced = [

    {
        name_ar: "اكمل تجربتك مع اختيارك من تخصصاتنا المميزة",
        name_en: "Complete your experience with a choice of our signature",
    },

    {
        name_ar: "كريم كراميل | تشيز كيك | ميرانج الفانيلا",
        name_en: "Cream Caramel | Cheesecake | Vanilla Meringue",
    },
]

export {
    ltb_soups,
    ltb_salads,
    ltb_hot_starters,
    ltb_hot_main_course,
    ltb_pasta,
    ltb_side_dishes,
    ltb_dessert,
    ltb_cake,
    ltb_drinks_fresh_fruit_juice,
    ltb_drinks_fresh_soft_drinks,
    ltb_drinks_hot_cold,
    ltb_drinks_mocktails,
    ltb_drinks_teas,
    ltb_drinks_water,
    ltb_flex_aft_menu_one,
    ltb_flex_aft_menu_two,
    ltb_flex_aft_scones_menu,
    ltb_flex_aft_tea_coffee,
    ltb_flex_aft_tea_coffee_enhanced,
}