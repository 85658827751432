import { PriceContainerStyled, CategoryContainerStyled, FillerContainerStyled, ItemContainerTeaStyled, ItemEnglishTeaTitleStyled, DrinksArabicContainerStyled, DrinksArabicTextStyled } from "../CommonStyled";
import {soft_drinks} from "../itemsData";

function CarteSoftDrinks({}) {
    return (
        <>
        <CategoryContainerStyled>
            {/* <CategoryHeaderStyled>
                <span>Soft Drinks</span>
            </CategoryHeaderStyled> */}
            {
                soft_drinks.map(({name_en, name_ar, price, currency}) => {
                    return (
                        <>
                            <ItemContainerTeaStyled>
                                <ItemEnglishTeaTitleStyled>
                                    {name_en}
                                    <PriceContainerStyled>
                                        {price}
                                        <span>{currency}</span>
                                    </PriceContainerStyled>
                                </ItemEnglishTeaTitleStyled>
                            </ItemContainerTeaStyled>
                            <DrinksArabicContainerStyled>
                                <DrinksArabicTextStyled>
                                    {name_ar}
                                </DrinksArabicTextStyled>
                            </DrinksArabicContainerStyled>
                            <FillerContainerStyled/>
                        </>
                    )
                   
                })
            }
            </CategoryContainerStyled>
        </>
    )
}

export default CarteSoftDrinks;