import styled from "styled-components";


function CoverContainerEv({setShowCover}) {

    function handleClick() {
        setShowCover(false);
    }
    return (
        <>
            <CoverContainerStyled onClick = {handleClick}>
                <LogoContainerStyled>
                    <img src = "/leBleu-menu-designs/LB-Logo-Black.png"/>
                </LogoContainerStyled>
                <CoverImageContainerStyled>
                    <img src = "/leBleu-menu-designs/LB-EventMenu-Cover-Girl.png"/>
                </CoverImageContainerStyled>
                <MenuImageStyled>
                    <img src = "/leBleu-menu-designs/LB-AlShaqabMenu-Button.png"/>
                </MenuImageStyled>
            </CoverContainerStyled>
        </>
    )
}

const CoverContainerStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 40px;
   
    margin-top: 30px;
`

const TextContainerStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: -16px;
    img {
        width: 70%;
    }
`

const LogoContainerStyled = styled.div`
    position: relative;
    width: 30%;
    margin-top: 58px;
    

    img {
        width: 100%;
    }
`

const MenuImageStyled = styled.div`
    position: relative;
    width: 40%;
    margin-top: 40px;

    

    img {
        width: 100%;
    }
`

const CoverImageContainerStyled = styled.div`
    position: relative;
    width: 90%;
    margin-top: 40px;
    margin-right: 40px;

    img {
        width: 100%;
    }
`

export default CoverContainerEv;