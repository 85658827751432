const coffee_en = [
    {
        name_en: "Flat White",
        description_en: "",
        price: "22",
        img_path: "/five-am-menu-designs/coffee/01-Coffee-Flat White-72dpi.jpg"
    },

    {
        name_en: "Espresso",
        description_en: "",
        price: "14",
        img_path: "/five-am-menu-designs/coffee/02-Coffee-Espresso-72dpi.jpg"
    },

    {
        name_en: "Double Espresso",
        description_en: "",
        price: "20",
        img_path: "/five-am-menu-designs/coffee/03-Coffee-Espresso Double-72dpi.jpg"
    },

    {
        name_en: "Cappuccino",
        description_en: "",
        price: "22",
        img_path: "/five-am-menu-designs/coffee/04-Coffee-Cappuccino-72dpi.jpg"
    },

    {
        name_en: "Latte",
        description_en: "",
        price: "22",
        img_path: "/five-am-menu-designs/coffee/05-Coffee-Latte-72dpi.jpg"
    },

    {
        name_en: "Spanish Latte",
        description_en: "",
        price: "24",
        img_path: "/five-am-menu-designs/coffee/06-Coffee-Spanish Latte-72dpi.jpg"
    },

    {
        name_en: "V60",
        description_en: "",
        price: "25",
        img_path: "/five-am-menu-designs/coffee/07-Coffee-V60-72dpi.jpg"
    },

    {
        name_en: "Cortado",
        description_en: "",
        price: "18",
        img_path: "/five-am-menu-designs/coffee/08-Coffee-Cortado-72dpi.jpg"
    },

    {
        name_en: "Macchiato",
        description_en: "",
        price: "18",
        img_path: "/five-am-menu-designs/coffee/09-Coffee-Macchiato-72dpi.jpg"
    },

    {
        name_en: "Mocca",
        description_en: "",
        price: "27",
        img_path: "/five-am-menu-designs/coffee/10-Coffee-Mocca-72dpi.jpg"
    },

    {
        name_en: "Turkish Coffee",
        description_en: "",
        price: "20",
        img_path: "/five-am-menu-designs/coffee/11-Coffee-Turkish-72dp.jpg"
    },

    {
        name_en: "Chocolate",
        description_en: "",
        price: "28",
        img_path: "/five-am-menu-designs/coffee/12-Coffee-Chocolate-72dpi.jpg"
    },

    {
        name_en: "Americano",
        description_en: "",
        price: "17",
        img_path: "/five-am-menu-designs/coffee/13-Coffee-Americano-72dpi.jpg"
    },

    {
        name_en: "French Press Coffee",
        description_en: "",
        price: "22",
        img_path: "/five-am-menu-designs/coffee/14-Coffee-French Press-72dpi.jpg"
    },
]

const coffee_ar = [
     {
        name_ar:  "إسبرسو",
        description_ar: "",
        price: "14",
        img_path: "/five-am-menu-designs/coffee/02-Coffee-Espresso-72dpi.jpg"
    },

    {
        name_ar:  "فالت وايت",
        description_ar: "",
        price: "22",
        img_path: "/five-am-menu-designs/coffee/01-Coffee-Flat White-72dpi.jpg"

    },

    {
        name_ar:  "كابتشينو",
        description_ar: "",
        price: "22",
        img_path: "/five-am-menu-designs/coffee/04-Coffee-Cappuccino-72dpi.jpg"

    },

    {
        name_ar:  "سبرسو دبل",
        description_ar: "",
        price: "20",
        img_path: "/five-am-menu-designs/coffee/03-Coffee-Espresso Double-72dpi.jpg"

    },

    {
        name_ar:  "لاتيه اسباني",
        description_ar: "",
        price: "24",
        img_path: "/five-am-menu-designs/coffee/06-Coffee-Spanish Latte-72dpi.jpg"

    },

    {
        name_ar:  "لاتيه",
        description_ar: "",
        price: "22",
        img_path: "/five-am-menu-designs/coffee/05-Coffee-Latte-72dpi.jpg"

    },

    {
        name_ar:  "كورتادو",
        description_ar: "",
        price: "18",
        img_path: "/five-am-menu-designs/coffee/08-Coffee-Cortado-72dpi.jpg"

    },

    {
        name_ar:  "في ٦٠",
        description_ar: "",
        price: "25",
        img_path: "/five-am-menu-designs/coffee/07-Coffee-V60-72dpi.jpg"

    },

    {
        name_ar:  "موكا",
        description_ar: "",
        price: "27",
        img_path: "/five-am-menu-designs/coffee/10-Coffee-Mocca-72dpi.jpg"

    },

    {
        name_ar:  "ماكياتو",
        description_ar: "",
        price: "18",
        img_path: "/five-am-menu-designs/coffee/09-Coffee-Macchiato-72dpi.jpg"

    },

    {
        name_ar:  "شوكولاته",
        description_ar: "",
        price: "28",
        img_path: "/five-am-menu-designs/coffee/12-Coffee-Chocolate-72dpi.jpg"

    },

    {
        name_ar:  "قهوة تركية",
        description_ar: "",
        price: "20",
        img_path: "/five-am-menu-designs/coffee/11-Coffee-Turkish-72dp.jpg"

    },

    {
        name_ar:  "فرينش برس",
        description_ar: "",
        price: "22",
        img_path: "/five-am-menu-designs/coffee/14-Coffee-French Press-72dpi.jpg"

    },

    {
        name_ar:  "أمريكانو",
        description_ar: "",
        price: "17",
        img_path: "/five-am-menu-designs/coffee/13-Coffee-Americano-72dpi.jpg"

    },
]

export {
    coffee_en,
    coffee_ar,
}