import { Grid } from "@mui/material";

function BowlsGrid() {
    return (
        <Grid container spacing = {0.1}>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-menu-designs/Menu-Title-JPG/Menu-Title-02.jpg"/>
                </div>
            </Grid>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-shaqab-menu-designs/Bowls JPG/Bowl-01.jpg"/>
                </div>
            </Grid>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-shaqab-menu-designs/Bowls JPG/Bowl-02.jpg"/>
                </div>
            </Grid>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-shaqab-menu-designs/Bowls JPG/Bowl-03.jpg"/>
                </div>
            </Grid>
            <Grid item xs = {12}>
                <div>
                    <img style = {{width: "100%"}} src = "/sek-shaqab-menu-designs/Bowls JPG/Bowl-04.jpg"/>
                </div>
            </Grid>
        </Grid>
    )
}
export default BowlsGrid;