import styled from "styled-components";

function SidesEn({}) {
    return (
        <>
        <FlexBoxContainerStyled>
            <div>
                <span>Egg <strong style = {{marginLeft: "9px"}}>12 QR</strong></span>
            </div>
            <div style = {{marginRight: "45px"}}>Cheese <strong style = {{marginLeft: "9px"}}>12 QR</strong></div>
        </FlexBoxContainerStyled>
        <FlexBoxContainerStyled>
            <div>
                <span>Beef bacon <strong style = {{marginLeft: "9px"}}>12 QR</strong></span>
            </div>
            <div style = {{marginRight: "50px"}}>
                <span>Turkey <strong style = {{marginLeft: "9px"}}>15 QR</strong></span>
            </div>
        </FlexBoxContainerStyled>
        <FlexBoxContainerStyled>
            <div>
                <span>Salmon <strong style = {{marginLeft: "9px"}}>18 QR</strong></span>
            </div>
            <div style = {{marginRight: "39px"}}>
                <span>Avocado <strong style = {{marginLeft: "9px"}}>10 QR</strong></span>
            </div>
        </FlexBoxContainerStyled>
        <FlexBoxContainerStyled>
            <div>
                <span>Chicken <strong style = {{marginLeft: "9px"}}>15 QR</strong></span>
            </div>
            <div style = {{marginRight: "16px"}}>
                <span>Greek yogurt <strong style = {{marginLeft: "9px"}}>22 QR</strong></span>
            </div>
        </FlexBoxContainerStyled>
        <FlexBoxContainerStyled>
            <div>
                <span>Vegetables <strong style = {{marginLeft: "9px"}}>10 QR</strong></span>
            </div>
            <div style = {{marginRight: "60px"}}>
                <span>Fruits <strong style = {{marginLeft: "9px"}}>12 QR</strong></span>
            </div>
        </FlexBoxContainerStyled>
        <FlexBoxContainerStyled>
            <div>
                <span>Honey <strong style = {{marginLeft: "9px"}}>12 QR</strong></span>
            </div>
            <div style = {{marginRight: "0px"}}>
                <span>Chocolate sauce <strong style = {{marginLeft: "9px"}}>12 QR</strong></span>
            </div>
        </FlexBoxContainerStyled>
        </>
    )
}

function SidesAr({}) {
    return (
        <>
        <FlexBoxContainerArStyled>
        <div style = {{paddingRight: "45px"}}>
                <span>
                جبنة فيتا<span style = {{marginRight: "5px"}}><strong>١٢ ر.ق</strong></span>
                    </span>
            </div>
            <div>
                <span>
                    بيض<span style = {{marginRight: "5px"}}><strong>١٢ ر.ق</strong></span>
                    </span>
            </div>
            
        </FlexBoxContainerArStyled>
        <FlexBoxContainerArStyled>
        <div>
                <span>
                ديك رومي<span style = {{ marginRight: "5px"}}><strong>١٥ ر.ق</strong></span>
                    </span>
            </div>
        <div>
                <span>
                لحم بقري مقدد<span style = {{ marginRight: "5px"}}><strong>١٢ ر.ق</strong></span>
                    </span>
            </div>
        </FlexBoxContainerArStyled>
        <FlexBoxContainerArStyled>
        <div style = {{paddingRight: "30px"}}>
                <span>
                سمك سالمون<span style = {{marginRight: "5px"}}><strong>١٨ ر.ق</strong></span>
                    </span>
            </div>
        <div>
                <span>
                الأفوكادو<span style = {{marginRight: "5px"}}><strong>١٠ ر.ق</strong></span>
                    </span>
            </div>
        </FlexBoxContainerArStyled>
        <FlexBoxContainerArStyled>
        <div>
                <span>
                الدجاج<span style = {{ marginRight: "5px"}}><strong>١٥ ر.ق</strong></span>
                    </span>
            </div>
        <div>
                <span>
                الزبادي اليوناني<span style = {{ marginRight: "5px"}}><strong>٢٢ ر.ق</strong></span>
                    </span>
            </div>
        </FlexBoxContainerArStyled>
        <FlexBoxContainerArStyled>
        <div style = {{paddingRight: "42px"}}>
                <span>
                الخضار<span style = {{ marginRight: "5px"}}><strong>١٠ ر.ق</strong></span>
                    </span>
            </div>
        <div>
                <span>
                الفاكهة<span style = {{ marginRight: "5px"}}><strong>١٢ ر.ق</strong></span>
                    </span>
            </div>
        </FlexBoxContainerArStyled>
        <FlexBoxContainerArStyled>
        <div>
                <span>
                العسل<span style = {{ marginRight: "5px"}}><strong>١٢ ر.ق</strong></span>
                    </span>
            </div>
        <div>
                <span>
                صوص شكولاته<span style = {{ marginRight: "5px"}}><strong>١٢ ر.ق</strong></span>
                    </span>
            </div>
        </FlexBoxContainerArStyled>
        </>
    )
}

const FlexBoxContainerStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    font-family: "Gotham Greek Book";
    font-size: 11px;
    margin-bottom: 14px;
`

const FlexBoxContainerArStyled = styled(FlexBoxContainerStyled)`
    flex-direction: row-reverse;
    justify-content: flex-end;
    font-family: "GE SS Text"; 
    font-size: 14px;
    direction: rtl;
    gap: 50px;
`

export {
    SidesEn,
    SidesAr,
};