import styled from "styled-components";
import {CategoryHeadingStyled, SingleFoodItemIndexStyled, FoodItemImageContainer, SingleFoodItemDescription, FoodTextContainerStyled, ItemNameEn, ItemNameAr, ItemPriceStyled} from "./ChariotCommonStyled";

function IceCreamGrid({}) {
    return (
        <>
            <CategoryHeadingStyled>
                <img src = "/chariot-menu-designs/Ice Cream PNG/Chariot-IceCream-V1-04.png" alt = "Ice Cream Category chariot menu"/>
            </CategoryHeadingStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/Ice Cream PNG/Chariot-IceCream-V1-02.png" alt = "Classic Vanilla Ice cream chartiot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>CLASSIC VANILLA ICE CREAM</span>
                    </ItemNameEn>
                    <ItemNameAr>
                       <span>آيس كريم فانيلا كلاسيك</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>25 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/Ice Cream PNG/Chariot-IceCream-V1-03.png" alt = "strawberry ice cream chariot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>STRAWBERRY ICE CREAM</span>
                    </ItemNameEn>
                    <ItemNameAr>
                       <span>آيس كريم الفرولة</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>25 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/Ice Cream PNG/ice cream- rose.png" alt = "rose ice cream chariot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>ROSE ICE CREAM</span>
                    </ItemNameEn>
                    <ItemNameAr>
                      <span>آيس كريم الورد</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>25 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
            <SingleFoodItemIndexStyled>
                <FoodItemImageContainer>
                <img src = "/chariot-menu-designs/Ice Cream PNG/ice cream- mango.png" alt = "strawberry ice cream chariot menu"/>
                </FoodItemImageContainer>
                <SingleFoodItemDescription>
                <FoodTextContainerStyled>
                    <ItemNameEn>
                        <span>MANGO ICE CREAM</span>
                    </ItemNameEn>
                    <ItemNameAr>
                      <span>آيس كريم المانجو</span>
                    </ItemNameAr>
                </FoodTextContainerStyled>
                <ItemPriceStyled>
                    <span style ={{fontFamily: "Futura Std", fontWeight: "bold", fontSize: "12px"}}>25 QR</span>
                </ItemPriceStyled>
            </SingleFoodItemDescription>
            </SingleFoodItemIndexStyled>
       
        </>
    )
}

export default IceCreamGrid;