import styled from "styled-components";
// import {Link} from "react-scroll";
import {NavLink} from "react-router-dom";
import {useState, useEffect, useRef} from "react";
import {ContactForm} from "../components/"
// import $ from 'jquery';



//the bone of the website
function LsdContact({}){

    const [isDesktop, setIsDesktop] = useState(true);
    const [triggerMobileNavBar, setTriggerMobileNavBar] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [triggerSubCategory, setTriggetSubCategory] = useState(false);

    if(triggerMobileNavBar) {
        document.body.style.overflow = 'hidden';
    }
    else {
        document.body.style.overfow = 'auto';
    }
  
    function handleSubCategory(action) {
        setTriggetSubCategory(action);
    }

    //controls when to switch to mobile
    function updateScreenSize() {
        if(window.innerWidth <= 700) setIsDesktop(false);
        else setIsDesktop(true);
    }

    //to open mobile menu bar
    function handleClick() {
        if(!isDesktop) {
             setTriggerMobileNavBar(true)
            setIsClicked(prevIsClicked => !prevIsClicked);
            if(isClicked) {
                setTriggerMobileNavBar(false);
            }
     
        }
    }

    //to close menu mobile bar
    function closeMobileMneu() {
        setTriggerMobileNavBar(false);
        setIsClicked(prevIsClicked => !prevIsClicked);
        if(isClicked) {
            setTriggerMobileNavBar(false);
        }
    }

    useEffect(() => {
        if(window.innerWidth <= 700) setIsDesktop(false);
        document.body.style.overfow = 'auto';
        window.addEventListener("resize", updateScreenSize);

        return () => {
            window.removeEventListener("resize", updateScreenSize);
        }
    }, [])
    
        return(
            <>
                 <nav>
                        {!isDesktop && <MobileTestBar/>}
                        {isDesktop && <LsdNavBarStyled>
                            <ul style = {{display: "flex", gap: "30px", height: "100%", fontFamily: "Recoleta", fontSize: "35px",  paddingTop: "0px", marginTop: "30px"}}>
                                <li>
                                    <NavLink to = "/home" className = {({isActive}) => isActive? "lsd-active-link": ""}  onClick = {() => handleSubCategory(false)}> 
                                        Work
                                    </NavLink>
                                </li>
                               <li>
                                    <NavLink to = "/about" className = {({isActive}) => isActive? "lsd-active-link": ""}  onClick = {() => handleSubCategory(false)} >
                                        About
                                    </NavLink>
                               </li>
                                <li>
                                    <NavLink  to = "/contact-us" className = {({isActive}) => isActive? "lsd-active-link": ""}  onClick = {() => handleSubCategory(false)}>
                                        Contact
                                    </NavLink>
                                </li>
                                <NavBarSocialsContainerStyled>
                                    <div>
                                        <li style = {{marginLeft: "-16px", marginTop: "14px", fontSize: "20px"}}>
                                        <a href = "https://www.instagram.com/lovelysunnydaymena/" target = "_blank">
                                            Instagram
                                        </a>
                                        </li>   
                                    </div>
                                    {/* <div>
                                        <li>
                                        <a>
                                            FacebookFlogo
                                            
                                        </a>
                                        </li>   
                                    </div>
                                    <div>
                                        <li>
                                        <a>
                                            Linkedin
                                        </a>
                                        </li>
                                    </div> */}
                                </NavBarSocialsContainerStyled>
                            </ul>
                        </LsdNavBarStyled>}
                        {triggerSubCategory && <SubNavBarStyled>
                            <ul style = {{marginTop: "15px", padding: "0px 50px", display: "flex", gap: "20px", fontSize: "11px", fontFamily: "Recoleta Alt"}}>
                                <li>
                                    <a href = "#category_1.1" alt = "Lovely Sunny Day">
                                        SELECTED
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.2" alt = "Lovely Sunny Day Brand Identity projects">
                                        BRAND IDENTITY
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.3" alt = "Lovely Sunny Day Motion projects">
                                        MOTION
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.4" alt = "Lovely Sunny Day WEB projects">
                                        WEB
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.5" alt = "Lovely Sunny Day environmental projects">
                                        ENVIRONMENTAL
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.6" alt = "Lovely Sunny Day advertising projects">
                                        ADVERTISING
                                    </a>
                                </li>
                                <li>
                                    <a href = "#category_1.7" alt = "Lovely Sunny Day photography projects">
                                        PHOTOGRAPHY
                                    </a>
                                </li>
                            </ul>
                        </SubNavBarStyled>}
                    </nav>
                <PageStyled>
                    {triggerMobileNavBar && <MobileNavBarStyled>
                        <MobileNavBarFlexBoxStyled>
                           <NavBarBlockStyled>
                                <li>
                                    <NavLink to = "/home" alt = "lovely sunny day studio work section" onClick = {closeMobileMneu}>
                                        <span>Work</span>
                                    </NavLink>
                                </li>
                           </NavBarBlockStyled>
                           <NavBarBlockStyled>
                                <li>
                                    <NavLink to = "/about" alt = "lovely sunny day studio about section" onClick = {closeMobileMneu}>
                                        <span>About</span>
                                    </NavLink>
                                </li>
                           </NavBarBlockStyled>
                           <NavBarBlockStyled>
                                <li>
                                    <NavLink to = "/contact-us" alt = "lovely sunny day studio contact section" onClick = {closeMobileMneu}>
                                        <span>Contact</span>
                                    </NavLink>
                                </li>
                           </NavBarBlockStyled>
                           <MobileSocialMediaContainerStyled>
                            <div>
                                <li>
                                    <a href = "" alt = "lovely sunny day studio social media instagram link">
                                        <span>Instagram</span>
                                    </a>
                                </li>
                            </div>
                            {/* <div>
                                <li>
                                    <a href = "" alt = "lovely sunny day studio social media facebook link">
                                        <span>Facebook</span>
                                    </a>
                                </li>
                            </div>
                            <div>
                                <li>
                                    <a href = "" alt = "lovely sunny day studio social media linkedin link">
                                        <span>Linkedin</span>
                                    </a>
                                </li>
                            </div> */}
                           </MobileSocialMediaContainerStyled>
                        </MobileNavBarFlexBoxStyled>
                    </MobileNavBarStyled>}
                    <LogoContainerStyled isClickable = {!isDesktop} onClick = {handleClick} isClicked = {isClicked}>
                        <ImageContainerFrontStyled>
                            <img src = "lsd_pics/LSD-burger-1.svg"/>
                        </ImageContainerFrontStyled>
                        <ImageContainerBackStyled>
                            <img src = "lsd_pics/LSD-burger-2.svg"/>
                        </ImageContainerBackStyled>
                    </LogoContainerStyled>
                    <ContactContainerStyled>
                        <FlexBoxOneStyled>
                                <span>Lovely Sunny Day Studio</span>
                                <br/>
                                <span>Office 14, floor 8,</span>
                                <br/>
                                <span>Ariane Real Estate Tower</span>
                                <br/>
                                <span>Msheireb, Doha</span>
                                <br/>
                                <span>State of Qatar</span>
                                <br/>
                                <span>+974 55365828</span>
                                <br/>
                                <a href = "mailto:lovelies@lovelysunnyday.com">lovelies@lovelysunnyday.com</a>
                            {/* <ContactForm/> */}
                        </FlexBoxOneStyled>
                        <FlexBoxTwoStyled>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2145.0027772210124!2d51.515488323714905!3d25.289071032397914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c4c6694153bf%3A0xccc742d38914931f!2zQXJpYW5lIFJlYWwgRXN0YXRlINij2LHZitin2YYg2KfZhNi52YLYp9ix2YrYqQ!5e0!3m2!1sen!2sqa!4v1698236487152!5m2!1sen!2sqa" style = {{border: "2px solid black"}}></iframe>
                        </FlexBoxTwoStyled>
                    </ContactContainerStyled>
                </PageStyled>
            </>
        )
}

const LsdNavBarStyled = styled.div`
width: 100%;
z-index: 1;
position: fixed;
inset: 0;
background-color: white;
height: 140px;
padding: 0px 10px;
`

const MobileTestBar = styled.div`
    position: fixed;
    width: 100%;
    height: 80px;
    background-color: white;
    z-index: 1;
`

const NavBarSocialsContainerStyled = styled.div`
    position: relative;
    display: flex;
    gap: 18px;
   
    margin-left: 20px;

    li {
        font-size: 16px;
    }
`

const MobileNavBarStyled = styled.div`
    position: absolute;
    inset: 0;
    width: 100%;
    background-color: #a8d9d6;
    z-index: 1;
`

const MobileNavBarFlexBoxStyled = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: -15px;
    justify-content: center;
    gap: 3px;
    align-items: center;
`

const NavBarBlockStyled = styled.div`
    position: relative;

    span {
        font-family: "Recoleta Alt";
        font-size: 50px;
    }
`

const MobileSocialMediaContainerStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 8px;
    text-align: center;

    span {
        font-family: "Recoleta Alt";
        font-size: 25px;
    }
`

const ContactContainerStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin-top: 120px;
    width: 100%;
    padding: 10px 0px;
    line-height: 26px;


    @media(max-width: 700px) {
        flex-direction: column-reverse;
        padding: 0px 24px;
        p {
            font-size: 16px;
        }
    }
`
const FlexBoxOneStyled = styled.div`
    position: relative;
    width: 50%;

    span, a {
        font-family: "Brandon Grotesque";
        font-size: 20px;
    }

    @media (max-width: 700px) {
        width: 100%;
    }
`
const FlexBoxTwoStyled = styled.div`
    position: relative;
    width: 50%;
    height: 400px;
    /* border: 5px solid black; */

    img {
        width: 100%;
    }

    iframe {
        width: 100%;
        height: 100%;
    }

    @media(max-width: 700px) {
        width: 100%;
    }

`

const PageStyled = styled.div`
    position: relative;
    width: 100%;
    padding: 10px 55px;

    @media(max-width: 700px) {
        padding: 10px 0px;
    }
`

const LogoContainerStyled = styled.div`
    position: fixed;
    right: 55px;
    top: 36px;
    width: 65px;
    height: 65px;
    z-index: 10;
    transform-style: preserve-3d;
    transition: transform .5s;

    ${({isClicked}) => 
        isClicked &&
        `
          transform: rotateY(180deg);
        `
    }

    @media(max-width: 700px) {
        right: 24px;
        top: 25px;
       
    }
`

const ImageContainerFrontStyled = styled.div`
    position: absolute;
    width: 100%;
    backface-visibility: hidden;

    img {
        width: 100%;
    }
`

const ImageContainerBackStyled = styled.div`
    position: absolute;
 
    backface-visibility: hidden;
    transform: rotateY(180deg);

    img {
        width: 100%;
    }
`
const SubNavBarStyled = styled.div`
    position: fixed;
    top: 40px;
    z-index: 1;

    a {
        cursor: pointer;
    }
`

export {
    LsdContact,
    LogoContainerStyled,
    ImageContainerFrontStyled,
    ImageContainerBackStyled,
}