let salads = [

    {
        name: "QUINOA SALAD",
        description: "QUINOA WHITE, CUCUMBER, MINT, POMEGRANATE, KALMATA OLIVES, FETA CHEESE, CHERRY TOMATOES WITH HONEY MUSTARD",
        price_en: "33",
        img_path: "/wcsNew-menu.designs/salads/Salads-01- quinoa salad.jpg",
    },

    {
        name: "PASTA SALAD",
        description: "FUSILLI, KALAMATA OLIVES, PARMESAN CHEESE WITH MAYO TOMATO SAUCE",
        price_en: "39",
        img_path: "/wcsNew-menu.designs/salads/Salads-02- pasta salad.jpg",
    },

    {
        name: "BUCKWHEAT SALAD",
        description: "SWEET CORN, GREEN CAPSICUM, TONKATSU, OLIVE OIL, HONEY, KALE, LEMON WITH HONEY MUSTARD",
        price_en: "36",
        img_path: "/wcsNew-menu.designs/salads/Salads-03- buckwheat salad.jpg",
    },
]

let saladsAr = [
    
    {
        name_ar: "سلطة الكينوا ",
        description_ar: "الكينوا البيضاء , خيار, نعناع, رمان, زيتون كالاماتا, فيتا, طماطم كرزية, نكهة الخردل بالعسل",
        price_en: "39",
        img_path: "/wcsNew-menu.designs/salads/Salads-02- pasta salad.jpg",
    },

    {
        name_ar: "سلطة الباستا ",
        description_ar: "معكرونة فوسيلي, زيتون كالاماتا, جبنة بارميزان صوص  مايونيز وصلصة طماطم",
        price_en: "33",
        img_path: "/wcsNew-menu.designs/salads/Salads-01- quinoa salad.jpg",
    },

    {
        name_ar: "سلطة الحنطة السوداء ",
        description_ar: "ذرة حلوه, الفليفلة الخضراء, صلصة تونكاتسو, زيت الزيتون, عسل, كرنب, ليمون أصفر نكهة الخردل بالعسل ",
        price_en: "36",
        img_path: "/wcsNew-menu.designs/salads/Salads-03- buckwheat salad.jpg",
    },
]

export  {
    salads,
    saladsAr,
};