import styled from "styled-components";

function Cover() {
    return(
        <>
        </>
        
    )
}

export default Cover;