import styled from "styled-components";

const GridPageStyled = styled.div`
    position: fixed;
    inset: 0;
    width: 100%;
    background-color: white;
    z-index: 2;
    
`

const GridContainerStyled = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 140px 40px;
    

 
    /* margin-top: 80px; */
    overflow: auto;
    z-index: 0;


  @media(max-width: 700px) {

    padding: 140px 24px;
  }
    
`

const GridTitleContainerStyled = styled.div`
    position: fixed;
    inset:0;
    padding: 20px 40px;
    width: 100%;
    background-color: white;
    height: 140px;
    font-family: "Recoleta";
    font-weight: 600;
    z-index: 10;

    @media(max-width: 700px) {
        z-index: 10;
}
`

const GridImageContainerStyled = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    /* box-shadow: 4px 4px 10px grey;
    border: 3px solid rgba(128, 128, 128, 0.4); */
    img {
        width: 100%;
        height: 100%;
    }
`

const ButtonContainerStyled = styled.div`
    position: fixed;
    cursor: pointer;
    right: 50px;
    width: 50px;
    height: 50;
    z-index: 1;
    

    img {
        width: 100%;
    }
`

const ComingSoonTempStyled = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    span {
        font-family: "Recoleta Alt"
    }
`


export {
    GridPageStyled,
    GridContainerStyled,
    GridTitleContainerStyled,
    GridImageContainerStyled,
    ButtonContainerStyled,
    ComingSoonTempStyled,
}