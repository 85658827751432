import styled from "styled-components";
import { FillerContainerStyled, CategoryHeaderStyled, ItemContainerStyled, ItemEnglishTitleStyled, ItemImageContainerStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, CategoryContainerStyled, ArabicContainerStyled, ItemArabicTitleStyled, ItemArabicDescriptionStyled } from "../lbMenu/CommonStyled";


function CarteSaladEv({}) {
    return (
        <>
            <CategoryContainerStyled>
                <CategoryHeaderStyled>
                    <span>Salad</span>
                </CategoryHeaderStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/salad/le bleu-salad-burrata.png" alt = " Burrata Salad"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        Burrata Salad
                        <PriceContainerStyled>
                            96
                            <span>QR</span>
                        </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Fresh burrata cheese, cherry tomatoes, sherry vinaigrette, basil, red currant, extra virgin olive oil</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        سلطة البوراتا
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        جبن بوراتا، طماطم كرزية، خل كرز، أوراق ريحان صغيرة، وزيت زيتون
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/salad/le bleu-salad-caeser salad.png" alt = "caesar salad"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        Caesar Salad
                        <PriceContainerStyled>
                            59
                            <span>QR</span>
                        </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Baby lettuce, caesar dressing, chicken breast, baguette bread, garlic, parmesan cheese</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        سلطة السيزر
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                            <p>خس، صلصة السيزر، صدر الدجاج، جبن البرميزان، خبز محمص مع الثوم</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
                <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = "/leBleu-menu-designs/A la carte/salad/le bleu-salad-roasted beetroot.png" alt = "roasted beetroot"/>
                    </ItemImageContainerStyled>
                    <ItemEnglishTitleStyled>
                        Roasted Beetroot Salad
                        <PriceContainerStyled>
                            62
                            <span>QR</span>
                        </PriceContainerStyled>
                    </ItemEnglishTitleStyled>
                    <ItemEnglishDescriptionStyled>
                        <p>Roasted beets, goat cheese puree, orange gel, citrus vinaigrette</p>
                        <p>Add: Chicken / 16 QR • Shrimps / 20 QR</p>
                    </ItemEnglishDescriptionStyled>
                    <ArabicContainerStyled>
                        <ItemArabicTitleStyled>
                        سلطة الشمندر
                        </ItemArabicTitleStyled>
                        <ItemArabicDescriptionStyled>
                        شمندر محمص مع جبنة الماعز المخفوق مع جل البرتقال
                        <p>اضافة: الدجاج / ١٦ ر.ق • الروبيان / ٢٠ ر.ق</p>
                        </ItemArabicDescriptionStyled>
                    </ArabicContainerStyled>
                    <FillerContainerStyled/>
                </ItemContainerStyled>
            </CategoryContainerStyled>
        </>
    )
}


export default CarteSaladEv;