import styled from "styled-components";

function WorkPhotography({}) {
    return (
        <>
            <WorkPhotographyStyled>
                <div>
                    work photography section goes here
                </div>
            </WorkPhotographyStyled>
        </>
    )
}

const WorkPhotographyStyled = styled.div`
    position: relative;
   
`

export default WorkPhotography;