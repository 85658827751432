import styled from "styled-components";

function WorkBrandIdentity({}) {
    return (
        <>
            <WorkBrandIdentityStyled>
                <div>
                    work brand identity section goes here
                </div>
            </WorkBrandIdentityStyled>
        </>

    )
}

const WorkBrandIdentityStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

`

export default WorkBrandIdentity;