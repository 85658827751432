import { afternoon_full_menu, full_menu_pastry_ensemble, full_menu_scones, full_menu_selection_of_premium_tea_coffee, enhanced_menu_afternoon_tea_expereince } from "../itemsData";
import { CategoryContainerStyled, FillerContainerStyled } from "../CommonStyled";
import { CategoryHeaderStyled, CategoryTitleArStyled, CategoryTitleEnglishStyled, ItemComponentStyled, ItemTextArStyled, ItemTextEnStyled, AwarenessComponentStyled, AwarnessLineOneStyled, AwarnessLineTwoStyled, AfternoonImageCoverStyled } from "./AfternoonCommonStyled";



function AfternoonEnhancedMenu({}) {
    return (
        <>
            <AfternoonImageCoverStyled>
                <img src = "/leBleu-menu-designs/LB-AfternoonTea.png"/>
            </AfternoonImageCoverStyled>
    
            <CategoryContainerStyled>
                <CategoryHeaderStyled style = {{color: "#006580", fontFamily: "Futura Std Book"}}>
                    <span>ENHANCED MENU</span>
                    <br/>
                    <span style = {{fontWeight: "bold"}}>285 QR</span>
                </CategoryHeaderStyled>
                {
                    afternoon_full_menu.map(({name_en, name_ar}) => {
                        return (
                            <ItemComponentStyled>
                                <ItemTextArStyled>
                                    <span>{name_ar}</span>
                                </ItemTextArStyled>
                                <ItemTextEnStyled>
                                    <span>{name_en}</span>
                                </ItemTextEnStyled>
                            </ItemComponentStyled>
                        )
                    })
                }
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <CategoryHeaderStyled>
                    <CategoryTitleArStyled>
                        <span>المعجنات</span>
                    </CategoryTitleArStyled>
                    <CategoryTitleEnglishStyled>
                        <span>Pastry Ensemble</span>
                    </CategoryTitleEnglishStyled>
                </CategoryHeaderStyled>
                {
                    full_menu_pastry_ensemble.map(({name_en, name_ar}) => {
                        return (
                            <ItemComponentStyled>
                                <ItemTextArStyled>
                                    <span>{name_ar}</span>
                                </ItemTextArStyled>
                                <ItemTextEnStyled>
                                    <span>{name_en}</span>
                                </ItemTextEnStyled>
                            </ItemComponentStyled>
                        )
                    })
                }
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <CategoryHeaderStyled>
                    <CategoryTitleArStyled>
                        <span>سكونز</span>
                    </CategoryTitleArStyled>
                    <CategoryTitleEnglishStyled>
                        <span>Scones</span>
                    </CategoryTitleEnglishStyled>
                </CategoryHeaderStyled>
                {
                    full_menu_scones.map(({name_en, name_ar}) => {
                        return (
                            <ItemComponentStyled>
                                <ItemTextArStyled>
                                    <span>{name_ar}</span>
                                </ItemTextArStyled>
                                <ItemTextEnStyled>
                                    <span>{name_en}</span>
                                </ItemTextEnStyled>
                            </ItemComponentStyled>
                        )
                    })
                }
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <CategoryHeaderStyled>
                    <CategoryTitleArStyled>
                        <span>تشكيلة استثنائية من الشاي او القهوة</span>
                    </CategoryTitleArStyled>
                    <CategoryTitleEnglishStyled>
                        <span>Selection of Premium Tea or Coffee</span>
                    </CategoryTitleEnglishStyled>
                </CategoryHeaderStyled>
                {
                    full_menu_selection_of_premium_tea_coffee.map(({name_en, name_ar}) => {
                        return (
                            <ItemComponentStyled>
                                <ItemTextArStyled>
                                    <span>{name_ar}</span>
                                </ItemTextArStyled>
                                <ItemTextEnStyled>
                                    <span>{name_en}</span>
                                </ItemTextEnStyled>
                            </ItemComponentStyled>
                        )
                    })
                }
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <CategoryHeaderStyled>
                    <CategoryTitleArStyled>
                        <span>عزز تجربتك للافترنون تي</span>
                    </CategoryTitleArStyled> 
                    <CategoryTitleEnglishStyled>
                        <span>Enhance your Afternoon Tea Experience</span>
                    </CategoryTitleEnglishStyled>
                </CategoryHeaderStyled>
                {
                    enhanced_menu_afternoon_tea_expereince.map(({name_en, name_ar}) => {
                        return (
                            <ItemComponentStyled>
                                <ItemTextArStyled>
                                    <span>{name_ar}</span>
                                </ItemTextArStyled>
                                <ItemTextEnStyled>
                                    <span>{name_en}</span>
                                </ItemTextEnStyled>
                            </ItemComponentStyled>
                        )
                    })
                }
                    <AwarenessComponentStyled>
                        <AwarnessLineOneStyled>
                            <span>Max party size is 2 guest per set</span>
                        </AwarnessLineOneStyled>
                        <AwarnessLineTwoStyled>
                            <p>If you have any concerns regarding food allergies, please alert your server prior to ordering
                                [v]-Vegetarian, (N)-Contains Nuts, (S)-Contains Shellfish, (G)-Contains Gluten, (D)-Contains Diary
                            </p>
                        </AwarnessLineTwoStyled>
                </AwarenessComponentStyled>
                <FillerContainerStyled/>
            </CategoryContainerStyled>
        </>
    )
}

export default AfternoonEnhancedMenu;