import styled from "styled-components";
import { GridPageStyled, GridContainerStyled, GridTitleContainerStyled, GridImageContainerStyled, ButtonContainerStyled, ComingSoonTempStyled } from "./GridCommanStyles";
import {Grid} from "@mui/material";
import {LogoContainerStyled, ImageContainerFrontStyled, ImageContainerBackStyled} from "../../pages/Lsd";


function FlatStoneGrid({setTriggerGrid, setIsClicked, isClicked}) {

  return (
    <>
            <GridPageStyled>
                <GridTitleContainerStyled>
                Flatstone Burger Joint
                </GridTitleContainerStyled>
                <GridContainerStyled>
                    <Grid container spacing = {2} rowSpacing={2}>
                        <Grid item xs = {12} sm = {12}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-01.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {5}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-12.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {7}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-14.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {8}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-02.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {4}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-03.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {4}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-04.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {8}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-05.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {12}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-07.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {5}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-08.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {7}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-09.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {8}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-10.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {4}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-11.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {12}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-06.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {6}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-13.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {6}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-15.jpg"/>
                            </GridImageContainerStyled>
                        </Grid>
                        <Grid item xs = {12} sm = {12}>
                            <GridImageContainerStyled>
                                <img src = "/lsd_pics/flatstone-grid/LSD-Website-Flatstone-16.jpg" style = {{marginBottom: "20px"}}/>
                            </GridImageContainerStyled>
                        </Grid>
                      </Grid>
                </GridContainerStyled>
            </GridPageStyled>
    </>
)
}



export default FlatStoneGrid;