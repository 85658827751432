import styled from "styled-components";
import { GridPageStyled, GridContainerStyled, GridTitleContainerStyled, GridImageContainerStyled, ButtonContainerStyled } from "./GridCommanStyles";
import {Grid} from "@mui/material";
import {LogoContainerStyled, ImageContainerFrontStyled, ImageContainerBackStyled} from "../../pages/Lsd";


function SekGrid({}) {

    return (
        <>
                <GridPageStyled>
                    <GridTitleContainerStyled>
                    Shawarma El Khebbez
                    </GridTitleContainerStyled>
                    <GridContainerStyled>
                        <Grid container spacing = {2} rowSpacing={2}>
                            <Grid item xs = {12} sm = {12}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-01.png"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {8}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1_P1.png"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-03.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-04.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-05.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-06.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-07.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-08.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-09.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-17.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-16.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-15.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {6}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-13.png"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {6}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-14.png"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-10.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-11.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                            <Grid item xs = {12} sm = {4}>
                                <GridImageContainerStyled>
                                    <img src = "/lsd_pics/sek grid/CN-Website-SEK-V1-12.jpg"/>
                                </GridImageContainerStyled>
                            </Grid>
                       
                        </Grid>
                    </GridContainerStyled>
                </GridPageStyled>
        </>
    )
}

export default SekGrid;