import { Grid } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import sides from "../../../data/ltbCategories/sides";
import {Test, ItemContainerStyled, ItemImageContainerStyled, EnglishArabicFlexStyled, ItemDescriptionContainerStyled, ItemEnglishTitleStyled, PriceContainerStyled, ItemEnglishDescriptionStyled, ArabicContainerStyled, ItemArabicDescriptionStyled, ItemArabicTitleStyled} from "./generalStyles"

function SideDishesGrid() {
       
    const [showDish, setShowDish] = useState(-1);
    function handleClick(id) {
        setShowDish(id);
    }

    if(showDish < 0) {
        return (
            <Grid container spacing = {1} rowSpacing = {0} paddingTop = {3} paddingBottom = {3} width = {"95%"}>
                <Grid item xs = {6}>
                    <div  onClick = {() => handleClick("0")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Side Dishes JPG/Grid JPG/Fries Side dishes.jpg"/>
                    <div className="dish-title">French Fries</div>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div onClick = {() => handleClick("1")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Side Dishes JPG/Grid JPG/Gratin Potato Side dishes.jpg"/>
                    <div className="dish-title">Gratin Potato</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "2" onClick = {() => handleClick("2")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Side Dishes JPG/Grid JPG/Green asparagus Side dishes.jpg"/>
                    <div className="dish-title">Green Asparagus</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "3" onClick = {() => handleClick("3")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Side Dishes JPG/Grid JPG/Green beans Side dishes.jpg"/>
                    <div className="dish-title">Green Beans</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "4" onClick = {() => handleClick("4")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Side Dishes JPG/Grid JPG/Mashed potato Side dishes.jpg"/>
                    <div className="dish-title">Mashed Potato</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "5" onClick = {() => handleClick("5")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Side Dishes JPG/Grid JPG/Steamed rice Side dishes.jpg"/>
                    <div className="dish-title">Steamed French Rice</div>
                    </div>
                </Grid>
            </Grid>
        )
    }

    if(showDish >= 0) {
        return (
            <Test>
            <div className = "dish-heading">
                Side Dishes
            </div>
            <ItemContainerStyled>
                    <ItemImageContainerStyled>
                        <img src = {sides[showDish].img_src}/>
                    </ItemImageContainerStyled>
                    <EnglishArabicFlexStyled>
                        <ItemDescriptionContainerStyled>
                            <ItemEnglishTitleStyled>
                                {sides[showDish].name_en}
                                <PriceContainerStyled>
                                    {sides[showDish].price} <span>QR</span>
                                </PriceContainerStyled>
                            </ItemEnglishTitleStyled>
                            <ItemEnglishDescriptionStyled>
                                {sides[showDish].description_en}
                            </ItemEnglishDescriptionStyled>
                            <ArabicContainerStyled>
                                <ItemArabicTitleStyled>
                                    {sides[showDish].name_ar}
                                </ItemArabicTitleStyled>
                                <ItemArabicDescriptionStyled>
                                    {sides[showDish].description_ar}
                                </ItemArabicDescriptionStyled>
                            </ArabicContainerStyled>
                        </ItemDescriptionContainerStyled>
                    </EnglishArabicFlexStyled>
                </ItemContainerStyled>
                <div className = "dish-close-icon" onClick ={()=>setShowDish(-1)}>
                    <FontAwesomeIcon icon = {faXmark}/>
                </div>
        </Test>
        )
    }
}

export default SideDishesGrid;